import { useFileTask } from "file/task"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { State } from "lib/apprise-frontend-core/state/api"
import { usePirs } from "pir/api"
import { usePirsFilesAndPictures } from "pir/api/fileAndPictures"
import { usePirCall } from "pir/calls"
import { Pir, PirEdting, PirExtendedUpload } from "pir/model"
import { pirmodelapi } from "pir/model/api"
import { PirStateObject } from "pir/state"
import { usePirTasks } from "pir/tasks"
import { useProcess } from "process/api"
import { onRegisterProps, onResolveProcessProps, Process, ProcessHandlers } from "process/model"
import { newProcess, newTask } from "process/model/api"
import { useResolveProcess } from "process/model/processes"
import { toastApi } from "toast/api"

export const SUBMIT_PROCESS_TYPE = 'pirSubmit'

export const SUBMIT_VERIFICATION_PROCESS_TYPE = 'pirSubmitVerify'

export const useSubmitProcess = () => {
  const pirs = usePirsFilesAndPictures()
  const {t} = useIntl()
  const {createFileUploadTask} = useFileTask()

  const self = {
    create: async (p:PirEdting, extended:PirExtendedUpload, state:State<PirStateObject>) => {

      let processApi = newProcess(SUBMIT_PROCESS_TYPE, pirmodelapi(p).id(), t('process.submit'))
      const {pictures, attachments} = await pirs.getPirExtendedFilesForUpload(p)
      
      pictures.forEach(f=>{
        processApi.addTask(createFileUploadTask({
          url:`/offline-api/submit-file/${pirmodelapi(p).getPirFormId()}/${f.url}`,
          subtype: f.contentType,
          name: f.filename,
          subject: `${pirmodelapi(p).id()}`,
          body: f.url,
          description: t('task.uploadPicture', {name:f.filename})
        }))
      })

      attachments.forEach(f=>{
        processApi.addTask(createFileUploadTask({
          url:`/offline-api/submit-file/${pirmodelapi(p).getPirFormId()}/${f.url}`,
          subtype: f.contentType,
          name: f.filename,
          subject: `${pirmodelapi(p).id()}`,
          body: f.url,
          description: t('task.uploadAttachment', {name:f.filename})
        }))
      })
    
      return processApi.process
    }
  }
  return self
}
  
export const getPirSubmitProcesses = (pir:Pir, ps:Process[]) => {
  const processes = ps.filter(p=>p.type===SUBMIT_PROCESS_TYPE && p.subject===pirmodelapi(pir).id())
  return processes ? processes[0] : null
}

export const usePirSubmitProcessInitializer = () => {
  const processes = useProcess()
  const call = usePirCall()
  const pirs = usePirs()
  const resolveProcessApi = useResolveProcess()
  const {t} = useIntl()
  const {createVerifyPirSubmitTask} = usePirTasks()

  const createSubmitVerificationProcess = (p:Pir) => {

    let processApi = newProcess(SUBMIT_VERIFICATION_PROCESS_TYPE, pirmodelapi(p).id(), t('process.verify'))
    processApi.addTask(createVerifyPirSubmitTask(p))
  
    return processApi.process
  }

  return {
    [SUBMIT_PROCESS_TYPE] : {  
      onResolve: async ({process}:onResolveProcessProps) => {
        const processes_snapshot = processes.all()
        try {
          const pir = pirs.find(process.subject)
          
          if(!pir){
            throw new Error(`couldn't get the pir from process subject ${process.subject}`)
          }

          const submitVerificationProcess = createSubmitVerificationProcess(pir)

          await processes.add(submitVerificationProcess)
          await resolveProcessApi.defaulResolve({process})
        } catch (error) {
          await processes.setAll(processes_snapshot)
        }
      }
    },
    [SUBMIT_VERIFICATION_PROCESS_TYPE] : {
      onResolve: async ({process}:onResolveProcessProps) => {
          const pir = pirs.find(process.subject)
          pir && await pirs.setAsSubmitted(pir)
          await resolveProcessApi.defaulResolve({process})
          pir && await toastApi().create({
              duration: 5000,
              color:"success",
              message: t('toast.pirSubmitted', {vessel:pirmodelapi(pir).title()})})
      }
    }
  } as ProcessHandlers
}

export const getPirSubmitVerificationProcesses = (pir:Pir, ps:Process[]) => {
const processes = ps.filter(p=>p.type===SUBMIT_VERIFICATION_PROCESS_TYPE && p.subject===pirmodelapi(pir).id())
return processes ? processes[0] : null
}
