import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { Loading } from 'components/Loading';
import { addOutline, removeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Autosizer from "react-virtualized-auto-sizer";
import './pdf.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const zoomStep = 0.5
const maxZoom = 3.0
const minZoom = 1.0


export const PDFViewer = ({data}) => {
    
    const [loading, setLoading] = useState(true)
    const [pages, setPages] = useState(0)
    const [zoom, setZoom] = useState(1.0)

    const incrementZoom = () => {
        setZoom(zoom + zoomStep)
    }

    const decrementZoom = () => {
        setZoom(zoom - zoomStep)
    }
    
    return <>
        <div className="pdf-file-wrapper">
            <Autosizer disableHeight>
                {({width}) => (
                    
                        <Document
                        key={`width-${width}`}
                        loading={<Loading spinning={true} duration={10000} msg={'preparing document'}/>}
                        onLoadSuccess={({numPages})=>setPages(numPages)}
                        file={data}>
                        
                        {Array.from(Array(pages), (_, i) => i+1).map(p=>{
                            return <Page key={`width-${width}-page${p}`} scale={zoom}
                            width={width} pageNumber={p} />  
                        })}
                
                        </Document>
                )}
            </Autosizer>
        </div>
        
        <IonFab className="pdf-zoom" horizontal="end" vertical="bottom" slot="fixed">
            <IonFabButton show onClick={decrementZoom} disabled={zoom===minZoom} color="light">
                <IonIcon icon={removeOutline}/>
            </IonFabButton>
            <IonFabButton show onClick={incrementZoom} disabled={zoom===maxZoom} color="light">
                <IonIcon icon={addOutline}/>
            </IonFabButton>
        </IonFab>
    </>
}