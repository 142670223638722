import { IonIcon, IonProgressBar } from '@ionic/react'
import { pencilOutline } from 'ionicons/icons'
import { steps } from 'pages/pir/Steps'
import { Pir } from 'pir/model'
import { pirmodelapi } from 'pir/model/api'
import React from 'react'

import './InspectionProgressBar.scss'

type Props = {
    pir:Pir
}

export const PirInspectionProgressBar = ({pir}:Props) => {

    const signing = pirmodelapi(pir).isSigning()

    const keys = steps.filter(s=>s.isForm).map(s=>s.key)
    const completed = pir.inspectionstatus && Object.keys(pir.inspectionstatus)
    .filter(k=>keys.includes(k))
    .filter(k=>k)

    const percentage = (completed && completed.length >0) ? (completed.length/keys.length) : 0.01

    const percentageNormailised = percentage * 0.8

    return <div className="pir-inspection-progress-bar">
        <div className="signature">
            <IonIcon icon={pencilOutline} />
        </div>
        <IonProgressBar color={signing ? 'warning' : undefined} value={percentageNormailised} className=""/>
    </div>
}