import { State } from 'apprise-frontend-core/state/api';
import { createContext } from 'react';
import { Config, defaultConfig } from './model';




export const ConfigContext = createContext<State<ConfigState>>(undefined!)

export type ConfigState = {

    static: Partial<Config>
    remote: Partial<Config>
}

export const initialConfigState : ConfigState = {

    static: defaultConfig,
    remote: undefined!
}