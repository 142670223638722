import { IonButton, IonIcon, IonItem, IonList, IonListHeader, useIonPopover } from "@ionic/react";
import { alert, information, informationCircle } from "ionicons/icons";
import { InspectionError } from "pir/model";
import React from "react";
import { ItemDescription } from "./Description";
import './Item.scss'

type Props = React.ComponentProps<typeof IonItem> & {
    children,
    error?:InspectionError<any>,
    description?,
    className?,
    mandatory?
    fixedDescription?
}

export const Item = ({error, description, className, children, mandatory, disabled, fixedDescription, ...props}:Props) => {
    
    const hasError = error && error.length >0 && error[0].message

    const classNames = `item ${className} ${hasError ? 'with-error' : ''}`

    const ErrorPopover = ({ onHide }) => {
        return <IonList className="pir-inspection-item-popover error">
            {error && error.length === 1 && <IonListHeader color="danger">{error[0] && error[0].message}</IonListHeader>}
            {error && error.length > 1 && error.map(e=><IonItem key={e.message as string} color="danger">{e.message}</IonItem>)}
        </IonList>
    };

    const [presentError, dismissError] = useIonPopover(ErrorPopover, { onHide: () => dismissError() });

    const InfoPopover = ({ onHide }) => {
        return <IonList className="pir-inspection-item-popover info">
            <IonListHeader color="info">{description}</IonListHeader>
        </IonList>
    };

    const [presentInfo, dismissInfo] = useIonPopover(InfoPopover, { onHide: () => dismissInfo() });

    return <div className={`pir-inspection-item ${disabled?'disabled':''}`}>

        {hasError && <div className="button-wrapper">
            <IonButton onClick={(e) => presentError({event: e.nativeEvent})} className="error-toggle" color="danger" shape="round" size="small"><IonIcon slot="icon-only" icon={alert}/></IonButton>
        </div>}
        {mandatory && <div className="mandatory">*</div>}
        <IonItem className={classNames} disabled={disabled} {...props}>
            {children}
        </IonItem>
        {description && fixedDescription && <ItemDescription>{description}</ItemDescription>}
        {description && !fixedDescription && <div className="button-wrapper right">
            <IonButton fill="outline" onClick={(e) => presentInfo({event: e.nativeEvent})} className="info-toggle" color="dark" shape="round" size="small"><IonIcon slot="icon-only" icon={information}/></IonButton>
        </div>}
    </div>
}