import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonLabel, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react"
import { Item } from "components/Item"
import { checkmarkOutline, close } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { Pir } from "pir/model"
import React, { useState } from "react"
import { useUser } from "user/api"

type Props = {
    show:boolean,
    onClose:(start?:boolean) => void,
    pir:Pir
}

export const StartTheInspectionModal = ({show, onClose:_onClose, pir}:Props) => {

    const userapi = useUser()

    const user = userapi.current()

    const [name, setName] = useState(user?.inspector?.name || pir.extended?.pir.monitoringDetails?.principalInspectorName)

    const onClose = (name?) => {
        if(name && user){
            userapi.mutate(u=>u.inspector.name = name).then(()=>_onClose(true))
        }
    }

    const {t} = useIntl()
    return <IonModal mode="ios" swipeToClose
    isOpen={show}
    onDidDismiss={()=>_onClose(false)}
    cssClass='my-custom-class'>
    <IonHeader>
        <IonToolbar>
            <IonTitle>{t('inspection.start.modal.title')}</IonTitle>
            <IonButtons slot="end">
                <IonButton onClick={()=>_onClose(false)}>
                    <IonIcon slot="icon-only" icon={close}/>
                </IonButton>
            </IonButtons>
        </IonToolbar>
    </IonHeader>
    <IonContent fullscreen>
            <Item lines="none">
                <IonText>
                <small>{t('inspection.start.modal.subtitle')}</small>
                </IonText>
            </Item>
            <Item>
                <IonLabel><strong>{t('inspection.start.modal.inspectorName')}</strong></IonLabel>
                <IonInput value={name} onIonChange={e=>setName(e.detail.value!)}></IonInput>
            </Item>
    </IonContent>

    <IonFooter className="ion-no-border">
        <IonToolbar mode="md">
            <IonButtons slot="end">
                <IonButton onClick={()=>_onClose(false)}>{t('inspection.start.modal.cancel')}</IonButton>
                <IonButton disabled={!name} color="primary" onClick={()=>onClose(name)}>{t('inspection.start.modal.start')} <IonIcon icon={checkmarkOutline}/></IonButton>
            </IonButtons>
        </IonToolbar>
    </IonFooter>
    
</IonModal>
}