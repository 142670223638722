import { createContext } from 'react';
import { State } from '../state/api';
import { defaultIntlConfiguration, IntlConfiguration, Language } from "./model";



export const InltContext = createContext<State<IntlState>>(undefined!)

export type IntlState = {


    // an integration point for a translation function.
    t: (msg: string, ...args: any[]) => string

    // the current language
    language: Language

    // an integration point for pasing the language to a third-party implementation.
    changeLanguage: (_: Language) => Promise<any>

    // internal configuration: used when there's not configuration support, or until there is.
    config: IntlConfiguration

}

// fill is for an implementation that doesn't exist or hasn't kicked in yet. 
export const initialIntlState: IntlState = {

    t: (msg: string) => msg  // does nothing.
    ,
    changeLanguage: () => Promise.resolve()     // does nothing.
    ,
    language: undefined!  
    ,
    config: defaultIntlConfiguration

}