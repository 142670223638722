import { IonIcon, IonProgressBar } from '@ionic/react'
import { Process, taskStatuses, taskStatusesProperties } from 'process/model'
import { processModelApi } from 'process/model/api'
import React from 'react'
import './ProcessViewer.scss'


type Props = {process:Process, showErrorIcon?}

export const ProcessMiniViewer = ({process, showErrorIcon}:Props) => {

    const percentage = processModelApi(process).getPercentage()/100
    const status = processModelApi(process).getStatus()

    return  <div className="process-mini-viewer-wrapper">
                {status === taskStatuses.REJECTED && showErrorIcon && 
                <div className="error-icon">
                    <IonIcon icon={taskStatusesProperties[status].icon} />
                </div>
                }
            <IonProgressBar
                className="process-mini-viewer"
                type={taskStatusesProperties[status].percentageType}
                color={taskStatusesProperties[status].color}
                value={percentage}
                />
            </div>
}