

export type Config = {

   
  mode: typeof process.env.NODE_ENV
  buildMode: typeof process.env.NODE_ENV
  baseUrl: string
  

} & Record<string,any>


export const defaultConfig: Config = {

    mode: 'development',
    buildMode: process.env.NODE_ENV,
    baseUrl: process.env.PUBLIC_URL ??  ''

}