import { IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react';
import { useVirtual } from 'react-virtual';
import AutoSizer from 'react-virtualized-auto-sizer';
import Highlighter from "react-highlight-words";

type Props = {
    options:string[],
    value?:string,
    onClick:(s:string)=>void
}
export const TypeAheadList = ({options, value, onClick}:Props) => {

  return (
    <AutoSizer disableWidth>
    {({height}) => (
      <List options={options} value={value} onClick={onClick} height={height}/>
    )}
    </AutoSizer>
  );
}

type ListProps = Props & {
    height:number
}
const List = ({height, options, value, onClick}:ListProps) => {

    let parentRef:React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

    const rowVirtualizer = useVirtual({
        size: options.length,
        parentRef,
        estimateSize: React.useCallback(() => 46, []),
        overscan: 5
    });
    
    return <div
        ref={parentRef}
        className="List"
        style={{
          minHeight: '200px',
          height: height > 200 ? `${height}px` : "200px",
          overflow: "auto"
        }}
      >
        <IonList
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: "100%",
            position: "relative"
          }}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => {

                return <IonItem
                    detail={false}
                    button
                    onClick={()=>onClick(options[virtualRow.index])}
                    key={virtualRow.index}
                    className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`
                    }}>
                   
                    <IonLabel>
                    <Highlighter
                      highlightClassName="strong"
                      searchWords={[value]}
                      autoEscape={true}
                      textToHighlight={options[virtualRow.index]}
                    />
                    </IonLabel>
                </IonItem>
            }
          )}
        </IonList>
      </div>
}