import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonSearchbar, IonTitle, IonToolbar } from "@ionic/react"
import { close } from "ionicons/icons"
import React from "react"
import { TypeAheadList } from "./List"
import { modalController } from "@ionic/core"

type Props = {
    show,
    onClose: () => void,
    title?,
    onClick:(s:string)=>void,
    options:string[],
    value?:string,
    onChange: (v:string) => void 
}
export const TypeAheadModal = ({show, onClose, title, value, options, onClick, onChange}:Props) => {

    const form:any = React.useRef(null);

    const onShow = ()=>{
        form.current && form.current.setFocus()
    }

    return <IonModal mode="ios" swipeToClose
        onDidPresent={onShow}
        onDidDismiss={()=>onClose()}
        isOpen={show}
        cssClass='my-custom-class'>
        <IonHeader>
            <IonToolbar>
                {title && <IonTitle>{title}</IonTitle>}
                <IonButtons slot="end">
                    <IonButton onClick={()=>onClose()}>
                        <IonIcon slot="icon-only" icon={close}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonToolbar>
                <form onSubmit={onClose}>
                <IonSearchbar ref={form} enterkeyhint="done" showCancelButton="never" searchIcon="none" debounce={0} value={value} onIonChange={e=>onChange(e.detail.value!)}/>
                </form>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <TypeAheadList value={value} options={options} onClick={onClick}/>
        </IonContent>
        
    </IonModal>
}