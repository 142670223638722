import { b64ToFile, resizeFile } from "file/api"
import { FormAttachmentPicture, FormPicture, RemoteFile } from "pir/model"
import { PirFileImageBase64, PirFileImageHandler } from "."

export const isImageContentType = (contentType) => contentType && contentType.indexOf('image') > -1
export const isPDF = (contentType) => contentType.indexOf('application/pdf') > -1

export const getFileBase64 = (file, contentType) => file.indexOf('data:') === 0 ? file : `data:${contentType};base64,${file}`

export const filemodelapi = (f:File) => {
    const self = {
        aaa: () => null
    }
    return self
}

/**
    * Format bytes as human-readable text.
    * 
    * @param bytes Number of bytes.
    * @param si True to use metric (SI) units, aka powers of 1000. False to use 
    *           binary (IEC), aka powers of 1024.
    * @param dp Number of decimal places to display.
    * 
    * @return {value, unit, label}.
*/
export const humanFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        const value = bytes
        const unit = 'B'
        return {
            value,
            unit,
            label: value + ' ' + unit
        }
    }

    const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    const value = bytes.toFixed(dp)
    const unit = units[u]

    return {
        value,
        unit,
        label: value + ' ' + unit
    }
}

export const pirfileapi = (file:PirFileImageBase64|PirFileImageHandler) => {
    const self = {
        src: () => file.type === "image" ? URL.createObjectURL(file.content) : file.content

        ,

        is: (p:RemoteFile | FormAttachmentPicture | FormPicture) => {
            return (p as RemoteFile).downloadLink ?
                file.url === (p as RemoteFile).downloadLink
            :
                file.url === (p as FormAttachmentPicture).uid
        }

        ,

        resize: async () => {
            if(file.type === "image"){
                return await resizeFile(file.content)
            }else{
                const blob = await b64ToFile(file.content, file.filename, file.contentType)
                return await resizeFile(blob)
            }
        }
    }
    return self
}