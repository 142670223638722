import { useForm } from "lib/apprise-frontend-core/utils/form"
import React from "react"
import { TypeAheadModal } from "./Modal"
import './Select.scss'
import { TypeAheadViewer } from "./Viewer"

type Props = {
    value?:string,
    onChange:(s?:string)=>void,
    options:string[],
    title?:string,
    placeholder?:string,
    showOptionsAlways?:boolean
}

export const TypeAheadWrapper = ({value, onChange, title, options, placeholder:_placeholder, showOptionsAlways}:Props) => {
    const placeholder = _placeholder || 'Type to get suggestions...'
    const [show, setShow] = React.useState(false)
    const {edited, set, reset} = useForm(value)

    const editedLowercase = edited && edited.toLowerCase && edited?.toLowerCase()

    const setSelected = (v) => set.to(v)
    
    const onClose = () => {
        onChange(edited)
        setShow(false)
    }

    const onClick = (s) => {
        setSelected(s)
        onChange(s)
        setShow(false)
    }

    const filtered = editedLowercase && editedLowercase.length > 0 ? options.filter(o=>o.toLowerCase().indexOf(editedLowercase) > -1) : (showOptionsAlways ? options : [])

    return <>
        <TypeAheadViewer onOpen={()=>setShow(true)}
        placeholder={placeholder} value={edited}/>
        
        <TypeAheadModal
        onClose={onClose}
        onClick={onClick}
        onChange={setSelected}
        value={edited}
        options={filtered.length > 0 ? filtered : (edited ? [edited] : [])}
        title={title}
        show={show}/>
    </>
}

export const TypeAhead = (props:Props) => 
    <TypeAheadWrapper key={`${props.title}${props.value}`} {...props} />
