import { Step } from "components/Step/model";
import _ from 'lodash';
import moment from "moment";
import { User } from "user/model";
import { getNumericId } from "utils";
import { v4 as uuid } from 'uuid';
import { Attachment, contentTypes, FishingAuthorization, FishingAuthorizationUpload, Form, FormAttachment, FormAttachmentUpload, Pir, PirExtended, PirExtendedPirUpload, PirExtendedRemote, PirExtendedUpload, PirInspectionErrors, PirInspectionSignatures, RemoteFile, RemoteFormAttachment, RemotePir, RemoteTranshipmentInfo, signatureStepKey, Status, Statuses, statuses, TranshipmentAuthorization, TranshipmentAuthorizationUpload, TranshipmentInfo, TranshipmentInfoContent, typesOfForm, VesselPicture } from ".";
import produce from 'immer'

export const dayAfterArchivation = 25

export const pirmodelapi = (pir: Pir) => {
  
  const self = {
    
    pir,

    id: () => pir.fileId
    
    ,
    
    title: () => pir.vesselIdentity.vesselName
    
    ,
    
    hasId: (id: string | number) => pir.fileId === getNumericId(id)
    
    ,
    
    isReady: () => pir.status === statuses.ready
    
    ,

    isPreparing: () => pir.status === statuses.preparing
    
    ,

    hasStatusMoreThan: (s:Status) => Statuses[statuses[pir.status]].order > Statuses[statuses[s]].order
    
    ,

    hasStatusLessThan: (s:Status) => Statuses[statuses[pir.status]].order < Statuses[statuses[s]].order
    
    ,
    
    isInspecting: () => pir.status === statuses.inspecting
    
    ,
    
    isNotReady: () => pir.status === statuses.notready
    
    ,

    isSigning: () => pir.status === statuses.signing

    ,

    isReadyToSubmit: () => pir.status === statuses.readytosubmit

    ,

    isSubmitting: () => pir.status === statuses.submitting

    ,

    isSubmitted: () => pir.status === statuses.submitted

    ,

    isToArchive: () => {

      if(self.isSubmitted() && pir.lifecycle && pir.lifecycle[statuses.submitted] && pir.lifecycle[statuses.submitted].date){ 
        const archivationDate = moment(pir.lifecycle[statuses.submitted].date).add(dayAfterArchivation, 'days')
        const now = moment()
        console.log('now')
        if(now.isAfter(archivationDate)){
          console.log('to archive')
          return true
        }else{
          return false
        }
      }
      return false
    }
    ,

    inspectionStarted: () => {
      return pir.lifecycle && pir.lifecycle[statuses.inspecting] && pir.lifecycle[statuses.inspecting].date ? true : false
    }

    ,

    formsCompleted: (currentStep:Step) => currentStep.key === signatureStepKey || self.isSigning() || self.isReadyToSubmit() || self.isSubmitting() || self.isSubmitted()

    ,

    removeInspectionInfo: () => {
      const newPir:Pir = {
        ...pir,
        inspectionErrors: {} as PirInspectionErrors,
        inspectionSignatures: {},
        inspectionstatus: {}
      }
      const newPir2:Pir = produce(newPir, p=>{
        if(p.lifecycle && p.lifecycle[statuses.inspecting]){
          delete p.lifecycle[statuses.inspecting]
        }
      }) 
      
      return newPir2
    }

    ,


    hasBeenClaimedBefore: () => {
      return pir.lifecycle && pir.lifecycle[statuses.ready] ? true : false
    },

    updateStatus: (newStatus:Status) => {
      const newPir = {
        ...pir,
        status:newStatus,
        lifecycle:{
          ...(pir.lifecycle || {}), [newStatus]:{
            date:new Date().toISOString(),
            previousStatus:pir.status}
        }
      } as Pir
      return newPir
    }

    ,

    getPirFormId: () => {
      return pir.offlinePendingForms.find(f=>f.typeOfForm === 'PIR')?.id || self.id()
    }

    ,

    //this will be used to retrieve the list of files for a specific form
    getForms: () => {
      //get rar file
      const rar: Form[] = pir.rarLink ? [pir.rarLink].map(f => ({ 
        name: f.name,
        typeOfForm: typesOfForm.RAR,
        submitDate: null,
        attachments: [],
        downloadLink: f.downloadLink,
        contentType: f.contentType
      })) : []

      return [
        ...rar,
        ...pir.submitedForms
      ]
    }
    
    ,

    getFiles: () => {
      let files:RemoteFile[] = []
      
      self.getForms().forEach((f)=>{
        files.push(getFileFromForm(f))
        f.attachments.forEach(a=>files.push(getFileFromAttachment(a)))
      })

      pir.vesselPictures.forEach(p=>{
        files.push(getFileFromVesselPicture(p))
      })

      return files
    }
    
    ,

    findFile: (id:string) => self.getFiles().find(f=>getFileId(f) === id)
    
    ,

    getFormTypes: (files:Form[] | undefined) => {
      let map = {};
      (files || self.getForms()).forEach(f=>{
        map[f.typeOfForm] = true
      })
      return Object.keys(map) as (keyof typeof typesOfForm)[]
    }
    
    ,

    hasBeenClaimedBy: (u:User) => pir.claimedByDevice === u.deviceName
    
    ,

    isVailablefor: (u:User) => !pir.claimedByDevice || self.hasBeenClaimedBy(u)

    ,

    getFormPictures: () => pir.extended?.pictures || []
  }

  return self
}

export const pirsModelApi = (pirs:Pir[]) => {
  const self = {

    find: (id:string|number) => {
      const pir = pirs.find(p=>pirmodelapi(p).hasId(id))
      return pir && pirmodelapi(pir)
    }
        
    ,

    haveStatusMoreThan: (s:Status) => pirsModelApi(pirs.filter(p=>pirmodelapi(p).hasStatusMoreThan(s)))

    ,
    
    ready: () => pirsModelApi(pirs.filter(p=>pirmodelapi(p).isReady()))
    
    ,
    
    preparing: () => pirsModelApi(pirs.filter(p=>pirmodelapi(p).isPreparing()))
    
    ,
    
    inspecting: () => pirsModelApi(pirs.filter(p=>pirmodelapi(p).isInspecting()))
    
    ,

    signing: () => pirsModelApi(pirs.filter(p=>pirmodelapi(p).isSigning()))
    
    ,

    submitting: () => pirsModelApi(pirs.filter(p=>pirmodelapi(p).isReadyToSubmit() || pirmodelapi(p).isSubmitting()))
    
    ,

    submitted: () => pirsModelApi(pirs.filter(p=>pirmodelapi(p).isSubmitted()))

    ,

    notready: () => pirsModelApi(pirs.filter(p=>pirmodelapi(p).isNotReady()))

    ,

    ports: () => {
      const ports = {}
      pirs.forEach(p=>ports[p.portOfArrival+""] = p.portOfArrival)
      return Object.keys(ports).map(k=>ports[k])
    }

    ,

    filterByText: (text?:string) => pirsModelApi(!text ? pirs : pirs.filter(p=>{
      return `${p.code}${pirmodelapi(p).title()}`.toLowerCase().indexOf(text.toLowerCase()) > -1
    }))

    ,

    filterByPorts: (ports?:number[]) => pirsModelApi((!ports || ports.length === 0) ? pirs : pirs.filter(p=>ports.includes(p.portOfArrival)))

    ,

    otherThan: (_pirs:Pir[]) => {
      const ids = _pirs.map(p=>pirmodelapi(p).id())
      return pirsModelApi(pirs.filter(p=>!ids.includes(pirmodelapi(p).id())))
    }

    ,

    pirs
  }

  return  self
}

const images = ['jpg', 'JPEG', 'JPG', 'png', 'TIFF', 'tiff', 'jpeg']
export const getRemoteFileContentType = (file:RemoteFile) => {
  if(!file.contentType){
    const ext = file.downloadLink.split('.').pop() || ''
    if(images.includes(ext)){
      return `image/${ext}`
    }
    return 'application/pdf'
  }
  return file.contentType
}

export const getFileFromForm = ({name, tagName, id, description, downloadLink, contentType}:Form) => ({name, tagName, id, description, downloadLink, contentType})

export const getFileFromAttachment = ({name, tagName, id, description, downloadLink, contentType}:Attachment) => ({name, tagName, id, description, downloadLink, contentType})

export const getFileFromVesselPicture = ({name, tagName, id, description, downloadLink, contentType}:VesselPicture) => ({name, tagName, id, description, downloadLink, contentType})

export const getFileId = (f:RemoteFile) =>
  f.name || `${f.id}`

export const remoteToPir = (p: RemotePir) => ({ ...p, status: statuses.notready } as Pir)

export const mergeRemoteToPir = (remote: RemotePir, internal: Pir) => {
  const _remote = remoteToPir(remote)

  let pir:Pir = {
    ..._remote,
    status: internal.status,
    lifecycle: internal.lifecycle,
    extended: internal.extended,
    inspectionstatus: internal.inspectionstatus,
    inspectionErrors: internal.inspectionErrors,
    inspectionSignatures: internal.inspectionSignatures
  }

  return pir
}

const convertRemoteTranshipmentInfoToInternal = (r:RemoteTranshipmentInfo) => {
  const {species, productForm, catchArea, quantity, objection, comments, ...properties} = r
  const content: TranshipmentInfoContent = {species, productForm, catchArea, quantity, objection, comments}
  const n:TranshipmentInfo = {
    ...properties, content: [content]
  }
  return n
}

const convertRemoteTranshipmentInfosToInternal = (data:RemoteTranshipmentInfo[]) => {
  //group data by location vessel, flag, id, date
  const map:{[key:string]:TranshipmentInfo} = {}
  const getKey = (r:RemoteTranshipmentInfo) => 
    [r.date,r.flagState,r.idNumber,r.name, r.isAtPort, r.atPort, r.atSea, r.srcForm]
    .map(v=>v||'').join('|')
  
  data.forEach(d=>{
    const k = getKey(d)
    const {species, productForm, catchArea, quantity, objection, comments, id, ...properties} = d
    const content: TranshipmentInfoContent = {species, productForm, catchArea, quantity, objection, comments, id}
    if(!map[k]){
      map[k] = {
        ...properties,
        content:[]
      }
    }
    map[k].content.push(content)
  })

  return Object.keys(map).map(k=>map[k])
}

const convertInternalTranshipmentInfoToRemote = (info:TranshipmentInfo) => {
  const remote:RemoteTranshipmentInfo[] = []
  const {content, ...properties} = info
  info.content.forEach(c=>{
    remote.push({
      ...properties,
      ...c
    })
  })
  return remote
}

export const convertExtendedRemoteToInternal = (p:PirExtendedRemote) => {
  const {pir, ...properties} = p
  const transhipmentInfo:TranshipmentInfo[] = convertRemoteTranshipmentInfosToInternal(p.pir.transhipmentInfoDonorVessel)
  const r:PirExtended = {
    ...properties,
    pir: {
      ...pir,
      transhipmentInfo
    }
  }
  return r
}

export const convertExtendedInternalToRemote = (p:PirExtended) => {
  const {pir:pirextended, ...properties} = p
  const {transhipmentInfo, ...pir} = pirextended
  const transhipmentInfoDonorVessel:RemoteTranshipmentInfo[] = p.pir.transhipmentInfo.reduce((a,b)=>[...a, ...convertInternalTranshipmentInfoToRemote(b)], <RemoteTranshipmentInfo[]>[])
  const r:PirExtendedRemote = {
    ...properties,
    pir: {
      ...pir,
      transhipmentInfoDonorVessel
    }
  }
  return r
}

const convertTranshipmentAuthorizationsInternalToUpload:(t:TranshipmentAuthorization)=>TranshipmentAuthorizationUpload = ({attachment, ...t}:TranshipmentAuthorization) => {
  return {
    ...t,
    attachment:attachment ? convertFormAttachmentToUpload(attachment) : null
  }
}

const convertFishingAuthorizationInternalToUpload:(t:FishingAuthorization)=>FishingAuthorizationUpload = ({attachment, ...t}:FishingAuthorization) => {
  return {
    ...t,
    attachment:attachment ? convertFormAttachmentToUpload(attachment) : null
  }
}

export const convertExtendedRemoteToUpload:(p:PirExtendedRemote, signatures:PirInspectionSignatures)=>PirExtendedUpload = ({pictures, vessel, attachments, pir:pirremote}, signatures) => {

  const {fishingAuthorizations, transhipmentAuthorizations,monitoredLanTrxAttachments, ...pir} = pirremote
 
  const pirupload:PirExtendedPirUpload = {
    ...pir,
    fishingAuthorizations: fishingAuthorizations.map(t=>convertFishingAuthorizationInternalToUpload(t)),
    transhipmentAuthorizations: transhipmentAuthorizations.map(t=>convertTranshipmentAuthorizationsInternalToUpload(t)),
    monitoredLanTrxAttachments: monitoredLanTrxAttachments.map(a=>convertFormAttachmentToUpload(a))
  }
  return {
    pir:pirupload,
    vessel,
    pictures,
    attachments:attachments ? attachments.map(a=>convertFormAttachmentToUpload(a)) : [],
    signatures
  }
}

export const isRemoteFormAttachment = (f:FormAttachment|RemoteFormAttachment) =>{
  const isRemote = (f as RemoteFormAttachment).documentTag !== undefined
  return isRemote
}

export const convertFormAttachmentToUpload:((f:FormAttachment|RemoteFormAttachment)=>FormAttachmentUpload) = (f:FormAttachment|RemoteFormAttachment) => {
  const isRemote = isRemoteFormAttachment(f)
  return {
    uid:f.uid,
    type:isRemote ? (f as RemoteFormAttachment).documentTag : (f as FormAttachment).type,
    name:isRemote ? getAttachmentName(f as FormAttachment) : (f as RemoteFormAttachment).name,
    offline:!isRemote
  } as FormAttachmentUpload
}

export const getAttachmentName = (f:FormAttachment)=>`${f.type}_${f.uid}.pdf`


export const createFakePir = ({
  id = uuid(),
  name = "aaaa",
  status = statuses.notready
}: { id?: string, name?: string, status?: Status }) => {
  const pir: Pir = {
    status,
    fileId: parseInt(id),
    code: "NFNLKBB_SEA KING TEST_SGP_20200904_7194",
    createDate: "2020-09-04",
    lastUpdateDate: "2020-09-28",
    creator: {
      id: 445,
      name: "iotc"
    },
    vesselIdentity: {
      vesselName: name,
      flagState: "Singapore",
      flagStateId: 195,
      iotcId: "13269",
      imoId: "",
      ircs: "IRCSNEW",
      mmsi: "",
      vesselId: 4123
    },
    fileAttachements: [],
    //file types excel, doc, zip
    submitedForms: [
      {
        name: "AREP_SEA-KING-TEST_SINGAPOUR_20200904",
        typeOfForm: "AREP",
        contentType: "application/pdf",
        //cancelled ? 
        attachments: [],
        submitDate: "2020-09-04",
        downloadLink: "arep/public/16608"
      },
      {
        name: "RAIAREP_SEA-KING-TEST_SINGAPORE_20200928-1",
        typeOfForm: "RAIAREP",
        attachments: [
          {
            name: "RAIAREP_AUTF_SEA-KING-TEST_SINGAPORE_20200928.JPEG",
            downloadLink: "/vfd/downloadAttachment/7195/0",
            contentType: "image/jpeg",
            fileType: "Authorisation to Fish/licence issued by the flag State",
            fileTypeId: 2
          },
          {
            name: "RAIAREP_LANA_SEA-KING-TEST_SINGAPORE_20200928.JPG",
            downloadLink: "/vfd/downloadAttachment/7195/1",
            contentType: "image/jpeg",
            fileType: "Landing authorisation/permit",
            fileTypeId: 6
          }
        ],
        submitDate: "2020-09-28",
        downloadLink: "raiarep/public/16610"
      }
    ],
    offlinePendingForms: [
      {
        id: 16611,
        name: "PIR_SEA-KING-TEST_SINGAPORE_20200928",
        typeOfForm: "PIR"
      }
    ],
    rarLink: {
      name: "Risk Assessment Report",
      downloadLink: "/vfd/7195/vair.pdf",
      contentType: "application/pdf",
      fileType: null,
      fileTypeId: null
    },
    vesselPictures: [
      {
        id: 1083,
        contentType: "image/jpeg",
        description: "MyPhoto",
        photoTakingDate: "2021-02-08",
        tagId: 4,
        tagName: "Photograph of vessel - Starboard Side Bow",
        downloadLink: "https://images.unsplash.com/photo-1575893240675-17e719ffa7c5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
      },
      {
        id: 1043,
        contentType: "image/jpeg",
        description: "MyPhoto 2",
        photoTakingDate: "2021-02-08",
        tagId: 4,
        tagName: "Photograph of vessel - Starboard Side Bow",
        downloadLink: "https://images.unsplash.com/photo-1545566239-0b2fb5c50bc6?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
      }
    ],
    claimedByDevice: null,
    inspectionComment: 'Some comments about the inspection to come',
    portOfArrival: 65813,
    startOfInspection: moment().add(_.random(1, 12, false), 'days').toDate(),
    expectedArrival: moment().add(_.random(1, 12, false), 'days').toDate()
  }
  return pir
}