export const flags = [
    {
      "id": 1,
      "code": "ABW",
      "nameEn": "Aruba",
      "nameFr": "Aruba"
    },
    {
      "id": 2,
      "code": "AFG",
      "nameEn": "Afghanistan",
      "nameFr": "Afghanistan"
    },
    {
      "id": 3,
      "code": "AGO",
      "nameEn": "Angola",
      "nameFr": "Angola"
    },
    {
      "id": 4,
      "code": "AIA",
      "nameEn": "Anguilla",
      "nameFr": "Anguilla"
    },
    {
      "id": 5,
      "code": "ALA",
      "nameEn": "Aland Islands",
      "nameFr": "Aland Islands"
    },
    {
      "id": 6,
      "code": "ALB",
      "nameEn": "Albania",
      "nameFr": "Albania"
    },
    {
      "id": 7,
      "code": "AND",
      "nameEn": "Andorra",
      "nameFr": "Andorra"
    },
    {
      "id": 8,
      "code": "ANT",
      "nameEn": "Netherlands Antilles",
      "nameFr": "Antilles Néerlandaises"
    },
    {
      "id": 9,
      "code": "ARE",
      "nameEn": "United Arab Emirates",
      "nameFr": "Émirats Arabes Unis"
    },
    {
      "id": 10,
      "code": "ARG",
      "nameEn": "Argentina",
      "nameFr": "Argentine"
    },
    {
      "id": 11,
      "code": "ARM",
      "nameEn": "Armenia",
      "nameFr": "Armenie"
    },
    {
      "id": 12,
      "code": "ASM",
      "nameEn": "American Samoa",
      "nameFr": "American Samoa"
    },
    {
      "id": 13,
      "code": "ATA",
      "nameEn": "Antarctica",
      "nameFr": "Antarctica"
    },
    {
      "id": 14,
      "code": "ATF",
      "nameEn": "French Southern Territories",
      "nameFr": "French Southern Territories"
    },
    {
      "id": 15,
      "code": "ATG",
      "nameEn": "Antigua and Barbuda",
      "nameFr": "Antigua and Barbuda"
    },
    {
      "id": 16,
      "code": "AUS",
      "nameEn": "Australia",
      "nameFr": "Australie"
    },
    {
      "id": 17,
      "code": "AUT",
      "nameEn": "Austria (EU)",
      "nameFr": "Autriche (UE)"
    },
    {
      "id": 18,
      "code": "AZE",
      "nameEn": "Azerbaijan",
      "nameFr": "Azerbaijan"
    },
    {
      "id": 19,
      "code": "BDI",
      "nameEn": "Burundi",
      "nameFr": "Burundi"
    },
    {
      "id": 20,
      "code": "BEL",
      "nameEn": "Belgium (EU)",
      "nameFr": "Belgique (UE)"
    },
    {
      "id": 21,
      "code": "BEN",
      "nameEn": "Benin",
      "nameFr": "Benin"
    },
    {
      "id": 22,
      "code": "BFA",
      "nameEn": "Burkina Faso",
      "nameFr": "Burkina Faso"
    },
    {
      "id": 23,
      "code": "BGD",
      "nameEn": "Bangladesh",
      "nameFr": "Bangladesh"
    },
    {
      "id": 24,
      "code": "BGR",
      "nameEn": "Bulgaria (EU)",
      "nameFr": "Bulgaria (EU)"
    },
    {
      "id": 25,
      "code": "BHR",
      "nameEn": "Bahrain",
      "nameFr": "Bahrain"
    },
    {
      "id": 26,
      "code": "BHS",
      "nameEn": "Bahamas",
      "nameFr": "Bahamas"
    },
    {
      "id": 27,
      "code": "BIH",
      "nameEn": "Bosnia and Herzegovina",
      "nameFr": "Bosnia and Herzegovina"
    },
    {
      "id": 28,
      "code": "BLM",
      "nameEn": "Saint Barthélemy",
      "nameFr": "Saint Barthélemy"
    },
    {
      "id": 29,
      "code": "BLR",
      "nameEn": "Belarus",
      "nameFr": "Belarus"
    },
    {
      "id": 30,
      "code": "BLZ",
      "nameEn": "Belize",
      "nameFr": "Bélize"
    },
    {
      "id": 31,
      "code": "BMU",
      "nameEn": "Bermuda",
      "nameFr": "Bermuda"
    },
    {
      "id": 32,
      "code": "BOL",
      "nameEn": "Bolivia",
      "nameFr": "Bolivia"
    },
    {
      "id": 33,
      "code": "BRA",
      "nameEn": "Brazil",
      "nameFr": "Brazil"
    },
    {
      "id": 34,
      "code": "BRB",
      "nameEn": "Barbados",
      "nameFr": "Barbados"
    },
    {
      "id": 35,
      "code": "BRN",
      "nameEn": "Brunei Darussalam",
      "nameFr": "Brunei Darussalam"
    },
    {
      "id": 36,
      "code": "BTN",
      "nameEn": "Bhutan",
      "nameFr": "Bhutan"
    },
    {
      "id": 37,
      "code": "BVT",
      "nameEn": "Bouvet Island",
      "nameFr": "Bouvet Island"
    },
    {
      "id": 38,
      "code": "BWA",
      "nameEn": "Botswana",
      "nameFr": "Botswana"
    },
    {
      "id": 39,
      "code": "CAF",
      "nameEn": "Central African Republic",
      "nameFr": "Central African Republic"
    },
    {
      "id": 40,
      "code": "CAN",
      "nameEn": "Canada",
      "nameFr": "Canada"
    },
    {
      "id": 41,
      "code": "CCK",
      "nameEn": "Cocos (Keeling) Islands",
      "nameFr": "Cocos (Keeling) Islands"
    },
    {
      "id": 42,
      "code": "CHE",
      "nameEn": "Switzerland",
      "nameFr": "Switzerland"
    },
    {
      "id": 43,
      "code": "CHL",
      "nameEn": "Chile",
      "nameFr": "Chile"
    },
    {
      "id": 44,
      "code": "CHN",
      "nameEn": "China",
      "nameFr": "China"
    },
    {
      "id": 45,
      "code": "CIV",
      "nameEn": "Côte d'Ivoire",
      "nameFr": "Côte d'Ivoire"
    },
    {
      "id": 46,
      "code": "CMR",
      "nameEn": "Cameroon",
      "nameFr": "Cameroon"
    },
    {
      "id": 47,
      "code": "COD",
      "nameEn": "Congo, the Democratic Republic of the",
      "nameFr": "Congo, the Democratic Republic of the"
    },
    {
      "id": 48,
      "code": "COG",
      "nameEn": "Congo",
      "nameFr": "Congo"
    },
    {
      "id": 49,
      "code": "COK",
      "nameEn": "Cook Islands",
      "nameFr": "Isles Cook"
    },
    {
      "id": 50,
      "code": "COL",
      "nameEn": "Colombia",
      "nameFr": "Colombie"
    },
    {
      "id": 51,
      "code": "COM",
      "nameEn": "Comoros",
      "nameFr": "Comores"
    },
    {
      "id": 52,
      "code": "CPV",
      "nameEn": "Cape Verde",
      "nameFr": "Cape Verde"
    },
    {
      "id": 53,
      "code": "CRI",
      "nameEn": "Costa Rica",
      "nameFr": "Costa Rica"
    },
    {
      "id": 54,
      "code": "CUB",
      "nameEn": "Cuba",
      "nameFr": "Cuba"
    },
    {
      "id": 55,
      "code": "CXR",
      "nameEn": "Christmas Island",
      "nameFr": "Christmas Island"
    },
    {
      "id": 56,
      "code": "CYM",
      "nameEn": "Cayman Islands",
      "nameFr": "Cayman Islands"
    },
    {
      "id": 57,
      "code": "CYP",
      "nameEn": "Cyprus",
      "nameFr": "Cyprus"
    },
    {
      "id": 58,
      "code": "CZE",
      "nameEn": "Czech Republic (EU)",
      "nameFr": "Czech Republic (EU)"
    },
    {
      "id": 59,
      "code": "DEU",
      "nameEn": "Germany (EU)",
      "nameFr": "Germany (UE)"
    },
    {
      "id": 60,
      "code": "DJI",
      "nameEn": "Djibouti",
      "nameFr": "Djibouti"
    },
    {
      "id": 61,
      "code": "DMA",
      "nameEn": "Dominica",
      "nameFr": "Dominica"
    },
    {
      "id": 62,
      "code": "DNK",
      "nameEn": "Denmark (EU)",
      "nameFr": "Denmark (UE)"
    },
    {
      "id": 63,
      "code": "DOM",
      "nameEn": "Dominican Republic",
      "nameFr": "Dominican Republic"
    },
    {
      "id": 64,
      "code": "DZA",
      "nameEn": "Algeria",
      "nameFr": "Algerie"
    },
    {
      "id": 65,
      "code": "ECU",
      "nameEn": "Ecuador",
      "nameFr": "Ecuador"
    },
    {
      "id": 66,
      "code": "EGY",
      "nameEn": "Egypt",
      "nameFr": "Egypte"
    },
    {
      "id": 67,
      "code": "ERI",
      "nameEn": "Eritrea",
      "nameFr": "Érythrée"
    },
    {
      "id": 68,
      "code": "ESH",
      "nameEn": "Western Sahara",
      "nameFr": "Western Sahara"
    },
    {
      "id": 69,
      "code": "ESP",
      "nameEn": "Spain (EU)",
      "nameFr": "Espagne (UE)"
    },
    {
      "id": 70,
      "code": "EUR",
      "nameEn": "European Union",
      "nameFr": "European Union"
    },
    {
      "id": 71,
      "code": "EST",
      "nameEn": "Estonia (EU)",
      "nameFr": "Estonia (UE)"
    },
    {
      "id": 72,
      "code": "ETH",
      "nameEn": "Ethiopia",
      "nameFr": "Ethiopia"
    },
    {
      "id": 73,
      "code": "FIN",
      "nameEn": "Finland (EU)",
      "nameFr": "Finland (UE)"
    },
    {
      "id": 74,
      "code": "FJI",
      "nameEn": "Fiji",
      "nameFr": "Fiji"
    },
    {
      "id": 75,
      "code": "FLK",
      "nameEn": "Falkland Islands (Malvinas)",
      "nameFr": "Falkland Islands (Malvinas)"
    },
    {
      "id": 76,
      "code": "FRA",
      "nameEn": "France (EU)",
      "nameFr": "France (UE)"
    },
    {
      "id": 77,
      "code": "FRAT",
      "nameEn": "France (Territories)",
      "nameFr": "France (Territoires)"
    },
    {
      "id": 78,
      "code": "FRO",
      "nameEn": "Faroe Islands",
      "nameFr": "Îles Feroé"
    },
    {
      "id": 79,
      "code": "FSM",
      "nameEn": "Micronesia, Federated States of",
      "nameFr": "Micronesia, Federated States of"
    },
    {
      "id": 80,
      "code": "GAB",
      "nameEn": "Gabon",
      "nameFr": "Gabon"
    },
    {
      "id": 81,
      "code": "GBR",
      "nameEn": "United Kingdom (EU)",
      "nameFr": "Royaume Uni (UE)"
    },
    {
      "id": 82,
      "code": "GEO",
      "nameEn": "Georgia",
      "nameFr": "Georgie"
    },
    {
      "id": 83,
      "code": "GGY",
      "nameEn": "Guernsey",
      "nameFr": "Guernsey"
    },
    {
      "id": 84,
      "code": "GHA",
      "nameEn": "Ghana",
      "nameFr": "Ghana"
    },
    {
      "id": 85,
      "code": "GIB",
      "nameEn": "Gibraltar",
      "nameFr": "Gibraltar"
    },
    {
      "id": 86,
      "code": "GIN",
      "nameEn": "Guinea",
      "nameFr": "Guinée"
    },
    {
      "id": 87,
      "code": "GLP",
      "nameEn": "Guadeloupe",
      "nameFr": "Guadeloupe"
    },
    {
      "id": 88,
      "code": "GMB",
      "nameEn": "Gambia",
      "nameFr": "Gambie"
    },
    {
      "id": 89,
      "code": "GNB",
      "nameEn": "Guinea-Bissau",
      "nameFr": "Guinea-Bissau"
    },
    {
      "id": 90,
      "code": "GNQ",
      "nameEn": "Equatorial Guinea",
      "nameFr": "Guinée équatoriale"
    },
    {
      "id": 91,
      "code": "GRC",
      "nameEn": "Greece (EU)",
      "nameFr": "Greece (EU)"
    },
    {
      "id": 92,
      "code": "GRD",
      "nameEn": "Grenada",
      "nameFr": "Grenada"
    },
    {
      "id": 93,
      "code": "GRL",
      "nameEn": "Greenland",
      "nameFr": "Greenland"
    },
    {
      "id": 94,
      "code": "GTM",
      "nameEn": "Guatemala",
      "nameFr": "Guatemala"
    },
    {
      "id": 95,
      "code": "GUF",
      "nameEn": "French Guiana",
      "nameFr": "French Guiane"
    },
    {
      "id": 96,
      "code": "GUM",
      "nameEn": "Guam",
      "nameFr": "Guam"
    },
    {
      "id": 97,
      "code": "GUY",
      "nameEn": "Guyana",
      "nameFr": "Guyane"
    },
    {
      "id": 98,
      "code": "HKG",
      "nameEn": "Hong Kong",
      "nameFr": "Hong Kong"
    },
    {
      "id": 99,
      "code": "HMD",
      "nameEn": "Heard Island and McDonald Islands",
      "nameFr": "Heard Island and McDonald Islands"
    },
    {
      "id": 100,
      "code": "HND",
      "nameEn": "Honduras",
      "nameFr": "Honduras"
    },
    {
      "id": 101,
      "code": "HRV",
      "nameEn": "Croatia (EU)",
      "nameFr": "Croatia (EU)"
    },
    {
      "id": 102,
      "code": "HTI",
      "nameEn": "Haiti",
      "nameFr": "Haiti"
    },
    {
      "id": 103,
      "code": "HUN",
      "nameEn": "Hungary (EU)",
      "nameFr": "Hungaire (UE)"
    },
    {
      "id": 104,
      "code": "IDN",
      "nameEn": "Indonesia",
      "nameFr": "Indonésie"
    },
    {
      "id": 105,
      "code": "IMN",
      "nameEn": "Isle Of Man",
      "nameFr": "Ile De Man\n\t\nîle de Man"
    },
    {
      "id": 106,
      "code": "IND",
      "nameEn": "India",
      "nameFr": "Inde"
    },
    {
      "id": 107,
      "code": "GBRT",
      "nameEn": "United Kingdom OT (BIOT)",
      "nameFr": "Royaume Uni (TOM)"
    },
    {
      "id": 108,
      "code": "IRL",
      "nameEn": "Ireland (EU)",
      "nameFr": "Irlande (UE)"
    },
    {
      "id": 109,
      "code": "IRN",
      "nameEn": "Iran, Islamic Republic of",
      "nameFr": "Iran, Islamic Republic of"
    },
    {
      "id": 110,
      "code": "IRQ",
      "nameEn": "Iraq",
      "nameFr": "Irak"
    },
    {
      "id": 111,
      "code": "ISL",
      "nameEn": "Iceland",
      "nameFr": "Islande"
    },
    {
      "id": 112,
      "code": "ISR",
      "nameEn": "Israel",
      "nameFr": "Israel"
    },
    {
      "id": 113,
      "code": "ITA",
      "nameEn": "Italy (EU)",
      "nameFr": "Italie (UE)"
    },
    {
      "id": 114,
      "code": "JAM",
      "nameEn": "Jamaica",
      "nameFr": "Jamaica"
    },
    {
      "id": 115,
      "code": "JEY",
      "nameEn": "Jersey",
      "nameFr": "Jersey"
    },
    {
      "id": 116,
      "code": "JOR",
      "nameEn": "Jordan",
      "nameFr": "Jordan"
    },
    {
      "id": 117,
      "code": "JPN",
      "nameEn": "Japan",
      "nameFr": "Japan"
    },
    {
      "id": 118,
      "code": "KAZ",
      "nameEn": "Kazakhstan",
      "nameFr": "Kazakhstan"
    },
    {
      "id": 119,
      "code": "KEN",
      "nameEn": "Kenya",
      "nameFr": "Kenya"
    },
    {
      "id": 120,
      "code": "KGZ",
      "nameEn": "Kyrgyzstan",
      "nameFr": "Kyrgyzstan"
    },
    {
      "id": 121,
      "code": "KHM",
      "nameEn": "Cambodia",
      "nameFr": "Cambodge"
    },
    {
      "id": 122,
      "code": "KIR",
      "nameEn": "Kiribati",
      "nameFr": "Kiribati"
    },
    {
      "id": 123,
      "code": "KNA",
      "nameEn": "Saint Kitts and Nevis",
      "nameFr": "Saint Kitts et Nevis"
    },
    {
      "id": 124,
      "code": "KOR",
      "nameEn": "Korea_Republic of",
      "nameFr": "Corée_République de"
    },
    {
      "id": 125,
      "code": "KWT",
      "nameEn": "Kuwait",
      "nameFr": "Kuwait"
    },
    {
      "id": 126,
      "code": "LAO",
      "nameEn": "Lao People's Democratic Republic",
      "nameFr": "Lao People's Democratic Republic"
    },
    {
      "id": 127,
      "code": "LBN",
      "nameEn": "Lebanon",
      "nameFr": "Lebanon"
    },
    {
      "id": 128,
      "code": "LBR",
      "nameEn": "Liberia",
      "nameFr": "Liberia"
    },
    {
      "id": 129,
      "code": "LBY",
      "nameEn": "Libyan Arab Jamahiriya",
      "nameFr": "Libyan Arab Jamahiriya"
    },
    {
      "id": 130,
      "code": "LCA",
      "nameEn": "Saint Lucia",
      "nameFr": "Saint Lucia"
    },
    {
      "id": 131,
      "code": "LIE",
      "nameEn": "Liechtenstein",
      "nameFr": "Liechtenstein"
    },
    {
      "id": 132,
      "code": "LKA",
      "nameEn": "Sri Lanka",
      "nameFr": "Sri Lanka"
    },
    {
      "id": 133,
      "code": "LSO",
      "nameEn": "Lesotho",
      "nameFr": "Lesotho"
    },
    {
      "id": 134,
      "code": "LTU",
      "nameEn": "Lithuania (EU)",
      "nameFr": "Lithuania (EU)"
    },
    {
      "id": 135,
      "code": "LUX",
      "nameEn": "Luxembourg (EU)",
      "nameFr": "Luxembourg (UE)"
    },
    {
      "id": 136,
      "code": "LVA",
      "nameEn": "Latvia (EU)",
      "nameFr": "Latvia (UE)"
    },
    {
      "id": 137,
      "code": "MAC",
      "nameEn": "Macao",
      "nameFr": "Macao"
    },
    {
      "id": 138,
      "code": "MAF",
      "nameEn": "Saint Martin (French part)",
      "nameFr": "Saint Martin (French part)"
    },
    {
      "id": 139,
      "code": "MAR",
      "nameEn": "Morocco",
      "nameFr": "Morocco"
    },
    {
      "id": 140,
      "code": "MCO",
      "nameEn": "Monaco",
      "nameFr": "Monaco"
    },
    {
      "id": 141,
      "code": "MDA",
      "nameEn": "Moldova, Republic of",
      "nameFr": "Moldova, Republic of"
    },
    {
      "id": 142,
      "code": "MDG",
      "nameEn": "Madagascar",
      "nameFr": "Madagascar"
    },
    {
      "id": 143,
      "code": "MDV",
      "nameEn": "Maldives",
      "nameFr": "Maldives"
    },
    {
      "id": 144,
      "code": "MEX",
      "nameEn": "Mexico",
      "nameFr": "Mexico"
    },
    {
      "id": 145,
      "code": "MHL",
      "nameEn": "Marshall Islands",
      "nameFr": "Iles Marshall"
    },
    {
      "id": 146,
      "code": "MKD",
      "nameEn": "Macedonia, the former Yugoslav Republic of",
      "nameFr": "Macedonia, the former Yugoslav Republic of"
    },
    {
      "id": 147,
      "code": "MLI",
      "nameEn": "Mali",
      "nameFr": "Mali"
    },
    {
      "id": 148,
      "code": "MLT",
      "nameEn": "Malta (EU)",
      "nameFr": "Malta (UE)"
    },
    {
      "id": 149,
      "code": "MMR",
      "nameEn": "Myanmar",
      "nameFr": "Myanmar"
    },
    {
      "id": 150,
      "code": "MNE",
      "nameEn": "Montenegro",
      "nameFr": "Montenegro"
    },
    {
      "id": 151,
      "code": "MNG",
      "nameEn": "Mongolia",
      "nameFr": "Mongolia"
    },
    {
      "id": 152,
      "code": "MNP",
      "nameEn": "Northern Mariana Islands",
      "nameFr": "Northern Mariana Islands"
    },
    {
      "id": 153,
      "code": "MOZ",
      "nameEn": "Mozambique",
      "nameFr": "Mozambique"
    },
    {
      "id": 154,
      "code": "MRT",
      "nameEn": "Mauritania",
      "nameFr": "Mauritania"
    },
    {
      "id": 155,
      "code": "MSR",
      "nameEn": "Montserrat",
      "nameFr": "Montserrat"
    },
    {
      "id": 156,
      "code": "MTQ",
      "nameEn": "Martinique",
      "nameFr": "Martinique"
    },
    {
      "id": 157,
      "code": "MUS",
      "nameEn": "Mauritius",
      "nameFr": "Mauritius"
    },
    {
      "id": 158,
      "code": "MWI",
      "nameEn": "Malawi",
      "nameFr": "Malawi"
    },
    {
      "id": 159,
      "code": "MYS",
      "nameEn": "Malaysia",
      "nameFr": "Malaysia"
    },
    {
      "id": 160,
      "code": "MYT",
      "nameEn": "Mayotte (EU)",
      "nameFr": "Mayotte (UE)"
    },
    {
      "id": 161,
      "code": "NAM",
      "nameEn": "Namibia",
      "nameFr": "Namibia"
    },
    {
      "id": 162,
      "code": "NCL",
      "nameEn": "New Caledonia",
      "nameFr": "New Caledonia"
    },
    {
      "id": 163,
      "code": "NER",
      "nameEn": "Niger",
      "nameFr": "Niger"
    },
    {
      "id": 164,
      "code": "NFK",
      "nameEn": "Norfolk Island",
      "nameFr": "Norfolk Island"
    },
    {
      "id": 165,
      "code": "NGA",
      "nameEn": "Nigeria",
      "nameFr": "Nigeria"
    },
    {
      "id": 166,
      "code": "NIC",
      "nameEn": "Nicaragua",
      "nameFr": "Nicaragua"
    },
    {
      "id": 167,
      "code": "NIU",
      "nameEn": "Niue",
      "nameFr": "Niue"
    },
    {
      "id": 168,
      "code": "NLD",
      "nameEn": "Netherlands (EU)",
      "nameFr": "Pays-Bas (UE)"
    },
    {
      "id": 169,
      "code": "NOR",
      "nameEn": "Norway",
      "nameFr": "Norway"
    },
    {
      "id": 170,
      "code": "NPL",
      "nameEn": "Nepal",
      "nameFr": "Nepal"
    },
    {
      "id": 171,
      "code": "NRU",
      "nameEn": "Nauru",
      "nameFr": "Nauru"
    },
    {
      "id": 172,
      "code": "NZL",
      "nameEn": "New Zealand",
      "nameFr": "New Zealand"
    },
    {
      "id": 173,
      "code": "OMN",
      "nameEn": "Oman",
      "nameFr": "Oman"
    },
    {
      "id": 174,
      "code": "PAK",
      "nameEn": "Pakistan",
      "nameFr": "Pakistan"
    },
    {
      "id": 175,
      "code": "PAN",
      "nameEn": "Panama",
      "nameFr": "Panama"
    },
    {
      "id": 176,
      "code": "PCN",
      "nameEn": "Pitcairn",
      "nameFr": "Pitcairn"
    },
    {
      "id": 177,
      "code": "PER",
      "nameEn": "Peru",
      "nameFr": "Peru"
    },
    {
      "id": 178,
      "code": "PHL",
      "nameEn": "Philippines",
      "nameFr": "Philippines"
    },
    {
      "id": 179,
      "code": "PLW",
      "nameEn": "Palau",
      "nameFr": "Palau"
    },
    {
      "id": 180,
      "code": "PNG",
      "nameEn": "Papua New Guinea",
      "nameFr": "Papua New Guinea"
    },
    {
      "id": 181,
      "code": "POL",
      "nameEn": "Poland (EU)",
      "nameFr": "Pologne (UE)"
    },
    {
      "id": 182,
      "code": "PRI",
      "nameEn": "Puerto Rico",
      "nameFr": "Puerto Rico"
    },
    {
      "id": 183,
      "code": "PRK",
      "nameEn": "Korea, Democratic People's Republic of",
      "nameFr": "Korea, Democratic People's Republic of"
    },
    {
      "id": 184,
      "code": "PRT",
      "nameEn": "Portugal (EU)",
      "nameFr": "Portugal (UE)"
    },
    {
      "id": 185,
      "code": "PRY",
      "nameEn": "Paraguay",
      "nameFr": "Paraguay"
    },
    {
      "id": 186,
      "code": "PSE",
      "nameEn": "Palestinian Territory, Occupied",
      "nameFr": "Palestinian Territory, Occupied"
    },
    {
      "id": 187,
      "code": "PYF",
      "nameEn": "French Polynesia",
      "nameFr": "French Polynesia"
    },
    {
      "id": 188,
      "code": "QAT",
      "nameEn": "Qatar",
      "nameFr": "Qatar"
    },
    {
      "id": 189,
      "code": "ROU",
      "nameEn": "Romania (EU)",
      "nameFr": "Roumanie (UE)"
    },
    {
      "id": 190,
      "code": "RUS",
      "nameEn": "Russian Federation",
      "nameFr": "Russian Federation"
    },
    {
      "id": 191,
      "code": "RWA",
      "nameEn": "Rwanda",
      "nameFr": "Rwanda"
    },
    {
      "id": 192,
      "code": "SAU",
      "nameEn": "Saudi Arabia",
      "nameFr": "Arabie saoudite"
    },
    {
      "id": 193,
      "code": "SDN",
      "nameEn": "Sudan",
      "nameFr": "Sudan"
    },
    {
      "id": 194,
      "code": "SEN",
      "nameEn": "Senegal",
      "nameFr": "Sénégal"
    },
    {
      "id": 195,
      "code": "SGP",
      "nameEn": "Singapore",
      "nameFr": "Singapour"
    },
    {
      "id": 196,
      "code": "SGS",
      "nameEn": "South Georgia and the South Sandwich Islands",
      "nameFr": "South Georgia and the South Sandwich Islands"
    },
    {
      "id": 197,
      "code": "SHN",
      "nameEn": "Saint Helena",
      "nameFr": "Saint Helena"
    },
    {
      "id": 198,
      "code": "SJM",
      "nameEn": "Svalbard and Jan Mayen",
      "nameFr": "Svalbard and Jan Mayen"
    },
    {
      "id": 199,
      "code": "SLB",
      "nameEn": "Solomon Islands",
      "nameFr": "Solomon Islands"
    },
    {
      "id": 200,
      "code": "SLE",
      "nameEn": "Sierra Leone",
      "nameFr": "Sierra Leone"
    },
    {
      "id": 201,
      "code": "SLV",
      "nameEn": "El Salvador",
      "nameFr": "El Salvador"
    },
    {
      "id": 202,
      "code": "SMR",
      "nameEn": "San Marino",
      "nameFr": "San Marino"
    },
    {
      "id": 203,
      "code": "SOM",
      "nameEn": "Somalia",
      "nameFr": "Somalie"
    },
    {
      "id": 204,
      "code": "SPM",
      "nameEn": "Saint Pierre and Miquelon",
      "nameFr": "Saint Pierre and Miquelon"
    },
    {
      "id": 205,
      "code": "SRB",
      "nameEn": "Serbia",
      "nameFr": "Serbie"
    },
    {
      "id": 206,
      "code": "STP",
      "nameEn": "Sao Tome and Principe",
      "nameFr": "Sao Tome and Principe"
    },
    {
      "id": 207,
      "code": "SUR",
      "nameEn": "Suriname",
      "nameFr": "Suriname"
    },
    {
      "id": 208,
      "code": "SVK",
      "nameEn": "Slovakia (EU)",
      "nameFr": "Slovakia (UE)"
    },
    {
      "id": 209,
      "code": "SVN",
      "nameEn": "Slovenia (EU)",
      "nameFr": "Slovenia (UE)"
    },
    {
      "id": 210,
      "code": "SWE",
      "nameEn": "Sweden (EU)",
      "nameFr": "Suede (UE)"
    },
    {
      "id": 211,
      "code": "SWZ",
      "nameEn": "Swaziland",
      "nameFr": "Swaziland"
    },
    {
      "id": 212,
      "code": "SYC",
      "nameEn": "Seychelles",
      "nameFr": "Seychelles"
    },
    {
      "id": 213,
      "code": "SYR",
      "nameEn": "Syrian Arab Republic",
      "nameFr": "Syrian Arab Republic"
    },
    {
      "id": 214,
      "code": "TCA",
      "nameEn": "Turks and Caicos Islands",
      "nameFr": "Turks and Caicos Islands"
    },
    {
      "id": 215,
      "code": "TCD",
      "nameEn": "Chad",
      "nameFr": "Chad"
    },
    {
      "id": 216,
      "code": "TGO",
      "nameEn": "Togo",
      "nameFr": "Togo"
    },
    {
      "id": 217,
      "code": "THA",
      "nameEn": "Thailand",
      "nameFr": "Thailand"
    },
    {
      "id": 218,
      "code": "TJK",
      "nameEn": "Tajikistan",
      "nameFr": "Tajikistan"
    },
    {
      "id": 219,
      "code": "TKL",
      "nameEn": "Tokelau",
      "nameFr": "Tokelau"
    },
    {
      "id": 220,
      "code": "TKM",
      "nameEn": "Turkmenistan",
      "nameFr": "Turkmenistan"
    },
    {
      "id": 221,
      "code": "TLS",
      "nameEn": "Timor-Leste",
      "nameFr": "Timor-Leste"
    },
    {
      "id": 222,
      "code": "TON",
      "nameEn": "Tonga",
      "nameFr": "Tonga"
    },
    {
      "id": 223,
      "code": "TTO",
      "nameEn": "Trinidad and Tobago",
      "nameFr": "Trinidad and Tobago"
    },
    {
      "id": 224,
      "code": "TUN",
      "nameEn": "Tunisia",
      "nameFr": "Tunisia"
    },
    {
      "id": 225,
      "code": "TUR",
      "nameEn": "Turkey",
      "nameFr": "Turkey"
    },
    {
      "id": 226,
      "code": "TUV",
      "nameEn": "Tuvalu",
      "nameFr": "Tuvalu"
    },
    {
      "id": 227,
      "code": "TWN",
      "nameEn": "Taiwan Province of China",
      "nameFr": "Taiwan Province de Chine"
    },
    {
      "id": 228,
      "code": "TZA",
      "nameEn": "Tanzania",
      "nameFr": "Tanzanie"
    },
    {
      "id": 229,
      "code": "UGA",
      "nameEn": "Uganda",
      "nameFr": "Uganda"
    },
    {
      "id": 230,
      "code": "UKR",
      "nameEn": "Ukraine",
      "nameFr": "Ukraine"
    },
    {
      "id": 231,
      "code": "UMI",
      "nameEn": "United States Minor Outlying Islands",
      "nameFr": "United States Minor Outlying Islands"
    },
    {
      "id": 232,
      "code": "URY",
      "nameEn": "Uruguay",
      "nameFr": "Uruguay"
    },
    {
      "id": 233,
      "code": "USA",
      "nameEn": "United States",
      "nameFr": "États-Unis"
    },
    {
      "id": 234,
      "code": "UZB",
      "nameEn": "Uzbekistan",
      "nameFr": "Uzbekistan"
    },
    {
      "id": 235,
      "code": "VAT",
      "nameEn": "Holy See (Vatican City State)",
      "nameFr": "Holy See (Vatican City State)"
    },
    {
      "id": 236,
      "code": "VCT",
      "nameEn": "Saint Vincent and the Grenadines",
      "nameFr": "Saint Vincent and the Grenadines"
    },
    {
      "id": 237,
      "code": "VEN",
      "nameEn": "Venezuela, Bolivarian Republic of",
      "nameFr": "Venezuela, Bolivarian Republic of"
    },
    {
      "id": 238,
      "code": "VGB",
      "nameEn": "Virgin Islands, British",
      "nameFr": "Virgin Islands, British"
    },
    {
      "id": 239,
      "code": "VIR",
      "nameEn": "Virgin Islands, U.S.",
      "nameFr": "Virgin Islands, U.S."
    },
    {
      "id": 240,
      "code": "VNM",
      "nameEn": "Viet Nam",
      "nameFr": "Viet Nam"
    },
    {
      "id": 241,
      "code": "VUT",
      "nameEn": "Vanuatu",
      "nameFr": "Vanuatu"
    },
    {
      "id": 242,
      "code": "WLF",
      "nameEn": "Wallis and Futuna",
      "nameFr": "Wallis and Futuna"
    },
    {
      "id": 243,
      "code": "WSM",
      "nameEn": "Samoa",
      "nameFr": "Samoa"
    },
    {
      "id": 244,
      "code": "YEM",
      "nameEn": "Yemen",
      "nameFr": "Yemen"
    },
    {
      "id": 245,
      "code": "ZAF",
      "nameEn": "South Africa",
      "nameFr": "Afrique du Sud"
    },
    {
      "id": 246,
      "code": "ZMB",
      "nameEn": "Zambia",
      "nameFr": "Zambia"
    },
    {
      "id": 247,
      "code": "ZWE",
      "nameEn": "Zimbabwe",
      "nameFr": "Zimbabwe"
    },
    {
      "id": 248,
      "code": "UNK",
      "nameEn": "Unknown",
      "nameFr": "Inconnu"
    }
  ]