import { IonCard, IonCardTitle, IonImg, IonLabel, IonThumbnail } from "@ionic/react";
import { Pir } from 'pir/model';
import { pirmodelapi } from "pir/model/api";
import { usePirStatus } from "pir/StatusBadge";
import { useVesselPicture } from "pir/Vessel/useVesselPictures";
import React, { useMemo } from 'react';
import { useHistory } from "react-router-dom";
import './CalendarItem.scss';


const className = "pir-calendar-item"

type Props = {
    pir: Pir
}

export const PirCalendarItem = ({ pir }:Props) => {

    let history = useHistory();

    const pathname = `/pir/${pirmodelapi(pir).id()}`
    function goToPir() {
        history.push(pathname)
    }

    const {file, picture} = useVesselPicture(pir)

    const {status, color, icon, label} = usePirStatus(pir)
    
    return useMemo(()=>Content({className, goToPir, color, file, picture, pir, label}), [color, label, pir.fileId, file])
}

const Content = ({className, goToPir, color, file, picture, pir, label}) => {
    return <div className={`${className}-wrapper`}>

        <IonCard
            color={color}
            button
            data-interception="off"
            onClick={goToPir} className={className}>
            <div className={`${className}-content`}>

                {/* {icon && <IonIcon icon={icon} />} */}
            
                {<IonThumbnail>
                    {file && <IonImg alt={picture.description} src={file?.content} />}
                </IonThumbnail>}
                <div className={`${className}-right`}>
                    <IonCardTitle>{pirmodelapi(pir).title()}</IonCardTitle>
                    <IonLabel>{label}</IonLabel>
                </div>
            </div>
    </IonCard>
    </div>
}