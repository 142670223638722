
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { UserState } from './state';

export const UserContext = createContext<State<UserState>>(undefined!)

const initialUserState: UserState = {
    user: undefined
}

export const UserProvider = (props: PropsWithChildren<{}>) => {

    return <StateProvider initialState={initialUserState} context={UserContext}>
            {props.children}
    </StateProvider>
}

