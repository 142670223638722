import { Mutator } from "apprise-frontend-core/state/api"
import { bridge } from "bridge"
import { documenttypeapi } from "documentType/api"
import { useBusyState } from "lib/apprise-frontend-core/utils/busyguard"
import { result } from "lodash"
import { useContext } from "react"
import { ReferencedataContent, ReferencedataTypes, referencedataTypesList } from "referencedata/model"
import Worker from 'worker'
import { useReferencedataCall } from "./calls"
import { ReferencedataContext } from "./provider"
import { initialReferencedata, ReferencedataState } from "./state"

const instance = new Worker();

export const useReferencedata = () => {
    
    const busy = useBusyState()
    const call = useReferencedataCall()
    const state = useContext(ReferencedataContext)

    const stateset = (type:ReferencedataTypes) => async (_:Mutator<ReferencedataState>) => {
        state.set(_)
        const content = state.get()[type]
        content && instance.referencedata.put(type, content)
    }

    const self = {

        fetch: async (type:ReferencedataTypes, force?, silent?) => {
            const data = await instance.referencedata.get(type)
            if(data && data.length > 0 && !force){
                console.log(`loading ${type} from cache`)
                await self.setState(type)(data)
                return {fromCache:true}
            } else {
                console.log(`loading ${type} from be ${silent?'silently':''}`)
                try {
                    const {data} = await call.fetch<ReferencedataContent>(type)
                    await self.setState(type)(data)
                    return {fromCache:false}
                } catch (error) {
                    throw(error)
                }
            }
            
        }

        ,

        setInitialized: (b:boolean = true) => {
            return state.set(_=>_.initialized = b)
        }

        ,

        setRefreshed: (b:boolean = true) => {
            return state.set(_=>_.refreshed = b)
        }

        ,

        initialized: () => {
            return self.current().initialized
        }

        ,

        refreshed: () => {
            return self.current().refreshed
        }

        ,

        fetchAll: async (force?, silent?) => {
            try {
                !silent && busy.toggle('referencedata', `fetching referencedata`)
                const result = await Promise.all(referencedataTypesList.map(async(t)=>{
                    const {fromCache} = await self.fetch(t, force, silent)
                    return {key:t, fromCache}
                }))
                !silent && busy.toggle('referencedata')
                return result
            } catch (error) {
                !silent && busy.toggle('referencedata')
                throw(error)
            }
        }

        ,

        setState: (type:ReferencedataTypes) => (content) => stateset(type)(s => s[type] = content)

        ,

        init: async () => {
            if(self.initialized() === undefined){
                try {
                    const results = await self.fetchAll()
                    const cached = results.filter(r=>r.fromCache).length>0
                    await state.set(_=>{
                        _.initialized=true
                        if(!cached){
                            _.refreshed=true
                        }
                    } )
                } catch (error) {
                    await self.setInitialized(false)
                }
            }
        }

        ,

        refresh: async () => {
            if(self.refreshed() === undefined){
                try {
                    await self.fetchAll(true, true)
                    await self.setRefreshed()
                } catch (error) {
                    await self.setRefreshed(false)
                }
            }
        }

        ,

        current: () => state.get()
        
        ,

        reset: async () => {
            await Promise.all(referencedataTypesList.map(async(t)=>
                await self.setState(t)(initialReferencedata[t])
            ))
        }

        ,

        documentType: documenttypeapi(state)
    }

    return self

}