
import { useConfig } from 'apprise-frontend-core/config/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { utils } from 'apprise-frontend-core/utils/common'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import * as React from 'react'
import { useClient } from './api'
import { ClientConfiguration, ClientInterceptors, ClientProps } from './model'
import { ClientContext, initialClientState } from './state'



// mounts state before rendering children that may consume it.

export const ClientProvider = (props: React.PropsWithChildren<{}>) => {

    const { children } = props

    return <StateProvider initialState={initialClientState} context={ClientContext}>
        {children}
    </StateProvider>
}



/**
    activates call management support.
    
    takes app-wide configuration, call interceptors, and mock initialisers.
    modules can provide additional setup via the api.
    
 
 */
export type ClientProviderProps = React.PropsWithChildren<{

    config?: Partial<ClientProps>

    interceptors?: Partial<ClientInterceptors>

}>


// mounts state and delegates to component that accesses it.

export const Client = (props: ClientProviderProps) => {

    const context = React.useContext(ClientContext)

    const initialiser = <Inner {...props} />

    return context ? initialiser : <ClientProvider children={initialiser}/>
}


const Inner = (props: ClientProviderProps) => {

    const client = useClient()

    const config = useConfig<{ client: ClientConfiguration }>()?.get().client

    const [active, activeSet] = React.useState(false)

    const { config: clientconfig = {}, interceptors = {}, children } = props


    //  updates in sync with shared config, typically when the latter is remotely loaded.
    React.useEffect(() => {

        // activation is alread a sync point, so we step in after that.
        if (active) {
            console.log("initialising client management on configuration change")
            client.init(config)
        }

        // eslint-disable-next-line
    }, [config])


    // initialises client and app-wide mockery.
    const activate = () => {

        const mergedConfig = utils().merge(config ?? {}, clientconfig, interceptors)

        client.init(mergedConfig)

        activeSet(true)

    }

    // activates before rendering children.
    const { content } = useRenderGuard({

        when: active,

        render: children,

        orRun: activate,

    })

    return content
}
