import { IonButton, IonItem, IonItemGroup, IonList } from "@ionic/react";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { useSize } from "components/useSize";
import { boatOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { VesselPictureButton } from "pir/Form/Pictures/Button";
import { PirFormPictures } from "pir/Form/Pictures/Pictures";
import { FormPicture, PirEdting, statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import React, { useRef } from "react";
import { FormStepButton } from "./FormStepButton";


const Content = ({isDefault, pir}:StepContentProps) => {
    
    const pirsapi = usePirs()
    const edited = pir as PirEdting

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const set = pirsapi.setStep(key, edited)

    const setPicture = (i) => (f:FormPicture) => set(p=>p.extended.pictures[i] = f)
    const removePicture = (i) => set(p=>p.extended.pictures.splice(i,1))
    const pictures = edited.extended && edited.extended.pictures || []

    const ref = useRef(null)

    const {t} = useIntl()

    const Button = ({onClick})=> <IonButton slot="end" onClick={onClick} color="primary">{t('inspection.pictures.add')}</IonButton>

    return <StepContent isForm={true} isDefault title={t('inspection.pictures.title')}>
        <StepDescription>
            {t('inspection.pictures.subtitle')}
        </StepDescription>
        <IonItemGroup>
            <IonItem disabled={readonly}>
                <VesselPictureButton Button={Button} pir={edited}/>
            </IonItem>
        </IonItemGroup>
        <IonList ref={ref}>
            <PirFormPictures disabled={readonly} pir={pir} wrapperRef={ref} pictures={pictures} onRemove={removePicture} onChange={setPicture} />                
        </IonList>
    </StepContent>
}
export const vesselPicturesKey = "vesselpictures"
const key = vesselPicturesKey

const Button = ({pir}:StepButtonProps) => {
    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    
    const isCompleted = pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
    const isDisabled =  pirmodelapi(pir).hasStatusLessThan(statuses.inspecting)

    const vesselPicturesSize = pir.vesselPictures.length
    const picturesSize = pir.extended?.pictures?.length

    const size = (picturesSize || 0) + (vesselPicturesSize || 0)

    const {adding} = useSize(size || undefined)

    const {t} = useIntl()

    return <StepLink 
        disabled={isDisabled}
        completed={isCompleted}
        isDefault={isDefault}
        first={first}
        last={last}
        isForm={true}
        path={getPath(key)}
        icon={boatOutline}
        title={t('inspection.pictures.title')}
        className={adding?'adding':''}
        badgeColor={adding ? 'success' : undefined}
        badge={size || undefined}
     />
}

export const VesselPicturesForm:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}