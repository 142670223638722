import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    multiple?
    ids?: number[],
    onChange,
    placeholder?
}
export const SpeciesSelect = ({ids, multiple, onChange, placeholder}:Props) => {
    
    const referencedataapi = useReferencedata()
    const species = referencedataapi.current().species
    
    const value = ids && species.filter(f=>ids.includes(f.id)).map(v=>v.id)
    
    return <Select title={multiple ? 'Select one or more fishing area' : 'Select one fishing area'}
    multiple={multiple}
    placeholder={placeholder}
    value={value || []}
    onChange={v=>onChange(v)}
    options={useReferencedataOptions({data:species})}/>
}