import { PirFileImageBase64 } from 'file/model'
import { usePirs } from "pir/api"
import { Pir } from 'pir/model'
import React, { useEffect } from "react"
import Resizer from "react-image-file-resizer";

export const useVesselPictures = (pir:Pir) => {
    const pirapi = usePirs()

    const [files, setFiles] = React.useState<PirFileImageBase64[]>([])

    useEffect(()=>{
        const getFiles = async () => {
            let files:PirFileImageBase64[] = []
            await Promise.all(pir.vesselPictures.map(async p=>{
                const f = await pirapi.getFormRemoteFile(pir, p) as PirFileImageBase64
                files.push(f)
            }))
            setFiles(files)
        }
        getFiles()
    }, [])

    return files
}

export const useVesselPicture = (pir:Pir) => {
    const pirapi = usePirs()

    const [file, setFile] = React.useState<PirFileImageBase64>()

    useEffect(()=>{
        const getFile = async () => {
            if(pir.vesselPictures && pir.vesselPictures[0]){
                const f = await pirapi.getFormRemoteFile(pir, pir.vesselPictures[0])
                setFile(f as PirFileImageBase64) 
            }            
        }
        getFile()
    }, [])

    return {file, picture:pir.vesselPictures[0]}
}

export const resizePicture = (file:File) =>
new Promise<File>((resolve) => {
  Resizer.imageFileResizer(
    file,
    3000,
    5000,
    "JPEG",
    70,
    0,
    (uri) => {
      resolve(uri as File);
    },
    "file"
  );
});