import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { useMockery } from 'lib/apprise-frontend-core/client/mocks'
import { useProcessRegistry } from 'process/api'
import { PropsWithChildren } from 'react'
import { useFileTasksInitializer } from './task'

export type FileLoaderProps = PropsWithChildren<Partial<{

    mock: boolean

}>>

export const FileLoader = (props: FileLoaderProps) => {

    const { children } = props

    const processRegistry = useProcessRegistry()
    const tasks = useFileTasksInitializer()
    
    const activate = async () => {
        await processRegistry.registerTask(tasks)
    }

    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content
}