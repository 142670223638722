import { ClientState } from "apprise-frontend-core/client/state";
import { State } from "apprise-frontend-core/state/api";
import { utils } from "apprise-frontend-core/utils/common";
import MockAdapter from "axios-mock-adapter/types";
import { referencedataTypes } from "referencedata/model";
import { purposes } from "common/mocks/purposes";


export const mockPurposes = (mock:MockAdapter) => {
        // mock calls
        mock.onGet(`/${referencedataTypes.purpose}`).reply((config)=>{
                return Promise.resolve([200,purposes]).then(utils().wait(500))
        })

}