import MockAdapter from 'axios-mock-adapter/types';
import { isImageContentType } from 'file/model/api';
import { utils } from 'lib/apprise-frontend-core/utils/common';
import _ from 'lodash';
import { JPEGimage } from './mocks/image';
import { filepdf } from './mocks/pdffile';
import { Pir, statuses } from './model';
import { createFakePir } from './model/api';

const fakePirs: Pir[] = [
    createFakePir({id:'1', name:'a Pir', status:statuses.notready}),
    createFakePir({id:'2',name:'another Pir'}),
    createFakePir({id:'3',name:'Name of the vessel'}),
    createFakePir({id:'4',name:'name of the vessel 2'})
]

export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const returnImage = async () => {
    const result = await fetch(`/vessel-pictures/3.JPG`, {
        headers: {
            'Content-Type': 'image/jpeg'
        }
    })
    const blob = await result.blob()
    const base64 = await blobToBase64(blob) as any[]
    return [200, base64]
  }
export const usePirMockery = () => {


    return (mock: MockAdapter) => {

        console.log("mocking pirs...")

        //const newUserId = () => `U-${shortid()}`

        // mock.onPost(new RegExp("/offline-api/pir/(.)+/device-claim")).reply(()=>Promise.resolve([200,{data:[]}]).then(utils().wait(2000)))
        // mock.onPost(new RegExp("/offline-api/pir/(.)+/device-release")).reply(()=>Promise.resolve([200,{data:[]}]).then(utils().wait(2000)))
        //mock.onPost(new RegExp("/offline-api/pir/(.)+/save")).reply(()=>Promise.resolve([200,{data:[]}]).then(utils().wait(200)))
        // mock.onGet(new RegExp("/offline-api/pir/(.)+/complete-submit")).reply(()=>Promise.resolve([200,{data:[]}]).then(utils().wait(8000)))
        // mock.onPost(new RegExp("/offline-api/pir/(.)+/complete-submit")).reply(()=>Promise.resolve([200,{data:[]}]).then(utils().wait(8000)))
        // mock.onGet(new RegExp("/offline-api/pir/(.)+")).reply(()=>Promise.resolve([200,pirExtended]).then(utils().wait(10000)))
        //mock.onGet(new RegExp("pir/public/(.)+")).reply(()=>Promise.resolve([200, filepdf]).then(utils().wait(7000)))
        //mock.onGet(new RegExp("nfv/public/(.)+")).reply(()=>Promise.resolve([200, filepdf]).then(utils().wait(7000)))
        //mock.onGet(new RegExp("arep/public/(.)+")).reply(()=>Promise.resolve([200, filepdf]).then(utils().wait(7000)))
        //mock.onGet(new RegExp("raiarep/public/(.)+")).reply(()=>Promise.resolve([200, filepdf]).then(utils().wait(4000)))
        //mock.onGet(new RegExp("/vfd/(.)+.(jpg|JPG|jpeg)")).reply(returnImage)
        //mock.onGet(new RegExp("/vfd/(.)+.pdf")).reply(()=>Promise.resolve([200, filepdf]).then(utils().wait(4000)))
        // mock.onGet(new RegExp("/vfd/downloadAttachment/(.)+/(.)+")).reply(async (config)=>{
        //     console.log('content type')
        //     const contentType = (config as any).contentType
        //     if(contentType && isImageContentType(contentType)){
        //         return await returnImage()
        //     }
        //     await utils().wait(4000)
        //     return [200, filepdf]
        // })

        const sleep = (value: number) =>
        new Promise((resolve) => setTimeout(resolve, value));

        // this mocks a request which slowly resolves (20% progress every 500ms)
        // mock.onPost(new RegExp("/submit-file/(.)+")).reply(async (config) => {
        //     const total = config.data.size; // mocked file size
        //     for (const progress of [0, 0.2, 0.4, 0.6, 0.8, 1]) {
        //         await sleep(_.random(5000,8000,false));
        //         if (config.onUploadProgress) {
        //         config.onUploadProgress({ loaded: total * progress, total });
        //         }
        //     }
        //     return [200, null];
        // });
    
    }

}


const pirExtended = {
    "vessel":{
    "idVessel":4123,
    "name":"SEA KING TEST",
    "flagState":"Singapore",
    "type":"Cargo Freezers",
    "dimension": {
        "length":24.7,
        "beam":6.66,
        "draft":12.0
    },
    "iotcId":"13269",
    "imoId":"",
    "ircs":"IRCSNEW",
    "mmsi":"",
    "certificateRegistryId":"P5203A-01-05-P",
    "externalId":"",
    "vesselOwnerName":null,
    "gears":[
        "Hand line and pole and line",
        "Pole and Line, Troll line",
        "Pole and Line, Hand Line, Troll line",
        "Pole and Line, Hand Line, Longline, Troll line",
        "Bottom and/or midwater trawls"
    ],
    "length":"24.7",
    "beam":"6.66",
    "draft":"12.0",
    "monitoringScope":"YesNational",
    "vmsType":[1],
    "vmsTypeOtherName":null,
    "masterName":null,
    "masterNationality":null,
    "registrationPort":"Dhaandhoo",
    "owners":[],
    "masters":[],
    "inmarsat":"",
    "activeSince":null,
    "changedValues":null,
    "totalVolumeOfFishHolds":99.999,
    "additionalRfmoIdEntity":null,
    "additionalRfmoIdCode":""},
    "pir":{
        "epsmResponsible":{
            "name":"ALVARO SANTOS NINE",
            "email1":"Espemardos@SkyFile.com",
            "email2":null,
            "email3":null,
            "nationalityEn":"Spain (EU)",
            "nationalityFr":"Espagne (UE)",
            "allEmailsInList": ["Espemardos@SkyFile.com"]
        },
        "dbFormId":null,
        "previewMode":false,
        "vesselFileContext":{
            "fileId":7195,
            "intendedPortOfCall":19,
            "vessel":4123,
            "arepReceived":1599226534000,
            "portOfCall":"Ball Bay Norfolk Island",
            "eta":1599483600000,
            "vesselName":"SEA KING TEST",
            "vesselFlag":"Singapore",
            "vesselIrcs":"IRCSNEW",
            "vesselIotcId":"13269",
            "vesselCertificateRegistryId":"P5203A-01-05-P",
            "vesselRcs":"IRCSNEW",
            "vesselImoNumber":"",
            "portOfCallState":"Australia"},
            "prefilledFormRecipients":[],
            "submitDate":null,
            "formId":"c4821edd-525a-424e-8409-aba18b3991a9",
            "name":"Port Inspection Report",
            "message":"This is the introduction / helper message to display below the title on a PIR web form",
            "steps":[
                {
                    "id":"pir.step.contentDetails",
                    "stepNumber":1,
                    "name":"pir.step.contentDetails",
                    "active":true
                },
                {
                    "id":"pir.step.formPreview",
                    "stepNumber":2,
                    "name":"pir.step.formPreview",
                    "active":false
                },
                {
                    "id":"pir.step.formSubmited",
                    "stepNumber":3,
                    "name":"pir.step.formSubmited",
                    "active":false
                }
            ],
            "currentStepId":"pir.step.contentDetails",
            "monitoringDetails":null,
            "inspectionReport":null,
            "portOfInspection":"Ball Bay Norfolk Island",
            "startOfInspection":null,
            "endOfInspection":null,
            "logBookExamination":null,
            "catchDocumentationSchemeCompliance":null,
            "tradeInformationSchemeCompliance":null,
            "gearExamination":null,
            "gearType":[],
            "findingsByInspector":null,
            "commentsByMaster":null,
            "infrigements":null,
            "actionsTaken":null,
            "purposes":[9],
            "purposeDetails":null,
            "lastPortOfCallEntryDate":null,
            "lastPortOfCallFreeForm":"",
            "fishingAuthorizations":[
                {
                    "objection":null,
                    "comments":null,
                    "srcForm":"AREP",
                    "id":12473,
                    "issuedBy":23,
                    "identifier":"fdfdfd",
                    "validFrom":1587945600000,
                    "validTo":1599177600000,
                    "fishingAreas":[
                        1,3,116,95
                    ],
                    "gear":[
                        16,7
                    ],
                    "species":[
                        24,26,15
                    ],
                    "attachment":null,
                    "formAttachment":null,
                    "fileName":null,
                    "fileToken":null,
                    "isTypeATF":true,
                    "isEditable":false,
                    "preloadedFrom":null,
                    "nonDupKey":"fdfdfd_23_true_7|16_15|24|26_1|3|95|116_",
                    "incomplete":false,
                    "empty":false
                }
            ],
            "transhipmentAuthorizations":[
                {
                    "objection":null,
                    "comments":null,
                    "srcForm":"AREP",
                    "id":1018,
                    "issuedBy":17,
                    "identifier":"kjhkh",
                    "validFrom":1596240000000,
                    "validTo":1604102400000,
                    "attachment":null,
                    "formAttachment":null,
                    "fileName":null,
                    "fileToken":null,
                    "isEditable":false,
                    "preloadedFrom":null,
                    "nonDupKey":"17_kjhkh_20201031_20200801_",
                    "incomplete":false,
                    "empty":false
                }
            ],
            "transhipmentInfoDonorVessel":[],
            "retainedCatches":[],
            "rfmoIuuListing":[],
            "controlledCatches":[],
            "exportVersion":false,
            "hasInfringements":undefined,
            "monitoredLanTrx":false,
            "monitoredLanTrxAttachments":[],
            "totalCatchOnBoard":{
                "objection":null,
                "comments":null,
                "srcForm":null,
                "id":null,
                "species":null,
                "productForm":null,
                "catchArea":[],
                "quantity":0,
                "quantityToLand":0,
                "quantityToTransship":0,
                "monitoredQuantity":0,
                "monitoredLandedQuantity":0,
                "monitoredTranshippedQuantity":0,
                "deltaLanded":0,
                "deltaTranshipped":0,
                "isEditable":null,
                "notQuantityEmpty":false,
                "incomplete":true,
                "empty":false
            },
            "totalRetainedCatches":{
                "objection":null,
                "comments":null,
                "srcForm":null,
                "id":null,
                "species":null,
                "productForm":null,
                "catchArea":[],
                "declaredQuantity":0,
                "actualQuantity":0,
                "deltaRetained":0,
                "isEditable":null,
                "notQuantityEmpty":false,
                "incomplete":true,
                "empty":false
            },
            "currentStep":{
                "id":"pir.step.contentDetails",
                "stepNumber":1,
                "name":"pir.step.contentDetails",
                "active":true
            }
        }
    }