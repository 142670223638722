import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    id?: number,
    onChange,
    placeholder?
    title?
}
export const FlagsSelect = ({id, onChange, title, placeholder}:Props) => {

    const flagsapi = useReferencedata()
    const flags = flagsapi.current().flag

    const value = flags.find(f=>f.id === id)
    
    return <Select title={placeholder || "Select a vessel flag"}
    placeholder={placeholder}
    value={value ? [value.id] : []}
    onChange={v=>onChange(v[0])}
    options={useReferencedataOptions({data:flags})}/>
}