import { authConfig, Data } from "api";
import { useClient } from "apprise-frontend-core/client/api";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { User } from "user/model";
import { Pir, PirExtendedUpload, statuses } from "./model";
import { pirmodelapi } from "./model/api";

export const usePirCall = () => {
    
    const {at} = useClient()

    const {t} = useIntl()
    
    const self = {
        fetchAll: async () => {
            const {listing} = await at(`/offline-api/current-files`)
            .get<{listing:Pir[]}>(authConfig())

            return listing.map(p=>{
                return {
                    ...p, 
                    status:statuses.notready,
                    inspenctionComment: p.inspectionComment,
                    portOfArrival: p.portOfArrival || 64
                }
            })
        }
        
        ,

        claim: (pir:Pir, user:User) => {
            return at(`/offline-api/pir/${pirmodelapi(pir).getPirFormId()}/device-claim`).post<number>({
                deviceIdentifier:user.deviceName
            }, authConfig()).then(n=>{
                if(n===0){
                    throw new Error(t('error.claim'));
                    
                }
            })
        }

        ,

        release: (pir:Pir, user:User) => {
            return at(`/offline-api/pir/${pirmodelapi(pir).getPirFormId()}/device-release`).post<Pir>({
                deviceIdentifier:user.deviceName
            }, authConfig())
        }

        ,

        startSubmit: (pir:Pir, extended:PirExtendedUpload, user:User) => {
            return at(`/offline-api/pir/${pirmodelapi(pir).getPirFormId()}/save`).post<void>(extended, authConfig())
        }

        ,

        completeSubmit: (pir:Pir) => {
            return at(`/offline-api/pir/${pirmodelapi(pir).getPirFormId()}/complete-submit`).get<boolean>(authConfig()).then(r=>{
                if(!r){
                    throw new Error(t('error.verify'));
                }
            })
        }
    }

    return self
}