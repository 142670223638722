import { AxiosError } from "axios"
import { useClient } from "lib/apprise-frontend-core/client/api"
import { useMockery } from "lib/apprise-frontend-core/client/mocks"
import { useRenderGuard } from "lib/apprise-frontend-core/utils/renderguard"
import { PropsWithChildren } from "react"
import { useUser } from "./api"
import { useUserMockery } from "./mockery"

export type UserLoaderProps = PropsWithChildren<Partial<{

    mock: boolean

}>>

export const UserLoader = (props: UserLoaderProps) => {

    const { children, mock } = props

    const user = useUser()
    const mocks = useMockery()
    const mockery = useUserMockery()
    const client = useClient()
    
    const onError = (e: AxiosError) => {
        switch (e.response?.status) {
                    
          case 401:
            if(e.message == 'SESSION_EXPIRED'){
                user.logout()
            }
            break;
        
          default:
            break;
        }
      
        return {} as never
    }

    const activate = async () => {
        await client.addErrorInterceptor(onError)
        return Promise.resolve(mock ? mocks.initMockery(mockery) : true)
        .then(user.init)
    }

    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content
}
