import { IonCheckbox, IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react'
import {useVirtual} from 'react-virtual'
import AutoSizer from 'react-virtualized-auto-sizer'
import { SelectableItem, SelecteableValue } from '.';
import Highlighter from "react-highlight-words";


type Props = {
    options:SelectableItem[],
    selected:SelecteableValue[],
    onToggle:(s:SelecteableValue, checked:boolean)=>void,
    filter:string
}
export const SelectList = ({options, selected, filter, onToggle}:Props) => {

  return (
    <AutoSizer disableWidth>
    {({height}) => (
      <List filter={filter} options={options} selected={selected} onToggle={onToggle} height={height}/>
    )}
    </AutoSizer>
  );
}

type ListProps = {
    height:number,
    options:SelectableItem[],
    selected:SelecteableValue[],
    onToggle:(s:SelecteableValue, checked:boolean)=>void,
    filter: string
}
const List = ({height, options, selected, filter, onToggle}:ListProps) => {

    let parentRef:React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

    const rowVirtualizer = useVirtual({
        size: options.length,
        parentRef,
        estimateSize: React.useCallback(() => 46, []),
        overscan: 5
    });
    
    return <div
        ref={parentRef}
        className="List"
        style={{
          minHeight: '300px',
          height: height > 400 ? `${height}px` : "400px",
          overflow: "auto"
        }}
      >
        <IonList
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: "100%",
            position: "relative"
          }}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => {

            const checked = selected.includes(options[virtualRow.index].value) ? true : false
                return <IonItem
                    key={virtualRow.index}
                    className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`
                    }}>
                    <IonCheckbox
                    checked={checked}
                    onClick={()=>onToggle(options[virtualRow.index].value, !checked)} />
                    <IonLabel>
                      <Highlighter
                      highlightClassName="strong"
                      searchWords={[filter]}
                      autoEscape={true}
                      textToHighlight={options[virtualRow.index].label}
                    />
                    </IonLabel>
                </IonItem>
            }
          )}
        </IonList>
      </div>
}