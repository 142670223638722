import { authConfig, Data } from "api"
import { AxiosRequestConfig } from "axios"
import { useClient } from "lib/apprise-frontend-core/client/api"
import { Task } from "./model"

export const useTaskCall = () => {
    
    const {at} = useClient()
    
    const self = {

        fetch: (t:Task) => {
            return at(t.url).get<any>(authConfig())
        }

    }

    return self
}