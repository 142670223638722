import { alertOutline, checkmarkDoneOutline, checkmarkOutline, createOutline, pencilOutline } from "ionicons/icons";
import { strEnum } from "model/utils";
import { User } from "user/model";

export const statuses = strEnum([
    'notready',
    'preparing',
    'ready',
    'inspecting',
    'signing',
    'readytosubmit',
    'submitting',
    'submitted',
    'claimed'
])

type StatusModel = {
    icon,
    color?,
    disabled?
    order
}

export type ValidateStep = (p:PirEdting)=>InspectionErrors<any>

export const Statuses: {[key:string]:StatusModel} = {
    [statuses.notready]: {
        icon: alertOutline,
        color: "danger",
        order: 1
    },
    [statuses.preparing]: {
        icon: null,
        disabled: true,
        order: 2
    },
    [statuses.ready]: {
        icon: checkmarkOutline,
        order: 3
    },
    [statuses.inspecting]: {
        color: "warning",
        icon: createOutline,
        order: 4
    },
    [statuses.signing]: {
        color: "warning",
        icon: pencilOutline,
        order: 5
    },
    [statuses.readytosubmit]:{
        color: 'success',
        icon: checkmarkOutline,
        order: 6
    },
    [statuses.submitting]:{
        color: 'primary',
        icon: null,
        disabled: true,
        order: 7
    },
    [statuses.submitted]:{
        color: 'primary',
        icon: checkmarkDoneOutline,
        order: 8
    },
    [statuses.claimed]: {
        icon: null,
        color: "warning",
        order: 9
    },
}

export type Status = keyof typeof statuses;

export type PirInspectionStatus = {
    [key:string]: boolean
}

export type PirInspectionErrors = {
    errors: {[key:string]: InspectionErrors<any>},
    isValid: boolean
}

export type InspectionErrors<T> = {
    [key in keyof T]?: InspectionError<any>
}

export type InspectionError<T> = (InspectionBaseError | InspectionErrors<T>)[]

export type InspectionBaseError = {message: string}

export type PirInspectionSignatures = {
    masterRefuses?: boolean,
    masterSignature?
    inspectorSignature?
}
export interface Pir extends RemotePir {
    status: Status
    preparation?: number //needed only when Status is preparing
    extended?: PirExtended //what we get after the praparation
    lifecycle?: Lifecycle
    inspectionstatus?: PirInspectionStatus
    inspectionErrors?: PirInspectionErrors,
    inspectionSignatures?: PirInspectionSignatures
}

export const signatureStepKey = 'signature'

export interface PirEdting extends Pir {
    extended: PirExtended //what we get after the praparation
}

export const contentTypes = strEnum([
    'image/jpeg',
    'application/pdf'
])

export type FormPicture = {
    author:string,
    type:number,
    uid:string //it matches the File UID
}

export type RemoteFile = {
    downloadLink: string,
    name?: string,
    id?: number,
    tagName?: string,
    description?: string,
    contentType?: keyof typeof contentTypes;
}

export type Attachment = RemoteFile & {
    fileType: string | null,
    fileTypeId: number | null
}

export type VesselPicture = RemoteFile & {
    photoTakingDate: string,
    tagId: number,
}

export const typesOfForm = strEnum([
    "RAR",
    "AREP",
    "RAIAREP",
    "PIR"
])

export type Form = RemoteFile & {
    typeOfForm: keyof typeof typesOfForm;
    submitDate: string | null,
    attachments: Attachment[]
}

export type RemotePir = {
    fileId:number,
    code: string,
    createDate: string,
    lastUpdateDate: string,
    creator: {
        id: number,
        name: string
    },
    vesselIdentity: {
        vesselName: string,
        flagState: string,
        flagStateId: number,
        iotcId: string,
        imoId: string,
        ircs: string,
        mmsi: string,
        vesselId: number
    },
    fileAttachements: any[],
    submitedForms: Form[],
    offlinePendingForms: {
        id: number,
        name: string,
        typeOfForm: string
    }[],
    vesselPictures: VesselPicture[],
    claimedByDevice: string | null,
    expectedArrival: Date,
    inspectionComment?: string,
    portOfArrival: number,
    startOfInspection: Date
    rarLink: Attachment,
}

type Lifecycle = {
    [key:string]:{
        date: string,
        previousStatus: string
    }
}

export type Dimension =  {
    "length": number,
    "beam": number,
    "draft": number
}

type Gear = any

type Type = any

type ChangedValue = {
    newValue: string,
    previousValue: string,
    property: string,
    propertyHrName: string,
    vesselId: 0
}

type Staff = {
    "adress": string,
    "email": string,
    "fax": string,
    "fsCode": string,
    "idVesselContact": number,
    "idVesselFile": number,
    "inmarsat": string,
    "isMaster": true,
    "isOwner": true,
    "mobile": string,
    "name": string,
    "nationality": string, //should be id ? code ?
    "phone": string,
    "position": string, //does it refer to a reference data ? 
    "tmpId": string //???
}

type EpsmResponsible = {
    name:string,
    email1:string,
    email2:null | string,
    email3:null | string,
    nationalityEn: string //should be id ? code ?
    nationalityFr: string //should be id ? code ?
    allEmailsInList: string[]
}

type VesselFileContext= {
    fileId:number,
    intendedPortOfCall:19, //id or code?
    vessel:number,
    arepReceived:number //id of the AREP?
    portOfCall:string //should be id? is it different from intendedPortOfCall
    eta:number ///????
    vesselName:string,
    vesselFlag:string,
    vesselIrcs:string,
    vesselIotcId:string,
    vesselCertificateRegistryId:string,
    vesselRcs:string,
    vesselImoNumber:string,
    portOfCallState:string //id or code?
}

type PrefilledFormRecipient = {
    country: string, //id or code?
    email: string,
    "messageId": string,
    "name": string,
    "port": string,
    "prefilledUser": true,
    "recipientType": {
      "code": string,
      "id": number,
      "includeStatusLink": true,
      "name": string,
      "nameEn": string,
      "nameFr": string
    },
    "sharedUser": true
}

type PirStep = {
    "active": true,
    "id": string,
    "name": string,
    "stepNumber": number
}

export type MonitoringDetails = {
    inspectingAuthority: string, //it is a string
    principalInspectorEmail: string,
    principalInspectorId: string, //where do I get this information
    principalInspectorName: string
}

export type FormAttachmentPicture = {
    uid: string
}

export type FormAttachmentContext = {
    key, 
    subject?
}
export type FormAttachment = {
    uid: string
    pictures:FormAttachmentPicture[]
    type: number, //id of filetype
    name: string,
    context?: FormAttachmentContext
}

export type RemoteFormAttachment = {
    content: any
    contentBytes: any
    contentType: any
    documentTag: number
    ignoreMe: boolean
    name:string
    originalFilename: any
    prefilledFrom: string
    uid; any
}

export type RFMOIUUListing = {
    empty: boolean,
    flagstateStatus: string,
    id: number,
    incomplete: boolean,
    isEditable: boolean,
    rfmo: string,
    vesselIdentifer: string,
    vesselOnAuthorizedList: boolean,
    vesselOnIUUList: boolean
}

export type FishingAuthorization = {
    objection:null | boolean,
    comments:null | string,
    srcForm:string,
    id:number, //what is this used for
    issuedBy:number,
    identifier:string,
    validFrom:number //date in milliseconds,
    validTo:number,
    fishingAreas:number[],
    gear:number[],
    species:number[],
    attachment?:null | FormAttachment, //?
    formAttachment?:null | any, //?
    fileName?:null | any, //?
    fileToken?:null | any, //?
    isTypeATF:boolean,
    isEditable:boolean,
    preloadedFrom?:null,
    nonDupKey?:string,  //"fdfdfd_23_true_7|16_15|24|26_1|3|95|116_" what is this used for
    incomplete?:boolean,
    empty?:boolean
}

export type TranshipmentAuthorization = {
    objection:null | boolean,
    comments:null | string,
    srcForm:string //"AREP" //?,
    id:number, //?
    issuedBy:number, //?
    identifier:string,
    validFrom:number,
    validTo:number,
    attachment?:null | any, //?
    formAttachment?:null | any, //?
    fileName?:null | any, //?
    fileToken?:null | any, //?
    isEditable:boolean,
    preloadedFrom?:null,
    nonDupKey?:string //:"17_kjhkh_20201031_20200801_",
    incomplete?:boolean,
    empty?:boolean
}

export type RemoteTranshipmentInfo = {
    atPort: string,
    atSea: string,
    catchArea: number[],
    comments: string,
    date: number,
    empty: boolean,
    flagState: number,
    id?: number,
    idNumber: string,
    incomplete: boolean,
    isAtPort: boolean,
    isEditable: boolean,
    isReceived: boolean,
    name: string,
    objection: boolean,
    productForm: number,
    quantity: number,
    species: number,
    srcForm: string
}

//internal use only
export type TranshipmentInfoContent = {
    species: number,
    productForm: number,
    catchArea: number[],
    quantity: number //kg,
    objection: boolean,
    comments: string,
    id?: number
}

//internal use only
export type TranshipmentInfo = {
    atPort: string,
    atSea: string,
    date: number,
    empty: boolean,
    flagState: number,
    idNumber: string,
    incomplete: boolean,
    isAtPort: boolean,
    isEditable: boolean,
    isReceived: boolean,
    content: TranshipmentInfoContent[]
    name: string,
    srcForm: string
}

export type TotalCatchOnBoard = {
    objection:null | boolean,
    comments:string,
    srcForm:null | string,
    id:null | number,
    species:number[],
    productForm?,
    catchArea:number[],
    quantity:number,
    quantityToLand:number,
    quantityToTransship:number,
    monitoredQuantity:number,
    monitoredLandedQuantity:number,
    monitoredTranshippedQuantity:number,
    deltaLanded:number,
    deltaTranshipped:number,
    isEditable:null | boolean,
    notQuantityEmpty:null | boolean,
    incomplete:boolean,
    empty:boolean
}

export type ControlledCatches = {
    catchArea: number[],
    comments: string,
    deltaLanded: number,
    deltaTranshipped: number,
    empty: boolean,
    id: number,
    incomplete: boolean,
    isEditable: boolean,
    monitoredLandedQuantity: number,
    monitoredQuantity: number,
    monitoredTranshippedQuantity: number,
    notQuantityEmpty: boolean,
    objection: boolean,
    productForm: number,
    quantity: number,
    quantityToLand: number,
    quantityToTransship: number,
    species: number,
    srcForm: string
}

export type RetainedCatches = {
    actualQuantity: number,
    catchArea: number[],
    comments: string,
    declaredQuantity: number,
    deltaRetained: number,
    empty: boolean,
    id: number,
    incomplete: boolean,
    isEditable: boolean,
    notQuantityEmpty: boolean,
    objection: boolean,
    productForm: number,
    species: number,
    srcForm: string
}

export type Examination = {
    comments?: string,
    status?: boolean
}

export interface PirExtendedPirRemote {
    epsmResponsible:EpsmResponsible,
    dbFormId:null | number //???
    previewMode:false //????
    vesselFileContext:VesselFileContext
    prefilledFormRecipients:PrefilledFormRecipient[],
    submitDate:string, //datestring
    formId:string,
    name:string, //is it needed?
    message:string, //is it needed?
    steps:PirStep[], //needed?
    currentStepId:string,  //needed? also into another property
    monitoringDetails:MonitoringDetails | null, //needed? 
    inspectionReport:string, //needed
    "portOfInspection":"Ball Bay Norfolk Island",
    startOfInspection:number,
    endOfInspection:number,
    logBookExamination:null | Examination,
    catchDocumentationSchemeCompliance:null | Examination,
    tradeInformationSchemeCompliance:null | Examination,
    gearExamination:null | Examination,
    gearType:number[],
    findingsByInspector:null | string,
    commentsByMaster:null | string,
    infrigements:null | string,
    actionsTaken:null | string,
    "purposes":number[],
    "purposeDetails":string|null,
    "lastPortOfCallEntryDate":null | number,
    "lastPortOfCallFreeForm":"",
    fishingAuthorizations:FishingAuthorization[],
    transhipmentAuthorizations:TranshipmentAuthorization[],
    transhipmentInfoDonorVessel:RemoteTranshipmentInfo[],
    retainedCatches:RetainedCatches[],
    rfmoIuuListing:RFMOIUUListing[],
    controlledCatches:ControlledCatches[],
    "exportVersion":false,
    hasInfringements:boolean,
    monitoredLanTrx:boolean,
    monitoredLanTrxAttachments: (FormAttachment|RemoteFormAttachment)[],
    totalCatchOnBoard:TotalCatchOnBoard[],
    "totalRetainedCatches":{
        "objection":null,
        "comments":null,
        "srcForm":null,
        "id":null,
        "species":null,
        "productForm":null,
        "catchArea":[],
        "declaredQuantity":0,
        "actualQuantity":0,
        "deltaRetained":0,
        "isEditable":null,
        "notQuantityEmpty":false,
        "incomplete":true,
        "empty":false
    },
    "currentStep":{
        "id":"pir.step.contentDetails",
        "stepNumber":1,
        "name":"pir.step.contentDetails",
        "active":true
    }
}

export interface PirExtendedPir extends PirExtendedPirRemote {
    transhipmentInfo: TranshipmentInfo[]
}

export interface PirExtendedVesselRemote {
    idVessel:string,
    name:string,
    flagState: string, //shoukd be id or code
    flagStateId: number, //shoukd be id or code
    type: string, //should beid or more complex object or code

    typeId: number, 
    dimension: Dimension, //numeric here and and in the main props list as strings
    iotcId:string,
    imoId:string,
    ircs:string,
    mmsi:string,
    certificateRegistryId:string,
    externalId:string,
    vesselOwnerName:null,
    gears: Gear[], //array of id or code

    gearIds: number[], //array of id
    length:string,
    beam:string,
    draft:string,
    monitoringScope:string, //no table and YesNational, YesRfmos, No
    vmsType:number[], //do it refer to code or id?
    vmsTypeOtherName: null | string //string if not null? when is that you read it?,
    masterName:null, // why here and in the master field
    masterNationality: null // why here and in the master field
    registrationPort: string //should be  id or code
    owners: Staff[], 
    masters: Staff[], 
    inmarsat:string,
    activeSince: string, //datestring
    changedValues: null | ChangedValue[],
    totalVolumeOfFishHolds:number, //max min?
    additionalRfmoIdEntity:null | number, //reference data?
    additionalRfmoIdCode:string
}
export interface PirExtendedRemote {
    pictures:FormPicture[],
    attachments:FormAttachment[]
    vessel:PirExtendedVesselRemote
    pir: PirExtendedPirRemote
}

export interface FormAttachmentUpload extends FormAttachment {
    pictures: [],
    offline:boolean
}

export interface PirExtendedPirUpload extends PirExtendedPirRemote {
    fishingAuthorizations:FishingAuthorizationUpload[],
    transhipmentAuthorizations:TranshipmentAuthorizationUpload[],
    monitoredLanTrxAttachments:FormAttachmentUpload[]
}

export interface TranshipmentAuthorizationUpload extends TranshipmentAuthorization {
    attachment: FormAttachmentUpload | null
}
export interface FishingAuthorizationUpload extends FishingAuthorization {
    attachment: FormAttachmentUpload | null
}
export interface PirExtended extends PirExtendedRemote {
    pir: PirExtendedPir
}
export interface PirExtendedUpload extends PirExtendedRemote {
    pir: PirExtendedPirUpload,
    attachments: FormAttachmentUpload[],
    signatures: PirInspectionSignatures
}
