import { ReferenceData } from 'common'
import { SelectableItem } from 'components/Select'
import { useContext } from 'react'
import { useSettings } from 'settings/api'
import { ReferencedataLoader, ReferencedataLoaderProps } from './loader'
import { ReferencedataContext, ReferencedataProvider } from './provider'
import { ReferencedataRefresh } from './refresh'

type Props = {
    data:ReferenceData[], codeAsValue?, showCode?
}
export const useReferencedataOptions = ({data, codeAsValue, showCode}:Props) => {
    const settings = useSettings()
    const language = settings.all().language
    return data.map(d=>{
        const item:SelectableItem = {
            value:codeAsValue ? d.code : d.id,
            label:`${showCode ? `[${d.code}] `:''}${language == 'en' ? d.nameEn : d.nameFr}`
        }
        return item
    })
}

export type ReferencedataProps = ReferencedataLoaderProps

// @refresh reset
// initialises the model and loads referencedatas, and mounts the state if required.
export const Referencedata = (props: ReferencedataProps) => {

    const { mock, children } = props

    const context = useContext(ReferencedataContext)

    const content = <ReferencedataLoader mock={mock}>
                        <ReferencedataRefresh/>
                        {children}
                    </ReferencedataLoader>

    return context ? content : <ReferencedataProvider>{content}</ReferencedataProvider>

}