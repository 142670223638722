import { useProcessRegistry } from "process/api"
import { usePirPreparationProcessInitializer } from "./preparation"
import { usePirSubmitProcessInitializer } from "./submit"

export const usePirProcessInitializer = () => ({
    ...usePirSubmitProcessInitializer(),
    ...usePirPreparationProcessInitializer()
})

export const PirProcesses = () => {
    const processRegistry = useProcessRegistry()
    const process = usePirProcessInitializer()
    
    processRegistry.registerProcess(process)

    return <></>
}