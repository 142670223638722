import { utils } from "apprise-frontend-core/utils/common";
import MockAdapter from "axios-mock-adapter/types";
import { designatedPorts } from "common/mocks/designatedPorts";
import { referencedataTypes } from "referencedata/model";


export const mockDesignatedPorts = (mock:MockAdapter) => {
        // mock calls
        mock.onGet(`/${referencedataTypes.designatedPort}`).reply((config)=>{
                return Promise.resolve([200,designatedPorts]).then(utils().wait(500))
        })

}