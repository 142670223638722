import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from '@ionic/react';
import { StepsNavBar } from 'components/Step/NavBar';
import { StepSwitchContent } from 'components/Step/SwitchContent';
import { boatOutline } from 'ionicons/icons';
import { Mutator } from 'lib/apprise-frontend-core/state/api';
import { usePirs } from 'pir/api';
import { PirCard } from 'pir/Card/Index';
import { Pir } from 'pir/model';
import { pirmodelapi } from 'pir/model/api';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toastApi } from 'toast/api';
import { useUser } from 'user/api';
import { User } from 'user/model';
import './Pir.scss';
import { steps } from './Steps';

type ContextProps = {
    set: (m:Mutator<Pir>)=>Promise<void>
}
const PirEditContext = React.createContext({} as ContextProps)

type Props = {
    pir:Pir,
    goToVessel,
    currentStep,
}
const Page = ({pir, goToVessel, currentStep}:Props) => {

    const formsCompleted = pirmodelapi(pir).formsCompleted(currentStep)

    return  <IonPage className="pir-page">
                <IonContent fullscreen>
                    <div className="pir-page-content">
                        <div className="pir-page-left">
                            <div className="pir-page-left-top">
                                {pir && <PirCard noLink noImage noProcess pir={pir}/>}
                                {pir && <div className="vessel-file-button">
                                    <IonFab vertical="top" horizontal="end">
                                        <IonFabButton mode="ios" onClick={goToVessel}>
                                            <IonIcon icon={boatOutline} />
                                        </IonFabButton>
                                    </IonFab>
                                </div>}
                            </div>
                            <div className={`pir-page-left-bottom ${formsCompleted ? 'forms-completed' : ''}`}>
                                {pir && currentStep && <StepsNavBar currentStep={currentStep} steps={steps} pir={pir}/>}
                            </div>
                        </div>
                        <div className="pir-page-right">
                            {pir && currentStep && <StepSwitchContent steps={steps} pir={pir} currentStep={currentStep}/>}
                        </div>
                    </div>
                </IonContent>
            </IonPage>
}

export const usePirEdit = () => React.useContext(PirEditContext)

export const PirCompilationPage = () => {

    let { id } = useParams<{id}>();
    
    const pirapi = usePirs()

    let history = useHistory();

    const match = useRouteMatch()

    const userapi = useUser()

    React.useEffect(()=>{
        pirapi.setLastOpenId(id)
    }, [id])

    const pir = pirapi.find(id)

    React.useEffect(()=>{
        if(!pir){
            history.push(`/reports`)
            toastApi().create({
                duration: 5000,
                color:"danger",
                message:`Your can't access Pir with id ${id}.`})
        }
    }, [pir])

    function goToVessel() {
        history.push(`${match.url}/vessel`)
    }

    const doRefresh = pirapi.fetchAll

    React.useEffect(() => {
        const asynctask= async () => {
            await doRefresh(undefined, true)
        }
        const interval = setInterval(() => {
            asynctask()
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const currentStep = steps.find(s=>!s.isCompleted(pir as Pir, userapi.current() as User)) || steps[steps.length-1]

    return !pir ? null : <Page pir={pir} goToVessel={goToVessel} currentStep={currentStep}/>
};