import { IonBackButton, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from "@ionic/react"
import { attachOutline, documentOutline, flagOutline, linkOutline, lockClosedOutline } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { usePirs } from "pir/api"
import { RemoteFile } from "pir/model"
import { getFileId, pirmodelapi } from "pir/model/api"
import { PirVesselPictures } from "pir/Vessel/Pictures"
import React, { useMemo, useRef, useState } from "react"
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom"
import './Files.scss'

const VesselFileContent = ({pir, onSetFilter, filter, onGoToFile}) => {
    const {t} = useIntl()
    const content = useRef<HTMLIonContentElement>(null)
    const forms = useMemo(() => pir ? pirmodelapi(pir).getForms() : [], [pir])
    return !pir ? null : <>

        <IonPage className="vessel-files-page">
        
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="secondary">
                    <IonButton target="_blank" href={`${process.env.REACT_APP_EPSM_WEBSITE}/vfd/${pir.fileId}/vesselfile.html`}>
                        {t('vesselPage.openOnEpsm')} <IonIcon icon={linkOutline} />
                    </IonButton>
                </IonButtons>

                <IonButtons slot="start">
                    <IonBackButton defaultHref="/"/>
                </IonButtons>
                
                <IonTitle>{pirmodelapi(pir).title()}</IonTitle>
            </IonToolbar>
            <IonToolbar>
                <IonChip outline color="dark">
                    <IonIcon icon={flagOutline} />
                    <IonLabel><small>{t('vesselPage.flagState')}:</small> {pir.vesselIdentity.flagState}</IonLabel>
                </IonChip>
                <IonChip outline color="dark">
                    <IonIcon icon={lockClosedOutline} />
                    <IonLabel><small>{t('vesselPage.vesselFile')}:</small> {pir.fileId}</IonLabel>
                </IonChip>
                
                {pir.vesselIdentity.imoId && <IonChip outline color="dark">
                    <IonLabel><small>{t('vesselPage.imoID')}:</small> {pir.vesselIdentity.imoId}</IonLabel>
                </IonChip>}

                <IonChip outline color="dark">
                    <IonLabel><small>{t('vesselPage.iotcID')}:</small> {pir.vesselIdentity.iotcId}</IonLabel>
                </IonChip>
                <IonChip outline color="dark">
                    <IonLabel><small>{t('vesselPage.ircs')}:</small> {pir.vesselIdentity.ircs}</IonLabel>
                </IonChip>
                {pir.vesselIdentity.mmsi ? <IonChip outline color="dark">
                    <IonLabel><small>{t('vesselPage.mmsi')}:</small> {pir.vesselIdentity.mmsi}</IonLabel>
                </IonChip> : null}
            </IonToolbar>
            <IonToolbar>
                <IonSegment value={filter} onIonChange={e => onSetFilter(e.detail.value as any)}>
                    <IonSegmentButton value="pictures">
                        {t('vesselPage.pictures')}
                    </IonSegmentButton>
                    {pirmodelapi(pir).getFormTypes(forms).map(t=><IonSegmentButton key={t} value={t}>
                        {t}
                    </IonSegmentButton>)}
                </IonSegment>
            </IonToolbar>
        </IonHeader>
        <IonContent ref={content}>

            { filter === 'pictures' && <PirVesselPictures wrapperRef={content} pir={pir}/>}

            {forms.filter(f=>f.typeOfForm === filter).map(f=>{
                return [
                    <IonItemDivider>
                        <IonLabel>{f.name}</IonLabel>
                    </IonItemDivider>,

                    <IonItem onClick={()=>onGoToFile(f)} button detail key={f.name}>
                        <IonIcon size="small" icon={documentOutline} />
                        <IonBadge slot="end">{f.typeOfForm}</IonBadge>
                        <IonLabel><div>{f.name}</div><small>{f.submitDate}</small></IonLabel>
                    </IonItem>,

                    ...f.attachments.map(a=>
                        <IonItem className="attachment" onClick={()=>onGoToFile(a)} button detail key={a.name}>
                            <IonIcon size="small" icon={attachOutline} />
                            <IonBadge slot="end">{f.typeOfForm}</IonBadge>
                            <IonLabel><div>{a.name}</div><small>{a.contentType}</small></IonLabel>
                        </IonItem>
                    )
                ]
            })}
        </IonContent>
    </IonPage>
    </>
}

export const VesselFiles = () => {

    const match = useRouteMatch()

    let { id, fileType } = useParams<{ id, fileType }>();

    const history = useHistory()
    const location = useLocation()

    const pirapi = usePirs()

    const pir = pirapi.find(id)

    const base = `/pir/${id}/vessel`

    const goToFile = (f:RemoteFile) => {
        history.push(`${base}/file/${getFileId(f)}`)
    }

    const goToFileType = (filter:string) => {
        if(filter != fileType)
            history.push(`${base}/category/${filter}`)
    }

    return useMemo(() => <VesselFileContent
    pir={pir}
    filter={fileType || 'pictures'}
    onSetFilter={goToFileType}
    onGoToFile={goToFile}/>, [id, fileType])
}