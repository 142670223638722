export const vmss = [
  {
    "id": 1,
    "code": 1,
    "nameEn": "Inmarsat",
    "nameFr": "Inmarsat"
  },
  {
    "id": 2,
    "code": 2,
    "nameEn": "Inmarsat C",
    "nameFr": "Inmarsat C"
  },
  {
    "id": 3,
    "code": 3,
    "nameEn": "Inmarsat D/D+",
    "nameFr": "Inmarsat D/D+"
  },
  {
    "id": 4,
    "code": 4,
    "nameEn": "Iridium",
    "nameFr": "Iridium"
  },
  {
    "id": 5,
    "code": 5,
    "nameEn": "Argos",
    "nameFr": "Argos"
  },
  {
    "id": 6,
    "code": 6,
    "nameEn": "Orbcomm",
    "nameFr": "Orbcomm"
  },
  {
    "id": 7,
    "code": 7,
    "nameEn": "Qualcomm",
    "nameFr": "Qualcomm"
  },
  {
    "id": 8,
    "code": 8,
    "nameEn": "No VMS",
    "nameFr": "Aucun VMS"
  },
  {
    "id": 9,
    "code": 9,
    "nameEn": "VMS Unspecified type",
    "nameFr": "VMS Non spécifié"
  }
]