import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonMenu, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from '@ionic/react'
import { UserIcon } from 'components/Usericon'
import { downloadOutline, globeOutline, moonOutline, refreshOutline, textOutline, trashBinOutline } from 'ionicons/icons'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import React from 'react'
import { useServiceWOrker } from 'serviceWorkerRegistration'
import { getPWAInstalledAppEvent, useSettings } from 'settings/api'
import { fontSizes, fontSizesInfo, fontSizesList, languages, languagesInfo, languagesList } from 'settings/model'
import { useInstall } from 'settings/usePWAInstall'
import { useState as useApplicationState } from 'state/api'
import { useUser } from 'user/api'
import { clearAllData } from 'utils'
import { RestoreButton } from './RestoreButton'
import './UserMenu.scss'
import { DownloadTheDataModal } from './DownloadTheDataModal'
import { alertController } from '@ionic/core';




export const UserMenu = () => {
    const {t} = useIntl()
    const installApp = useInstall()
    const settingsapi = useSettings()
    const {darkMode, showAdvancedFeatures, language, fontSize} = settingsapi.all()
    const userapi = useUser()
    const user = userapi.current()
    const stateApi = useApplicationState()
    const [showDownloadModal, setShowDownloadModal] = React.useState(false)
    const {updateServiceWorker, ingestAvailableUpdate} = useServiceWOrker()

    const pwaInstalledAppEvent = getPWAInstalledAppEvent()

    const updateAvailable = settingsapi.all().updateAvailable

    const showClearAllModal = async () => {
      const dialog = await alertController.create({
          header: t('dialog.clearAll.header'),
          message: t('dialog.clearAll.message'),
          buttons: [
          {
              text: t('dialog.clearAll.no'),
              role: 'cancel'
          },
          {
              text: t('dialog.clearAll.yes'),
              handler: ()=>clearAllData()
          }]
      });
      dialog.present()
  }
    
    return <IonMenu side="end" swipeGesture content-id="content" menuId="user-menu">
    <IonHeader>
      <IonToolbar>
        <UserIcon/>
        <IonTitle><div className="title-content">{user?.name}</div></IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent id="content">
      <IonList lines="none" color="transparent">
        
        <IonItem onClick={()=>installApp(pwaInstalledAppEvent)} button disabled={pwaInstalledAppEvent==null}
          color="transparent">
          <IonLabel>{t('usermenu.installTheApplication')}</IonLabel>
          <IonIcon icon={downloadOutline}/>
        </IonItem>

        {!updateAvailable && <IonItem onClick={updateServiceWorker} button
          color="transparent">
          <IonLabel>{t('usermenu.checkForUpdates')}</IonLabel>
          <IonIcon icon={refreshOutline}/>
        </IonItem>}

        {updateAvailable && <IonItem onClick={ingestAvailableUpdate} button
          color="success">
          <IonLabel>{t('usermenu.installAvailableUpdate')}</IonLabel>
          <IonIcon icon={refreshOutline}/>
        </IonItem>}
        

        <IonItemDivider></IonItemDivider>
        
        <IonItem color="transparent">
          <IonIcon slot="start" icon={moonOutline}/>
          <IonLabel>{t('settings.darkMode')}</IonLabel>
          <IonToggle onIonChange={(e)=>settingsapi.set('darkMode', e.detail.checked)} checked={darkMode}/>
        </IonItem>

        <IonItem color="transparent">
          <IonIcon slot="start" icon={globeOutline}/>
          <IonLabel>{t('settings.language.label')}</IonLabel>
          <IonSelect value={language} placeholder={t('settings.language.placeholder')} onIonChange={(e)=>settingsapi.changeLanguage(e.detail.value)}>
            {languagesList.map(l=>
              <IonSelectOption key={l} value={languages[l]}>{t(languagesInfo[languages[l]].label)}</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>

        <IonItem color="transparent">
          <IonIcon slot="start" icon={textOutline}/>
          <IonLabel>{t('settings.fontsize.label')}</IonLabel>
          <IonSelect value={fontSize} placeholder={t('settings.fontsize.placeholder')} onIonChange={(e)=>settingsapi.set('fontSize', e.detail.value)}>
            {fontSizesList.map(l=>
              <IonSelectOption key={l} value={fontSizes[l]}>{t(fontSizesInfo[fontSizes[l]].label)}</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>

        <IonItemDivider></IonItemDivider>
        
        <IonItem color="transparent">
          <IonLabel>{t('usermenu.showAdvancedFeatures')}</IonLabel>
          <IonToggle onIonChange={(e)=>settingsapi.set('showAdvancedFeatures', e.detail.checked)} checked={showAdvancedFeatures}/>
        </IonItem>
        {
          showAdvancedFeatures && [
            <IonItemDivider><IonLabel>Advanced features</IonLabel></IonItemDivider>,
            <IonItem>
              <IonLabel>v0.0.1</IonLabel>
            </IonItem>,
            <IonItem color="danger" button onClick={()=>showClearAllModal()}>
              <IonIcon slot="end" icon={trashBinOutline}/>
              <IonLabel>Erase all the data</IonLabel>
            </IonItem>,
            <IonItem button onClick={()=>setShowDownloadModal(true)}>
              <IonIcon slot="end" icon={downloadOutline}/>
              <IonLabel>Download data</IonLabel>
            </IonItem>,
            <RestoreButton/>
          ]
        }

      </IonList>
    </IonContent>
    <IonFooter>
      <IonButton onClick={()=>userapi.logout()} expand="block">{t('user.logout')}</IonButton>
    </IonFooter>
    <DownloadTheDataModal show={showDownloadModal} onClose={()=>setShowDownloadModal(false)}/>
  </IonMenu>
}