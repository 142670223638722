import { AxiosRequestConfig } from "axios"

export type Token = {
    jwt: string
    expire: number
}

export const getTokenExpirationInMilliseconds = () => {
    return (((getToken()?.expire || 100)-30)*1000)
}

let token:Token | null

export interface Data<T> {
    data: T
}

// const onCall = (config: AxiosRequestConfig, state: State) => {
//     config.headers['Authorization'] = `Bearer ${getToken()}`
//     console.log('staring a call here')
// }

export const initToken = () => token = null
export const getToken = () => token
export const setToken = (t:Token | null) => {
    token = t
}

export const baseConfig:()=>AxiosRequestConfig = () => {
   
    return {
        timeout: 30000,
        baseURL: process.env.REACT_APP_BACKEND,
        withCredentials: true
    }
}

export const authConfig:()=>AxiosRequestConfig = () => ({
    ...baseConfig(),
    headers: { Authorization: `Bearer ${getToken()?.jwt}` }
})