import { IonFab, IonFabButton, IonIcon, IonLabel } from "@ionic/react"
import { MoreButtons } from "components/MoreButtons"
import { StepContent } from "components/Step/Content"
import { StepDescription } from "components/Step/Description"
import { StepLink } from "components/Step/Link"
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model"
import { getPath } from "components/Step/utils"
import { useStateRef } from "components/useStateRef"
import { createOutline } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { usePirs } from "pir/api"
import { statuses } from "pir/model"
import { pirmodelapi } from "pir/model/api"
import React, { useEffect } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { ReleaseClaimButton } from "../ClaimedAndPrepared/Button"
import { FormStepButton } from "../FormStepButton"
import { StartTheInspectionModal } from "./StartTheInspectionModal"

const Content = ({pir, isDefault}:StepContentProps) => {

    const isCompleted = pir && pirmodelapi(pir).isInspecting()
    const {t} = useIntl()

    return <StepContent isDefault={isDefault} isForm={false} title={isCompleted ? t('inspection.start.started.title') : t('inspection.start.default.title')}>
        <StepDescription>
            {isCompleted ? t('inspection.start.started.subtitle') : t('inspection.start.default.subtitle')}
        </StepDescription>
        </StepContent>
    }

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    
    const {t} = useIntl()
    const isCompleted = pir && pirmodelapi(pir).inspectionStarted()
    const isDisabled = !isDefault
    const date = isCompleted && pir.lifecycle && pir.lifecycle[statuses.inspecting] && pir.lifecycle[statuses.inspecting].date || undefined

    return <StepLink 
        disabled={true}
        completed={isCompleted}
        isDefault={isDefault}
        first={first}
        last={last}
        isForm={false}
        path={getPath(key)}
        title={isCompleted ? t('inspection.start.started.title') : t('inspection.start.default.title')}
        date={date}
     />
}

type State = {
    showModal,
    start
}

const initialState:State = {
    showModal: false,
    start: false,
}

const Button = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    const [state, setState, {set}] = useStateRef<State>(initialState)

    const match = useRouteMatch();
    const history = useHistory();

    useEffect(()=>{
        const startInspection = async () => {
            await pirapi.startInspection(pir)
            history.push(match.url)
        }
        if(state.start){
            startInspection()
        }
    }, [state.start])

    const {t} = useIntl()
    
    return <>
        <FormStepButton pir={pir} stepkey={key} noPhoto noCompleteStepButton>
            <MoreButtons><ReleaseClaimButton pir={pir}/></MoreButtons>
            <IonFab vertical="bottom" slot="fixed" horizontal="end">
                <IonFabButton
                    onClick={()=>set(s=>s.showModal=true)}
                    color="warning"
                    className="ion-fab-button-with-label" mode="ios">
                    <IonLabel>{t('inspection.start.button')}</IonLabel>
                    <IonIcon icon={createOutline} />
                </IonFabButton>
            </IonFab>
        </FormStepButton>
        <StartTheInspectionModal pir={pir} show={state.showModal} onClose={(close)=>set(s=>{s.showModal=false;if(close){s.start=true}})}/>
    </>
}

const key = "inspection started"
export const InspectionStarted:Step = {
    key,
    isCompleted: (pir) => pirmodelapi(pir).inspectionStarted(),
    Link,
    Button,
    Content
}