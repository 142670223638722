import { IonInput, IonLabel } from "@ionic/react";
import React from 'react';


type Props = {
    value?:string,
    placeholder?,
    onOpen?
    onClick?:(s:string)=>void,

}
export const TypeAheadViewer = ({onOpen, value, placeholder}:Props) => {
    const isEmpty = !value?'empty':''
    return <IonLabel
    className={`input-like ${isEmpty?'empty':''}`}
    onClick={onOpen}>
        {isEmpty ? (placeholder || '') : value}
    </IonLabel>
}