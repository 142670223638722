import { Authority } from "authorities/model"
import { initialAuthorities } from "authorities/state"
import { DesignatedPort, ImageType } from "common"
import { initialDesignatedPorts } from "designatedPort/state"
import { DocumentType } from "documentType/model"
import { initialDocumenttypes } from "documentType/state"
import { FishingArea } from "fishingArea/model"
import { initialFishingAreas } from "fishingArea/state"
import { Flag } from "flag/model"
import { initialFlags } from "flag/state"
import { Gear } from "gear/model"
import { initialGears } from "gear/state"
import { initialImagetypes } from "imageType/state"
import { Port } from "port/model"
import { initialPorts } from "port/state"
import { ProductForm } from "productForm/model"
import { initialProductForms } from "productForm/state"
import { Purpose } from "purpose/model"
import { initialPurposes } from "purpose/state"
import { Rfmo } from "rfmo/model"
import { initialRfmos } from "rfmo/state"
import { Species } from "species/model"
import { initialSpecies } from "species/state"
import { initialSpeciesForFishingAuthorization } from "speciesForFishingAuthorization/state"
import { VesselType } from "vesseltype/model"
import { initialVesseltype } from "vesseltype/state"
import { Vms } from "vms/model"
import { initialVmss } from "vms/state"

export type ReferencedataState = {

    flag: Flag[],
    gear: Gear[],
    rfmo: Rfmo[],
    vesselType: VesselType[],
    port: Port[],
    designatedPort: DesignatedPort[],
    vms: Vms[],
    purpose: Purpose[],
    documentType: DocumentType[]
    imageType: ImageType[]
    authority: Authority[]
    fishingArea: FishingArea[]
    species: Species[],
    productForms: ProductForm[],
    initialized?: boolean,
    refreshed?:boolean,
    speciesForFishingAuthorization: Species[],
}

export const initialReferencedata:ReferencedataState = {

    flag: initialFlags,
    gear: initialGears,
    rfmo: initialRfmos,
    vesselType: initialVesseltype,
    port: initialPorts,
    designatedPort: initialDesignatedPorts,
    vms: initialVmss,
    purpose: initialPurposes,
    documentType: initialDocumenttypes,
    imageType: initialImagetypes,
    authority: initialAuthorities,
    fishingArea: initialFishingAreas,
    species: initialSpecies,
    productForms: initialProductForms,
    initialized: undefined,
    refreshed: undefined,
    speciesForFishingAuthorization: initialSpeciesForFishingAuthorization
}