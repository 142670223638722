import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonSlide, IonSlides, IonText, IonTitle, IonToolbar } from "@ionic/react"
import { close, informationCircleOutline } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { Pir } from "pir/model"
import React from "react"
import { PirCard, PirCardPlaceholder } from "pir/Card/Index"
import { pirmodelapi } from "pir/model/api"

type Props = {
    pirs:Pir[],
    title:string,
    noItems?
}

export const PirSlider:React.FC<Props> = ({pirs, title, noItems}) => {

    const {t} = useIntl()

    const [showInfo, setShowInfo] = React.useState(false)

    return !((pirs && pirs.length>0) || noItems) ? null : <div className="pir-slider">
        
        <div className="pir-slider-title">
            <h2>{t(`${title}.group.title`)}</h2>
            <IonButton onClick={()=>setShowInfo(true)} shape="round" color="dark" fill='clear'>
                <IonIcon slot="icon-only" icon={informationCircleOutline} />
            </IonButton>
        </div>
        
        <IonModal mode="ios" swipeToClose onDidDismiss={()=>setShowInfo(false)} isOpen={showInfo} cssClass='pir-slider-modal'>
            <IonHeader>
                <IonToolbar>
                    <IonTitle><div className="title-content">{t(`${title}.infomodal.title`)}</div></IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={()=>setShowInfo(false)}><IonIcon slot="icon-only" icon={close}></IonIcon></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem lines="none">
                    <IonText><p>{t(`${title}.infomodal.text`)}</p></IonText>
                </IonItem>
            </IonContent>
        </IonModal>

        <IonSlides key={pirs.map(p=>pirmodelapi(p).id).join('')} options={{
            slidesPerView: 2.75,
            spaceBetween: 24
        }}>
            {
                pirs.length == 0 && noItems ?
                [1,2,3].map((pir,i)=><IonSlide key={i}><PirCardPlaceholder/></IonSlide>)
                :
                pirs.map((pir,i)=><IonSlide key={i}><PirCard showCommentBadge pir={pir}/></IonSlide>)   
            }
        
        </IonSlides>
    </div>
}
