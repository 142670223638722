import { useContext } from 'react'
import { SettingsLoader, SettingsLoaderProps } from './loader'
import { SettingsContext, SettingsProvider } from './provider'
export type SettingsProps = SettingsLoaderProps

// @refresh reset
// initialises the model and loads settingss, and mounts the state if required.
export const Settings = (props: SettingsProps) => {

    const { mock, children } = props

    const context = useContext(SettingsContext)

    const content = <SettingsLoader >
                {children}
            </SettingsLoader>

    return context ? content : <SettingsProvider>{content}</SettingsProvider>

}