import { BaseState, initialBaseState } from "state"
import { Pir } from "./model"

export type PirState = BaseState & {
    pirs: Pir[],
    lastOpenId: string | null
}

export type PirStateObject = {
    pirstate: PirState
}

export const initialPirs:PirStateObject = {
    pirstate: {
        ...initialBaseState,
        pirs: [],
        lastOpenId: null
    }
}


