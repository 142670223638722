import { Process, Task, taskMethod, taskMethods, taskStatuses, taskStatusesProperties } from "process/model";
import { JSON } from "process/tasks";
import { v4 as uuid } from 'uuid';

export const processModelApi = (p:Process) => {

    const self = {

        addTask: (t:Task) => {p.tasks.push(t); return self}
        
        ,

        process: p
        
        ,
        
        isCompleted: () => p.tasks.filter(t=>t.status === taskStatuses.RESOLVED || (t.canIgnore && t.status === taskStatuses.IGNORED)).length === p.tasks.length

        ,

        containsIgnorable: () => p.tasks.filter(t=>t.canIgnore && t.status === taskStatuses.REJECTED).length > 0

        ,

        getTasksToRegister: () => p.tasks.filter(t=>t.status === taskStatuses.UNDEFINED)

        ,

        getTasksToResolve: () => p.tasks.filter(t=>t.status === taskStatuses.COMPLETED)
        
        ,

        getRegectedTasks: () =>  p.tasks.filter(t=>t.status === taskStatuses.REJECTED)

        ,

        getIgnorableTasks: () =>  p.tasks.filter(t=>t.status === taskStatuses.REJECTED && t.canIgnore)

        ,

        getPercentage: () => {
            let percentage = 0
            const taskPercentageContribution = 100/p.tasks.length
            p.tasks.forEach(t=>{
                const tval = taskStatusesProperties[t.status].percentage
                if(tval>=0){
                    percentage += taskPercentageContribution*(getTaskPercentage(t)/100)
                }
            })
            return percentage
        }
        
        ,

        getStatus: () => {
            if(p.tasks.find(t=>t.status===taskStatuses.REJECTED)){
                return taskStatuses.REJECTED
            }
            
            if(p.tasks.find(t=>t.status===taskStatuses.REGISTERED)){
                return taskStatuses.REGISTERED
            }

            if(p.tasks.find(t=>t.status===taskStatuses.UNDEFINED)){
                return taskStatuses.UNDEFINED
            }
            
            if(p.tasks.find(t=>t.status===taskStatuses.COMPLETED)){
                return taskStatuses.COMPLETED
            }
            
            return taskStatuses.RESOLVED

        }
    }

    return self
}

export const getTaskPercentage = (t:Task) => {
    let percentage = 0
    let slotval
    const tval = taskStatusesProperties[t.status].percentage
    
    if(t.completion>0 && t.status === taskStatuses.REGISTERED){
        slotval = taskStatusesProperties[taskStatuses.COMPLETED].percentage * t.completion

    }else{
        slotval = tval
    }
    if(tval>=0){
        percentage += 100*(slotval/100)
    }
    return percentage
}
export const newProcess = (type, subject, description) => processModelApi({
    type,
    subject,
    tasks: [],
    uid: uuid(),
    description
})

export const newTask = () => {

    const self = {
        create: ({url, name, type=JSON, method, body, subject, description, subtype, canIgnore}:{method?:taskMethod} & Omit<Task, "uid"|"completion"|"method"|"status">) => ({
            completion: 0,
            type,
            subtype,
            method: method || taskMethods.GET,
            url,
            uid: uuid(),
            body,
            subject,
            status: taskStatuses.UNDEFINED,
            description,
            name,
            canIgnore
        } as Task)

        ,

        data: ({url, method, type=JSON, body, subject, description,subtype='', canIgnore}:
            {url:string, method?:taskMethod, body?:any, type?:string, subject: any, description?:string, subtype?, canIgnore?}) =>
            self.create({url, type, method, body, subject, subtype, canIgnore, description:description || `getting data from ${url}`})
    }

    return self
}
