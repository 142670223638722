import { useTaskCall } from "./call"
import { onRegisterProps, TaskHandlers } from "./model"
import { useRegisterTask } from "./model/tasks"

export const JSON = 'JSON'

export const useTaskInitializer = () => {
    const call = useTaskCall()
    const registerTaskApi = useRegisterTask()
    const task:TaskHandlers = {
        [JSON] : {  
            onRegister: async (props:onRegisterProps) => {
                const {task} = props
                try {
                    const result = await call.fetch(task)
                    task.result = result
                    registerTaskApi.defaultRegister(props)
                } catch (error) {
                    registerTaskApi.defaultRegisterFail(props, error)
                }
            }
        }
    }
    return task
}