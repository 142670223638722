import { IonFab, IonFabButton, IonIcon, IonItemDivider, IonItemGroup, IonLabel, IonList, IonToggle } from "@ionic/react";
import { Item } from "components/Item";
import { MoreButtons } from "components/MoreButtons";
import { SignatureField } from "components/SignatureField";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { checkmarkOutline, pencilOutline } from "ionicons/icons";
import { usePirs } from "pir/api";
import { PirEdting, signatureStepKey, statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { stepContainsErrors } from "pir/model/inspectionErrorApi";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FormStepButton } from "./FormStepButton";
import {alertController} from '@ionic/core'
import { useIntl } from "lib/apprise-frontend-core/intl/api";

const Content = ({pir, isDefault}:StepContentProps) => {

    const readonly =  !pirmodelapi(pir).isSigning()

    const pirapi = usePirs()

    const disabled = readonly

    const set = !disabled ? pirapi.setUsing(pir as PirEdting) : (a:any)=>null

    const masterSignatureDisabled = disabled || pir.inspectionSignatures?.masterRefuses

    const inspectorSignatureDisabled = disabled || (!masterSignatureDisabled && !pir.inspectionSignatures?.masterSignature)

    const {t} = useIntl()

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.signatures.section.title')}>
            <StepDescription>
                {pirmodelapi(pir).hasStatusMoreThan(statuses.signing) ?
                t('inspection.signatures.section.subtitleSigned')
                :
                t('inspection.signatures.section.subtitle')}
            </StepDescription>
        <IonList>
            
            <IonItemGroup >
                <IonItemDivider>
                    <IonLabel>{t('inspection.signatures.masterSignature.label')}</IonLabel>
                </IonItemDivider>
                <Item disabled={disabled} description={disabled ? undefined : t('inspection.signatures.masterRefuses.help')}>
                    <IonLabel>{t('inspection.signatures.masterRefuses.label')}</IonLabel>
                    <IonToggle
                    color={pir.inspectionSignatures?.masterRefuses ? 'danger' : undefined}
                    checked={pir.inspectionSignatures?.masterRefuses ? true : false}
                    onIonChange={e=>e.detail.checked !== undefined && set((p)=>{
                        if(!p.inspectionSignatures){p.inspectionSignatures = {masterRefuses:false}}
                        p.inspectionSignatures.masterRefuses = e.detail.checked
                    })}/>
                </Item>
                <Item disabled={masterSignatureDisabled}
                description={t('inspection.signatures.masterSignature.help')}>
                    <SignatureField
                    value={pir.inspectionSignatures?.masterSignature}
                    disabled={masterSignatureDisabled}
                    onChange={v=>set((p)=>{
                        if(!p.inspectionSignatures){p.inspectionSignatures = {masterSignature:''}}
                        p.inspectionSignatures.masterSignature = v
                    })}/>
                </Item>
            </IonItemGroup>

            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('inspection.signatures.inspectorSignature.label')}</IonLabel>
                </IonItemDivider>
                <Item disabled={inspectorSignatureDisabled}
                description={t('inspection.signatures.inspectorSignature.help')}>
                    <SignatureField
                    value={pir.inspectionSignatures?.inspectorSignature}
                    onChange={v=>set((p)=>{
                        if(!p.inspectionSignatures){p.inspectionSignatures = {inspectorSignature:''}}
                        p.inspectionSignatures.inspectorSignature = v
                    })}/>
                </Item>
            </IonItemGroup>

        </IonList>
    </StepContent>
}

const key = signatureStepKey



const Button = ({pir, isDefault}:StepButtonProps) => {

    const isSigning = pirmodelapi(pir).isSigning()

    const pirsapi = usePirs()

    const match = useRouteMatch();
    const history = useHistory();
    const {t} = useIntl()

    const showStartSigningDialog = async (onStartSigning) => {
        
        const dialog = await alertController.create({
            header: t('dialog.startSigning.header'),
            message: t('dialog.startSigning.message'),
            buttons: [
              {
                text: t('dialog.startSigning.no'),
                role: 'cancel'
              },
              {
                text: t('dialog.startSigning.yes'),
                handler: onStartSigning
              }]
        });
            
        dialog.present() 
    }

    const startSigning = () => {
        showStartSigningDialog(()=>pirsapi.set(pirmodelapi({...pir}).updateStatus(statuses.signing)))
    }

    const completeSigning = async () => {
        await pirsapi.endInspection(pir)
        history.push(match.url)
    }

    const canComplete = pir.inspectionSignatures?.inspectorSignature && (pir.inspectionSignatures.masterSignature || pir.inspectionSignatures?.masterRefuses)

    return <>
            {!isSigning &&
                <FormStepButton pir={pir} stepkey={key} noPhoto noCompleteStepButton>
                <IonFab vertical="bottom" slot="fixed" horizontal="end">
                    <IonFabButton
                        className="ion-fab-button-with-label"
                        color="warning"
                        disabled={!isDefault}
                        onClick={startSigning}
                        mode="ios">
                        <IonLabel>{t('inspection.signatures.section.start')}</IonLabel>
                        <IonIcon icon={pencilOutline} />
                    </IonFabButton>
                </IonFab>
                </FormStepButton>
            }

            {isSigning &&
                <FormStepButton pir={pir} stepkey={key} noPhoto noCompleteStepButton>
                    <IonFab vertical="bottom" slot="fixed" horizontal="end">
                        <IonFabButton
                        className="ion-fab-button-with-label"
                        color="success"
                        disabled={!canComplete}
                        onClick={completeSigning}
                        mode="ios">
                            <IonLabel>{t('inspection.signatures.section.complete')}</IonLabel>
                            <IonIcon icon={checkmarkOutline} />
                        </IonFabButton>
                    </IonFab>
                </FormStepButton>
            }
        </>
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    const {t} = useIntl()
    return StepLink({
        completed: pirmodelapi(pir).isReadyToSubmit() || pirmodelapi(pir).isSubmitting() || pirmodelapi(pir).isSubmitted(),
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.signing) && !isDefault,
        icon:pencilOutline,
        first,
        last,
        path:getPath(key),
        isSignature:true,
        title:t('inspection.signatures.section.title'),
        hasError: stepContainsErrors(key, pir as PirEdting)
    })}

export const Signature:Step = {
    key,
    path: getPath(key),
    isSignature:true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=>{
        return pirmodelapi(pir).isReadyToSubmit() || pirmodelapi(pir).isSubmitting() || pirmodelapi(pir).isSubmitted()
    }
}