import { FileTasks } from 'file/tasks'
import { useContext } from 'react'
import { PirLoader, PirLoaderProps } from './loader'
import { PirProcesses } from './processes'
import { PirContext, PirProvider } from './provider'
import { PirTasks } from './tasks'

export type PirProps = PirLoaderProps

// @refresh reset
// initialises the model and loads pirs, and mounts the state if required.
export const Pirs = (props: PirProps) => {

    const { mock, children } = props

    const context = useContext(PirContext)

    const content = <PirLoader mock={mock}>
                        <PirProcesses/>
                        <PirTasks/>
                        <FileTasks/>
                        {children}
                    </PirLoader>

    return context ? content : <PirProvider>{content}</PirProvider>

}