import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { usePirs } from "pir/api"
import { Pir } from "pir/model"
import { convertExtendedRemoteToInternal, pirmodelapi } from "pir/model/api"
import { useProcessRegistry } from "process/api"
import { useTaskCall } from "process/call"
import { onRegisterProps, onResolveProps, TaskHandlers } from "process/model"
import { newTask } from "process/model/api"
import { useRegisterTask, useResolveTask } from "process/model/tasks"
import { useEffect } from "react"
import { usePirCall } from "./calls"

export const EXTENDS_PIR_SUBTYPE = 'PIREXTENDSUBTYPE'
export const PIR_VERIFY_SUBMIT = 'PIR_VERIFY_SUBMIT'

export const usePirTasks = () => {
    const {t} = useIntl()
    const createGetExtendedPirTask = (p:Pir) => newTask().data({
        url:`/offline-api/pir/${pirmodelapi(p).getPirFormId()}`,
        type: EXTENDS_PIR_SUBTYPE,
        subject: pirmodelapi(p).id(),
        description: t('task.getExtendedInfo')
    })

    const createVerifyPirSubmitTask = (p:Pir) => newTask().data({
        url:`/offline-api/pir/${pirmodelapi(p).getPirFormId()}`,
        type: PIR_VERIFY_SUBMIT,
        subject: pirmodelapi(p).id(),
        description: t('task.verifyingPirSubmission')
    })

    return {createGetExtendedPirTask, createVerifyPirSubmitTask}
}


export const usePirTasksInitializer = () => {

    const pirs = usePirs()
    const resolvetasksapi = useResolveTask()
    const call = usePirCall()
    const registerTaskApi = useRegisterTask()

    const task:TaskHandlers = {
        [EXTENDS_PIR_SUBTYPE] : {  
            onResolve: (props:onResolveProps) => {
                const {task} = props
                resolvetasksapi.defaultResolve(props)
                const pir = pirs.find(task.subject)
                console.log('pir extended info:')
                console.log(task.result)
                pir && pirs.set({...pirmodelapi(pir).removeInspectionInfo(), extended:convertExtendedRemoteToInternal(task.result)})
            }
        },
        [PIR_VERIFY_SUBMIT] : {  
            onRegister: async (props:onRegisterProps) => {
                const {task} = props
                try {
                    const pir = pirs.find(task.subject)
                    if(!pir){
                        throw new Error('missing PIR in task')
                    }
                    const result = await call.completeSubmit(pir)
                    task.result = result
                    registerTaskApi.defaultRegister(props)
                } catch (error) {
                    registerTaskApi.defaultRegisterFail(props, error)
                }
            }
        }
    }

    return task
}

export const PirTasks = () => {
    const processRegistry = useProcessRegistry()
    const tasks = usePirTasksInitializer()
    
    processRegistry.registerTask(tasks)

    return <></>
}