import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react'
import { cameraOutline } from 'ionicons/icons'
import { PirAttachmentButton } from 'pir/Form/Attachment/Button'
import { Pir } from 'pir/model'
import { VesselPictureButton } from 'pir/Form/Pictures/Button'
import React from 'react'

type Props = {
    pir:Pir,
    type?: 'picture' | 'other'
}
export const AddPhotoStepButton = ({pir, type}:Props) => {

    return <IonFab vertical="bottom" slot="fixed" horizontal="end">
            <IonFabButton
                color="primary"
                mode="ios">
                 <IonIcon icon={cameraOutline} />
            </IonFabButton>
            <IonFabList side="top">
              <VesselPictureButton pir={pir}/>
              <PirAttachmentButton pir={pir} />
            </IonFabList>
          </IonFab>
}