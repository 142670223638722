export const fishingAreas = [
  {
    "id": 1,
    "code": "FIAUS",
    "nameEn": "Australia",
    "nameFr": "Australie"
  },
  {
    "id": 2,
    "code": "FIBHR",
    "nameEn": "Bahrain",
    "nameFr": "Bahre�n"
  },
  {
    "id": 3,
    "code": "FIBGD",
    "nameEn": "Bangladesh",
    "nameFr": "Bengladesh"
  },
  {
    "id": 4,
    "code": "FICOM",
    "nameEn": "Comoros",
    "nameFr": "Comores"
  },
  {
    "id": 5,
    "code": "FIDJI",
    "nameEn": "Djibouti",
    "nameFr": "Djibouti"
  },
  {
    "id": 6,
    "code": "FIEGY",
    "nameEn": "Egypt",
    "nameFr": "Egypte"
  },
  {
    "id": 7,
    "code": "FIERI",
    "nameEn": "Eritrea",
    "nameFr": "�rythr�e"
  },
  {
    "id": 8,
    "code": "FIFRA",
    "nameEn": "France (EU)",
    "nameFr": "France (UE)"
  },
  {
    "id": 9,
    "code": "FIFRAT",
    "nameEn": "France (Territories)",
    "nameFr": "France (Territoires)"
  },
  {
    "id": 10,
    "code": "FIIND",
    "nameEn": "India",
    "nameFr": "Inde"
  },
  {
    "id": 11,
    "code": "FIIDN",
    "nameEn": "Indonesia",
    "nameFr": "Indon�sie"
  },
  {
    "id": 12,
    "code": "FIIRN",
    "nameEn": "Iran",
    "nameFr": "Iran"
  },
  {
    "id": 13,
    "code": "FIIRQ",
    "nameEn": "Iraq",
    "nameFr": "Iraq"
  },
  {
    "id": 14,
    "code": "FIISR",
    "nameEn": "Israel",
    "nameFr": "Isra�l"
  },
  {
    "id": 15,
    "code": "FIJOR",
    "nameEn": "Jordan",
    "nameFr": "Jordanie"
  },
  {
    "id": 16,
    "code": "FIKEN",
    "nameEn": "Kenya",
    "nameFr": "Kenya"
  },
  {
    "id": 17,
    "code": "FIKWT",
    "nameEn": "Kuwait",
    "nameFr": "Kowe�t"
  },
  {
    "id": 18,
    "code": "FIMDG",
    "nameEn": "Madagascar",
    "nameFr": "Madagascar"
  },
  {
    "id": 19,
    "code": "FIMYS",
    "nameEn": "Malaysia",
    "nameFr": "Malaisie"
  },
  {
    "id": 20,
    "code": "FIMDV",
    "nameEn": "Maldives",
    "nameFr": "Maldives"
  },
  {
    "id": 21,
    "code": "FIMUS",
    "nameEn": "Mauritius",
    "nameFr": "Maurice"
  },
  {
    "id": 22,
    "code": "FIMOZ",
    "nameEn": "Mozambique",
    "nameFr": "Mozambique"
  },
  {
    "id": 23,
    "code": "FIMMR",
    "nameEn": "Myanmar",
    "nameFr": "Myanmar"
  },
  {
    "id": 24,
    "code": "FIOMN",
    "nameEn": "Oman",
    "nameFr": "Oman"
  },
  {
    "id": 25,
    "code": "FIPAK",
    "nameEn": "Pakistan",
    "nameFr": "Pakistan"
  },
  {
    "id": 26,
    "code": "FIQAT",
    "nameEn": "Qatar",
    "nameFr": "Qatar"
  },
  {
    "id": 27,
    "code": "FISAU",
    "nameEn": "Saudi Arabia",
    "nameFr": "Arabie saoudite"
  },
  {
    "id": 28,
    "code": "FISEY",
    "nameEn": "Seychelles",
    "nameFr": "Seychelles"
  },
  {
    "id": 29,
    "code": "FISGP",
    "nameEn": "Singapore",
    "nameFr": "Singapour"
  },
  {
    "id": 30,
    "code": "FISOM",
    "nameEn": "Somalia",
    "nameFr": "Somalie"
  },
  {
    "id": 31,
    "code": "FIZAF",
    "nameEn": "South Africa",
    "nameFr": "Afrique du Sud"
  },
  {
    "id": 32,
    "code": "FILKA",
    "nameEn": "Sri Lanka",
    "nameFr": "Sri Lanka"
  },
  {
    "id": 33,
    "code": "FISDN",
    "nameEn": "Sudan",
    "nameFr": "Soudan"
  },
  {
    "id": 34,
    "code": "FITZA",
    "nameEn": "Tanzania",
    "nameFr": "Tanzanie"
  },
  {
    "id": 35,
    "code": "FITHA",
    "nameEn": "Thailand",
    "nameFr": "Tha�lande"
  },
  {
    "id": 36,
    "code": "FITLS",
    "nameEn": "Timor Lest",
    "nameFr": "Timor Oriental"
  },
  {
    "id": 37,
    "code": "FIARE",
    "nameEn": "United Arabs Emirates",
    "nameFr": "�mirats Arabes Unis"
  },
  {
    "id": 38,
    "code": "FIGBR",
    "nameEn": "United Kingdom (OT)",
    "nameFr": "Royaume Uni (TOM)"
  },
  {
    "id": 39,
    "code": "FIYEM",
    "nameEn": "Yemen",
    "nameFr": "Y�men"
  },
  {
    "id": 40,
    "code": "FIICC",
    "nameEn": "RFMO Tuna - ICCAT",
    "nameFr": "ORGP Thon - ICCAT"
  },
  {
    "id": 41,
    "code": "FIIOT",
    "nameEn": "RFMO Tuna - IOTC",
    "nameFr": "ORGP Thon - IOTC"
  },
  {
    "id": 42,
    "code": "FICCS",
    "nameEn": "RFMO Tuna - CCSBT",
    "nameFr": "ORGP Thon - CCSBT"
  },
  {
    "id": 43,
    "code": "FIIAT",
    "nameEn": "RFMO Tuna - IATTC",
    "nameFr": "ORGP Thon - IATTC"
  },
  {
    "id": 44,
    "code": "FIWCP",
    "nameEn": "RFMO Tuna - WCPFC",
    "nameFr": "ORGP Thon - WCPFC"
  },
  {
    "id": 45,
    "code": "FINEA",
    "nameEn": "RFMO - NEAFC",
    "nameFr": "ORGP - NEAFC"
  },
  {
    "id": 46,
    "code": "FINAF",
    "nameEn": "RFMO - NAFO",
    "nameFr": "ORGP - NAFO"
  },
  {
    "id": 47,
    "code": "FINAS",
    "nameEn": "RFMO - NASCO",
    "nameFr": "ORGP - NASCO"
  },
  {
    "id": 48,
    "code": "FISEA",
    "nameEn": "RFMO - SEAFO",
    "nameFr": "ORGP - SEAFO"
  },
  {
    "id": 49,
    "code": "FISIO",
    "nameEn": "RFMO - SIOFA",
    "nameFr": "ORGP - SIOFA"
  },
  {
    "id": 50,
    "code": "FISPR",
    "nameEn": "RFMO - SPRFMO",
    "nameFr": "ORGP - SPRFMO"
  },
  {
    "id": 51,
    "code": "FICCA",
    "nameEn": "RFMO - CCAMLR",
    "nameFr": "ORGP - CCAMLR"
  },
  {
    "id": 52,
    "code": "FIGFC",
    "nameEn": "RFMO - GFCM",
    "nameFr": "ORGP - GFCM"
  },
  {
    "id": 53,
    "code": "FISWI",
    "nameEn": "RFB - SWIOFC",
    "nameFr": "RFB - SWIOFC"
  },
  {
    "id": 54,
    "code": "FIREC",
    "nameEn": "RFMO - RECOFI",
    "nameFr": "ORGP - RECOFI"
  },
  {
    "id": 55,
    "code": "FICRS",
    "nameEn": "CSRP",
    "nameFr": "CSRP"
  },
  {
    "id": 56,
    "code": "FICEC",
    "nameEn": "RFB - CECAF",
    "nameFr": "RFB - CECAF"
  },
  {
    "id": 58,
    "code": "FIAPF",
    "nameEn": "RFB - APFIC",
    "nameFr": "RFB - APFIC"
  },
  {
    "id": 59,
    "code": "FICSA",
    "nameEn": "Coastal State Atlantic Ocean",
    "nameFr": "�tat C�tier Oc�an Atlantique"
  },
  {
    "id": 60,
    "code": "FICSP",
    "nameEn": "Coastal State Pacific Ocean",
    "nameFr": "�tat C�tier Oc�an Pacifique"
  },
  {
    "id": 61,
    "code": "FIHSA",
    "nameEn": "High Sea Atlantic Ocean",
    "nameFr": "Haute Mer Oc�an Atlantique"
  },
  {
    "id": 62,
    "code": "FIHSI",
    "nameEn": "High Sea Indian Ocean",
    "nameFr": "Haute Mer Oc�an Indien"
  },
  {
    "id": 63,
    "code": "FIHSP",
    "nameEn": "High Sea Pacific Ocean",
    "nameFr": "Haute Mer Oc�an Pacifique"
  },
  {
    "id": 64,
    "code": "FINEI",
    "nameEn": "Not elsewhere identified",
    "nameFr": "Non identifi� ailleurs"
  },
  {
    "id": 65,
    "code": "FAO51",
    "nameEn": "FAO 51 (IOTC)",
    "nameFr": "FAO 51 (CTOI)"
  },
  {
    "id": 66,
    "code": "FAO57",
    "nameEn": "FAO 57 (IOTC)",
    "nameFr": "FAO 57 (CTOI)"
  },
  {
    "id": 67,
    "code": "FAO21",
    "nameEn": "FAO 21 (ICCAT)",
    "nameFr": "FAO 21 (CICTA)"
  },
  {
    "id": 68,
    "code": "FAO27",
    "nameEn": "FAO 27 (ICCAT)",
    "nameFr": "FAO 27 (CICTA)"
  },
  {
    "id": 69,
    "code": "FAO31",
    "nameEn": "FAO 31 (ICCAT)",
    "nameFr": "FAO 31 (CICTA)"
  },
  {
    "id": 70,
    "code": "FAO34",
    "nameEn": "FAO 34 (ICCAT)",
    "nameFr": "FAO 34 (CICTA)"
  },
  {
    "id": 71,
    "code": "FAO41",
    "nameEn": "FAO 41 (ICCAT)",
    "nameFr": "FAO 41 (CICTA)"
  },
  {
    "id": 72,
    "code": "FAO47",
    "nameEn": "FAO 47 (ICCAT)",
    "nameFr": "FAO 47 (CICTA)"
  },
  {
    "id": 73,
    "code": "FIUSA",
    "nameEn": "United states of America",
    "nameFr": "�tats-Unis d'Am�rique"
  },
  {
    "id": 74,
    "code": "FIGHN",
    "nameEn": "Ghana",
    "nameFr": "Ghana"
  },
  {
    "id": 75,
    "code": "FICAN",
    "nameEn": "Canada",
    "nameFr": "Canada"
  },
  {
    "id": 76,
    "code": "FAO48",
    "nameEn": "FAO 48 (ICCAT)",
    "nameFr": "FAO 48 (ICCAT)"
  },
  {
    "id": 77,
    "code": "FISPM",
    "nameEn": "France (St-pierre et miquelon)",
    "nameFr": "France (St-pierre et miquelon)"
  },
  {
    "id": 78,
    "code": "FIBRA",
    "nameEn": "Brazil",
    "nameFr": "Br�sil"
  },
  {
    "id": 79,
    "code": "FIMAR",
    "nameEn": "Maroc",
    "nameFr": "Maroc"
  },
  {
    "id": 80,
    "code": "FICIV",
    "nameEn": "Ivory Coast",
    "nameFr": "C�te d'ivoire"
  },
  {
    "id": 81,
    "code": "FIAGO",
    "nameEn": "Angola",
    "nameFr": "Angola"
  },
  {
    "id": 82,
    "code": "FIRUS",
    "nameEn": "Russia",
    "nameFr": "Russie"
  },
  {
    "id": 83,
    "code": "FIGAB",
    "nameEn": "Gabon",
    "nameFr": "Gabon"
  },
  {
    "id": 84,
    "code": "FICPV",
    "nameEn": "Cabo Verde",
    "nameFr": "Cap-vert"
  },
  {
    "id": 85,
    "code": "FIURY",
    "nameEn": "Uruguay",
    "nameFr": "Uruguay"
  },
  {
    "id": 86,
    "code": "FISTP",
    "nameEn": "Sao Tome and Principe",
    "nameFr": "S�o tom� et principe"
  },
  {
    "id": 87,
    "code": "FIVEN",
    "nameEn": "Venezuela",
    "nameFr": "Venezuela"
  },
  {
    "id": 88,
    "code": "FIGNQ",
    "nameEn": "Equatorial Guinea",
    "nameFr": "Guin�e �quatoriale"
  },
  {
    "id": 89,
    "code": "FIGIN",
    "nameEn": "Guinea REP",
    "nameFr": "Guin�e rep"
  },
  {
    "id": 90,
    "code": "FILBY",
    "nameEn": "Libya",
    "nameFr": "Libye"
  },
  {
    "id": 91,
    "code": "FITUN",
    "nameEn": "Tunisia",
    "nameFr": "Tunisie"
  },
  {
    "id": 92,
    "code": "FIPAN",
    "nameEn": "Panama",
    "nameFr": "Panama"
  },
  {
    "id": 93,
    "code": "FITTO",
    "nameEn": "Trinidad & tobago",
    "nameFr": "Trinit�-et-Tobago"
  },
  {
    "id": 94,
    "code": "FINAM",
    "nameEn": "Namibia",
    "nameFr": "Namibie"
  },
  {
    "id": 95,
    "code": "FIBRB",
    "nameEn": "Barbados",
    "nameFr": "Barbade"
  },
  {
    "id": 96,
    "code": "FIHND",
    "nameEn": "Honduras",
    "nameFr": "Honduras"
  },
  {
    "id": 97,
    "code": "FIDZA",
    "nameEn": "Alg�rie",
    "nameFr": "Alg�rie"
  },
  {
    "id": 98,
    "code": "FIMEX",
    "nameEn": "Mexico",
    "nameFr": "Mexico"
  },
  {
    "id": 99,
    "code": "FIISL",
    "nameEn": "Iceland",
    "nameFr": "Islande"
  },
  {
    "id": 100,
    "code": "FITUR",
    "nameEn": "Turkey",
    "nameFr": "Turquie"
  },
  {
    "id": 101,
    "code": "FINOR",
    "nameEn": "Norway",
    "nameFr": "Norvege"
  },
  {
    "id": 102,
    "code": "FINIC",
    "nameEn": "Nicaragua",
    "nameFr": "Nicaragua"
  },
  {
    "id": 103,
    "code": "FIGTM",
    "nameEn": "Guatemala",
    "nameFr": "Guatemala"
  },
  {
    "id": 104,
    "code": "FISEN",
    "nameEn": "Senegal",
    "nameFr": "Senegal"
  },
  {
    "id": 105,
    "code": "FIBLZ",
    "nameEn": "Belize",
    "nameFr": "Belize"
  },
  {
    "id": 106,
    "code": "FISYR",
    "nameEn": "Syria",
    "nameFr": "Syrie"
  },
  {
    "id": 107,
    "code": "FIVCT",
    "nameEn": "St vincent & the grenadines",
    "nameFr": "Saint-Vincent-et-les Grenadines"
  },
  {
    "id": 108,
    "code": "FINGA",
    "nameEn": "Nigeria",
    "nameFr": "Nig�ria"
  },
  {
    "id": 109,
    "code": "FIALB",
    "nameEn": "Albania",
    "nameFr": "Albanie"
  },
  {
    "id": 110,
    "code": "FISLE",
    "nameEn": "Sierra leone",
    "nameFr": "Sierra leone"
  },
  {
    "id": 111,
    "code": "FIMRT",
    "nameEn": "Mauritania",
    "nameFr": "Mauritanie"
  },
  {
    "id": 112,
    "code": "FICUW",
    "nameEn": "Cura�ao",
    "nameFr": "Cura�ao"
  },
  {
    "id": 113,
    "code": "FILBR",
    "nameEn": "Liberia",
    "nameFr": "Liberia"
  },
  {
    "id": 114,
    "code": "FISLV",
    "nameEn": "El salvador",
    "nameFr": "Salvador"
  },
  {
    "id": 115,
    "code": "FIGNB",
    "nameEn": "Guin�-bissau, rep. of",
    "nameFr": "Guin�-bissau, rep. of"
  },
  {
    "id": 116,
    "code": "FIBEL",
    "nameEn": "Belgium (EU)",
    "nameFr": "Belgique (UE)"
  },
  {
    "id": 117,
    "code": "FIHRV",
    "nameEn": "Croatia (EU)",
    "nameFr": "Croatie (UE)"
  },
  {
    "id": 118,
    "code": "FICYP",
    "nameEn": "Cyprus (EU)",
    "nameFr": "Chypre (UE)"
  },
  {
    "id": 119,
    "code": "FIDNK",
    "nameEn": "Denmark (EU)",
    "nameFr": "Danemark (UE)"
  },
  {
    "id": 120,
    "code": "FIEST",
    "nameEn": "Estonia (EU)",
    "nameFr": "Estonie (UE)"
  },
  {
    "id": 121,
    "code": "FIFIN",
    "nameEn": "Finland (EU)",
    "nameFr": "Finlande (UE)"
  },
  {
    "id": 122,
    "code": "FIDEU",
    "nameEn": "Germany (EU)",
    "nameFr": "Allemagne (UE)"
  },
  {
    "id": 123,
    "code": "FIGRC",
    "nameEn": "Greece (EU)",
    "nameFr": "Grece (UE)"
  },
  {
    "id": 124,
    "code": "FIIRL",
    "nameEn": "Ireland (EU)",
    "nameFr": "Irlande (UE)"
  },
  {
    "id": 125,
    "code": "FIITA",
    "nameEn": "Italy (EU)",
    "nameFr": "Italie (UE)"
  },
  {
    "id": 126,
    "code": "FILVA",
    "nameEn": "Latvia (EU)",
    "nameFr": "Lettonie (UE)"
  },
  {
    "id": 127,
    "code": "FILTU",
    "nameEn": "Lithuania (EU)",
    "nameFr": "Lithuanie (UE)"
  },
  {
    "id": 128,
    "code": "FIMLT",
    "nameEn": "Malta (EU)",
    "nameFr": "Malte (UE)"
  },
  {
    "id": 129,
    "code": "FINLD",
    "nameEn": "Netherlands (EU)",
    "nameFr": "Pays-Bas (UE)"
  },
  {
    "id": 130,
    "code": "FIPRT",
    "nameEn": "Portugal (EU)",
    "nameFr": "Portugal (UE)"
  },
  {
    "id": 131,
    "code": "FISVN",
    "nameEn": "Slovenia (EU)",
    "nameFr": "Slov�nie (UE)"
  },
  {
    "id": 132,
    "code": "FIESP",
    "nameEn": "Spain (EU)",
    "nameFr": "Espagne (UE)"
  },
  {
    "id": 133,
    "code": "FISWE",
    "nameEn": "Sweden (EU)",
    "nameFr": "Suede (UE)"
  },
  {
    "id": 134,
    "code": "FGBR2",
    "nameEn": "United Kingdom (EU)",
    "nameFr": "Royaume Uni (UE)"
  }
]