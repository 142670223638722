import { IonButton, IonItem, IonItemGroup, IonList } from "@ionic/react";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { useSize } from "components/useSize";
import { attachOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { PirAttachmentButton } from "pir/Form/Attachment/Button";
import { PirFormAttachments } from "pir/Form/Attachment/PirAttachmentPictures";
import { PirEdting, statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import React, { useRef } from "react";
import { FormStepButton } from "./FormStepButton";


const Content = ({isDefault, pir}:StepContentProps) => {

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)
    const {t} = useIntl()
    const pirsapi = usePirs()
    const edited = pir as PirEdting

    const set = pirsapi.setStep(key, edited)

    const ref = useRef(null)

    const Button = ({onClick})=> <IonButton slot="end" onClick={onClick} color="primary">{t('inspection.attachments.section.add')}</IonButton>

    return <StepContent isForm={true} isDefault title={t('inspection.attachments.section.title')}>
        <StepDescription>{t('inspection.attachments.section.subtitle')}</StepDescription>
        <IonItemGroup>
            <IonItem disabled={readonly}>
                <PirAttachmentButton Button={Button} pir={edited}/>
            </IonItem>
        </IonItemGroup>
        <IonList ref={ref}>
            {   
                edited.extended && edited.extended.attachments && 
                <PirFormAttachments
                disabled={readonly}
                pir={pir}
                attachments={edited.extended.attachments}
                wrapperRef={ref}
                onSet={set}
                />
            }
                
        </IonList>
    </StepContent>
}
export const pirattachmentKey = "pirattachments"
const key = pirattachmentKey

const Button = ({pir}:StepButtonProps) => {
    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {

    const attachmentsSize = pir.extended?.attachments?.length || 0

    const {adding} = useSize(attachmentsSize)
    
    const isCompleted = pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
    const isDisabled = pirmodelapi(pir).hasStatusLessThan(statuses.inspecting)

    const {t} = useIntl()

    return <StepLink 
        disabled={isDisabled}
        completed={isCompleted}
        isDefault={isDefault}
        first={first}
        last={last}
        isForm={true}
        path={getPath(key)}
        icon={attachOutline}
        title={t('inspection.attachments.section.title')}
        className={adding?'adding':''}
        badgeColor={adding ? 'success' : undefined}
        badge={attachmentsSize || undefined}
     />
}

export const PirAttachmentsForm:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}