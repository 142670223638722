import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    id?: number,
    onChange,
    placeholder?
    title?
}
export const VesseltypesSelect = ({id, onChange, placeholder, title}:Props) => {

    const vesseltypesapi = useReferencedata()
    const vesseltypes = vesseltypesapi.current().vesselType

    const value = vesseltypes.find(f=>f.id === id)
    
    return <Select title={title || "Select a vessel type"}
    placeholder={placeholder}
    value={value ? [value.code] : []}
    onChange={v=>onChange(v[0])}
    options={useReferencedataOptions({data:vesseltypes, showCode:true, codeAsValue:true})}/>
}