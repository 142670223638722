import { IonAvatar } from '@ionic/react'
import React from 'react'
import iotclogo from 'static/iotc.png'

import './Logo.scss'

export const Logo = () => {

    return <IonAvatar className="logo-wrapper">
        <img alt={"logo"} src={iotclogo}/>
    </IonAvatar>

}