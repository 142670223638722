
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { initialPirs, PirStateObject } from './state';

export const PirContext = createContext<State<PirStateObject>>(undefined!)

export const PirProvider = (props: PropsWithChildren<{}>) => {

    return <StateProvider  initialState={initialPirs} context={PirContext}>
            {props.children}
    </StateProvider>
}

