import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { useMockery } from 'lib/apprise-frontend-core/client/mocks'
import { PropsWithChildren } from 'react'
import { useReferencedata } from './api'
import { useReferencedataMockery } from './mockery'

export type ReferencedataLoaderProps = PropsWithChildren<Partial<{

    mock: boolean

}>>

export const ReferencedataLoader = (props: ReferencedataLoaderProps) => {

    const { children, mock } = props

    const referencedata = useReferencedata()
    const mocks = useMockery()
    const mockery = useReferencedataMockery()
    
    const activate = () => Promise.resolve(mock ? mocks.initMockery(mockery) : true).then(referencedata.init)
    const initialized = referencedata.initialized()

    const { content } = useRenderGuard({
        when: initialized === true,
        render: children,
        orRun: activate
    })

    return content
}