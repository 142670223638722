export const gears = [
  {
    "id": 1,
    "code": "PS",
    "nameEn": "Purse seine",
    "nameFr": "Senne tournante"
  },
  {
    "id": 2,
    "code": "LL",
    "nameEn": "Drifting longline",
    "nameFr": "Palangres dérivante"
  },
  {
    "id": 3,
    "code": "BB",
    "nameEn": "Pole and line",
    "nameFr": "Canne"
  },
  {
    "id": 4,
    "code": "GILL",
    "nameEn": "Gill nets",
    "nameFr": "Filets maillants"
  },
  {
    "id": 5,
    "code": "SUPP",
    "nameEn": "Supply Vessel Purse Seiners",
    "nameFr": "Bateau auxiliaire Senneurs"
  },
  {
    "id": 6,
    "code": "HABB",
    "nameEn": "Hand line and pole and line",
    "nameFr": "Ligne à main et Canne"
  },
  {
    "id": 7,
    "code": "TRAW",
    "nameEn": "Bottom and/or midwater trawls",
    "nameFr": "Chaluts de fond et/ou pelagiques"
  },
  {
    "id": 8,
    "code": "TROL",
    "nameEn": "Troll line",
    "nameFr": "Ligne de traîne"
  },
  {
    "id": 9,
    "code": "HAND",
    "nameEn": "Hand line",
    "nameFr": "Ligne à main"
  },
  {
    "id": 10,
    "code": "FLL",
    "nameEn": "Fresh Longline",
    "nameFr": "Palangre Frais"
  },
  {
    "id": 11,
    "code": "LLTR",
    "nameEn": "Longline and Troll line",
    "nameFr": "Palangre et Ligne de traîne"
  },
  {
    "id": 12,
    "code": "LINE",
    "nameEn": "Line",
    "nameFr": "Ligne"
  },
  {
    "id": 13,
    "code": "LLF",
    "nameEn": "Set longline",
    "nameFr": "Palangre calée"
  },
  {
    "id": 14,
    "code": "TRAP",
    "nameEn": "Traps",
    "nameFr": "Pièges"
  },
  {
    "id": 15,
    "code": "LLLI",
    "nameEn": "Longline and line",
    "nameFr": "Palangre et ligne"
  },
  {
    "id": 16,
    "code": "PSS",
    "nameEn": "Coastal purse seine",
    "nameFr": "Senne côtière"
  },
  {
    "id": 17,
    "code": "SJIG",
    "nameEn": "Squid Jigger",
    "nameFr": "Encornet (Calamar)"
  },
  {
    "id": 18,
    "code": "LISJ",
    "nameEn": "Line and squid jigging",
    "nameFr": "Ligne et encornet"
  },
  {
    "id": 19,
    "code": "LLPS",
    "nameEn": "Drifting longline and purse seine and trap",
    "nameFr": "Palangre dérivante et senne tournante et pièges"
  },
  {
    "id": 20,
    "code": "BBTR",
    "nameEn": "Pole and Line, Troll line",
    "nameFr": "Canne, Ligne de traîne"
  },
  {
    "id": 21,
    "code": "BBLI",
    "nameEn": "Pole and Line, Hand Line, Troll line",
    "nameFr": "Canne, Ligne à main, Ligne de traîne"
  },
  {
    "id": 22,
    "code": "HOOK",
    "nameEn": "Pole and Line, Hand Line, Longline, Troll line",
    "nameFr": "Canne, Ligne à main, Palangre, Ligne de traîne"
  },
  {
    "id": 23,
    "code": "LLBH",
    "nameEn": "Pole and Line, Hand Line, Longline",
    "nameFr": "Canne, Ligne à main, Palangre"
  },
  {
    "id": 24,
    "code": "LLHA",
    "nameEn": "Longline and hand line",
    "nameFr": "Palangre et Ligne à main"
  },
  {
    "id": 25,
    "code": "LLGI",
    "nameEn": "Longline and Gill nets",
    "nameFr": "Palangre et Filets mailants"
  },
  {
    "id": 26,
    "code": "LLTW",
    "nameEn": "Longline and Trawl",
    "nameFr": "Palangre et Chaluts de fond et/ou pelagiques"
  },
  {
    "id": 27,
    "code": "UNCL",
    "nameEn": "Unknown",
    "nameFr": "Inconnu"
  },
  {
    "id": 28,
    "code": "UNCV",
    "nameEn": "Reefer, Cargo",
    "nameFr": "Navire transporteur"
  }
]