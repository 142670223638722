import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { Pir } from './model'
import { usePirs } from './api'
import { pirmodelapi, pirsModelApi } from './model/api'

type Props = {
    value: Pir,
    onChange,
    placeholder?
    title?
    pirs?: Pir[]
}
export const PirSelect = ({value, onChange, placeholder, title, pirs:pirsProps}:Props) => {
    
    const pirsapi = usePirs()
    const pirs = pirsProps || pirsapi.all()

    const _value = value && pirs.find(p=>pirmodelapi(p).id() === pirmodelapi(value).id())

    const _onChange = (id) => onChange(pirs.find(p=>pirmodelapi(p).id() === id))
        
    return <Select title={title || placeholder }
    placeholder={placeholder}
    value={_value ? [pirmodelapi(_value).id()] : []}
    onChange={v=>_onChange(v[0])}
    options={pirs.map(f=>({value:pirmodelapi(f).id(), label:`${pirmodelapi(f).title()}`}))}/>
}