import { alertController } from '@ionic/core';
import { Loading } from "components/Loading";
import { Bridge } from "lib/apprise-frontend-core/utils/bridge";
import React from "react";

export const bridge : Partial<Bridge> = {

    Indicator: (props) => <Loading {...props}/>
    ,

    renderError: async (e,msg?,rethrow?) => {

        const alert = await alertController.create({
            header: msg,
            message: e.message,
        });
        alert.present()

        if (rethrow)
            throw e

    }

    ,

    renderDialog: async props => {
    
        const { title, msg, okLabel, canceLabel, onOk, onCancel } = props
        
        const alert = await alertController.create({
            header: title,
            message: msg,
            buttons: [
                {
                  text: canceLabel || `Cancel`,
                  role: 'cancel',
                  handler: onCancel
                },
                {
                  text: okLabel || 'ok',
                  handler: onOk
                }
            ]
        });
        alert.present()
    
    }

}