import { IonCard, IonCardContent, IonCardSubtitle, IonFab, IonFabButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { MoreButtons } from "components/MoreButtons";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { Fireworks } from 'fireworks/lib/react';
import { archiveOutline, cloudUploadOutline, closeCircleOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import moment from "moment";
import { usePirs } from "pir/api";
import { Pir } from "pir/model";
import { PirEdting, statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { stepContainsErrors } from "pir/model/inspectionErrorApi";
import { getPirSubmitProcesses, getPirSubmitVerificationProcesses } from "pir/processes/submit";
import { useProcess } from "process/api";
import { Process, taskStatusesProperties } from "process/model";
import { processModelApi } from "process/model/api";
import { ProcessFullViewer } from "process/Viewer/Full";
import { ProcessMiniViewer } from "process/Viewer/Mini";
import React from "react";
import { useSettings } from "settings/api";
import { useUser } from "user/api";
import { User } from "user/model";
import { FormStepButton } from "./FormStepButton";
import {alertController} from '@ionic/core'
import { getDatesDifferenceInSeconds } from "utils";

const submitFailed = (pir:Pir, process:Process | null) => {
    if(!pirmodelapi(pir).isSubmitting()){
        return false
    }

    if(!process && pir.lifecycle){
        const submittingDate=pir.lifecycle[statuses.submitting].date

        const elapsedFromSubmitting = getDatesDifferenceInSeconds(new Date(submittingDate), new Date())

        if(elapsedFromSubmitting > 60){
            return true
        }

        return false
    }

    return false
    
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const pirapi = usePirs()

    const {t} = useIntl()

    const set = pirapi.setUsing(pir as PirEdting)

    const processApi = useProcess()
    const process = getPirSubmitProcesses(pir, processApi.all()) || getPirSubmitVerificationProcesses(pir, processApi.all())

    const submittedDate = pir.lifecycle && pir.lifecycle[statuses.submitted] && pir.lifecycle[statuses.submitted].date
    
    const fxProps = {
        count: 3,
        colors: ['#cc3333', '#4CAF50', '#81C784'],
        calc: (props, i) => ({
          ...props,
          x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
          y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
        })
    }

    const submitHasFailed = submitFailed(pir, process)

    return <StepContent isForm={false}
        isDefault
        title={t('inspection.submit.section.title')}>
            <StepDescription>
                {pirmodelapi(pir).isReadyToSubmit() && t('inspection.submit.section.subtitleToSubmit')}
                {pirmodelapi(pir).isSubmitting() && !submitHasFailed && t('inspection.submit.section.subtitleSubmitting')}
                {pirmodelapi(pir).isSubmitted() && t('inspection.submit.section.subtitleSubmitted', {date:moment(submittedDate).format('ll')})}
                {submitHasFailed && t('inspection.submit.section.subtitleSubmitFailed')}
            </StepDescription>

            {pirmodelapi(pir).isSubmitted() && <><br/><StepDescription>{t('inspection.submit.section.moreInfoSubmitted')}</StepDescription></>}
        
        {pirmodelapi(pir).isSubmitting() && process && 
           <ProcessFullViewer process={process}/>
        }

        {pirmodelapi(pir).isSubmitted() && <>
        <Fireworks {...fxProps} />
        
        {/* {pir.lifecycle && pir.lifecycle[statuses.submitted] && pir.lifecycle[statuses.submitted].date &&
            <IonCard style={{borderRadius: '10px'}} mode="ios" color="danger">
                <IonCardContent>
                    <IonCardSubtitle><IonIcon icon={archiveOutline}/> {t('inspection.submit.archiveCard.title')}</IonCardSubtitle>
                    {t('inspection.submit.archiveCard.message')} {moment(pir.lifecycle[statuses.submitted].date).add(25, 'days').fromNow()}
                </IonCardContent>
            </IonCard>
        } */}

        </>}
    </StepContent>
}

const key = 'submit'

const Button = ({pir, isDefault}:StepButtonProps) => {

    const pirapi = usePirs()

    const userapi = useUser()

    const settingsapi = useSettings()
    const showAdvancedFeatures = settingsapi.all().showAdvancedFeatures

    const processApi = useProcess()
    const process = getPirSubmitProcesses(pir, processApi.all()) || getPirSubmitVerificationProcesses(pir, processApi.all())
    const status =  process && processModelApi(process).getStatus()

    const submitHasFailed = submitFailed(pir, process)


    const {t} = useIntl()

    const showArchiveDialog = async () => {
        const dialog = await alertController.create({
            header: t('dialog.archive.header'),
            message: t('dialog.archive.message'),
            buttons: [
            {
                text: t('dialog.archive.no'),
                role: 'cancel'
            },
            {
                text: t('dialog.archive.yes'),
                handler: ()=>pirapi.archive(pir)
            }]
        });
        dialog.present()
    }

    const user = userapi.current() as User
    return <>
            {   
                pirmodelapi(pir).isReadyToSubmit() && 
                <FormStepButton pir={pir} stepkey={key} noPhoto noCompleteStepButton>
                    <IonFab vertical="bottom" slot="fixed" horizontal="end">
                        <IonFabButton
                            onClick={()=>pirapi.submit(pir as PirEdting, user)}
                            disabled={pirmodelapi(pir).isSubmitting() || pirmodelapi(pir).isSubmitted()}
                            className="ion-fab-button-with-label"
                            color="primary"
                            mode="ios">
                                <IonLabel>{t('inspection.submit.section.submit')}</IonLabel>
                                <IonIcon icon={cloudUploadOutline} />
                            </IonFabButton>
                        
                    </IonFab>
                </FormStepButton>
            }

            {           
                submitHasFailed &&
                <FormStepButton pir={pir} stepkey={key} noPhoto noCompleteStepButton>
                    <IonFab vertical="bottom" slot="fixed" horizontal="end">
                        <IonFabButton
                            onClick={()=>pirapi.submit(pir as PirEdting, user)}
                            disabled={pirmodelapi(pir).isSubmitted()}
                            className="ion-fab-button-with-label"
                            color="primary"
                            mode="ios">
                                <IonLabel>{t('inspection.submit.section.retry')}</IonLabel>
                                <IonIcon icon={cloudUploadOutline} />
                            </IonFabButton>
                        
                    </IonFab>
                </FormStepButton>
            }

            {
                pirmodelapi(pir).isSubmitted() &&
                <FormStepButton pir={pir} stepkey={key} noPhoto noCompleteStepButton>
                    <IonFab vertical="bottom" slot="fixed" horizontal="end">
                        <IonFabButton
                            onClick={showArchiveDialog}
                            className="ion-fab-button-with-label"
                            color="danger"
                            mode="ios">
                                <IonLabel>{t('inspection.submit.section.archive')}</IonLabel>
                                <IonIcon icon={archiveOutline} />
                            </IonFabButton>
                    </IonFab>
                </FormStepButton>
            }

            {
                pirmodelapi(pir).isSubmitting() && !submitHasFailed && process && status &&
                <FormStepButton pir={pir} stepkey={key} noPhoto noCompleteStepButton>
                    {   
                        showAdvancedFeatures &&
                        <MoreButtons>
                            <IonFabButton
                            onClick={()=>pirapi.cancelSubmit(pir as PirEdting)}
                            disabled={!pirmodelapi(pir).isSubmitting()}
                            className="ion-fab-button-with-label"
                            color="primary"
                            mode="ios">
                                <IonLabel>{t('inspection.submit.section.cancel')}</IonLabel>
                                <IonIcon icon={closeCircleOutline} />
                            </IonFabButton>
                        </MoreButtons>
                    }
                    <IonFab className="claimed-button" vertical="bottom" slot="fixed" horizontal="end">
                        <IonItem color={taskStatusesProperties[status].color} className="claimed-button-status">
                            <div className="claimed-button-content">
                                <div className="claimed-button-head">
                                    <IonLabel>{process.description}</IonLabel>
                                    <IonIcon icon={taskStatusesProperties[status].icon} />
                                </div>
                                <div className="claimed-button-footer">
                                    <ProcessMiniViewer process={process}/>
                                </div>
                            </div>
                        </IonItem>
                    </IonFab>
                </FormStepButton>
            }


        </>
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) =>{
    const {t} = useIntl()
    return StepLink({
        completed: pirmodelapi(pir).isSubmitted(),
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.readytosubmit),
        first,
        last,
        icon:cloudUploadOutline,
        path:getPath(key),
        title:t('inspection.submit.section.title'),
        date: pirmodelapi(pir).isSubmitted() && pir.lifecycle && pir.lifecycle[statuses.submitted] && pir.lifecycle[statuses.submitted].date || undefined,
        isSubmit: true,
        hasError: stepContainsErrors(key, pir as PirEdting)
    })
}
export const Submit:Step = {
    key,
    path: getPath(key),
    isSubmit: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=>{
        return pirmodelapi(pir).isSubmitted()
    }
}