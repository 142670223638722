import { IonIcon, IonLabel } from "@ionic/react"
import { menuController } from "@ionic/core"
import { personCircleOutline } from "ionicons/icons"
import React from "react"

import './UserIcon.scss'
import { useUser } from "user/api"
import { useSettings } from "settings/api"

export const UserIcon = () => {

    const clickHandler = async (e:any)=>{
        e.stopPropagation();
        menuController.open()
    }

    const userapi = useUser()
    const user = userapi.current()

    const settings = useSettings()
    const haveUpdates = settings.all().updateAvailable

    return !user ? null : <div onClick={clickHandler} className={`user-icon ${haveUpdates ? 'update-available':''}`}>
        <IonIcon icon={personCircleOutline} />
        <IonLabel>{user?.deviceName}</IonLabel>
    </div>
}
