import { IonTitle } from '@ionic/react'
import React from 'react'

import "./Content.scss"

export const StepContent = ({isForm, isDefault, title, children}) => {

    const className = ["pir-step-content",
        ...(isDefault ? ["is-default"]:[]),
        ...(isForm ? ["is-form"]:[]),
    ].join(" ")

    return <div className={`step-content ${className}`}>
        <IonTitle><div className="title-content">{title}</div></IonTitle>
        {children}
        <div className="page-bottom-spacer"/>
    </div>
}