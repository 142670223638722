import { fallbackStateOver } from 'apprise-frontend-core/state/api'
import { utils } from 'apprise-frontend-core/utils/common'
import { useContext } from 'react'
import { Config } from './model'
import { ConfigContext, initialConfigState } from './state'



// quick access derivdations.
export const useBuildMode = () => {

    const development = useConfig().get().mode === 'development'

    return {development,production:!development}
}

export const useConfigMode = () => {

    const development = useConfig().get().mode === 'development'

    return {development,production:!development}
}


export const useConfig = <T=Config> () => {
    
    const state = useContext(ConfigContext) ?? fallbackStateOver(initialConfigState)
    
    const self = {

        static: () =>state.get().static as T & Config

        ,

        remote: () =>state.get().remote as T & Config

        ,


        get: () => self.remote() ?? self.static()

        ,

        addStatic: (config:Partial<Config>) => state.set(s=>s.static= utils().merge(self.static(),config))

        ,

        // merges remote configuration over static.
        addRemote: (config:Partial<Config>) => state.set(s=>s.remote= utils().merge(self.static() ?? {},config))


    }
        
    return self



}

