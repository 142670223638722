import { IonDatetime, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { InspectionErrors, PirEdting, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, containsErrors, getStepErrors } from "pir/model/inspectionErrorApi";
import { PortsTypeAhead } from "port/TypeAhead";
import { PurposesSelect } from "purpose/Select";
import React from "react";
import { useReferencedata } from "referencedata/api";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    lastPortOfCallFreeForm,
    lastPortOfCallEntryDate,
    purposes,
    purposeDetails
}

const useValidation = () => {
    const referencedataapi = useReferencedata()
    const purposes = referencedataapi.current().purpose
    const otherId = purposes.find(p=>p.code === 'OTH')?.id

    const {t} = useIntl()

    const validate:ValidateStep = (p) => {
        if(!p.extended){
            return {} as InspectionErrors<any>
        }

        const error:InspectionErrors<ValidationType> = {
            lastPortOfCallFreeForm: check(p.extended.pir.lastPortOfCallFreeForm, t('inspection.lastPortOfCall.label')).isEmpty().errors,
            // lastPortOfCallEntryDate: check(p.extended.pir.lastPortOfCallEntryDate, t('inspection.dateLastPortOfCall.label')).isEmpty().errors,
            purposes: check(p.extended.pir.purposes, 'Purposes').isEmpty().errors,
            purposeDetails: (otherId && p.extended.pir.purposes.includes(otherId)) ? check(p.extended.pir.purposeDetails, t('inspection.otherPurposes.label')).isEmpty().errors : []
        }

        return error
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const pirsapi = usePirs()

    const {t} = useIntl()

    const edited = pir as PirEdting

    const validate = useValidation()

    const set = pirsapi.setStep(key, edited, validate)

    const error:InspectionErrors<ValidationType> = getStepErrors(key, edited) || validate(edited)
    
    const referencedataapi = useReferencedata()
    const purposes = referencedataapi.current().purpose

    const otherId = purposes.find(p=>p.code === 'OTH')?.id

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)
    const noPurposeDetails = !edited.extended.pir.purposes.includes(otherId || 12)
    
    return <StepContent isForm={true} isDefault title={t('inspection.purpose.section.title')}>
        <StepDescription>{t('inspection.purpose.section.subtitle')}</StepDescription>
        <IonList>
            <IonItemGroup>
                <Item description={t('inspection.purpose.lastPortOfCall.help')} disabled={readonly} mandatory error={error.lastPortOfCallFreeForm}>
                    <IonLabel>{t('inspection.purpose.lastPortOfCall.label')}</IonLabel>
                    <PortsTypeAhead
                    title={t('inspection.purpose.lastPortOfCall.label')}
                    placeholder={t('inspection.purpose.lastPortOfCall.placeholder')}
                    value={edited.extended.pir.lastPortOfCallFreeForm}
                    onChange={(v)=>set((p)=>p.extended.pir.lastPortOfCallFreeForm = v )}/>
                </Item>
                <Item description={t('inspection.purpose.dateLastPortOfCall.help')} disabled={readonly} error={error.lastPortOfCallEntryDate}>
                    <IonLabel>{t('inspection.purpose.dateLastPortOfCall.label')}</IonLabel>
                    <IonDatetime placeholder={t('inspection.purpose.dateLastPortOfCall.placeholder')} value={edited.extended.pir.lastPortOfCallEntryDate ? new Date(edited.extended.pir.lastPortOfCallEntryDate).toISOString(): null}
                    onIonChange={e => set((p)=>p.extended.pir.lastPortOfCallEntryDate = e.detail.value ? new Date(e.detail.value!).getTime(): null )}/>
                </Item>
                <Item description={t('inspection.purpose.purposes.help')} disabled={readonly} mandatory error={error.purposes}>
                    <IonLabel>{t('inspection.purpose.purposes.label')}</IonLabel>
                    <PurposesSelect
                    placeholder={t('inspection.purpose.purposes.placeholder')}
                    ids={edited.extended.pir.purposes}
                    onChange={(v)=>set((p)=>p.extended.pir.purposes = v )}/>
                </Item>
                <Item description={t('inspection.purpose.otherPurposes.help')} disabled={readonly}
                    mandatory={!noPurposeDetails ? true : false}
                    error={error.purposeDetails}>
                    <IonLabel>{t('inspection.purpose.otherPurposes.label')}</IonLabel>
                    <IonInput
                    value={edited.extended.pir.purposeDetails}
                    disabled={noPurposeDetails}
                    placeholder={t('inspection.purpose.otherPurposes.placeholder')}
                    onIonChange={e=>set((p)=>p.extended.pir.purposeDetails = (e.detail.value!))}></IonInput>
                </Item>

            </IonItemGroup>
            
        </IonList>
    </StepContent>
}
const key = "purposeandportofcall"

const Button = ({pir}:StepButtonProps) => {

    const validate = useValidation()

    const isValid=!containsErrors(getStepErrors(key, pir as PirEdting) || validate(pir as PirEdting))

    return <FormStepButton stepkey={key} pir={pir} isValid={isValid}/>
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {

    const {t} = useIntl()

    const validate = useValidation()

    return <StepLink 
    completed={pir.inspectionstatus && pir.inspectionstatus[key] ? true : false}
    isDefault={isDefault}
    disabled={ pirmodelapi(pir).hasStatusLessThan(statuses.inspecting)}
    first={first}
    last={last}
    path={getPath(key)}
    isForm
    title={t('inspection.purpose.section.title')}
    hasError={ containsErrors(getStepErrors(key, pir as PirEdting) || validate(pir as PirEdting)) }
     />
}

export const PurposeAndPortOfCall:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}