import { useContext } from 'react'
import { UserContent } from './Content'
import { UserInitialiser, UserInitialiserProps } from './initialiser'
import { UserLoader, UserLoaderProps } from './loader'
import { UserContext, UserProvider } from './provider'
export type UserProps = UserInitialiserProps & UserLoaderProps

// @refresh reset
// initialises the model and loads users, and mounts the state if required.
export const User = (props: UserProps) => {

    const { mock, children } = props

    const context = useContext(UserContext)

    const content = <UserInitialiser>
            <UserLoader mock={mock}>
                <UserContent>
                    {children}
                </UserContent>
            </UserLoader>
    </UserInitialiser>

    return context ? content : <UserProvider>{content}</UserProvider>

}