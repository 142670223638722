import { IonBadge, IonIcon, IonTab, IonTabButton, IonText } from "@ionic/react"
import { alertOutline, wifi } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import React from "react"
import { useSettings } from "settings/api"
import { Logo } from "../Logo/Index"
import './AppStatus.scss'


export const AppStatus = () => {
    const {t} = useIntl()
    const Settings = useSettings()

    const onLine = Settings.all().onLine
    return <div className='app-status-item'>
    <Logo/>
    {
      onLine &&
      <IonBadge className="status-badge online" color="primary">
        <IonIcon icon={wifi}/>
        <IonText>{t('app.status.online')}</IonText>
      </IonBadge> 
    }

    {
      !onLine &&
      <IonBadge className="status-badge offline" color="danger">
        <IonIcon icon={alertOutline}/>
        <IonText>{t('app.status.offline')}</IonText>
      </IonBadge> 
    }
      
  </div>
}