
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { initialSettings, SettingsState } from './state';
export const SettingsContext = createContext<State<SettingsState>>(undefined!)

export const SettingsProvider = (props: PropsWithChildren<{}>) => {

    return <StateProvider  initialState={initialSettings()} context={SettingsContext}>
            {props.children}
    </StateProvider>
}

