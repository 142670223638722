import { getTokenExpirationInMilliseconds } from 'api'
import React, { useState } from 'react'
import { useUser } from './api'
import { Login } from './Login/Login'


export const UserContent:React.FC  = ({children}) => {
    const [loggedIn, setLoggedIn] = useState<undefined | boolean>(undefined)

    const userapi = useUser()
    const user = userapi.current()

    React.useEffect(()=>{
        const checkUserLoggedIn = async () => {
            const isLoggedIn = await userapi.isLoggedIn()
            if(isLoggedIn){
                setLoggedIn(true)
            }else{
                setLoggedIn(false)
            }
        }

        user !== undefined && checkUserLoggedIn()
        
    }, [user])

    React.useEffect(()=>{
        const checkUserLoggedIn = async () => {
            const isLoggedIn = await userapi.isLoggedIn()
            if(isLoggedIn){
                setLoggedIn(true)
            }else{
                setLoggedIn(false)
            }
        }

        const refreshToken= async () => {
            if(navigator.onLine){
                const token = await userapi.refreshToken()
                if(!token){
                    await checkUserLoggedIn()
                }
            }
        }

        if(loggedIn===true){
            const interval = setInterval(() => {
                refreshToken()
            }, getTokenExpirationInMilliseconds());
            return () => clearInterval(interval);
        }
    }, [loggedIn])
    
    return <User loggedIn={loggedIn} children={children}/>
}

const User = ({children, loggedIn}) => <>{loggedIn===false ? <Login/> : loggedIn===true ? children : null}</>

