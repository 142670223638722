import { bridge } from "./bridge";
import { ClientProvider } from "lib/apprise-frontend-core/client/client";
import { ConfigurationProvider } from "lib/apprise-frontend-core/config/configuration";
import { IntlProvider } from "lib/apprise-frontend-core/intl/intl";
import { ComponentBridge } from "lib/apprise-frontend-core/utils/bridge";
import { BusyGuard } from "lib/apprise-frontend-core/utils/busyguard";

export const AppriseApplication = ({children}) => 
<ComponentBridge bridge={bridge}>
    <BusyGuard>
        <IntlProvider>
            <ConfigurationProvider>
                <ClientProvider>
                    {children}
                </ClientProvider>
            </ConfigurationProvider>
        </IntlProvider>
    </BusyGuard>
</ComponentBridge>