import { StepContent } from "components/Step/Content";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { pirmodelapi } from "pir/model/api";
import React from "react";
import { useUser } from "user/api";
import { ClaimedButton } from "./ClaimedAndPrepared/Button";

const Content = ({pir, isDefault}:StepContentProps) => {

const userapi = useUser()
const user = userapi.current()
const isAvailable = pir && user && pirmodelapi(pir).isVailablefor(user) ? true : false

const {t} = useIntl()
return <StepContent isForm={false} isDefault={isDefault} title={isAvailable ? t('inspection.piravailable.title') : t('inspection.pirunavailable.title')}>
        {
            isAvailable && t('inspection.piravailable.subtitle')
        }
        {!isAvailable && t('inspection.pirunavailable.subtitle')}
        {!isAvailable && <strong> {pir.claimedByDevice}</strong>}
    </StepContent>
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    
    const userapi = useUser()
    const user = userapi.current()

    const isCompleted = pir && user && pirmodelapi(pir).isVailablefor(user) ? true : false

    const {t} = useIntl()
    return <StepLink 
        completed={isCompleted}
        isDefault={isDefault}
        disabled={true}
        first={first}
        last={last}
        isForm={false}
        path={getPath(key)}
        title={isCompleted ? t('inspection.piravailable.title') : t('inspection.pirunavailable.title')}
     />
}

const key = "piravailable"
export const PirAvailable:Step = {
    key,
    path: getPath(key),
    isForm: false,
    Link,
    Button: ClaimedButton,
    Content,
    isCompleted: (pir, user) => pir && user && pirmodelapi(pir).isVailablefor(user) ? true : false
}