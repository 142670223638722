import JSZip from 'jszip';
import FileSaver from 'file-saver';
export const getNumericId = (id:number | string) => typeof id == "string" ? parseInt(id) : id

export const swap = (arr, from, to) => {
    arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
}

export const move = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
}

export const clearAllData = async () => {
    const dbs = await (window.indexedDB as any).databases()
    dbs.forEach(db => { window.indexedDB.deleteDatabase(db.name) })
    window.location.reload()
}

export const deltaTreshHold = 10

export const getDeltaPercentage = (declared, verified) => (declared !== undefined && verified !== undefined && verified-declared !== 0 ) ?
    100 * Math.abs( (declared - verified) / ( (declared+verified)/2 )) : 0

export const  blobToFile = (theBlob: Blob, fileName:string): File => {
    var b: File = theBlob as any;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    (b as any).lastModified = new Date();
    (b as any).name = fileName;

    //Cast to a File() type
    return b;
}

export const sleep = (value: number) =>
    new Promise((resolve) => setTimeout(resolve, value));

export const downloadFile = ({ data, fileName, fileExtension, fileType }) => {
    return new Promise((resolve, reject) => {
        const zip = new JSZip();
        zip.file(`${fileName}.${fileExtension}`, data);
        zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: {
                level: 9
            }
        }).then(function (content) {
            FileSaver.saveAs(content, `${fileName}.zip`);
            resolve(content)
        });
    })
}
    
export const exportToJson = async (data, fileName, e:Event) => {
    //e.preventDefault()
    await downloadFile({
        data: JSON.stringify(data),
        fileName,
        fileExtension: 'json',
        fileType: 'text/json',
    })
}

export async function asyncForEach<T>(array: Array<T>, callback: (item: T, index: number) => void) {
    if(!array) return 
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index);
    }
}

export const getDatesDifferenceInSeconds = (startDate:Date, endDate:Date):number => {
    const msInSecond = 1000;
  
    return Math.round(
      Math.abs(endDate.getTime() - startDate.getTime()) / msInSecond,
    );
}