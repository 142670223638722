export const documentTypes = [
  {
    "id": 1,
    "code": "LECA",
    "nameEn": "Letter from the Competent Authority of the port State",
    "nameFr": "Lettre de l'authorité compétente de l'Etat du Port"
  },
  {
    "id": 2,
    "code": "AUTF",
    "nameEn": "Authorisation to Fish/licence issued by the flag State",
    "nameFr": "Autorisation de Pêche/license octroyée par l'Etat du Pavillon"
  },
  {
    "id": 3,
    "code": "FLCS",
    "nameEn": "Fishing Licence issued by a coastal State",
    "nameFr": "License de Pêche octroyée par un Etat Côtier"
  },
  {
    "id": 4,
    "code": "TRAU",
    "nameEn": "Transhipment Authorisation",
    "nameFr": "Autorisation de Transbordement"
  },
  {
    "id": 5,
    "code": "TRDE",
    "nameEn": "Transhipment Declaration",
    "nameFr": "Déclaration de Transbordement"
  },
  {
    "id": 6,
    "code": "LANA",
    "nameEn": "Landing authorisation/permit",
    "nameFr": "Autorisation/permis de débarquement"
  },
  {
    "id": 7,
    "code": "LAND",
    "nameEn": "Landing Declaration",
    "nameFr": "Déclaration de Débarquement"
  },
  {
    "id": 8,
    "code": "LANP",
    "nameEn": "Landing Declaration in last port of call",
    "nameFr": "Déclaration de Débarquement dans le port de dernière escale"
  },
  {
    "id": 9,
    "code": "AUEP",
    "nameEn": "Authorisation to enter port",
    "nameFr": "Autorisation d'entrée au port"
  },
  {
    "id": 10,
    "code": "VMSR",
    "nameEn": "VMS report",
    "nameFr": "Rapport SSN"
  },
  {
    "id": 11,
    "code": "VMST",
    "nameEn": "VMS track",
    "nameFr": "Tracé SSN"
  },
  {
    "id": 12,
    "code": "VCNR",
    "nameEn": "Vessel Certificate of National Registration",
    "nameFr": "Certificat d'immatriculation du navire"
  },
  {
    "id": 13,
    "code": "VITC",
    "nameEn": "Vessel International Tonnage Certificate",
    "nameFr": "Certificat international de jaugeage"
  },
  {
    "id": 14,
    "code": "CRLI",
    "nameEn": "Crew list",
    "nameFr": "Liste d'équipage"
  },
  {
    "id": 15,
    "code": "CAMA",
    "nameEn": "Cargo manifest",
    "nameFr": "Manifeste de fret"
  },
  {
    "id": 16,
    "code": "CAST",
    "nameEn": "Cargo/Stowage plan",
    "nameFr": "Plan d'arrimage/de fret"
  },
  {
    "id": 17,
    "code": "ISSC",
    "nameEn": "International Ship Security Certificate",
    "nameFr": "Certificat International de Sécurité du Navire"
  },
  {
    "id": 18,
    "code": "SAMC",
    "nameEn": "Safety Management Certificate",
    "nameFr": "Certificat de Gestion de la Sécurité"
  },
  {
    "id": 19,
    "code": "ILLC",
    "nameEn": "International Load Line Certificate",
    "nameFr": "Certificat international de franc-bord"
  },
  {
    "id": 20,
    "code": "SRSL",
    "nameEn": "Ship radio station licenses",
    "nameFr": "Licence de station radio de bord"
  },
  {
    "id": 21,
    "code": "CODR",
    "nameEn": "Construction drawings",
    "nameFr": "Dessin de construction"
  },
  {
    "id": 22,
    "code": "MSMD",
    "nameEn": "Minimum safe manning document",
    "nameFr": "Document des effectifs minimaux de sécurité"
  },
  {
    "id": 23,
    "code": "RENA",
    "nameEn": "Records of navigational activities",
    "nameFr": "Carnet de navigation"
  },
  {
    "id": 24,
    "code": "CMOR",
    "nameEn": "Certificates for masters, officers or ratings",
    "nameFr": "Permis du capitaine, officier"
  },
  {
    "id": 25,
    "code": "DIVA",
    "nameEn": "Document of Identification of the vessel agent",
    "nameFr": "Document d'identification de l'agent du navire"
  },
  {
    "id": 26,
    "code": "DIVM",
    "nameEn": "Document of Identification of the vessel master",
    "nameFr": "Document d'identification du capitaine du navire"
  },
  {
    "id": 27,
    "code": "DIFM",
    "nameEn": "Document of Identification of the vessel fishing master",
    "nameFr": "Document d'identification du capitaine de pêche du navire"
  },
  {
    "id": 28,
    "code": "DIOW",
    "nameEn": "Document of Identification of the owner of the vessel",
    "nameFr": "Document d'identification du Propriétairee du navire"
  },
  {
    "id": 29,
    "code": "DIOP",
    "nameEn": "Document of Identification of the operateur of the vessel",
    "nameFr": "Document d'identification de l'armateur du navire"
  },
  {
    "id": 30,
    "code": "DIBO",
    "nameEn": "Document of Identification of the beneficial owner of the vessel",
    "nameFr": "Document d'identification du Propriétaire bénéficiaire du navire"
  },
  {
    "id": 31,
    "code": "PEIR",
    "nameEn": "Previous inspection report (port and/or sea)",
    "nameFr": "Dernier rapport d'inspection (au port ou en mer)"
  },
  {
    "id": 32,
    "code": "CARE",
    "nameEn": "Catch report",
    "nameFr": "Rapport de capture"
  },
  {
    "id": 33,
    "code": "FILO",
    "nameEn": "Fishing logbook",
    "nameFr": "Livre de pêche"
  },
  {
    "id": 34,
    "code": "PRLO",
    "nameEn": "Processing logbook",
    "nameFr": "Livre de transformation"
  },
  {
    "id": 35,
    "code": "SRCL",
    "nameEn": "Ship Radio Communication logbook",
    "nameFr": "Carnet de communication radio du navire"
  },
  {
    "id": 36,
    "code": "FRLO",
    "nameEn": "Freezer logbook",
    "nameFr": "Livre de congélation"
  },
  {
    "id": 37,
    "code": "PWHO",
    "nameEn": "Photograph of vessel - whole",
    "nameFr": "Photographie du navire - entier"
  },
  {
    "id": 38,
    "code": "PPSB",
    "nameEn": "Photograph of vessel - Port Side Bow",
    "nameFr": "Photographie du navire - Babord proue"
  },
  {
    "id": 39,
    "code": "PPSS",
    "nameEn": "Photograph of vessel - Port Side Stern",
    "nameFr": "Photographie du navire - Babord poupe"
  },
  {
    "id": 40,
    "code": "PSSB",
    "nameEn": "Photograph of vessel - Starboard Side Bow",
    "nameFr": "Photographie du navire - Tribord proue"
  },
  {
    "id": 41,
    "code": "PSSS",
    "nameEn": "Photograph of vessel - Starboard Side Stern",
    "nameFr": "Photographie du navire - Tribord poupe"
  },
  {
    "id": 42,
    "code": "PFRB",
    "nameEn": "Photograph of vessel - Front Bow",
    "nameFr": "Photographie du navire - Avant proue"
  },
  {
    "id": 43,
    "code": "PSTE",
    "nameEn": "Photograph of vessel - Stern",
    "nameFr": "Photographie du navire - Poupe"
  },
  {
    "id": 44,
    "code": "PPBR",
    "nameEn": "Photograph of vessel - Port Side bridge",
    "nameFr": "Photographie du navire - Babord Passerelle"
  },
  {
    "id": 45,
    "code": "PSBR",
    "nameEn": "Photograph of vessel - Starboard Side bridge",
    "nameFr": "Photographie du navire - Tribord Passerelle"
  },
  {
    "id": 46,
    "code": "PAIRE",
    "nameEn": "Photograph of vessel - From air",
    "nameFr": "Photographie du navire - Air"
  },
  {
    "id": 47,
    "code": "NEI",
    "nameEn": "Not elsewhere identified",
    "nameFr": "Non identifié ailleurs"
  },
  {
    "id": 48,
    "code": "PIR",
    "nameEn": "Port Inspection Report",
    "nameFr": "Rapport d'inspection au port"
  },
  {
    "id": 49,
    "code": "PCL",
    "nameEn": "Last port clearance",
    "nameFr": "Dernier accès au port"
  },
  {
    "id": 50,
    "code": "MFR",
    "nameEn": "Monitoring forms (LAN/TRX)",
    "nameFr": "Formulaire de suivi (LAN/TRX)"
  },
  {
    "id": 51,
    "code": "PER",
    "nameEn": "Permit application",
    "nameFr": "Demande de permis"
  },
  {
    "id": 52,
    "code": "PERT",
    "nameEn": "Permit",
    "nameFr": "Permis"
  },
  {
    "id": 53,
    "code": "PROF",
    "nameEn": "Permit proof of payment",
    "nameFr": "Preuve payment permis"
  },
  {
    "id": 54,
    "code": "SPLI",
    "nameEn": "Species list",
    "nameFr": "Liste des especes"
  }
]