import { useContext } from 'react'
import { ProcesLoader, ProcesLoaderProps } from './Loader'
import { ProcesContext, ProcesProvider } from './provider'

export type ProcesProps = ProcesLoaderProps

// @refresh reset
// initialises the model and loads process, and mounts the state if required.
export const Process = (props: ProcesProps) => {

    const { children } = props

    const context = useContext(ProcesContext)

    const content = <ProcesLoader >
                {children}
            </ProcesLoader>

    return context ? content : <ProcesProvider>{content}</ProcesProvider>

}
