import { useFile } from "file/api"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { useBusyState } from "lib/apprise-frontend-core/utils/busyguard"
import { usePirs } from "pir/api"
import { Pir } from "pir/model"
import { pirmodelapi } from "pir/model/api"
import { useProcess } from "process/api"
import { useSettings } from "settings/api"
import { useUser } from "user/api"
import { exportToJson } from "utils"

export const useState = () => {

    const userApi = useUser()
    const pirsApi = usePirs()
    const settingsApi = useSettings()
    const processApi = useProcess()
    const fileApi = useFile()
    const busy = useBusyState()
    const {t} = useIntl()
    
    const self = {

        reset: async () => {
            await userApi.reset()
            await pirsApi.reset()
            await settingsApi.reset()
            await processApi.reset()
        },

        export: async (e, only?:Pir[], imageQuality?:number, maxSize?:number) => {
            
            await busy.toggle('exportData', t('loading.exportingData'))
            const user = await userApi.export()
            const pirs = await pirsApi.export(only)
            const settings = await settingsApi.export()
            const process =  await processApi.export()
            const file =  await fileApi.export(only, imageQuality, maxSize)

            //create JSON file
            await exportToJson({user, pirs, settings, process, file}, `pir-offline-export${only?.length === 1 ? '-'+pirmodelapi(only[0]).id() : ''}-${new Date().getTime().toString()}`, e)
            await busy.toggle('exportData')
        },

        restore: async ({user, pirs, settings, process, file}) => {
            await userApi.restore(user)
            await pirsApi.restore(pirs)
            await settingsApi.restore(settings)
            await processApi.restore(process)
            await fileApi.restore(file)
        }
        
    }

    return self

}