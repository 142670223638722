import * as React from 'react'
import { utils } from './common'


export type Placeholder = 'none' | 'list' | 'empty' | 'error'

export type WaitIndicatorProps = React.PropsWithChildren<{

    context: string
    spinning: boolean
    msg: React.ReactNode
    fullbleed: boolean

    style: React.CSSProperties

}>

export type DialogProps = {
    title: string,
    msg: string,
    okLabel: string
    onOk: () => void,
    canceLabel: string
    onCancel: () => void
}


export type Bridge = {

    placeholder: Partial<Record<Placeholder, React.ReactNode>>

    renderError: (_: Error, msg?: string, rethrow?: boolean) => any 

    renderDialog: (_: Partial<DialogProps>) => void

    Indicator: (_: Partial<WaitIndicatorProps>) => JSX.Element

}




export const initialComponentBridge: Bridge = {


    placeholder: {

        none: null,
        list: <><li>waiting...</li><li>waiting...</li></>,
        error: <div>something is wrong.</div>,
        empty: 'no data'

    }

    ,

    renderError: (e: Error, msg?: string, rethrow: boolean = true) => {

        alert(`${msg ?? e.message}${e["details"] ? `\n\n  ${e["details"]}` : ''}`)

        if (rethrow)
            throw e

    }

    ,

    renderDialog: ({ msg, onOk } = {}) => {

        if (window.confirm(msg))
            onOk?.()

    }

    ,

    Indicator: props => {

        const { spinning, msg, fullbleed, children, style } = props
        const overlay: React.CSSProperties = { position:fullbleed ? 'fixed' : 'absolute',background: "#fdfdfde0", zIndex: 9999, display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }

        return <div style={{ ...style, position: "relative"}}>
            {spinning && <div style={overlay}><div>{msg ?? 'loading...'}</div></div>}
            {children}
        </div>

    }
}



export type ComponentBridgeProps = React.PropsWithChildren<{

    bridge?: Partial<Bridge>
}>


const ComponentBridgeContext = React.createContext<Bridge>(initialComponentBridge)

//  intent-revealing wrapper
export const useComponentBridge = () => React.useContext(ComponentBridgeContext) ?? initialComponentBridge

//  overlays application's bridge over default one, the propagates down in context. 
export const ComponentBridge = (props: ComponentBridgeProps) => {

    const { bridge: clientBridge = {}, children } = props

    const bridge = utils().merge(initialComponentBridge, clientBridge) 

    return <ComponentBridgeContext.Provider value={bridge}>
        {children}
    </ComponentBridgeContext.Provider>

}

