import React from "react"
import { Route, Switch } from "react-router-dom"
import { VesselFile } from "./File"
import { VesselFiles } from "./Files"

export const PirVesselPage = ({match}) => {
    
    return <Switch>
            <Route exact path={`${match.path}`} component={VesselFiles}/>
            <Route path={`${match.path}/category/:fileType`} component={VesselFiles}/>
            <Route exact path={`${match.path}/file/:fileId`} component={VesselFile}/>
        </Switch>
}