import {  } from "pir/processes/preparation";
import { onResolveProcessProps } from ".";
import { useBaseProcess, useProcessRegistry } from "process/api";

export const useResolveProcess = () => {
    const processes = useBaseProcess()
    const registry = useProcessRegistry()

    const self = {
        defaulResolve: async (props:onResolveProcessProps) => {
            const {process} = props
            await processes.delete(process)
        },

        resolve: async (props:onResolveProcessProps) => {
            const {process} = props
            if(process.type && registry.processes()[process.type] && registry.processes()[process.type].onResolve){
                await registry.processes()[process.type]?.onResolve(props)
            }else{
                await self.defaulResolve(props)
            }
        }

    }
    return self
}
