import { mockAuthorities } from "authorities/mocks"
import MockAdapter from "axios-mock-adapter/types"
import { mockDesignatedPorts } from "designatedPort/mocks"
import { mockDocumenttypes } from "documentType/mocks"
import { mockFishingAreas } from "fishingArea/mocks"
import { mockFlags } from "flag/mocks"
import { mockGears } from "gear/mocks"
import { mockPorts } from "port/mocks"
import { mockProductForms } from "productForm/mocks"
import { mockPurposes } from "purpose/mocks"
import { mockRfmos } from "rfmo/mocks"
import { mockSpecies } from "species/mocks"
import { mockSpeciesForFishingAuthorization } from "speciesForFishingAuthorization/mocks"
import { mockVesseltypes } from "vesseltype/mocks"
import { mockVmss } from "vms/mocks"

export const useReferencedataMockery = () => {


    return (mock: MockAdapter) => {

        console.log("mocking referencedata...")
        mockGears(mock)
        mockFlags(mock)
        mockVesseltypes(mock)
        mockPorts(mock)
        mockDesignatedPorts(mock)
        mockVmss(mock)
        mockDocumenttypes(mock)
        mockRfmos(mock)
        mockPurposes(mock)
        mockAuthorities(mock)
        mockFishingAreas(mock)
        mockSpecies(mock)
        mockProductForms(mock)
        mockSpeciesForFishingAuthorization(mock)

    }
}