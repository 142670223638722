import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import React from 'react'
import { SelectableItem } from '.'

type Props = {
    value?: string,
    options: SelectableItem[],
    onChange: (v:string)=>void,
    className?
}

export const SelecSegment = ({options, onChange, value, className}:Props) => {
    return <IonSegment className={className} value={value}
        onIonChange={e => {
            (e.detail.value !== undefined && e.detail.value !== null && e.detail.value !== value) && onChange(e.detail.value!)
        }}>
        {options.map(o=>
            <IonSegmentButton key={o.value} value={o.value}>
                <IonLabel>{o.label}</IonLabel>
            </IonSegmentButton>
        )}
    </IonSegment>
}

type YesNoProps = {
    value?:boolean,
    onChange: (v:boolean) => void
}
export const YesNoSelect = ({value, onChange}:YesNoProps) => {
    return <SelecSegment className="yes-no-select"
    value={value === true ? 'yes' : (value === false ? 'no' : undefined)}
    onChange={(v)=>onChange(v==='yes' ? true : false)}
    options={[{label:'Yes', value:'yes'},{label:'No', value:'no'}]}
    />
}