import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    ids?: number[],
    onChange,
    disabled?,
    placeholder?
}

export const PurposesSelect = ({disabled, ids, onChange, placeholder}:Props) => {

    const referencedataapi = useReferencedata()
    const purposes = referencedataapi.current().purpose
    
    const value = ids && purposes.filter(f=>ids.includes(f.id)).map(v=>v.id)
    
    return <Select title="Select one or more purposes"
    multiple
    placeholder={placeholder}
    value={value || []}
    onChange={v=>onChange(v)}
    options={useReferencedataOptions({data:purposes})}/>
}

