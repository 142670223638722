import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    ids?: number[],
    onChange: (v:any[]) => void,
    placeholder?
    title?
}
export const FishingAreasSelect = ({ids, onChange, placeholder, title}:Props) => {
    
    const referencedataapi = useReferencedata()
    const fishingAreas = referencedataapi.current().fishingArea
    
    const value = ids && fishingAreas.filter(f=>ids.includes(f.id)).map(v=>v.id)
    
    return <Select title={title || placeholder || "Select one or more fishing area"}
    multiple
    placeholder={placeholder}
    value={value || []}
    onChange={v=>onChange(v)}
    options={useReferencedataOptions({data:fishingAreas})}/>
}