import { Flag } from "flag/model"
import { Gear } from "gear/model"
import { strEnum } from "model/utils"
import { VesselType } from "vesseltype/model"
import { Port } from "port/model"
import { Vms } from "vms/model"
import { DocumentType } from "documentType/model"
import { Rfmo } from "rfmo/model"
import { Purpose } from "purpose/model"
import { Authority } from "authorities/model"
import { FishingArea } from "fishingArea/model"
import { Species } from "species/model"
import { ProductForm } from "productForm/model"
import { ImageType } from "imageType/model"
import { DesignatedPort, SpeciesForFishingAuthorization } from "common"

export const referencedataTypes = strEnum([
    'flag',
    'gear',
    'vesselType',
    'port',
    'designatedPort',
    'vms',
    'documentType',
    'imageType',
    'rfmo',
    'purpose',
    'authority',
    'fishingArea',
    'species',
    'productForms',
    'speciesForFishingAuthorization'
])

export const referencedataTypesList = Object.keys(referencedataTypes) as ReferencedataTypes[]

export type ReferencedataTypes = keyof typeof referencedataTypes

export type ReferencedataContent = Flag[] | Gear[] | VesselType[] | Port[] | DesignatedPort[] |
Vms[] | DocumentType[] | ImageType[] | Rfmo[] | Purpose[] | Authority[] |
FishingArea[] | Species[] | ProductForm[] | SpeciesForFishingAuthorization[]