import { IonLoading } from '@ionic/react';
import { useIntl } from 'lib/apprise-frontend-core/intl/api';
import React from 'react';

type Props = {
    spinning?, children?, msg?, duration?
}
export const Loading = (props:Props) => {
    const {spinning, children, msg} = props

    // keeps track of the last non-null msg and uses it as fallback after the task is finished and before the spinner stops.
    const ref = React.useRef(msg)

    React.useEffect(()=> {

        if (msg)
        ref.current = msg
    })

  return <>
        
        <IonLoading
        isOpen={spinning ? true : false}
        message={msg ?? ref.current ?? 'Loading...'}/>

        {children}

    </>
};

// indicator: props => {

//     const { spinning, msg, children, style } = props
//     const overlay: React.CSSProperties = { opacity:.8, background: "#fdfdfde0", position: "absolute", zIndex: 1000, display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }

//     return <div style={{ ...style, position: "relative"}}>
//         {spinning && <div style={overlay}><div>{msg ?? 'loading...'}</div></div>}
//         {children}
//     </div>

// }