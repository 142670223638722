import { authConfig, baseConfig, Data } from "api";
import { useClient } from "lib/apprise-frontend-core/client/api";
import { ReferencedataTypes } from "./model";

export const useReferencedataCall = () => {
    
    const {at} = useClient()
    
    const self = {
        fetch: <T>(type:ReferencedataTypes) => {
            return at(`/reference_data/${type}`).get<Data<T>>(authConfig())
        }
    }

    return self
}