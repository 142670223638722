import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { calendarOutline } from 'ionicons/icons';
import moment from 'moment';
import { usePirs } from 'pir/api';
import { PirCalendarItem } from 'pir/CalendarItem/Index';
import { Pir } from 'pir/model';
import { pirmodelapi } from 'pir/model/api';
import React, {useMemo} from 'react';
import { pir } from 'worker/worker';
import './Calendar.scss';

const days:Date[] = [
    ...[1,2,3].map((d, di, items)=>moment().add(-items.length+di, 'days').toDate()), //3 days back
    ...[new Date()],                                              //today
    ...[1,2,3].map((d, di)=>moment().add(di+1, 'days').toDate()),  //3 days future
]

const getDayString = (d?:Date) => (d || (new Date())).toLocaleDateString("en-US")
export const Calendar = () => {
  const pirsapi = usePirs()
  const all = pirsapi.all()

  const dates:{[key:string]:Pir[]} = {}
  all.filter(p=>p.startOfInspection).map(p=>{
    const date = getDayString(new Date(p.startOfInspection))
    if(!dates[date])
      dates[date] = []
    dates[date].push(p)
  })

  const days = Object.keys(dates).map(k=>new Date(k)).sort((a,b)=>a > b ? 1 : -1)
  const pastDays = days.filter(d=>moment(d).isBefore(new Date(), 'day'))
  const futureDays = days.filter(d=>moment(d).isAfter(new Date(), 'day'))

  return useMemo(()=>CalendarPage({pastDays, futureDays, dates}), [all.map(p=>pirmodelapi(p).id()).join('')])
}

const CalendarPage = ({dates, pastDays, futureDays}:{
  dates:{[key:string]:Pir[]},
  pastDays:Date[],
  futureDays:Date[]
}) => {
  return <IonPage className="calendar-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle><IonIcon icon={calendarOutline}/> Calendar</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen> 
        <div className="calendar">
          {pastDays.map(d=><CalendarDay pirs={dates[getDayString(d)] || []} date={d} key={d.toISOString()}/>)}
          {[new Date()].map(d=><CalendarDay pirs={dates[getDayString(d)] || []} date={d} key={d.toISOString()}/>)}
          {futureDays.map(d=><CalendarDay pirs={dates[getDayString(d)] || []} date={d} key={d.toISOString()}/>)}
        </div>
      </IonContent>
    </IonPage>
}


const CalendarDay = ({date, pirs}:{date:Date, pirs:Pir[]}) => {
    const today = moment(date).isSame(new Date(), 'day')
    const past = moment(date).isBefore(new Date(), 'day')
    return useMemo(()=>CalendarDayContent({today, date, past, pirs}), [date.toISOString, pirs.map(p=>p.fileId).join('.')])
}

const CalendarDayContent = ({today, date, past, pirs}) => {
  return <div className={`calendar-day ${today?'today':''} ${past?'past':''}`}>
            <div className="calendar-date">
                <div className="month-year">{moment(date).format("MMM YY")}</div>
                <div className="calendar-number">{moment(date).format("D")}</div>
                <div className="day-of-the-week">{moment(date).format('dddd')}</div>
            </div>
            <div className="calendar-day-content">
              {pirs.map(p=><PirCalendarItem key={p.fileId} pir={p}/>)}
            </div>
        </div>
}