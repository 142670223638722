import { IonButton, IonButtons, IonDatetime, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonSegment, IonSegmentButton, IonToggle } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { FishingAreasSelect } from "fishingArea/Select";
import { FlagsSelect } from "flag/Select";
import copyAndApplyChanges from 'immer';
import { removeOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { Mutator } from "lib/apprise-frontend-core/state/api";
import { usePirs } from "pir/api";
import { InspectionErrors, PirEdting, statuses, TranshipmentInfo, TranshipmentInfoContent, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, stepContainsErrors } from "pir/model/inspectionErrorApi";
import { PortsTypeAhead } from "port/TypeAhead";
import { ProductFormsSelect } from "productForm/Select";
import React from "react";
import { SpeciesSelect } from "species/Select";
import { FormStepButton } from "../FormStepButton";
import './TranshipmentInfo.scss';


type ValidationType = {
    transhipmentInfo
}

const useValidation = () => {
    const {t} = useIntl()
    const validate:ValidateStep = (p) => {
        let errors:InspectionErrors<ValidationType> = {}
        errors.transhipmentInfo = [] as InspectionErrors<TranshipmentInfo>[]
        p.extended.pir.transhipmentInfo.forEach(r=>{
            const error:InspectionErrors<TranshipmentInfo> = {
                date: check(r.date, t('inspection.trxInfo.date.label')).isEmpty().isHigherThan(new Date().toISOString(), 'today').errors,
                isAtPort: check(r.isAtPort, t('inspection.trxInfo.portSea.label')).isEmpty().errors,
                atPort: r.isAtPort ? check(r.atPort, t('inspection.trxInfo.location.label')).isEmpty().errors : undefined,
                atSea: !r.isAtPort ? check(r.atSea, t('inspection.trxInfo.location.label')).isLatitudeLongitude().errors : undefined,
                name: check(r.name, t('inspection.trxInfo.vesselName.label')).isEmpty().errors,
                flagState: check(r.flagState, t('inspection.trxInfo.vesselFlag.label')).isEmpty().errors,
                idNumber: check(r.idNumber, t('inspection.trxInfo.idNumber.label')).isEmpty().errors,
                content: r.content.length === 0 ? check(r.content, 'Species/Product').isEmpty().errors :
                r.content.map(c=>({
                    species: check(c.species, t('inspection.trxInfo.species.label')).isEmpty().errors,
                    quantity: check(c.species, t('inspection.trxInfo.quantity.label')).isEmpty().errors,
                    catchArea: check(c.species, t('inspection.trxInfo.fishingAreas.label')).isEmpty().errors, 
                    productForm: check(c.species, t('inspection.trxInfo.productForm.label')).isEmpty().errors, 
                } as InspectionErrors<TranshipmentInfoContent>))
            }
            errors.transhipmentInfo && errors.transhipmentInfo.push(error)
        })

        return errors
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()
    
    const validate = useValidation()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const errors:InspectionErrors<ValidationType> = getStepErrors(key, edited)

    const set = pirsapi.setStep(key, edited, validate)

    const editItem = (i, _m:Mutator<TranshipmentInfo>) => set(c=>{
        c.extended.pir.transhipmentInfo[i] = copyAndApplyChanges(c.extended.pir.transhipmentInfo[i], (s: TranshipmentInfo) => void _m(s))
    })

    const removeItem = (i) => set(c=>{
        c.extended.pir.transhipmentInfo.splice(i, 1)
    })

    const addItem = () => set(c=>{
        const newInfo = {
            isAtPort: false,
            isReceived: false,
            content:[{} as TranshipmentInfoContent]
        } as TranshipmentInfo

        if(c.extended.pir.transhipmentInfo === null){
            c.extended.pir.transhipmentInfo = [newInfo]
        }
        c.extended.pir.transhipmentInfo && c.extended.pir.transhipmentInfo.unshift( newInfo )
    })

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.trxInfo.section.title')}>
        <StepDescription>{t('inspection.trxInfo.section.subtitle')}</StepDescription>
        <IonList>
            
            <IonItemGroup>
                <IonItem disabled={readonly}>
                    <IonButton disabled={readonly} slot="end" onClick={addItem} color="primary">{t('inspection.trxInfo.section.add')}</IonButton>
                </IonItem>
            </IonItemGroup>

            {[...edited.extended.pir.transhipmentInfo].map((c, ci, items) => {
                const transhipmentTitle = `${t('inspection.trxInfo.section.trxInfo')} ${items.length - ci}`
                const error = (((errors && errors.transhipmentInfo && errors.transhipmentInfo[ci]) || {}) as InspectionErrors<TranshipmentInfo>)
                return <IonItemGroup className="transhipment-info-group" key={transhipmentTitle}>
                     <IonItemDivider>
                        <IonLabel>{transhipmentTitle}</IonLabel>
                        
                        <IonButtons slot="end">
                            <IonButton color="danger" shape="round" slot="end" size="small"
                            onClick={()=>removeItem(ci)}>
                                {t('common.delete')}
                                <IonIcon icon={removeOutline} />
                            </IonButton>
                        </IonButtons>

                    </IonItemDivider>
                    
                    <Item description={t('inspection.trxInfo.date.help')} disabled={readonly} mandatory error={error.date}>
                        <IonLabel>{t('inspection.trxInfo.date.label')}</IonLabel>
                        <IonDatetime placeholder={t('inspection.trxInfo.date.placeholder')} max={new Date().toISOString()} value={c.date ? new Date(c.date).toISOString() : undefined}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.date = new Date(e.detail.value!).getTime())}/>
                    </Item>

                    <Item description={t('inspection.trxInfo.portSea.help')} disabled={readonly} mandatory error={error.isAtPort}>
                        <IonLabel>{t('inspection.trxInfo.portSea.label')}</IonLabel>
                        <IonSegment style={{flex:1}} value={c.isAtPort ? 'port' : 'sea'}
                        placeholder={t('inspection.trxInfo.portSea.placeholder')}
                        onIonChange={e => editItem(ci, (p)=>p.isAtPort = e.detail.value === 'port' ? true : false)}>
                            <IonSegmentButton value="port">
                                <IonLabel>{t('inspection.trxInfo.portSea.port')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="sea">
                                <IonLabel>{t('inspection.trxInfo.portSea.sea')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </Item>

                    <Item disabled={readonly} mandatory
                    description={!c.isAtPort ? t('inspection.trxInfo.location.helpSea') : t('inspection.trxInfo.location.helpPort')}
                    error={[...error.atPort || [], ...error.atSea || [] ]} key={`${c.isAtPort ? 'atport' : 'atsea'}`}>
                        <IonLabel>{t('inspection.trxInfo.location.label')}</IonLabel>
                        {
                            c.isAtPort && <PortsTypeAhead
                            placeholder={t('inspection.trxInfo.location.placeholderPort')}
                            value={c.atPort}
                            onChange={(v)=>editItem(ci, (p)=>p.atPort = v)}/>
                        }
                        {
                            !c.isAtPort && [
                            <IonInput
                            value={c.atSea && c.atSea.indexOf('-')>-1 ? c.atSea.split('-')[0] : undefined}
                            placeholder={t('inspection.trxInfo.location.latitude')}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                if(p.atSea && p.atSea.indexOf('-')>-1){
                                    p.atSea = `${e.detail.value}-${p.atSea.split('-')[1]}`
                                }else{
                                    p.atSea = `${e.detail.value}-`
                                }
                            })}></IonInput>
                            ,
                            <IonInput
                            value={c.atSea && c.atSea.indexOf('-')>-1 ? c.atSea.split('-')[1] : undefined}
                            placeholder={t('inspection.trxInfo.location.longitude')}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                if(p.atSea && p.atSea.indexOf('-')>-1){
                                    p.atSea = `${p.atSea.split('-')[0]}-${e.detail.value}`
                                }else{
                                    p.atSea = `-${e.detail.value}`
                                }
                            })}></IonInput>
                            ]
                        }
                    </Item>

                    <Item description={t('inspection.trxInfo.receiveOffload.help')} disabled={readonly} mandatory error={error.isReceived}>
                        <IonLabel>{t('inspection.trxInfo.receiveOffload.label')}</IonLabel>
                        <IonSegment style={{flex:1}} value={c.isReceived ? 'receive' : 'offload'}
                        onIonChange={e => editItem(ci, (p)=>p.isReceived = e.detail.value === 'receive' ? true : false)}>
                            <IonSegmentButton value="receive">
                                <IonLabel>Receive</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="offload">
                                <IonLabel>Offload</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </Item>

                    <Item description={t('inspection.trxInfo.vesselName.help')} disabled={readonly} mandatory error={error.name}>
                        <IonLabel>{t('inspection.trxInfo.vesselName.label')}</IonLabel>
                        <IonInput
                        value={c.name}
                        placeholder={t('inspection.trxInfo.vesselName.placeholder')}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.name = (e.detail.value!))}></IonInput>
                    </Item>

                    <Item description={t('inspection.trxInfo.vesselFlag.help')} disabled={readonly} mandatory error={error.flagState}>
                        <IonLabel>{t('inspection.trxInfo.vesselFlag.label')}</IonLabel>
                        <FlagsSelect id={c.flagState}
                        placeholder={t('inspection.trxInfo.vesselFlag.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.flagState = v )}/>
                    </Item>

                    <Item description={t('inspection.trxInfo.idNumber.help')} disabled={readonly} mandatory error={error.idNumber}>
                        <IonLabel>{t('inspection.trxInfo.idNumber.label')}</IonLabel>
                        <IonInput
                        value={c.idNumber}
                        placeholder={t('inspection.trxInfo.idNumber.placeholder')}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.idNumber = (e.detail.value!))}></IonInput>
                    </Item>
                    <IonItem disabled={readonly}>
                        <IonButton slot="end"  color="primary" onClick={()=>editItem(ci, (p)=>p.content.unshift({} as TranshipmentInfoContent))}>
                            {t('inspection.trxInfo.section.addSpeciesProduct')}
                        </IonButton>
                    </IonItem>
                    <Item disabled={readonly} mandatory error={error.content && error.content.length && error.content.length > 0 && error.content[0].message ? error.content : undefined}>
                        <IonList className="transhipment-info-content">
                        {c.content && c.content.map((content, contentIndex, contentItems)=>{
                            const contentTitle = `${t('inspection.trxInfo.section.speciesProduct')} ${contentItems.length - contentIndex}`
                            const contentError = (error.content && error.content.length > 0 && !error.content[contentIndex].message ? error.content[contentIndex] : {} )as InspectionErrors<TranshipmentInfoContent>
                            return [
                                <IonItemDivider key={contentTitle+'divider'}>
                                    <IonLabel>{contentTitle}</IonLabel>
                                    <IonButtons slot="end">
                                        <IonButton disabled={readonly} color="danger" shape="round" slot="end" size="small"
                                        onClick={()=>editItem(ci, (p)=>p.content.splice(contentIndex, 1))}>
                                            {t('common.delete')}
                                            <IonIcon icon={removeOutline} />
                                        </IonButton>
                                    </IonButtons>
                                </IonItemDivider>
                                ,
                                <Item description={t('inspection.trxInfo.fishingAreas.help')} key={contentTitle+'catchArea'} disabled={readonly} mandatory error={contentError.catchArea}>
                                    <IonLabel>{t('inspection.trxInfo.fishingAreas.label')}</IonLabel>
                                    <FishingAreasSelect ids={content.catchArea}
                                    placeholder={t('inspection.trxInfo.fishingAreas.placeholder')}
                                    onChange={(v)=>editItem(ci, (p)=>p.content[contentIndex].catchArea = v )}/>
                                </Item>
                                ,
                                <Item description={t('inspection.trxInfo.species.help')} key={contentTitle+'species'} disabled={readonly} mandatory error={contentError.species}>
                                    <IonLabel>{t('inspection.trxInfo.species.label')}</IonLabel>
                                    <SpeciesSelect ids={content.species ? [content.species] : undefined}
                                    placeholder={t('inspection.trxInfo.species.placeholder')}
                                    onChange={(v)=>editItem(ci, (p)=>p.content[contentIndex].species = v ? v[0] : undefined )}/>
                                </Item>
                                ,
                                <Item description={t('inspection.trxInfo.productForm.help')} key={contentTitle+'productForm'} disabled={readonly} mandatory error={contentError.productForm}>
                                    <IonLabel>{t('inspection.trxInfo.productForm.label')}</IonLabel>
                                    <ProductFormsSelect ids={content.productForm ? [content.productForm] : undefined}
                                    placeholder={t('inspection.trxInfo.productForm.placeholder')}
                                    onChange={(v)=>editItem(ci, (p)=>p.content[contentIndex].productForm = v ? v[0] : undefined )}/>
                                </Item>
                                ,
                                <Item description={t('inspection.trxInfo.quantity.help')} key={contentTitle+'quantity'} disabled={readonly} mandatory error={contentError.quantity}>
                                    <IonLabel>{t('inspection.trxInfo.quantity.label')}</IonLabel>
                                    <IonInput
                                    min="1"
                                    value={content.quantity}
                                    type="number"
                                    placeholder={t('inspection.trxInfo.quantity.placeholder')}
                                    onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.content[contentIndex].quantity = +(e.detail.value!))}></IonInput>
                                </Item>
                                ,
                                <Item description={t('inspection.trxInfo.objection.help')} key={contentTitle+'objection'} disabled={readonly} >
                                    <IonLabel>{t('inspection.trxInfo.objection.label')}</IonLabel>
                                    <IonToggle slot="end"
                                    color={content.objection ? 'success' : undefined}
                                    checked={content.objection ? true : false}
                                    onIonChange={e=>e.detail.checked !== undefined && editItem(ci, (p)=>p.content[contentIndex].objection = e.detail.checked)}/>
                                </Item>
                                ,
                                <Item description={t('inspection.trxInfo.comments.help')} style={{marginBottom:'30px'}} key={contentTitle+'comments'} disabled={readonly}>
                                    <IonLabel>{t('inspection.trxInfo.comments.label')}</IonLabel>
                                    <IonInput
                                    placeholder={t('inspection.trxInfo.comments.placeholder')}
                                    value={content.comments}
                                    onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.content[contentIndex].comments = (e.detail.value!))}></IonInput>
                                </Item>
                            ]
                        })}
                        </IonList>
                    </Item>
                </IonItemGroup>
            })}
        </IonList>
    </StepContent>
}
const key = "transhipmentInfo"

const Button = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) =>{
    const {t} = useIntl()
    return StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.trxInfo.section.title'),
        badge: pir.extended?.pir.transhipmentInfo.length,
        hasError: stepContainsErrors(key, pir as PirEdting)
    })}
export const TranshipmentInformation:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}