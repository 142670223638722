import { Pir } from "pir/model"
import { pirmodelapi } from "pir/model/api"
import React from "react"
import { useLocation } from "react-router-dom"
import { Step } from "./model"
import { getPath } from "./utils"

type Props = {
    steps: Step[],
    pir:Pir,
    currentStep:Step
}

export const StepSwitchContent = ({steps, pir, currentStep}:Props) => {
    
    const {hash} = useLocation()

    const stepsWithContent = steps.filter(s=>s.Content)
    const hashStep = stepsWithContent.find(s=>getPath(s)===hash)
    const step = hashStep !== undefined ? hashStep : currentStep
    const isInspecting = pirmodelapi(pir).isInspecting()
    
    return <>
        {!step.Content ? null : <step.Content isDefault={currentStep.key===step.key} pir={pir} />}
        {isInspecting && step.Button &&  <step.Button pir={pir}  isDefault={currentStep.key===step.key} />}
        {!isInspecting && currentStep && currentStep.Button && <currentStep.Button pir={pir}/>}
    </>
}