import { PirStateObject } from "pir/state"
import { ProcesState } from "process/state"
import { SettingsState } from "settings/state"
import { UserState } from "user/state"

export type AppState = PirStateObject & UserState & SettingsState & ProcesState

export type BaseState = {
    isInitialized: boolean
}

export const initialBaseState:BaseState= {
    isInitialized:false
}