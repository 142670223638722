import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonToggle } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { FishingAreasSelect } from "fishingArea/Select";
import copyAndApplyChanges from 'immer';
import { removeOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { Mutator } from "lib/apprise-frontend-core/state/api";
import { usePirs } from "pir/api";
import { ControlledCatches, InspectionErrors, PirEdting, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, stepContainsErrors } from "pir/model/inspectionErrorApi";
import { ProductFormsSelect } from "productForm/Select";
import React from "react";
import { SpeciesSelect } from "species/Select";
import { deltaTreshHold, getDeltaPercentage } from "utils";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    controlledCatches
}

const useValidation = () => {
    const {t} = useIntl()
    const validate:ValidateStep = (p) => {
        let errors:InspectionErrors<ValidationType> = {}
        errors.controlledCatches = [] as InspectionErrors<ControlledCatches>[]
        p.extended.pir.controlledCatches.forEach(r=>{
            const error:InspectionErrors<ControlledCatches> = {
                species: check(r.species, t('inspection.catchOnboard.species.label')).isEmpty().errors,
                productForm: check(r.productForm, t('inspection.catchOnboard.productForm.label')).isEmpty().errors,
                catchArea: check(r.catchArea, t('inspection.catchOnboard.catchArea.label')).isEmpty().errors,
                quantity: check(r.quantity, t('inspection.catchOnboard.quantityOnboardDeclared.label')).isEmpty().errors,
                quantityToLand: check(r.quantityToLand, `${t('inspection.catchOnboard.quantityToBeLanded.label')} (${t('inspection.catchOnboard.section.declared')})`).isEmpty().isLowerThan(0, '0').errors,
                monitoredLandedQuantity: check(r.monitoredLandedQuantity, `${t('inspection.catchOnboard.quantityToBeLanded.label')} (${t('inspection.catchOnboard.section.verified')})`).isEmpty().isLowerThan(0, '0').errors,
                quantityToTransship: check(r.quantityToTransship, `${t('inspection.catchOnboard.quantityToBeTranshipped.label')} (${t('inspection.catchOnboard.section.declared')})`).isEmpty().isLowerThan(0, '0').errors,
                monitoredTranshippedQuantity: check(r.monitoredTranshippedQuantity, `${t('inspection.catchOnboard.quantityToBeTranshipped.label')} (${t('inspection.catchOnboard.section.verified')})`).isEmpty().isLowerThan(0, '0').errors,
            }
            errors.controlledCatches && errors.controlledCatches.push(error)
        })

        return errors
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const validate = useValidation()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const errors:InspectionErrors<ValidationType> = getStepErrors(key, edited)

    const set = pirsapi.setStep(key, edited, validate)

    const editItem = (i, _m:Mutator<ControlledCatches>) => set(c=>{
        c.extended.pir.controlledCatches[i] = copyAndApplyChanges(c.extended.pir.controlledCatches[i], (s: ControlledCatches) => void _m(s))
    })

    const removeItem = (i) => set(c=>{
        c.extended.pir.controlledCatches.splice(i, 1)
    })

    const addItem = () => set(c=>{
        c.extended.pir.controlledCatches && c.extended.pir.controlledCatches.unshift({} as ControlledCatches)
    })

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.catchOnboard.section.title')}>
        <StepDescription>{t('inspection.catchOnboard.section.subtitle')}</StepDescription>
        <IonList>
            
            <IonItemGroup>
                <IonItem><IonButton disabled={readonly} slot="end" onClick={addItem} color="primary">{t('inspection.catchOnboard.section.add')}</IonButton></IonItem>
            </IonItemGroup>

            {[...edited.extended.pir.controlledCatches].map((c, ci, items) => {
                const title = `${t('inspection.catchOnboard.section.catch')} ${items.length - ci}`
                const error = (((errors && errors.controlledCatches && errors.controlledCatches[ci]) || {}) as InspectionErrors<ControlledCatches>)
                const toBeLandedDeltaPercentage = getDeltaPercentage(c.quantityToLand, c.monitoredLandedQuantity)
                const toBeTranshippedDeltaPercentage = getDeltaPercentage(c.quantityToTransship, c.monitoredTranshippedQuantity)
                return <IonItemGroup key={ci} className="new-item">
                     <IonItemDivider>
                        <IonLabel>{title}</IonLabel>
                        
                        <IonButtons slot="end">
                            <IonButton disabled={readonly} color="danger" shape="round" slot="end" size="small"
                            onClick={()=>removeItem(ci)}>
                                {t('common.delete')}
                                <IonIcon icon={removeOutline} />
                            </IonButton>
                        </IonButtons>

                    </IonItemDivider>

                    <Item description={t('inspection.catchOnboard.species.help')} disabled={readonly} mandatory error={error.species}>
                        <IonLabel>{t('inspection.catchOnboard.species.label')}</IonLabel>
                        <SpeciesSelect ids={c.species ? [c.species] : undefined}
                        placeholder={t('inspection.catchOnboard.species.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.species = v ? v[0] : undefined )}/>
                    </Item>

                    <Item description={t('inspection.catchOnboard.productForm.help')} disabled={readonly} mandatory error={error.productForm}>
                        <IonLabel>{t('inspection.catchOnboard.productForm.label')}</IonLabel>
                        <ProductFormsSelect ids={[c.productForm]}
                        placeholder={t('inspection.catchOnboard.productForm.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.productForm = v ? v[0] : undefined )}/>
                    </Item>

                    <Item description={t('inspection.catchOnboard.catchArea.help')} disabled={readonly} mandatory error={error.catchArea}>
                        <IonLabel>{t('inspection.catchOnboard.catchArea.label')}</IonLabel>
                        <FishingAreasSelect ids={c.catchArea}
                        placeholder={t('inspection.catchOnboard.catchArea.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.catchArea = v )}/>
                    </Item>

                    <Item description={t('inspection.catchOnboard.quantityOnboardDeclared.help')} disabled={readonly} mandatory error={error.quantity}>
                        <IonLabel>{t('inspection.catchOnboard.quantityOnboardDeclared.label')}</IonLabel>
                        <IonInput
                        min="0"
                        placeholder={t('inspection.catchOnboard.quantityOnboardDeclared.placeholder')}
                        type="number"
                        value={c.quantity}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.quantity = +(e.detail.value!))}></IonInput>
                    </Item>

                    <Item description={t('inspection.catchOnboard.quantityToBeLanded.help')} disabled={readonly} mandatory error={[...(error.quantityToLand || []), ...(error.monitoredLandedQuantity || [])]}>
                        <IonLabel style={{flex:2}}>{t('inspection.catchOnboard.quantityToBeLanded.label')}</IonLabel>
                        
                        <IonItem lines="none" disabled={readonly} style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchOnboard.section.declared')}</IonLabel>
                            <IonInput
                            min="0"
                            type="number"
                            value={c.quantityToLand}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                p.quantityToLand = +(e.detail.value!);
                                p.deltaLanded = p.quantityToLand >=0 && p.monitoredLandedQuantity >=0 ? p.monitoredLandedQuantity - p.quantityToLand : p.deltaLanded
                            })}></IonInput>
                        </IonItem>
                        <IonItem lines="none" disabled={readonly} style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchOnboard.section.verified')}</IonLabel>
                            <IonInput
                            min="0"
                            type="number"
                            value={c.monitoredLandedQuantity}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                p.monitoredLandedQuantity = +(e.detail.value!);
                                p.deltaLanded = p.quantityToLand >=0 && p.monitoredLandedQuantity >=0 ? p.monitoredLandedQuantity - p.quantityToLand : p.deltaLanded
                            })}></IonInput>
                        </IonItem>
                        <IonItem lines="none" disabled={true}
                        className={'delta-field ' + (toBeLandedDeltaPercentage && toBeLandedDeltaPercentage >= deltaTreshHold ? 'with-error' : '')}
                        style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchOnboard.section.delta')}</IonLabel>
                            <IonInput
                            type="number"
                            value={c.deltaLanded}></IonInput>
                        </IonItem>
                    </Item>

                    <Item description={t('inspection.catchOnboard.quantityToBeTranshipped.help')} disabled={readonly} mandatory error={[...(error.quantityToTransship || []), ...(error.monitoredTranshippedQuantity || [])]}>
                        <IonLabel style={{flex:2}}>{t('inspection.catchOnboard.quantityToBeTranshipped.label')}</IonLabel>
                        
                        <IonItem lines="none" disabled={readonly} style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchOnboard.section.declared')}</IonLabel>
                            <IonInput
                            min="0"
                            type="number"
                            value={c.quantityToTransship}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                p.quantityToTransship = +(e.detail.value!);
                                p.deltaTranshipped = p.quantityToTransship >=0 && p.monitoredTranshippedQuantity >=0 ? p.monitoredTranshippedQuantity - p.quantityToTransship : p.deltaTranshipped
                            })}></IonInput>
                        </IonItem>
                        <IonItem lines="none" disabled={readonly} style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchOnboard.section.verified')}</IonLabel>
                            <IonInput
                            min="0"
                            type="number"
                            value={c.monitoredTranshippedQuantity}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                p.monitoredTranshippedQuantity = +(e.detail.value!);
                                p.deltaTranshipped = p.quantityToTransship >=0 && p.monitoredTranshippedQuantity >=0 ? p.monitoredTranshippedQuantity - p.quantityToTransship : p.deltaTranshipped
                            })}></IonInput>
                        </IonItem>
                        <IonItem lines="none" disabled
                        className={'delta-field ' + (toBeTranshippedDeltaPercentage && toBeTranshippedDeltaPercentage >= deltaTreshHold ? 'with-error' : '')}
                        style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchOnboard.section.delta')}</IonLabel>
                            <IonInput
                            type="number"
                            value={c.deltaTranshipped}></IonInput>
                        </IonItem>
                    </Item>

                    <Item description={t('inspection.catchOnboard.objection.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.catchOnboard.objection.label')}</IonLabel>
                        <IonToggle
                        color={c.objection ? 'success' : undefined}
                        checked={c.objection ? true : false}
                        onIonChange={e=>e.detail.checked !== undefined && editItem(ci, (p)=>p.objection = e.detail.checked)}/>
                    </Item>

                    <Item description={t('inspection.catchOnboard.comments.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.catchOnboard.comments.label')}</IonLabel>
                        <IonInput
                        placeholder={t('inspection.catchOnboard.comments.placeholder')}
                        value={c.comments}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.comments = (e.detail.value!))}></IonInput>
                    </Item>
                
                </IonItemGroup>
            })}
        </IonList>
    </StepContent>
}
const key = "ControlledCatches"

const Button = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    const {t} = useIntl()
    return StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.catchOnboard.section.linkTitle'),
        badge: pir.extended?.pir.controlledCatches.length,
        hasError: stepContainsErrors(key, pir as PirEdting)
    })}
export const CatchOnBoard:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}