import { useEffect, useState } from "react"

export const useSize = (value) => {
    const [{size, adding}, setSize] = useState({size:value, adding:false})

    useEffect(()=>{

        let adding = size<value

        setSize({size:value, adding})

    }, [value])

    useEffect(()=>{
        let timeout
        if(adding){
            timeout = setTimeout(() => { 
                setSize({size:value, adding:false})
            }, 3000);
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [adding])

    return {size, adding}
}