import { IonFab, IonFabButton, IonFabList, IonIcon } from "@ionic/react"
import { ellipsisVerticalOutline } from "ionicons/icons"
import React from "react"

export const MoreButtons = ({children, disabled=false}) => {
    return <IonFab  vertical="bottom" slot="fixed" horizontal="end">
    <IonFabButton
        disabled={disabled} 
        color="dark"
        mode="ios">
         <IonIcon icon={ellipsisVerticalOutline} />
    </IonFabButton>
    <IonFabList side="top">
      {children}
    </IonFabList>
  </IonFab>
}