import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    id?: number,
    onChange,
    placeholder?
    title?
}
export const AuthoritiesSelect = ({id, onChange, placeholder, title}:Props) => {
    
    const referencedataapi = useReferencedata()
    const authorities = referencedataapi.current().authority
    
    const value = id && authorities.filter(f=>id === f.id).map(v=>v.id)
    
    return <Select title={title || placeholder || "Select an authority"}
    value={value || []}
    placeholder={placeholder}
    onChange={v=>onChange(v[0])}
    options={useReferencedataOptions({data:authorities})}/>
}