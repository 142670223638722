export const pictureTypeCodes = [
    "PWHO",
    "PPSB",
    "PPSS",
    "PSSB",
    "PSSS",
    "PFRB",
    "PSTE",
    "PPBR",
    "PSBR",
    "PAIRE" 
]