import { IonItemDivider, IonItemGroup, IonLabel, IonList, IonTextarea } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { PirEdting, statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { stepContainsErrors } from "pir/model/inspectionErrorApi";
import React from "react";
import { FormStepButton } from "./FormStepButton";

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const set = pirsapi.setStep(key, edited)

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.findings.section.title')}>
            <StepDescription>{t('inspection.findings.section.subtitle')}</StepDescription>
        <IonList>
            
            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('inspection.findings.findings.label')}</IonLabel>
                </IonItemDivider>
                <Item description={t('inspection.findings.findings.help')} disabled={readonly}>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value !== undefined && set((p)=>p.extended.pir.findingsByInspector = e.detail.value!)}
                    value={pir.extended?.pir.findingsByInspector}/>
                </Item>
            </IonItemGroup>

            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('inspection.findings.masterComments.label')}</IonLabel>
                </IonItemDivider>
                <Item disabled={readonly} description={t('inspection.findings.masterComments.help')}>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value !== undefined && set((p)=>p.extended.pir.commentsByMaster = e.detail.value!)}
                    value={pir.extended?.pir.commentsByMaster}/>
                </Item>
            </IonItemGroup>

            
        </IonList>
    </StepContent>
}
const key = "findings"

const Button = ({pir}:StepButtonProps) => {

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    const {t} = useIntl()
    return StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.findings.section.title'),
        hasError: stepContainsErrors(key, pir as PirEdting)
    })
}
export const Findings:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}