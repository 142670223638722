import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { useMockery } from 'lib/apprise-frontend-core/client/mocks'
import { useProcessRegistry } from 'process/api'
import { PropsWithChildren } from 'react'
import { usePirs } from './api'
import { usePirMockery } from './mockery'
import { usePirProcessInitializer } from './processes'
import { usePirTasksInitializer } from './tasks'

export type PirLoaderProps = PropsWithChildren<Partial<{

    mock: boolean

}>>

// as a module we separate loading the logged user from loading all the users, in case the first may be neeeded without the second.
// <Users> will chain them in common use case. there's some `waterfalling` here, but we need to know the user before making any other call anyway.

export const PirLoader = (props: PirLoaderProps) => {

    const { children, mock } = props

    const pirs = usePirs()
    const mocks = useMockery()
    const mockery = usePirMockery()
    
    const activate = () => Promise.resolve(mock ? mocks.initMockery(mockery) : true).then(pirs.init)

    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content
}