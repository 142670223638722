import { IonApp, IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonTabBar, IonTabButton, IonTitle, IonToolbar } from '@ionic/react'
import { AppStatus } from 'components/AppStatus/Index'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useUser } from 'user/api'
import './Login.scss'


export const Login = () => {
    const userapi = useUser()
    const user = userapi.current()
    const [username, setUsername] = useState<string | null | undefined>()
    const [password, setPassword] = useState<string | null | undefined>()
    const onSubmit = (e) => {
        e.preventDefault()
        username && password && userapi.login({username, password})
    }
    const usernameref = useRef<HTMLIonInputElement>() as RefObject<HTMLIonInputElement>
    const passwordref = useRef<HTMLIonInputElement>() as RefObject<HTMLIonInputElement>

    useEffect(() => {
        let interval = setInterval(() => {
          if (usernameref.current) {
            setUsername(usernameref?.current?.value as string)
            clearInterval(interval)
          }
        }, 100)

        return interval && clearInterval(interval)
    })

    useEffect(() => {
        let interval = setInterval(() => {
          if (passwordref.current) {
            setPassword(passwordref?.current?.value as string)
            clearInterval(interval)
          }
        }, 100)

        return interval && clearInterval(interval)
    })

    const {t} = useIntl()

    return <IonApp className="login-page">
        <IonContent >
            <IonToolbar >
                <IonTitle>{t('login.title')}</IonTitle>
            </IonToolbar>
            
            {user && 
            <IonToolbar>
                <IonTitle size="small">{t('login.doLoginAsUser', {user:user.name})}</IonTitle>
                <br/>
                <IonTitle size="small">{t('login.doLoginAsDevice', {device:user.deviceName})}</IonTitle>
            </IonToolbar>
            }
            
            <form onSubmit={onSubmit}>
            
            <IonList>
                <IonItem>
                    <IonLabel position="fixed">{t('login.username')}</IonLabel>
                    <IonInput ref={usernameref} onIonChange={e=>setUsername(e.detail.value)} value={username}></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="fixed">{t('login.password')}</IonLabel>
                    <IonInput ref={passwordref} type="password" onIonChange={e=>setPassword(e.detail.value)} value={password}></IonInput>
                </IonItem>
            </IonList>
            <IonButton type="submit" disabled={!username || !password}>{t('login.button')}</IonButton>
            </form>
        </IonContent>
        <IonTabBar className="main-tab-bar" slot="bottom">
        
        <IonTabButton className="side-tab tab-status">
          <AppStatus/>
        </IonTabButton>

        </IonTabBar>
    </IonApp>
}