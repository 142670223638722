import { AxiosRequestConfig } from "axios"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { usePirs } from "pir/api"
import { dbProcessApi } from "process/api"
import { onRegisterProps, Task, TaskHandlers, taskMethods } from "process/model"
import { newTask } from "process/model/api"
import { useRegisterTask } from "process/model/tasks"
import { b64ToFile, useFile } from "./api"
import { useFileCall } from "./call"
import { PirFile, PirPdfHandler } from "./model"
import { humanFileSize, isImageContentType } from "./model/api"

export const FILE = 'FILE'
export const FILE_UPLOAD = 'FILE_UPLOAD'


export const useFileTasksInitializer = () => {

    const pirs = usePirs()
    const fileapi = useFile()
    const call = useFileCall()
    const registerTaskApi = useRegisterTask()
    
    const task:TaskHandlers = {
        [FILE] : {  
            onRegister: async (props:onRegisterProps) => {
                const {task} = props
                try {
                    const {base64, blob} = await call.fetch(task.url, task.subtype)
                    const pir = pirs.find(task.subject)
                    if(!pir){
                        //TODO: handle error
                        return
                    }
                    if (isImageContentType(task.subtype)){
                        await fileapi.setImageByBase64string({base64, pir, contentType:task.subtype, filename:task.name || '', uid:task.url, size:blob.size})
                    }else{
                        await fileapi.setFileByBase64string({base64, pir, contentType:task.subtype, filename:task.name || '', uid:task.url, size:blob.size})
                    }
                    registerTaskApi.defaultRegister(props)
                } catch (error) {
                    registerTaskApi.defaultRegisterFail(props, error)
                }
            }
        },

        [FILE_UPLOAD] : {  
            onRegister: async (props:onRegisterProps) => {
                const {task} = props
                try {
                    const file = await fileapi.get(task.body) as PirFile
                    const config:AxiosRequestConfig = {
                        onUploadProgress: async progress => {
                            console.log('uploading:', progress)
                            task.completion = progress.loaded/progress.total
                            task.completionDetails = `${humanFileSize(progress.loaded).label}/${humanFileSize(progress.total).label}`
                            props.processes && await dbProcessApi().put(props.processes)
                        }
                    }

                    let fileBody:File
                    if(typeof file.content === "string"){
                        fileBody = await b64ToFile(file.content, file.filename, file.contentType)
                    }else{
                        fileBody = file.content
                    }
                    const result = await call.uploadFile(task.url, fileBody, config)
                    task.result = result
                    registerTaskApi.defaultRegister(props)
                } catch (error) {
                    registerTaskApi.defaultRegisterFail(props, error)
                }
            }
        }
    }

    return task
}

interface NewFileTaskProps extends Pick<FileTask, "url" | "subject" | "subtype" |  "name" > {
    description?

    canIgnore?
}

interface NewFileUploadTaskProps extends NewFileTaskProps{
    body: string
}

export interface FileTask extends Task {
    type: "FILE",
    name: string
}

export interface FileUploadTask extends Task {
    type: "FILE_UPLOAD",
    name: string
}

export const useFileTask = () => {
    const {t} = useIntl()
    const createFileTask = ({url, subject, name, subtype,  description, canIgnore}:NewFileTaskProps) =>
    newTask().create({
        url,
        subtype,
        type:FILE,
        subject,
        name,
        canIgnore,
        description:description || t('task.downloadingFile', {url})
    })

    const createFileUploadTask = ({url, subject, name, subtype, description, body}:NewFileUploadTaskProps) =>
    newTask().create({
        url,
        type:FILE_UPLOAD,
        subject,
        subtype,
        method:taskMethods.POST,
        name,
        body,
        description:description || t('task.uploadingFile', {url})
    })

    return {createFileTask, createFileUploadTask}
}