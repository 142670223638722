import { baseConfig, Data } from "api";
import { useClient } from "lib/apprise-frontend-core/client/api";
import { Login, LoginResult, RefreshTokenResult } from "./model";

export const useUserCalls = () => {

    const {at} = useClient()


    const self = {
    
        login: (login:Login) => {
            return at("/login").post<Data<LoginResult>>(login, baseConfig()).then(({data})=>data)
        }

        ,

        refreshToken: () => {
            return at("/refresh_token").get<Data<RefreshTokenResult>>(baseConfig()).then(({data})=>data)
        }
    }

    return self
}