import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { UploadButton } from "components/UploadButton";
import { bandageOutline } from "ionicons/icons";
import React from "react";
import { useState } from "state/api";

export const RestoreButton = () => {
    const stateApi = useState()

    const Button = ({onClick}) => <IonItem button onClick={onClick}>
        <IonIcon slot="end" icon={bandageOutline}/>
        <IonLabel>Restore data</IonLabel>
    </IonItem>

    const onUpload = (files:File[]) => {
        var reader = new FileReader();
        reader.readAsText(files[0]);
        reader.onloadend = () => {
            const json = JSON.parse(reader.result as string)
            stateApi.restore(json)
        };
    }

    return React.useMemo(()=><UploadButton accept={"text/json"} multiple={false} onUpload={onUpload} Button={Button}/>, [])
}