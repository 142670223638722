

import { useIntl } from "apprise-frontend-core/intl/api";
import { fallbackStateOver } from 'apprise-frontend-core/state/api';
import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useContext } from 'react';
import { utils } from '../utils/common';
import { useMockery } from './mocks';
import { ClientConfiguration, ErrorInterceptor, RequestInterceptor, ServiceConfig } from "./model";
import { ClientContext, initialClientState } from "./state";



export const useClient = () => {

    const state = useContext(ClientContext) ?? fallbackStateOver(initialClientState)

    const mocks = useMockery()

    const defaultOnError = useDefaultOnError()

    // passes request condifguration through all methods with a callback.
    const request = (reqconfig: AxiosRequestConfig) => {

        state.get().config.onRequest

            // purges the undefined.
            .filter(i => i)

            .forEach(interceptor => interceptor!(reqconfig))

        return reqconfig


    }



    const error = (e: AxiosError): never => {

        // invokes all error handlers (app,modules,default), until one throws.
        [

            ...state.get().config.onError,

            defaultOnError

        ]

            // purges the undefined.
            .filter(i => i)

            .forEach(interceptor => interceptor(e))

        throw e;  //   throw it if no handler has yet (typechecker demands it).
    }




    const self = {

        config: () => state.get().config,


        addErrorInterceptor: (interceptor: ErrorInterceptor) => {

            state.setQuietly(s => s.config.onError.unshift(interceptor))
        }

        ,

        addRequestInterceptor: (interceptor: RequestInterceptor) => {

            state.setQuietly(s => s.config.onRequest.unshift(interceptor))
        }

        ,


        fq: (path: string, targetName?: string) => {



            const target = targetName ? self.service(targetName) : self.defaultService();
            const prefix = target.prefix;
            return `${prefix}${path}`;


        }

        ,

        at: (path: string, targetName?: string) => self.atPath(self.fq(path, targetName)),



        atPath: (path: string) => ({

            get: <T>(config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'get', url: path })).catch(error).then(r => config.raw ? r : r.data) as Promise<T>,
            post: <T>(data?: any, config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'post', data, url: path })).catch(error).then(r => config.raw ? r : r.data) as Promise<T>,
            put: <T>(data?: any, config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'put', data, url: path })).catch(error).then(r => config.raw ? r : r.data) as Promise<T>,
            delete: (config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'delete', url: path })).catch(error).then(r => config.raw ? r : r.data) as Promise<void>,

        })

        ,

        service: (name: string): ServiceConfig => {

            const config = state.get().config

            return config.services[name] ?? self.defaultService()

        },

        defaultService: (): ServiceConfig => {

            const config = state.get().config

            for (let k in config.services)
                if (config.services[k].default)
                    return config.services[k]


            const keys = Object.keys(config.services)

            if (keys.length === 1)
                return config.services[keys[0]]

            throw new Error("no default service!");

        }

        ,

        init: (config: Partial<ClientConfiguration>) => {

            // initialises axios implements.
            state.set(s => {

                // merges client config with internal config.
                s.config = utils().merge(s.config, config ?? {})

                // stores axios instance.
                s.impl = Axios.create(s.config.request)

            })

            // initialises whichever mockeries may already be installed.
            const mockeries = state.get().mockeries ?? []

            mockeries.forEach(mocks.initMockery)

        }

    }

    return self;

}

export type RequestConfig = AxiosRequestConfig & Partial<{

    raw: boolean
}>


// default handling of edge errors and server errors with 'canonical' {message,details} payload.  
export const useDefaultOnError = () => {

    const { t } = useIntl()

    return (e: AxiosError) => {

       const url = e.config?.url

        if (e.response) { //  if response is conventional, parse it. else use bare error message.

            // build a fallback message from url and available message.
            const baremsg = t("client.bare", { url, message: e.message })

            // uses the fallback unless it finds one in a `data` payload
            e.message = t(e.response.data?.message ?? baremsg)

            // force details as a custom property of axios errors
            e["details"] = e.response.data?.stacktrace ?? e.response.data ?? baremsg

        }
        else

            // builds a message in the lack of a response.
            e.message = `${e.request ? t("client.noresponse", { url }) : t("client.norequest", { url })}: ${e.message}`

        throw e;
    }

}
