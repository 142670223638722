import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonProgressBar, useIonPopover } from '@ionic/react'
import { code, documentOutline } from 'ionicons/icons'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import { useProcess } from 'process/api'
import { Process, Task, taskStatuses, taskStatusesProperties } from 'process/model'
import { getTaskPercentage, processModelApi } from 'process/model/api'
import React from 'react'
import './ProcessViewer.scss'


type Props = {process:Process}

export const ProcessFullViewer = ({process}:Props) => {

    const processApi = useProcess()

    const percentage = processModelApi(process).getPercentage()/100
    const status = processModelApi(process).getStatus()

    const {t} = useIntl()

    return <>
    
    <IonList lines="none" className="process-component">
        <IonItem >
            <div className="task-content">
            <IonLabel>{process.description}</IonLabel>
            <IonProgressBar
                type={taskStatusesProperties[status].percentageType}
                color={taskStatusesProperties[status].color}
                value={percentage}
                />
            </div>
            {status === taskStatuses.REJECTED && <IonButton onClick={()=>processApi.retryTasks(process)} size="small" color="danger" >{t('process.retryMany')}</IonButton>}
            {processModelApi(process).containsIgnorable() && <IonButton onClick={()=>processApi.ignoreIgnorableTasks(process)} size="small" color="primary" >{t('process.ignoreMany')}</IonButton>}
            <IonButton disabled fill="outline" className="task-info-toggle" color="dark" shape="round" size="small">
                <IonIcon size="small" icon={taskStatusesProperties[status].icon}/>
            </IonButton>
        
        </IonItem>
    </IonList>

    <IonList lines="none" className="process-component list">
        {process.tasks.map(task=><TaskRow process={process} task={task}/>)}
    </IonList>
    </>
}


const TaskRow = ({task, process}: {task:Task, process:Process}) => {
    const processApi = useProcess()

    const {t} = useIntl()


    const InfoPopover = ({ onHide }) => {
        return <IonList className="pir-inspection-item-popover info">
            <IonListHeader color="info">{JSON.stringify(task.result.message)}</IonListHeader>
        </IonList>
    };

    const [presentInfo, dismissInfo] = useIonPopover(InfoPopover, { onHide: () => dismissInfo() });

    const hasErrorInfo = task.status === taskStatuses.REJECTED && task.result

    return <>
    <IonItem key={task.uid} className="process-task">
        <IonIcon size="small" icon={code}/>
        <div className="task-content">
            <IonLabel>{task.description}</IonLabel>
            <div className="completion-details">{task.completionDetails}</div>
            <IonProgressBar
            type={taskStatusesProperties[task.status].percentageType}
            color={taskStatusesProperties[task.status].color}
            value={getTaskPercentage(task)/100}
            />
        </div>
        {task.status === taskStatuses.REJECTED && <IonButton onClick={()=>processApi.retryTasks(process, [task])} size="small" color="danger" >{t('process.retry')}</IonButton>}
        {task.status === taskStatuses.REJECTED && task.canIgnore && <IonButton onClick={()=>processApi.ignoreIgnorableTasks(process, [task])} size="small" color="primary" >{t('process.ignore')}</IonButton>}
        {
            <IonButton disabled={!hasErrorInfo} fill="outline" onClick={(e) => presentInfo({event: e.nativeEvent})} className="task-info-toggle" color="dark" shape="round" size="small">
                <IonIcon slot="icon-only" icon={taskStatusesProperties[task.status].icon}/>
            </IonButton>
        }

    </IonItem>
    </>
}