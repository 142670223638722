import { IonButton, IonButtons, IonChip, IonIcon, IonLabel, IonTitle, IonToolbar } from '@ionic/react'
import { checkmarkDone, checkmarkOutline, pencilOutline, removeOutline } from 'ionicons/icons'
import { useIntl } from 'lib/apprise-frontend-core/intl/api';
import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react'
import SignatureCanvas from 'react-signature-canvas'
import Autosizer from "react-virtualized-auto-sizer";

import './Signature.scss'


type Props = {
    height?,
    disabled?
    value?, onChange,
    width?
}
export const SignatureFieldComp = ({disabled, width, height, value, onChange:_onChange}:Props) => {
    const {t} = useIntl()

    const onChange = (v?:string) => !disabled && _onChange(v)
    
    const [data, setData] = useState([])
    const [ref, setRef] = useState<any>()

    const handleRef = useCallback((node) => {
        setRef(node);
    }, []);

    useEffect(()=>{
        if(ref){
            if(value){
                ref?.fromDataURL(value)
                ref?.off()
            }
            if(disabled){
                ref.off()
            }
        }
    }, [ref])

    useEffect(()=>{
        if(value===undefined){
            reset()
        }else{
            ref && ref.off()
            ref && ref.clear()
            ref && ref.fromDataURL(value)
        }
    }, [width])

    useEffect(()=>{
        if(disabled){
            ref && ref.off()
            reset()
        }else{
            ref && ref.on()
        }
    }, [disabled])

    useEffect(()=>{
        if(value===undefined){
            ref && ref.on()
        }else{
            ref && ref.off()
            ref && ref.clear()
            ref && ref.fromDataURL(value)
        }
    }, [value])

    const onEnd = () => setData(ref?.toData())

    const complete = () => onChange(ref?.toDataURL())

    const reset= () => {
        ref && ref?.clear()
        setData([])
        onChange(undefined)
    }

    const isCompleted = value ? true : false

    return <div className="signature-field">
        <Autosizer disableHeight>
                {({width}) => (
                    <SignatureCanvas clearOnResize={true} canvasProps={{clearOnResize:true, width: width || 500, height: height || 200, className: 'sigCanvas', throttle:0}} ref={handleRef} onEnd={onEnd}/>
                )}
        </Autosizer>
        <IonToolbar>
            <IonChip color={isCompleted ? 'success' : undefined}>
                <IonLabel>
                    {isCompleted ? t('inspection.signatures.field.signatureRegistered') : t('inspection.signatures.field.insertSignature')}
                </IonLabel>
                {isCompleted ? <IonIcon icon={checkmarkDone}/> : <IonIcon icon={pencilOutline}/>}
            </IonChip>
            <IonButtons slot="end">
                <IonButton disabled={data.length === 0 && !value} onClick={reset} color="danger">{t('inspection.signatures.field.reset')}<IonLabel></IonLabel><IonIcon icon={removeOutline}/></IonButton>
                <IonButton disabled={value || data.length === 0} onClick={complete} color="success">{t('inspection.signatures.field.complete')}<IonLabel></IonLabel><IonIcon icon={checkmarkOutline}/></IonButton>
            </IonButtons>
        </IonToolbar>
    </div>
}

export const SignatureField = ({width, ...props}:Props) => <div className="signature-field-wrapper"><Autosizer disableHeight>
{({width}) => (<SignatureFieldComp width={width} {...props}/>)}</Autosizer></div>