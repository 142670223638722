import { IonFabButton, IonIcon, IonLabel } from "@ionic/react";
import { useStateRef } from "components/useStateRef";
import { boatOutline } from "ionicons/icons";
import { vesselPicturesKey } from "pages/pir/Steps/VesselPictures";
import React, { useEffect, useMemo } from "react";
import { usePirs } from "../../api";
import { FormPicture, Pir, PirEdting } from "../../model";
import { PirAttachmentTypeModal } from "../Attachment/TypeModal";
import { v4 as uuid } from 'uuid'
import { useUser } from "user/api";
import { useIntl } from "lib/apprise-frontend-core/intl/api";

type Props = {
    pir:Pir,
    Button?: ({onClick}) => JSX.Element
}
type StateType = {
    files:File[],
    showModals:boolean,
    uid
}

const DefaultButton = ({onClick}) => {
    const {t} = useIntl()
    return <IonFabButton
            className="ion-fab-button-with-label"
            onClick={onClick}
            color="dark"
            mode="ios">
            <IonLabel>{t('inspection.pictures.add')}</IonLabel>
            <IonIcon icon={boatOutline} />
        </IonFabButton>
}

const getinitialState = () => ({
    files:[],
    showModals:false,
    uid:uuid()
})

export const VesselPictureButton = ({pir, Button}:Props) => {
    const pirapi = usePirs()
    const userapi = useUser()
    const inspector = userapi.current()?.inspector.name
    const fileInput: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

    const [state, setState, {set}] = useStateRef<StateType>(getinitialState())

    const finish = async ({type}) => {
        let pictures:FormPicture[] = []
        await Promise.all(state.files.map(async f=>{
            const picture = await pirapi.setFormPicture(f, pir)
            pictures.push({...picture, type:type || picture.type, author: inspector || picture.author})
        }))
        await pirapi.setStep(vesselPicturesKey, pir as PirEdting)(p=>p.extended.pictures = [...p.extended.pictures || [], ...pictures])
        setState(getinitialState())
    }

    const onChange = (e) => {
        set(s=>{
            s.showModals=true;
            s.files = [...s.files, ...(Array.from(e.target.files as any) as File[])];
        })
    }

    const LocalButton = useMemo(()=>Button || DefaultButton, [])

    return <>
        <input
        ref={fileInput}
        hidden
        type="file"
        multiple
        accept="image/*"
        onChange={onChange}
        />
        <LocalButton onClick={()=>fileInput?.current?.click()}/>
        {useMemo(()=>state.showModals && <Modals key={state.uid} onFinish={finish} />, [state.showModals, state.uid])}
    </>
}

type ModalsState = {
    showType:boolean,
    type,
    complete:boolean
}

const initialModalsState:ModalsState = {
    showType:true,
    type:undefined,
    complete:false
}
const Modals = ({onFinish}) => {
    const [state, setState, {set}] = useStateRef<ModalsState>(initialModalsState)
    const complete = state.complete
    useEffect(()=>{
        if(complete){
            onFinish({type:state.type})
        }
    }, [complete])
    return <>
        <PirAttachmentTypeModal mode="picture" show={state.showType} onClose={type=>{set(s=>{
            s.type = type;s.showType=false;s.complete=true
        })}}/>
    </>
}