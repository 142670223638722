import { Mutator } from "apprise-frontend-core/state/api"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import moment from "moment"
import { useContext } from "react"
import { getUpdateAvailableRegistration } from "serviceWorkerRegistration"
import { languages, languagesList, Settings } from "settings/model"
import Worker from 'worker'
import { SettingsContext } from "./provider"
import { initialSettings, SettingsState } from "./state"

const instance = new Worker();

export const getDarkMode = () => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    return mq.matches ? true : false;
}

export const getIntl = () => {
    let lng:keyof typeof languages
    if (navigator.languages !== undefined && languagesList.find(l=>navigator.languages[0].indexOf('l')===0)){
        lng = (languagesList.find(l=>navigator.languages[0].indexOf('l')===0) || languages.en) as keyof typeof languages
    }

    lng = languages.en
    
    return lng
}

export const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return 'pwa';
    } else if ((navigator as any).standalone || isStandalone) {
      return 'standalone';
    }
    return 'browser';
}

export const getPWAInstalledAppEvent = () => {
    return (window as any).deferredPrompt;
}

export const setPWAInstalledAppEvent = (e?) => {
    (window as any).deferredPrompt = e
}

export const useSettings = () => {

    const state = useContext(SettingsContext)
    const {changeLanguage} = useIntl()

    const stateset = async (_:Mutator<SettingsState>) => {
        state.set(_)
        instance.settings.put(state.get().settings)
    }

    const self = {

        fetchAll: async () => {
            const settings = await instance.settings.get()
            if(settings){
                const language = settings.language !== undefined ? settings.language : getIntl()
                const application = settings.application !== undefined ? settings.application : initialSettings().settings.application
                self.localChangeLanguage(language)
                self.setAll({
                    ...settings,
                    language,
                    darkMode: settings.darkMode !== undefined ? settings.darkMode : getDarkMode(),
                    onLine: navigator.onLine,
                    updateAvailable: getUpdateAvailableRegistration() ? true : false,
                    filters: {
                        ports: settings.filters && settings.filters.ports || []
                    },
                    application
                });
            }
        }
        ,
        all: () => state.get().settings
        ,
        setAll: (all: Settings) => stateset(s => s.settings = all)
        ,
        stateset
        ,
        set: (key, value:Settings[keyof Settings]) => stateset(s=>s.settings[key] = value)
        ,
        reset: () => stateset(s=>s.settings = initialSettings().settings)
        ,
        localChangeLanguage: (value) => {
            changeLanguage(value);
            moment.locale(value); 
        }
        ,
        changeLanguage: (value) => {
            self.localChangeLanguage(value);
            self.set('language', value);
        }
        ,
        filters: () => self.all().filters
        ,
        resetFilter: () => stateset(s=>s.settings.filters = initialSettings().settings.filters)
        ,
        hasActiveFilters: () => {
            const ports = self.filters().ports
            if(ports && ports.length > 0){
                return true
            }
        }
        ,
        setPortsFilter: (value:number[]) => stateset(s=>s.settings.filters.ports = value)
        ,
        export: async () => {
            const settings = await instance.settings.get()
            return settings
        }
        ,
        restore: async (settings:Settings) => {
            stateset(s=>s.settings = settings)
        }
        
    }

    return self

}