
import { AxiosError, AxiosRequestConfig } from "axios"
import MockAdapter from "axios-mock-adapter/types"


export type Mockery = (mock: MockAdapter) => void


export type ClientConfiguration = ClientProps & ClientInterceptors

export type ClientProps = {

    // backend services.
    services: { [key: string]: ServiceConfig }
    
    // default request config.
    request: AxiosRequestConfig

    mocks: Partial<{

        debug: boolean
        responseDelay: number
    }>
}


export type ClientInterceptors = {

    onError: ErrorInterceptor[]
    onRequest: RequestInterceptor[]

}

export type ServiceConfig = {

    prefix: string
    label: string
    default?: boolean

}

 
export type ErrorInterceptor = (_: AxiosError) => never | void
export type RequestInterceptor = (_: AxiosRequestConfig) => void


export const defaultClientConfiguration: ClientConfiguration = {

    request: {
        timeout: 10000
    },
    services: {
        be: { "prefix": "", label: "Backend" }
    },
    mocks: {
        responseDelay: 200
    }
    ,

    onRequest: [],
    onError:[]
}
