import { IonItem, IonIcon, IonLabel } from "@ionic/react"
import React from "react"
import moment from 'moment'

type Props = {pir, icon, title?, dateAsItIs?, date?: Date | string, value?, children?}
export const PirCardItem = ({pir, icon, title, date, value, dateAsItIs, children}:Props) => {
    return <IonItem lines="none">
    <IonIcon icon={icon}/>
    <div className="multi-label">
    {   title &&
        <IonLabel className="small">
            {title}
        </IonLabel>
    }
    {   value &&
        <IonLabel>
            {value}
        </IonLabel>
    }
    {   children && 
        <IonLabel>
            {children}
        </IonLabel>}
    {   date &&
        <IonLabel>
            {dateAsItIs ? date : moment(new Date(date)).fromNow()}
        </IonLabel>
    }
    </div>
</IonItem>
}