
import produce from "immer"
import { useEffect, useState } from "react"
import { useIntl } from '../intl/api'
import { useComponentBridge } from "./bridge"
import { utils } from "./common"


type ConsentMode = {

  confirm: () => void
  quietly: () => void
}


export type FormState<T = any> = {

  edited: T
  initial: T

  dirty: boolean

  // replaces the state or updates it a with mutating function, 
  // or returns a callback that does it (eg. as an event handler). 
  set: {

    // replaces the state.
    to: (t: T) => void

    // returns a callback that replaces the state.
    it: (t: T) => () => void

    // updates the state with a mutating function.
    using: (mutator: (t: T) => any) => void

    // returns callback that passes args to mutating function.
    with: (mutator: (t: T, ...[values]) => any) => (...[values]) => void


  }

  // replaces the state and reset its initial value for dirty tracking, optionally after obtaining the user's consent.
  reset: {

    // resets to initial state.
    toInitial: ConsentMode

    // resets to a given state, optionally with consent.
    to: (t: T) => ConsentMode
  }

}



export const useForm = <T>(start: T, resetOn? : ()=>boolean): FormState<T> => {

  const { normalise } = useFormUtils()

  // clone deeply to preserve originals, compare to for dirty tracking.
  const { deepequals, deepclone } = utils()

  const { t } = useIntl()
  const { renderDialog } = useComponentBridge()



  // object under edit, but aslo original as this may be reset and we need to track the last one.
  const [state, setState] = useState<T[]>(() => [deepclone(start), start])

  const [edited, initial] = state

  // resets the form on a given condition, by default when the target object changes.
 // eslint-disable-next-line
  useEffect(()=>{

    if (resetOn?.() ?? start !==initial ){
    
      //console.log("resetting form")
      setState([deepclone(start), start])
    
    }
   
      
  })

  const dirty = !deepequals(edited, initial)

  const set = {

    with: (mutator: (t: T, ...[args]) => any) => (...[args]) => set.using(t => mutator(t as any, args)),

    using: (mutator: (t: T) => any) => {

      // produces copy with applied changes as most syntactically convenient 
      // (no need to open curly braces, void operator discards return values anyway).
      const updated = produce(edited, t => void (mutator(t as any)))

      set.to(updated)

    },

    it: (t: T) => () => set.to(t),

    to: (t: T) => {

      // avoids false positive in dirty tracking: the empty values are normalised to match the original's.
      const normalised = normalise(t, edited, initial)

      setState([normalised, initial])
    }

  }

  // base reset function, as most conveniente: captures target and returns callback.\
  const doreset = (t: T) => () => setState([deepclone(t), t])

  const withConsent = (action: () => void) => ({

    confirm: () => renderDialog({
      title: t("form.unsaved_title"),
      msg: t("form.unsaved_msg"),
      okLabel: t("form.unsaved_ok_msg"),
      canceLabel: t("form.unsaved_cancel_msg"),
      onOk: action
    }),
    quietly: action

  })

  // reset options: with/without consent, to initial or to arbitrary value.
  const reset = {

    toInitial: withConsent(doreset(initial)),
    to: (t: T) => withConsent(doreset(t))

  }

  return { edited, initial, dirty, set, reset }

}


export const useFormUtils = () => {



  const self = {

    isNotEmpty: (v: any, recurse: boolean = false) =>

      v !== undefined && v !== null && (

        typeof v === 'object' ?
          // empty if all values are, but inner empty objects don't count:
          // {} => empty, {a:undefined} => empty, {a:{}} => not empty
          Object.keys(v).length === 0 ? recurse : Object.values(v).some(v => self.isNotEmpty(v, true))

          : true
      )

    ,

    isEmpty: (v: any, recurse: boolean = false) => !self.isNotEmpty(v, recurse)

    ,

    canonicalEmptyOf: (v: any) => Array.isArray(v) ? [] : typeof v === 'object' && v ? {} : undefined

    ,

    normalise: <T> (current:T, previous:T, initial:T) => {

      // values that haven't changed  don't need to be normalised against their initial counterpart.
      if (current === previous)
        return current
     
      if (self.isEmpty(current))
        if (self.isEmpty(initial))    // both empty, return old.
          return initial
        else
          return self.canonicalEmptyOf(current)  // only new is empty, return a canonical form.
      else
        if (self.isEmpty(initial)) 
          return current                      // only old is empty, return new.
  
      // both non-empty, recurse if matching.
  
      if (Array.isArray(current) && Array.isArray(initial))
        return current.map((ae, i) => self.normalise(ae, previous?.[i], initial?.[i]))
  
      else if (typeof current === 'object' && typeof initial === 'object')  
  
        return Object.keys(current ?? {}).reduce((acc, k) =>
  
          k in (initial ?? {}) ?   // never 'cancel' a key.
  
            { ...acc, [k]: self.normalise(current[k], previous?.[k], initial?.[k]) }
  
            :
  
            self.isEmpty(current[k]) ? acc : { ...acc, [k]: current[k] }   // prunes new empty data
            
          , {}) 
  
       
      return current        // return new.
  
    }

  }
  return self

}