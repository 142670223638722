import { DocumenttypesSelect } from "documentType/Select"
import { ImagetypesSelect } from "imageType/Select"
import React, { useState } from "react"

type Props = {
    show:boolean,
    onClose:(name?:number) => void,
    mode: "other" | "picture"
}

export const PirAttachmentTypeModal = ({show, onClose, mode}:Props) => {

    const [type] = useState(undefined) 

    const onChangeType = (t)=>{
        onClose(t)
    } 

    return mode === 'picture' ? 
        <ImagetypesSelect codeId={type} onChange={onChangeType} modalOnly forceShow={show} onClosed={()=>onClose()} />
    :
        <DocumenttypesSelect codeId={type} onChange={onChangeType} modalOnly forceShow={show} onClosed={()=>onClose()} />
}