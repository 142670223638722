import deepmerge from "deepmerge";
import { cloneDeep, isEqual } from "lodash";
import { FC, ReactNode } from 'react';

export const utils = () => {


  const self = {


    /*
    *  encapsulates a runtime check on a react node to verify it's an element of a functional component.
    * uses type names in a dev build as a stable identity, and the type identity in production build (where names are mangled).
    * it's curried for convenience of use in iterations:  
    * array.filter(isElementOf(MyComponent)) 
    */
    isElementOf: (component: FC<any> ) => (child:ReactNode) => 

           process.env.NODE_ENV === 'production' ? 

          (child as any)?.type === component : 
          
          (child as any)?.type?.name === component?.name 
    
  

    
    ,


    compose: <T>(ts: T[]) => ts.reduce((acc, next) => ({ ...acc, ...next }), {})

    ,

    wait: <T>(ms: any) => (x: T) => new Promise<T>(v => setTimeout(() => v(x), ms))

    ,


    through: <T>(fn: (t: T) => any) => (a: T): Promise<T> => Promise.resolve(fn(a)).then(_ => a)

    ,

    merge: <T>(target: T, ...values: any): T => deepmerge.all([target, ...values], {

      arrayMerge: (_, destination) => destination

    }) as any as T

    ,

    group: <T>(values: T[] = []) => ({

      by: <S>(key: (_: T) => S, compare: (t1: S, t2: S) => number) => values.reduce((acc, next) => {

        const nextkey = key(next)

        if (!nextkey)
          return acc

        const match = acc.find(e => compare(e.key, nextkey) === 0)

        if (match) {
          match.group.push(next)
          return acc
        }

        return [{ key: nextkey, group: [next] }, ...acc]

      }, [] as { key: S, group: T[] }[]).sort((g1, g2) => compare(g1.key, g2.key))



    })
    ,

    deepequals: <T>(t1: T, t2: T) => {
      return isEqual(t1, t2)
    }

    ,

    deepclone: <T>(t: T) => {
      return cloneDeep(t) as T
    }

    ,

    dedup: <T>(ts: T[]) => ts.filter((_, i) => ts.indexOf(_) === i)

    ,

    index: <T>(values: T[]) => ({

      by: (key: (_: T) => string | undefined) => values.reduce((acc, next) => key(next) ? { ...acc, [key(next)!]: next } : acc, {}) as { [_: string]: T }
    
    })

    ,

    compareStringsOf : <T> (f: (_: T) => string | undefined) => (r1: T, r2: T) => (f(r1)??'')?.localeCompare(f(r2)??'')
  
    ,

    compareNumbersOf : <T> (f: (_: T) => number) => (n1: T, n2: T) => (f(n1) ?? 0) - (f(n2) ?? 0)

    ,


    compareDatesOf : <T> (f: (_: T) => string | number | undefined) => (d1: T, d2: T) => self.compareDates(f(d1),f(d2))

    ,

    compareDates: (d1: string | number | undefined, d2: string | number | undefined, nullFirst: boolean = true) => {

      // moment far too slow in parsing, matters in a loop (eg. table sorting).
      const outcome = !d1 ? (d2 ? nullFirst ? -1 : 1 : 0) : !d2 ? (nullFirst ? 1 : -1) : new Date(d1).getTime() - new Date(d2).getTime()

      //console.log("compared",d1,d2,"outcome",outcome)

      return outcome;

    }

    ,

    arrayOf: <T>(vals: T | T[]) => Array.isArray(vals) ? vals : vals !== undefined ? [vals] : []

    ,

    waitOn: (...all: Promise<any>[]) => Promise.allSettled(all).then(self.throwFirst)

    ,

    throwFirst: (all: PromiseSettledResult<any>[]) => all.filter((a): a is PromiseRejectedResult => a.status === 'rejected').forEach(r => { throw Error(r.reason) })
  }


  return self;
}
