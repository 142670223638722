import { ToastButton, toastController } from '@ionic/core'
import { close } from 'ionicons/icons'

// a hook that takes the state from a context and makes the api available to components.
export const toastApi = () => {

    const self = {
        create: async ({message, duration=2000, color = 'dark', dismissable=false}) => {
            const buttons:(ToastButton | string)[] = []
            if(dismissable){
                buttons.push({
                    icon: close,
                    role:"cancel"
                })
            }
            const toast = await toastController.create({
                color,
                duration,
                message,
                buttons
              });
        
              await toast.present();
        }
    }

    return self

}