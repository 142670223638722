import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    ids?: number[],
    onChange,
    placeholder?
}
export const GearsSelect = ({ids, onChange, placeholder}:Props) => {
    
    const referencedataapi = useReferencedata()
    const gears = referencedataapi.current().gear
    
    const value = ids && gears.filter(f=>ids.includes(f.id)).map(v=>v.id)

    // const _onChange = (val:any[]) => {
    //     (JSON.stringify(val) !== JSON.stringify(value)) && onChange(val)
    // }
    
    return <Select placeholder={placeholder} title="Select one or more gear type"
    multiple
    value={value || []}
    onChange={v=>onChange(v)}
    options={useReferencedataOptions({data:gears,showCode:true})}/>
}