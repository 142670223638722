export const speciesForFishingAuthorization = [
    {
      "id": 1,
      "code": 1,
      "nameEn": "IOTC Species",
      "nameFr": "Esp�ces CTOI"
    },
    {
      "id": 2,
      "code": 2,
      "nameEn": "IOTC Species excluding Blue fin tuna",
      "nameFr": "Esp�ces CTOI excluant le thon rouge"
    },
    {
      "id": 3,
      "code": 3,
      "nameEn": "ICCAT Species",
      "nameFr": "Esp�ces CICTA"
    },
    {
      "id": 4,
      "code": 4,
      "nameEn": "IATTC Species",
      "nameFr": "Esp�ces IATTC"
    },
    {
      "id": 5,
      "code": 5,
      "nameEn": "WCPFC Species",
      "nameFr": "Esp�ces WCPFC"
    },
    {
      "id": 6,
      "code": 6,
      "nameEn": "CCSBT Species (SBF)",
      "nameFr": "Esp�ce CCSBT (SBF)"
    },
    {
      "id": 7,
      "code": 7,
      "nameEn": "All tuna RFMOs Species",
      "nameFr": "Toutes esp�ces des ORGP Thons"
    },
    {
      "id": 8,
      "code": 8,
      "nameEn": "Tuna and tuna like species",
      "nameFr": "Thons et esp�ces apparent�es"
    },
    {
      "id": 9,
      "code": 9,
      "nameEn": "Yellowfin tuna (YFT) and bigeye tuna (BET)",
      "nameFr": "Albacore (YFT) et patudo (BET)"
    },
    {
      "id": 10,
      "code": 10,
      "nameEn": "Yellowfin tuna (YFT), bigeye tuna (BET) and skipjack tuna (SKY)",
      "nameFr": "Albacore (YFT), patudo (BET) et listao (SKJ)"
    },
    {
      "id": 11,
      "code": 11,
      "nameEn": "Yellowfin tuna (YFT) and skipjack tuna (SKY)",
      "nameFr": "Albacore (YFT) et listao (SKJ)"
    },
    {
      "id": 12,
      "code": 12,
      "nameEn": "Yellowfin tuna (YFT) and swordfish (SWO)",
      "nameFr": "Albacore (YFT) et espadon (SWO)"
    },
    {
      "id": 13,
      "code": 13,
      "nameEn": "Tropical tunas and/or swordfish (SWO)",
      "nameFr": "Thons tropicaux et/ou espadon (SWO)"
    },
    {
      "id": 14,
      "code": 14,
      "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), bigeye tuna (BET), skipjack tuna (SKY), swordfish, sailfish",
      "nameFr": "Germon (ALB), Albacore (YFT), patudo (BET), listao (SKJ), Espadon (SWO), voilier"
    },
    {
      "id": 15,
      "code": 15,
      "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), bigeye tuna (BET), swordfish (SWO)",
      "nameFr": "Germon (ALB), Albacore (YFT), patudo (BET),  Espadon (SWO)"
    },
    {
      "id": 16,
      "code": 16,
      "nameEn": "Yellowfin tuna (YFT), bigeye tuna (BET), swordfish (SWO)",
      "nameFr": "Albacore (YFT), patudo (BET), Espadon (SWO)"
    },
    {
      "id": 17,
      "code": 17,
      "nameEn": "Kawakawa (KAW) & Frigate tuna (FRI) & Bullet tuna (BLT)",
      "nameFr": "Thonine orientale (KAW) & Auxide (FRI) & Bonitou (BLT)"
    },
    {
      "id": 18,
      "code": 18,
      "nameEn": "Black marlin (BLM), blue marlin (BUM) and striped marlin (MLS)",
      "nameFr": "Makaire noir (BLM), Makaire bleu (BUM) et Marlin ray� (MLS)"
    },
    {
      "id": 19,
      "code": 19,
      "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), bigeye tuna (BET), swordfish (SWO), sailfish",
      "nameFr": "Germon (ALB), Albacore (YFT), Patudo (BET), Espadon (SWO), voilier"
    },
    {
      "id": 20,
      "code": 20,
      "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), bigeye tuna (BET), Skipjack tuna (SKY)",
      "nameFr": "Germon (ALB), Albacore (YFT), Patudo (BET), Listao (SKJ)"
    },
    {
      "id": 21,
      "code": 21,
      "nameEn": "Albacore (ALB), Swordfish (SWO)",
      "nameFr": "Germon (ALB), Espadon (SWO)"
    },
    {
      "id": 22,
      "code": 22,
      "nameEn": "Skipjack tuna (SKJ), Swordfish (SWO)",
      "nameFr": "Listao (SKJ), Espadon (SWO)"
    },
    {
      "id": 23,
      "code": 23,
      "nameEn": "Bigeye tuna (BET), Skipjack tuna (SKJ)",
      "nameFr": "Patudo (BET), Listao (SKJ)"
    },
    {
      "id": 24,
      "code": 24,
      "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), Bigeye tuna (BET)",
      "nameFr": "Germon (ALB), Albacore (YFT), Patudo (BET)"
    },
    {
      "id": 25,
      "code": 25,
      "nameEn": "Tropical tunas, swordfish (SWO), Albacore (ALB)",
      "nameFr": "Thons tropicaux, espadon (SWO), Germon (ALB)"
    },
    {
      "id": 26,
      "code": 26,
      "nameEn": "Bigeye (BET), Yellowfin (YFT), Southern Bluefin Tuna (SBF)",
      "nameFr": "Patudo (BET), Albacore (YFT), Thon rouge du Sud (SBF)"
    },
    {
      "id": 27,
      "code": 27,
      "nameEn": "Bluefin Tuna and Skipjack (SKY)",
      "nameFr": "Thon Rouge et listao (SKJ)"
    },
    {
      "id": 28,
      "code": 28,
      "nameEn": "Swordfish (SWO) and Sharks",
      "nameFr": "Espadon (SWO) et Requins"
    },
    {
      "id": 29,
      "code": 29,
      "nameEn": "Swordfish (SWO), sharks, tunas (not Southern Bluefin Tuna SBF)",
      "nameFr": "Espadon (SWO), requins, thon ( pas de thon rouge du sud SBF)"
    },
    {
      "id": 30,
      "code": 30,
      "nameEn": "Not elsewhere identified",
      "nameFr": "Non identifi� ailleurs"
    }
  ]