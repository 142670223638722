export const rfmos = [
  {
    "id":1,
    "code":"CCAMLR"
  },
  {
    "id":2,
    "code":"CCSBT"
  },
  {
    "id":3,
    "code":"IATTC"
  },
  {
    "id":4,
    "code":"ICCAT"
  },
  {
    "id":5,
    "code":"IATTC"
  },
  {
    "id":6,
    "code":"NAFO"
  },
  {
    "id":6,
    "code":"NASCO"
  },
  {
    "id":7,
    "code":"NEAFC"
  },
  {
    "id":8,
    "code":"SEAFO"
  },
  {
    "id":9,
    "code":"SIOFA"
  },
  {
    "id":10,
    "code":"SPRFMO"
  },
  {
    "id":11,
    "code":"WCPFC"
  }
]