import { Mutator } from "lib/apprise-frontend-core/state/api"
import { cloneDeep } from "lodash"
import { useMemo, useRef, useState } from "react"
import copyAndApplyChanges from "immer";

export type StateRefContent<T = any> = [
  T,
  (v:T) => void,
  {
    get: () => T
    set:(_m:Mutator<T>) => void,
    setQuietly:(_m:Mutator<T>) => void,
    reset: () => void
  }
]

export const useStateRef = <T>(value: T) => {

  const [renderCounter, render] = useState(0)

  const state = useRef(cloneDeep(value))  // a clone supports reset.

  const get = () => state.current

  const set = (mutator: Mutator<any>) => setQuietly(mutator).then(() => render(r => ++r))

  const setQuietly = (mutator: Mutator<any>) => {

    state.current = copyAndApplyChanges(state.current, (s: any) => void mutator(s))

    return Promise.resolve()

  }

  const setStateRef = (_value:T) => {

    state.current = cloneDeep(_value)

    render(r => ++r)

  }

  const reset = () => {

    setStateRef(value)

  }


  const stateapi = useMemo(() => ( // changes only if state does, not on every client re-render.
    [
      state.current,
      setStateRef,
      {
        get,
        set,
        setQuietly,
        reset
      }
    ]
  ),
    // render consumers only set()/reset(), not on setQuitely() or other reasons (eg parent renders)
    // eslint-disable-next-line    
    [renderCounter])

  return stateapi as StateRefContent<T>
}

