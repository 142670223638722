import MockAdapter from 'axios-mock-adapter/types';
import { utils } from 'lib/apprise-frontend-core/utils/common';
import { v4 as uuid } from 'uuid';
import { ExternalUser, Login } from './model';

const fakeUser = (name="a User" as any) => {
    console.log(name)
    const user:ExternalUser = {id:uuid(), name, deviceName:'table-1', adminUser:false, flagStateUser:false, portStateUser:true}
    const token = {jwt:'aaaa', expire:10000}
    return {user, token}
}

export const useUserMockery = () => {
    

    return (mock: MockAdapter) => {

        console.log("mocking users...")

        mock.onPost("/login").reply((config)=>{
                const data:Login = JSON.parse(config.data);
                const {username} = data;
                return Promise.resolve([200,{data:fakeUser(username)}]).then(utils().wait(400))
        })
    
    }

}
