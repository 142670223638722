import React from 'react'
import { StepButtonProps } from "components/Step/model"
import { usePirs } from "pir/api"
import { IonFab, IonFabButton, IonLabel, IonIcon, IonItem } from '@ionic/react'
import { cutOutline, downloadOutline } from 'ionicons/icons'
import { pirmodelapi } from 'pir/model/api'
import { ProcessMiniViewer } from 'process/Viewer/Mini'
import { useUser } from 'user/api'

import './Button.scss'
import { processModelApi } from 'process/model/api'
import { taskStatusesProperties } from 'process/model'
import { getPirPreparationProcesses } from 'pir/processes/preparation'
import { Pir, statuses } from 'pir/model'

import {alertController} from "@ionic/core"
import { useProcess } from 'process/api'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import { User } from 'user/model'
import { FormStepButton } from '../FormStepButton'

export const ReleaseClaimButton = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()
    const userapi = useUser()
    const user = userapi.current()
    const {t} = useIntl()

    const ShowReleaseDialog = async (onRelease) => {
        
        const dialog = await alertController.create({
            header: t('dialog.release.header'),
            buttons: [
              {
                text: t('dialog.release.no'),
                role: 'cancel'
              },
              {
                text: t('dialog.release.yes'),
                handler: onRelease
              }]
        });
            
        dialog.present() 
    }
    
    return !user ? null : <IonFabButton color="dark"
    onClick={()=>ShowReleaseDialog(()=>pirapi.release(pir, user))}
    className="ion-fab-button-with-label" mode="ios">
        <IonLabel>{t('inspection.claimed.default.button')}</IonLabel>
        <IonIcon icon={cutOutline} />
    </IonFabButton>
}
    


export const ClaimedButton = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    const userapi = useUser()
    const user = userapi.current()

    const disabled = pirmodelapi(pir).isPreparing()
    const claimed = pirmodelapi(pir).hasStatusMoreThan(statuses.preparing)
    const claimedByOther = pir.claimedByDevice && pir.claimedByDevice !== userapi.current()?.deviceName

    const processApi = useProcess()
    
    const process = getPirPreparationProcesses(pir, processApi.all())
    const status =  process && processModelApi(process).getStatus()
    const {t} = useIntl()

    const dialogReclaim = async (pir:Pir) => {
        const dialog = await alertController.create({
            header: t('dialog.reclaim.header'),
            message: t('dialog.reclaim.message', {vessel:pirmodelapi(pir).title(), device:pir.claimedByDevice}),
            buttons: [
            {
                text: t('dialog.reclaim.no'),
                role: 'cancel'
            },
            {
                text: t('dialog.reclaim.yes'),
                handler: ()=>pirapi.claim(pir, user as User)
            }]
        });
        await dialog.present()
    }

    return !user ? <></> : <FormStepButton noPhoto noCompleteStepButton pir={pir} stepkey={undefined}>
        <IonFab className="claimed-button" vertical="bottom" slot="fixed" horizontal="end">
        {
            disabled && process && status ?
            <IonItem color={taskStatusesProperties[status].color} className="claimed-button-status">
                <div className="claimed-button-content">
                    <div className="claimed-button-head">
                        <IonLabel>{process.description}</IonLabel>
                        <IonIcon icon={taskStatusesProperties[status].icon} />
                    </div>
                    <div className="claimed-button-footer">
                        <ProcessMiniViewer process={process}/>
                    </div>
                </div>
            </IonItem>
        :
            [
                claimed  && <ReleaseClaimButton pir={pir}/>

                ,
                
                (!claimed || claimedByOther ) && <IonFabButton disabled={disabled}
                onClick={()=>claimedByOther ? dialogReclaim(pir) : pirapi.claim(pir, user)}
                className="ion-fab-button-with-label" mode="ios">
                    <IonLabel>{claimedByOther ? t('inspection.claimed.notClaimed.reclaim') : t('inspection.claimed.notClaimed.button')}</IonLabel>
                    <IonIcon icon={downloadOutline} />
                </IonFabButton>
            ]
        }
        </IonFab></FormStepButton>
}