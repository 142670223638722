import { alertController } from '@ionic/core';
import { IonFabButton, IonIcon, IonLabel } from "@ionic/react";
import { useStateRef } from 'components/useStateRef';
import { attachOutline } from "ionicons/icons";
import { pirattachmentKey } from "pages/pir/Steps/PirAttachments";
import { usePirs } from "pir/api";
import { Pir, PirEdting } from "pir/model";
import React, { useEffect, useMemo } from "react";
import { PirAttachmentTypeModal } from './TypeModal';
import { v4 as uuid } from 'uuid'
import { useIntl } from 'lib/apprise-frontend-core/intl/api';

export type OnFinishType = (o:{type?,files:File[], attachmentUid}) => Promise<void>

type ButtonProps = {onClick, disabled?}


type Props = {
    pir:Pir,
    attachmentUid?
    Button?: ({onClick, disabled}:ButtonProps) => JSX.Element,
    fileType?
    onFinish?: OnFinishType,
    disabled?
}

const DefaultButton = ({onClick}) => {
    const {t} = useIntl()
    return <IonFabButton
            className="ion-fab-button-with-label"
            onClick={onClick}
            color="dark"
            mode="ios">
            <IonLabel>{t('inspection.attachments.section.add')}</IonLabel>
            <IonIcon icon={attachOutline} />
        </IonFabButton>
}

type StateType = {
    files:File[],
    showModals:boolean,
    uid
}

const getinitialState = () => ({
    files:[],
    showModals:false,
    uid:uuid(),
    
})
export const PirAttachmentButton = ({pir, attachmentUid, Button, disabled, fileType, onFinish}:Props) => {
    const pirapi = usePirs()
    const fileInput: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);
    const {t} = useIntl()
    
    const [state, setState, {set, get, setQuietly}] = useStateRef<StateType>(getinitialState())
    
    const finish = async (obj?:{type}) => {
        const type = obj?.type
        const files = get().files
        if(onFinish){
            await onFinish({type, files, attachmentUid})
        }else{
            let attachment = await pirapi.setFormAttachmentPictures(files, pir)
        
            await (pirapi.setStep(pirattachmentKey, pir as PirEdting)(p=>{
                if(attachmentUid){
                    const index = p.extended.attachments.findIndex(a=>a.uid === attachmentUid)
                    if(index >= 0){
                        p.extended.attachments[index].pictures = [...p.extended.attachments[index].pictures,  ...attachment.pictures]
                    }
                }else{
                    let newattachment = {...attachment}
                    if(type && type > 0){
                        newattachment.type = type
                    }
                    p.extended.attachments = [...p.extended.attachments || [], ...[newattachment]]
                }
            }))
        }

        setState(getinitialState())
    }

    const showMoreDialog = async () => {
        const alert = await alertController.create({
            header: t('dialog.addAttachmentMorePage.header'),
            buttons: [
              {
                text: t('dialog.addAttachmentMorePage.no'),
                role: 'cancel',
                handler: () => !attachmentUid ? set(s=>s.showModals = true) : finish()
              },
              {
                text: t('dialog.addAttachmentMorePage.yes'),
                handler: () => fileInput?.current?.click()
              }]
        });
        alert.present()
    }

    const onClick = ()=>fileInput?.current?.click()

    const LocalButton = useMemo(()=>Button || DefaultButton, [])

    const uid = state.uid
    const showModals = state.showModals

    return <>
        <input
        ref={fileInput}
        hidden
        type="file"
        multiple
        accept="image/*"
        onChange={async(e)=>{
            
            if(e.target.files){
                setQuietly(s=>{
                    s.files = [...s.files, ...(Array.from(e.target.files as any) as File[])];
                })
                showMoreDialog()
            }
        }}
        />
        <LocalButton onClick={onClick} disabled={disabled}/>
        {useMemo(()=>state.showModals && <Modals fileType={fileType} key={state.uid} onFinish={finish} />, [showModals, uid])}
    </>
}

type ModalsState = {
    showType:boolean,
    type,
    complete:boolean
}

const initialModalsState:ModalsState = {
    showType:true,
    type:undefined,
    complete:false
}

type ModalsProps = {
    onFinish, fileType?
}
const getInitialModalState = (fileType) => 
    fileType ? {
            ...initialModalsState, 
            type:fileType,
            showType:false,
            complete:true
        } :
        initialModalsState


const Modals = ({onFinish, fileType}:ModalsProps) => {
    
    const [state, setState, {set}] = useStateRef<ModalsState>(getInitialModalState(fileType))
    const complete = state.complete
    useEffect(()=>{
        if(complete){
            onFinish({type:state.type})
        }
    }, [complete])
    return <>
        <PirAttachmentTypeModal mode="other" show={state.showType} onClose={type=>{set(s=>{
            s.type = type;s.showType=false;s.complete=true
        })}}/>
    </>
}