import { getUpdateAvailableRegistration } from "serviceWorkerRegistration"
import { getDarkMode, getIntl } from "./api"
import { Settings } from "./model"

export type SettingsState = {
    settings: Settings
}

export const initialSettings = () => ({
    settings: {
        darkMode: getDarkMode(),
        showAdvancedFeatures: false,
        onLine: navigator.onLine,
        updateAvailable: getUpdateAvailableRegistration() ? true : false,
        language: getIntl(),
        fontSize: 'medium',
        filters:{
            ports: []
        },
        application: {
            installed:false
        }
    }
} as SettingsState)


