import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { useIntl } from 'lib/apprise-frontend-core/intl/api';
import { usePirs } from 'pir/api';
import { pirsModelApi } from 'pir/model/api';
import React, { useEffect, useState } from 'react';
import { useSettings } from 'settings/api';
import { ConfigurationModal } from './ConfigurationModal';
import './Reports.scss';
import { PirSlider } from './Slider';
import { ReportsToolbar } from './ToolBar';


export const ReportsPage = () => {
    const [text, _setText] = React.useState(localStorage.getItem('textFilter') || '')
    const setText = (value:string)=>{
        localStorage.setItem('textFilter', value);
        _setText(value)
    }
    const pirsapi = usePirs()

    const settingsapi = useSettings()
    const {ports} = settingsapi.filters() || {ports:undefined, text:undefined}

    const all = pirsapi.all()
    const pirs = pirsModelApi(all).filterByPorts(ports)

    const filtered = pirs.filterByText(text).pirs

    const inspecting = [...pirs.signing().pirs, ...pirs.inspecting().pirs]

    const submitting = pirs.submitting().pirs

    const submitted = pirs.submitted().pirs

    const ready = pirs.ready().pirs

    const preparing = pirs.preparing().pirs

    const notready = pirsapi.notready(pirs.pirs)

    const claimedByOthers = pirsapi.claimedByOthers(pirs.pirs)

    const [configurationOpen, setConfigurationOpen] = useState(false)

    const doRefresh = pirsapi.fetchAll

    const { t } = useIntl()

    useEffect(() => {
        const asynctask= async () => {
            await doRefresh()
        }
        const interval = setInterval(() => {
            asynctask()
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    return <IonPage className="page-reports">
        <IonContent fullscreen>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
                <IonRefresherContent>
                </IonRefresherContent>
            </IonRefresher>
            <h1>{t('pir.reports.title')}</h1>
            <ReportsToolbar text={text} onSetText={setText} onToggleOptions={() => setConfigurationOpen(true)} />
            {
                filtered.length < all.length ? <div className="filtering">{t('pir.reports.search.filtering', {n:filtered.length, tot:all.length})}</div> : null
            }
            {
                text && text.length > 0 ?
                    <>
                    <PirSlider noItems={filtered.length == 0} pirs={filtered} title={'pir.reports.filtered'}/>
                    <PirSlider pirs={pirs.otherThan(filtered).pirs} title={'pir.reports.unfiltered'}/>
                    </>
                :
                    <>
                        {text && text.length > 0 && 'no items matching your filter criteria'}
                        <PirSlider pirs={submitting} title={'pir.reports.submitting'}/>
                        <PirSlider pirs={inspecting} title={'pir.reports.inspecting'}/>
                        <PirSlider pirs={[...ready, ...preparing]} title={'pir.reports.ready'}/>
                        <PirSlider pirs={notready} title={'pir.reports.notready'}/>
                        <PirSlider pirs={claimedByOthers} title={'pir.reports.claimedByOthers'}/>
                        <PirSlider pirs={submitted} title={'pir.reports.submitted'}/>
                    </>
            }
        </IonContent>
        <ConfigurationModal open={configurationOpen} onSetOpen={setConfigurationOpen}/>
    </IonPage>
}