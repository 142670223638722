import { TypeAhead } from 'components/TypeAhead'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useSettings } from 'settings/api'

type Props = {
    value?: string,
    onChange,
    title?,
    placeholder?
}
export const PortsTypeAhead = ({value, title, onChange, placeholder}:Props) => {

    const portsapi = useReferencedata()
    const ports = portsapi.current().port

    const language = useSettings().all().language
    
    return <TypeAhead value={value}
    placeholder={placeholder}
    title={title || placeholder}
    options={ports.map(f=>`${language == 'en' ? f.nameEn: f.nameFr}, ${f.countryCode} (${f.code})`)}
    onChange={onChange}/>
}