import { InspectionBaseError, InspectionErrors, PirEdting } from ".";

const isAnEmailRegex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const isDigitRegex = /^\d+$/

export const isEmptyError = (label:string) => ({message:`${label} cannot be empty`} as InspectionBaseError)

export const isNotDigitError = (label:string) => ({message:`${label} should be made only of digits`} as InspectionBaseError)

export const isNotIntegerError = (label:string) => ({message:`${label} should be an integer`} as InspectionBaseError)

export const customError = (message) =>  ({message:`${message}`} as InspectionBaseError)

export const isNotEmailError = (label:string) => ({message:`${label} should be an email`} as InspectionBaseError)

export const isLowerThanError = (label:string, compareLabel:string) => ({message:`${label} cannot be lower than ${compareLabel}`} as InspectionBaseError)

export const isLowerOrEqualThanError = (label:string, compareLabel:string) => ({message:`${label} cannot be lower or equal than ${compareLabel}`} as InspectionBaseError)

export const isHigherOrEqualThanError = (label:string, compareLabel:string) => ({message:`${label} cannot be higher or equal than ${compareLabel}`} as InspectionBaseError)

export const isHigherThanError = (label:string, compareLabel:string) => ({message:`${label} cannot be higher than ${compareLabel}`} as InspectionBaseError)

export const isNotPositiveNumberError = (label:string) => ({message:`${label} should be a number higher than 0`} as InspectionBaseError)

export const isEmpty = v => (v === undefined || v === null || v === '' || (v.length >= 0 && v.length === 0))

export const isNE = v => (v === 'NE')

export const hasNDigit = (v, n:number) => v.toString().split('').length === n

export const isDigit = (v) => isDigitRegex.test(v)

export const isNumber = (v) => +v + 0 === v

export const isPostiveNumber = (v) => isNumber(v) && v > 0


export const check = (v:any, label:string) => {

    let errors:InspectionBaseError[] = []

    const self = {
        isLatitudeLongitude : () => {
            if(!v || v.indexOf('-') === -1){
                errors.push({message:'Insert valid latitude and longitude fields'} as InspectionBaseError)
            }else{
                errors.push(...check(v.split('-')[0], 'Latitude').isEmpty().errors)
                errors.push(...check(v.split('-')[1], 'Longitude').isEmpty().errors)
            }
            return self
        }

        ,

        isNotDigit : () => {
            !isEmpty(v) && (!isDigitRegex.test(v) && errors.push(isNotDigitError(label)))
            return self
        }

        ,

        isNotInteger : () => {
            !isEmpty(v) && ((!isDigitRegex.test(v) || v <= 0 || (v+'').indexOf('0') === 0) && errors.push(isNotIntegerError(label)))
            return self
        }

        ,

        isNotPositiveNumber : () => {
            !isEmpty(v) && !isPostiveNumber(v) && errors.push(isNotPositiveNumberError(label))
            return self
        }

        ,

        isEmpty : () => {
            isEmpty(v) && errors.push(isEmptyError(label));
            return self
        }

        ,

        isNotUnique : (list:any[], message) => {
            list.filter(l=>l===v).length > 1 && errors.push(customError(message));
            return self
        }
        
        ,

        isEmail : () => {
            !isAnEmailRegex.test(v) && errors.push(isNotEmailError(label));
            return self
        }

        ,
        
        isLowerThan: (cv, compareLabel) => {
            v < cv && errors.push(isLowerThanError(label, compareLabel));
            return self
        }
        
        ,

        isLowerOrEqualThan: (cv, compareLabel) => {
            v <= cv && errors.push(isLowerOrEqualThanError(label, compareLabel));
            return self
        }

        ,

        isHigherOrEqualThan: (cv, compareLabel) => {
            v >= cv && errors.push(isHigherOrEqualThanError(label, compareLabel));
            return self
        }

        ,

        isHigherThan: (cv, compareLabel) => {
            v > cv && errors.push(isHigherThanError(label, compareLabel));
            return self
        }

        ,

        custom: (isError:()=>boolean, errorMessage:string) => {
            isError() && errors.push(customError(errorMessage))
            return self
        }

        ,
        
        errors
    }

    return self

} 

export const getStepErrors = (key:string, p:PirEdting) => p.inspectionErrors && p.inspectionErrors[key] && p.inspectionErrors[key].errors

export const containsErrors = (p?:InspectionErrors<any> | InspectionBaseError) => {
    if(!p){
        return false
    }
    if(p.message){
        return true
    }
    return Object.keys(p).find(k=>{
        if(!p[k]){
            return false
        }
        return p[k].find(t=>containsErrors(t)) !== undefined ? true : false
    })!==undefined ? true : false
}

//this shouldn't be needed because this information is already stored into isValid
export const stepContainsErrors = (key:string, p:PirEdting) => containsErrors(getStepErrors(key, p))

export const stepIsValid = (key:string, p:PirEdting) =>  p.inspectionErrors && p.inspectionErrors[key].isValid ? true : false