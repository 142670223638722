import { authConfig } from "api"
import { AxiosError, AxiosRequestConfig } from "axios"
import { bridge } from "bridge"
import { useClient } from "lib/apprise-frontend-core/client/api"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { blobToBase64 } from "./api"
import { getFileBase64 } from "./model/api"

export const useFileCall = () => {
    
    const {at} = useClient()
    const {t} = useIntl()
    
    const self = {

        fetch: async (url:string, contentType:string) => {
            try {
                const {data:blob} = await at(url).get<{data:Blob}>({...authConfig(), contentType, raw:true, responseType:'blob'} as any)
                const _base64 = await blobToBase64(blob) as string
                return {base64:getFileBase64(_base64, contentType), blob}
            } catch (error) {
                if((error as AxiosError).response?.status === 500 &&
                (error as AxiosError).response?.data.size === 0){
                    throw new Error(t('error.fileDownload'))
                }
                const data = await (error as any).response.data.text()
                const _err = JSON.parse(data) as Error
                bridge && bridge.renderError && bridge.renderError(_err)
                throw new Error(_err.message)
            }
        },

        fetchFile: (url:string, contentType:string) => {
            return at(url).get<File>({...authConfig(), contentType} as any)
            //return at(t.url).getbase64()
        },

        uploadFile: (url:string, file:File, config?:AxiosRequestConfig) => {
            const baseConfig = authConfig()
            return at(url).post<string>(file, {...baseConfig, ...config, headers:{
                ...baseConfig.headers,
                "Content-Type": "application/octet-stream"
            }}).then(r=>{
                if(r.indexOf('ok')!==0){
                    throw new Error(t('error.upload'));
                }
            })
        } 
    }

    return self
}