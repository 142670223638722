
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { initialProces, initialProcessRegistry, ProcessRegistryState, ProcesState } from './state';



export const ProcesContext = createContext<State<ProcesState>>(undefined!)
export const ProcessRegistryContext = createContext<State<ProcessRegistryState>>(undefined!)

export const ProcesProvider = (props: PropsWithChildren<{}>) => {

    return <StateProvider initialState={initialProcessRegistry} context={ProcessRegistryContext}>
            <StateProvider  initialState={initialProces()} context={ProcesContext}>
                    {props.children}
            </StateProvider>
        </StateProvider>
}

