import { ItemReorderEventDetail } from '@ionic/core'
import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonReorder, IonReorderGroup, IonThumbnail } from "@ionic/react"
import { useDeleteDialog } from 'components/DeleteDialog'
import { DocumenttypesSelect } from "documentType/Select"
import { PirFileImageHandler } from "file/model"
import { humanFileSize, pirfileapi } from "file/model/api"
import { addOutline, attachOutline, expandOutline, removeOutline, reorderTwoOutline } from "ionicons/icons"
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import { Mutator } from "lib/apprise-frontend-core/state/api"
import { FormAttachment, FormAttachmentPicture, Pir, PirEdting, RemoteFormAttachment } from "pir/model"
import { isRemoteFormAttachment } from 'pir/model/api'
import React, { useMemo, useState } from "react"
import { PhotoSwipe } from 'react-photoswipe'
import { move } from 'utils'
import { OnFinishType, PirAttachmentButton } from './Button'
import './PirAttachmentsPictures.scss'
import { usePirFormAttachment } from "./useAttachments"



type Props = {
    file: PirFileImageHandler
    picture:FormAttachmentPicture,
    onRemove,
    onClick,
    index,
    disabled?
}

export const PirFormAttachmentPictureItem = ({picture, disabled, file, onRemove, onClick, index}:Props) => {
        
    if(!file) return null

    const {width, height, size, filename, content} = file

    return <IonItemSliding>
        <IonItem disabled={disabled} className="pir-attachment-picture-item" detail detailIcon={expandOutline} button onClick={()=>onClick()}>
            <IonReorder slot="start">
                <IonIcon icon={reorderTwoOutline} />
            </IonReorder>
            <IonThumbnail slot="start">
                <img src={pirfileapi(file).src()}/>
            </IonThumbnail>
            <div>
                <IonLabel>{filename}</IonLabel>
                <div className="item-detail">
                    {humanFileSize(size).label}
                </div>
                <div className="item-detail">
                    {width} x {height}
                </div>
            </div>
        </IonItem>
        <IonItemOptions side="end">
            <IonItemOption disabled={disabled} onClick={onRemove} color="danger">Delete</IonItemOption>
        </IonItemOptions>
    </IonItemSliding>
}

type PirFormAttachmentsProps = {
    disabled?
    pir:Pir
    attachments:(FormAttachment|RemoteFormAttachment)[],
    wrapperRef
    typeDisabled?
    onSet?: (_m:Mutator<PirEdting>) => void,
    onFinish?: OnFinishType
    onSetAttachment?: (index, f:FormAttachment) => void
    onRemoveAttachmentPicture?: (attachmentIndex, pictureIndex) => void
    onRemoveAttachment?: (index) => void
    onReorderAttachmentPictures?: (attachmentIndex, from, to) => void
}

export const PirFormAttachments = ({pir, disabled, attachments, onSet, wrapperRef, onFinish,
    onSetAttachment,
    onRemoveAttachment,
    onRemoveAttachmentPicture,
    onReorderAttachmentPictures,
    typeDisabled
}:PirFormAttachmentsProps) => {

    const showDeleteDialog = useDeleteDialog()
    const {t} = useIntl()
    
    const setAttachment = (i) => (f:FormAttachment ) => onSetAttachment ? onSetAttachment(i, f) : onSet && onSet(p=>p.extended.attachments[i] = f)
    const removeAttachmentPicture = (attachmentIndex) => (pictureIndex) =>  showDeleteDialog({text:t('dialog.deleteAttachmentPicture.header'),onDelete:()=>onRemoveAttachmentPicture ? onRemoveAttachmentPicture(attachmentIndex, pictureIndex) : onSet && onSet(p=>p.extended.attachments[attachmentIndex].pictures.splice(pictureIndex,1))})
    const removeAttachment = (i) => showDeleteDialog({text:t('dialog.deleteAttachment.header'), onDelete:()=>onRemoveAttachment ? onRemoveAttachment(i) : onSet && onSet(p=>p.extended.attachments.splice(i,1))})
    const onReorderPictures = (i) => (from, to) => onReorderAttachmentPictures ? onReorderAttachmentPictures(i, from, to) : onSet && onSet(p=>move(p.extended.attachments[i].pictures, from, to))

    const AddImageButton = ({onClick, disabled:_d}:{onClick, disabled?})=><IonButton disabled={disabled||_d} onClick={onClick} shape="round" slot="end" size="small">
            Add page
            <IonIcon icon={addOutline} />
        </IonButton>
    
    return <>
    {attachments.map((a, i)=>{
        const isRemote = isRemoteFormAttachment(a)

        const ra = a as RemoteFormAttachment
        const ia = a as FormAttachment

        return <IonItemGroup key={a.uid}>
            <IonItemDivider>
                <IonIcon slot="start" icon={attachOutline}/>
                <IonLabel>Attachment {i+1} - {a.name}</IonLabel>
                <IonButtons slot="end">
                    <PirAttachmentButton disabled={isRemote} onFinish={onFinish} attachmentUid={a.uid} pir={pir} Button={AddImageButton}/>
                    <IonButton disabled={disabled||isRemote} color="danger" shape="round" slot="end" size="small" onClick={()=>removeAttachment(i)}>
                        Delete
                        <IonIcon icon={removeOutline} />
                    </IonButton>
                </IonButtons>
            </IonItemDivider>

            <IonItem disabled={disabled}>
                <IonLabel>Attachment type</IonLabel>
                <DocumenttypesSelect
                disabled={typeDisabled||isRemote}
                codeId={isRemote ? ra.documentTag : ia.type}
                onChange={(type, name)=>setAttachment(i)({...ia, type, name})}/>
            </IonItem>
            
            {!isRemote && <PirFormAttachmentPictures disabled={disabled||isRemote} onReorder={onReorderPictures(i)} wrapperRef={wrapperRef} attachment={ia} onRemove={removeAttachmentPicture(i)}/>}                
        </IonItemGroup>
    })}
    </>
}

type PicturesProps = {
    attachment:FormAttachment
    disabled?
    onRemove,
    onReorder: (from, to) => void
    wrapperRef:React.MutableRefObject<HTMLIonItemElement | null>
}
const PirFormAttachmentPictures = ({attachment, disabled, onRemove, onReorder, wrapperRef}:PicturesProps) => {


    const [pictureOpened, setPictureOpened] = useState<null | number>(null)

    const setOpen = (i) => {
        wrapperRef.current?.classList.add('open-photoswipe')
        setPictureOpened(i)
    }
    const setClose = () => {
        wrapperRef.current?.classList.remove('open-photoswipe')
        setPictureOpened(null)
    }

    const files = usePirFormAttachment(attachment)

    const uid = attachment.pictures.map(a=>a.uid).join()

    const filesuid = files.map(a=>a.url).join()

    const onReorderPictures = (event: CustomEvent<ItemReorderEventDetail>) => {
        onReorder(event.detail.from, event.detail.to)
        event.detail.complete();
    }

    return useMemo(() => <Attachments
    disabled={disabled}
    onReorder={onReorderPictures}
    attachment={attachment}
    files={files}
    pictureOpened={pictureOpened}
    onRemove={onRemove}
    onSetClose={setClose}
    onSetOpen={setOpen}/>, [uid, filesuid, pictureOpened])
}

export const Attachments = ({attachment, onSetClose, onSetOpen, files, pictureOpened, onRemove, onReorder, disabled}) => {

    return <>
        <PhotoSwipe
            isOpen={pictureOpened!==null}
            items={attachment.pictures.map(p=>{
                const file = files.find(f=>f.url==p.uid)
                return file && {w: file.width,h: file.height,src:pirfileapi(file).src(),title:`${file.filename}`}
            }).filter(a=>a)}
            options={{index:pictureOpened,shareEl:false, fullscreenEl:false}} onClose={onSetClose}/>

        <IonReorderGroup disabled={disabled} onIonItemReorder={onReorder}>
            {attachment.pictures.map((p, pi)=>{
                const file = files.find(f=>f.url===p.uid)
                return file && <PirFormAttachmentPictureItem disabled={disabled} index={pi+1} key={p.uid} picture={p} file={file} onClick={()=>onSetOpen(pi)} onRemove={()=>onRemove(pi)}/>
            }).filter(a=>a)}
        </IonReorderGroup>
    </>
}