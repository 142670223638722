import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonToggle } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { FishingAreasSelect } from "fishingArea/Select";
import copyAndApplyChanges from 'immer';
import { removeOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { Mutator } from "lib/apprise-frontend-core/state/api";
import { usePirs } from "pir/api";
import { InspectionErrors, PirEdting, ValidateStep, RetainedCatches, statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, stepContainsErrors } from "pir/model/inspectionErrorApi";
import { ProductFormsSelect } from "productForm/Select";
import React from "react";
import { SpeciesSelect } from "species/Select";
import { deltaTreshHold, getDeltaPercentage } from "utils";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    retainedCatches
}

const useValidation = () => {

    const {t} = useIntl()

    const validate:ValidateStep = (p) => {
        let errors:InspectionErrors<ValidationType> = {}
        errors.retainedCatches = [] as InspectionErrors<RetainedCatches>[]
        p.extended.pir.retainedCatches.forEach(r=>{
            const error:InspectionErrors<RetainedCatches> = {
                species: check(r.species, t('inspection.catchRetained.species.label')).isEmpty().errors,
                productForm: check(r.productForm, t('inspection.catchRetained.productForm.label')).isEmpty().errors,
                catchArea: check(r.catchArea, t('inspection.catchRetained.catchArea.label')).isEmpty().errors,
                actualQuantity: check(r.actualQuantity, `${t('inspection.catchRetained.quantity.label')} (${t('inspection.catchRetained.section.verified')})`).isEmpty().isLowerThan(0, '0').errors,
                declaredQuantity: check(r.declaredQuantity, `${t('inspection.catchRetained.quantity.label')} (${t('inspection.catchRetained.section.declared')})`).isEmpty().isLowerThan(0, '0').errors,
            }
            errors.retainedCatches && errors.retainedCatches.push(error)
        })

        return errors
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const validate = useValidation()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const errors:InspectionErrors<ValidationType> = getStepErrors(key, edited)

    const set = pirsapi.setStep(key, edited, validate)

    const editItem = (i, _m:Mutator<RetainedCatches>) => set(c=>{
        c.extended.pir.retainedCatches[i] = copyAndApplyChanges(c.extended.pir.retainedCatches[i], (s: RetainedCatches) => void _m(s))
    })

    const removeItem = (i) => set(c=>{
        c.extended.pir.retainedCatches.splice(i, 1)
    })

    const addItem = () => set(c=>{
        c.extended.pir.retainedCatches && c.extended.pir.retainedCatches.unshift({} as RetainedCatches)
    })

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.catchRetained.section.title')}>
        <StepDescription>{t('inspection.catchRetained.section.subtitle')}</StepDescription>
        <IonList>
            
            <IonItemGroup>
                <IonItem><IonButton disabled={readonly} slot="end" onClick={addItem} color="primary">{t('inspection.catchRetained.section.add')}</IonButton></IonItem>
            </IonItemGroup>

            {[...edited.extended.pir.retainedCatches].map((c, ci, items) => {
                const retainedTitle = `${t('inspection.catchRetained.section.catchRetained')} ${items.length - ci}`
                const error = (((errors && errors.retainedCatches && errors.retainedCatches[ci]) || {}) as InspectionErrors<RetainedCatches>)
                const retainedDeltaPercentage = getDeltaPercentage(c.declaredQuantity, c.actualQuantity)
                return <IonItemGroup key={ci} className="new-item">
                     <IonItemDivider>
                        <IonLabel>{retainedTitle}</IonLabel>
                        
                        <IonButtons slot="end">
                            <IonButton disabled={readonly} color="danger" shape="round" slot="end" size="small"
                            onClick={()=>removeItem(ci)}>
                                {t('common.delete')}
                                <IonIcon icon={removeOutline} />
                            </IonButton>
                        </IonButtons>

                    </IonItemDivider>

                    <Item description={t('inspection.catchRetained.species.help')} disabled={readonly} mandatory error={error.species}>
                        <IonLabel>{t('inspection.catchRetained.species.label')}</IonLabel>
                        <SpeciesSelect ids={c.species ? [c.species] : undefined}
                        placeholder={t('inspection.catchRetained.species.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.species = v ? v[0] : undefined )}/>
                    </Item>

                    <Item description={t('inspection.catchRetained.productForm.help')} disabled={readonly} mandatory error={error.productForm}>
                        <IonLabel>{t('inspection.catchRetained.productForm.label')}</IonLabel>
                        <ProductFormsSelect ids={[c.productForm]}
                        placeholder={t('inspection.catchRetained.productForm.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.productForm = v ? v[0] : undefined )}/>
                    </Item>

                    <Item description={t('inspection.catchRetained.catchArea.help')} disabled={readonly} mandatory error={error.catchArea}>
                        <IonLabel>{t('inspection.catchRetained.catchArea.label')}</IonLabel>
                        <FishingAreasSelect ids={c.catchArea}
                        placeholder={t('inspection.catchRetained.catchArea.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.catchArea = v )}/>
                    </Item>

                    <Item description={t('inspection.catchRetained.quantity.help')} disabled={readonly} mandatory error={[...(error.declaredQuantity || []), ...(error.actualQuantity || [])]}>
                        <IonLabel style={{flex:2}}>{t('inspection.catchRetained.quantity.label')}</IonLabel>
                        
                        <IonItem lines="none" disabled={readonly} style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchRetained.section.declared')}</IonLabel>
                            <IonInput
                            min={'0'}
                            type="number"
                            value={c.declaredQuantity}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                p.declaredQuantity = +(e.detail.value!);
                                p.deltaRetained = p.declaredQuantity >=0 && p.actualQuantity >=0 ? p.actualQuantity - p.declaredQuantity : p.deltaRetained
                            })}></IonInput>
                        </IonItem>
                        <IonItem lines="none" disabled={readonly} style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchRetained.section.verified')}</IonLabel>
                            <IonInput
                            min={'0'}
                            type="number"
                            value={c.actualQuantity}
                            onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>{
                                p.actualQuantity = +(e.detail.value!);
                                p.deltaRetained = p.declaredQuantity >=0 && p.actualQuantity >=0 ? p.actualQuantity - p.declaredQuantity : p.deltaRetained
                            })}></IonInput>
                        </IonItem>
                        <IonItem lines="none" disabled
                        className={'delta-field ' + (retainedDeltaPercentage && retainedDeltaPercentage >= deltaTreshHold ? 'with-error' : '')}
                        style={{flex:2}}>
                            <IonLabel position="stacked">{t('inspection.catchRetained.section.delta')}</IonLabel>
                            <IonInput
                            type="number"
                            value={c.deltaRetained}></IonInput>
                        </IonItem>
                    </Item>

                    <Item description={t('inspection.catchRetained.objection.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.catchRetained.objection.label')}</IonLabel>
                        <IonToggle
                        color={c.objection ? 'success' : undefined}
                        checked={c.objection ? true : false}
                        onIonChange={e=>e.detail.checked !== undefined && editItem(ci, (p)=>p.objection = e.detail.checked)}/>
                    </Item>

                    <Item description={t('inspection.catchRetained.comments.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.catchRetained.comments.label')}</IonLabel>
                        <IonInput
                        value={c.comments}
                        placeholder={t('inspection.catchRetained.comments.placeholder')}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.comments = (e.detail.value!))}></IonInput>
                    </Item>
                
                </IonItemGroup>
            })}
        </IonList>
    </StepContent>
}
const key = "catchretained"

const Button = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) =>{
    const {t} = useIntl()
    return StepLink({
            completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
            isDefault,
            disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
            first,
            last,
            path:getPath(key),
            isForm: true,
            title: t('inspection.catchRetained.section.title'),
            badge: pir.extended?.pir.retainedCatches.length,
            hasError: stepContainsErrors(key, pir as PirEdting)
        })}

export const CatchRetained:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}