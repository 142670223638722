
export type Language = "fr" | "en" | "ar" | "zh" | "es" | "ru"

export const allLanguages: Language[] = [ "en", "fr", "es", "zh", "ar", "ru"]

export const languageOf = (lang:Language) => `intl.${lang}`

export type IntlConfiguration = {

    path: string
    defaultLanguage: Language
    supportedLanguages: Language[]
    requiredLanguages: Language[]
}

export const defaultIntlConfiguration: IntlConfiguration = {


    path: 'locale/{{lng}}_translation.json',
    defaultLanguage: 'en',
    supportedLanguages: ['en'],
    requiredLanguages: []

}


//  multilang ...


export type Multilang = {

    [key in Language]?: string

}

export const isMultilang = ( ml : any) : ml is Multilang => ml && typeof ml === 'object' && Object.keys(ml).every(k=>allLanguages.includes(k as Language))






