import React from "react";

type Props = {
    accept:string,
    multiple?,
    Button: ({onClick})=>JSX.Element,
    onUpload: (files:File[])=>void
}
export const UploadButton = ({accept, multiple, Button, onUpload}) => {

    const fileInput: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

    const onChange = (e) => {
        onUpload(Array.from(e.target.files as any) as File[])
    }

    return <>
        <input
        ref={fileInput}
        hidden
        type="file"
        multiple={multiple}
        accept={accept}
        onChange={onChange}
        />
        <Button onClick={()=>fileInput?.current?.click()}/>
    </>
}
