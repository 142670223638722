export const purposes = [
  {
    "id": 1,
    "code": "LAN",
    "nameEn": "Landing",
    "nameFr": "D�barquement",
    "requireUserDetails": 0
  },
  {
    "id": 2,
    "code": "TRX",
    "nameEn": "Transhipment",
    "nameFr": "Transbordement",
    "requireUserDetails": 0
  },
  {
    "id": 3,
    "code": "PAC",
    "nameEn": "Packaging",
    "nameFr": "Conditionnement",
    "requireUserDetails": 0
  },
  {
    "id": 4,
    "code": "PRO",
    "nameEn": "Processing Fish",
    "nameFr": "Transformation du poisson",
    "requireUserDetails": 0
  },
  {
    "id": 5,
    "code": "REF",
    "nameEn": "Refueling",
    "nameFr": "Ravitaillement",
    "requireUserDetails": 0
  },
  {
    "id": 6,
    "code": "RES",
    "nameEn": "Resupplying",
    "nameFr": "R�approvisionnement",
    "requireUserDetails": 0
  },
  {
    "id": 7,
    "code": "MAI",
    "nameEn": "Maintenance",
    "nameFr": "Entretien",
    "requireUserDetails": 0
  },
  {
    "id": 8,
    "code": "DOC",
    "nameEn": "Drydocking",
    "nameFr": "Mise en cale s�che",
    "requireUserDetails": 0
  },
  {
    "id": 9,
    "code": "FOR",
    "nameEn": "Force Majeure",
    "nameFr": "Force majeure",
    "requireUserDetails": 0
  },
  {
    "id": 10,
    "code": "CRE",
    "nameEn": "Change Crew",
    "nameFr": "Changement d'�quipage",
    "requireUserDetails": 0
  },
  {
    "id": 11,
    "code": "INS",
    "nameEn": "Inspection",
    "nameFr": "Inspection",
    "requireUserDetails": 0
  },
  {
    "id": 12,
    "code": "OTH",
    "nameEn": "Other",
    "nameFr": "Autre",
    "requireUserDetails": 1
  }
]