import { IonChip, IonIcon, IonLabel } from '@ionic/react'
import { checkmarkCircle, close } from 'ionicons/icons'
import React from 'react'
import { SelectableItem, SelecteableValue } from '.'
type Props = {
    options:SelectableItem[],
    selected:SelecteableValue[],
    placeholder?,
    preview?
    onOpen?
    disabled?
    onToggle?:(s:SelecteableValue, checked:boolean)=>void,

}
export const SelectViewer = ({onOpen, onToggle, preview, selected, options, placeholder, disabled}:Props) => {
    return <div className={`select-viewer mode-${preview ? 'preview' : 'edit'}`}>
        {preview && selected.length === 0 && <IonChip onClick={onOpen}>
            <IonLabel>{placeholder}</IonLabel>
          </IonChip>
        }
        {selected.length > 0 && options.filter(o=>selected.includes(o.value)).map(o=>{
            return <IonChip disabled={disabled} color="secondary" key={o.value} onClick={()=>!preview ? (onToggle && onToggle(o.value, false)): onOpen()}>
            <IonIcon icon={checkmarkCircle}/>
            <IonLabel>{o.label}</IonLabel>
            {!preview && <IonIcon icon={close} />}
          </IonChip>
        })}
    </div>
}