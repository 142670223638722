export const productForms = [
  {
    "id": 1,
    "code": 1,
    "nameEn": "Whole/rounded - Not processed",
    "nameFr": "Entier - non transform�"
  },
  {
    "id": 2,
    "code": 2,
    "nameEn": "Head on, Gilled & Gutted, Tail on",
    "nameFr": "Avec t�te, Sans branchie, Evisc�r�, avec caudal"
  },
  {
    "id": 3,
    "code": 3,
    "nameEn": "Head on, Gilled & Gutted, Tail off",
    "nameFr": "Avec t�te, Sans branchies, Evisc�r�, sans caudal"
  },
  {
    "id": 4,
    "code": 4,
    "nameEn": "Head off, Gilled & Gutted, Tail on",
    "nameFr": "Et�t�, Sans branchies, Evisc�r�, avec caudal"
  },
  {
    "id": 5,
    "code": 5,
    "nameEn": "Head off, Gilled & Gutted, Tail off",
    "nameFr": "Et�t�, Sans branchies, Evisc�r�, sans caudal"
  },
  {
    "id": 6,
    "code": 6,
    "nameEn": "Loins",
    "nameFr": "Longe"
  },
  {
    "id": 7,
    "code": 7,
    "nameEn": "Fillet",
    "nameFr": "Filets"
  },
  {
    "id": 8,
    "code": 8,
    "nameEn": "Belly meat",
    "nameFr": "Ventre"
  },
  {
    "id": 9,
    "code": 9,
    "nameEn": "Shark Fins",
    "nameFr": "Ailerons de requins"
  },
  {
    "id": 10,
    "code": 10,
    "nameEn": "Not elsewhere identified",
    "nameFr": "Non identifi� ailleurs"
  },
  {
    "id": 11,
    "code": 11,
    "nameEn": "Head on, Gilled & Gutted, Tail on with fins attached (for sharks)",
    "nameFr": "Avec t�te, sans branchie, �visc�r�, avec caudale, avec ailerons attach�s (pour Requins)"
  },
  {
    "id": 12,
    "code": 12,
    "nameEn": "Head on, Gilled & Gutted, Tail off, with fins attached (for sharks)",
    "nameFr": "Avec t�te, sans branchies, �visc�r�, sans caudale, avec ailerons attach�s (pour Requins)"
  },
  {
    "id": 13,
    "code": 13,
    "nameEn": "Head off, Gilled & Gutted, Tail on, with fins attached (for sharks)",
    "nameFr": "Et�t�, sans branchies, �visc�r�, avec caudale, avec ailerons attach�s (pour Requins)"
  },
  {
    "id": 14,
    "code": 14,
    "nameEn": "Head off, Gilled & Gutted, Tail off, with fins attached (for sharks)",
    "nameFr": "Et�t�, sans branchies, �visc�r�, sans caudale, avec ailerons attach�s (pour Requins)"
  },
  {
    "id": 15,
    "code": 15,
    "nameEn": "Eggs",
    "nameFr": "Oeufs"
  },
  {
    "id": 16,
    "code": 16,
    "nameEn": "Stomach",
    "nameFr": "Estomat"
  },
  {
    "id": 17,
    "code": 17,
    "nameEn": "Shark liver oil",
    "nameFr": "Squaleme"
  }
]