import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonToggle } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import copyAndApplyChanges from 'immer';
import { removeOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { Mutator } from "lib/apprise-frontend-core/state/api";
import { usePirs } from "pir/api";
import { InspectionErrors, PirEdting, RFMOIUUListing, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, stepContainsErrors } from "pir/model/inspectionErrorApi";
import React from "react";
import { RfmosSelect } from "rfmo/Select";
import { RfmoTypeAhead } from "rfmo/TypeAhead";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    rfmoIuuListing
}

const useValidation = () => {
    const {t} = useIntl()
    const validate:ValidateStep = (p) => {
        let errors:InspectionErrors<ValidationType> = {}
        errors.rfmoIuuListing = [] as InspectionErrors<RFMOIUUListing>[]
        p.extended.pir.rfmoIuuListing.forEach(r=>{
            const error:InspectionErrors<RFMOIUUListing> = {
                rfmo: check(r.rfmo, 'RFMO').isEmpty().isNotUnique(p.extended.pir.rfmoIuuListing.map(r=>r.rfmo), t('inspection.statusInRfmo.rfmo.errorNotUnique')).errors,
                flagstateStatus: check(r.flagstateStatus, t('inspection.statusInRfmo.flagStateStatus.label')).isEmpty().errors,
                vesselIdentifer: r.vesselOnAuthorizedList ? check(r.vesselIdentifer, t('inspection.statusInRfmo.vesselIdentifier.label')).isEmpty().errors : [],
                vesselOnAuthorizedList: check(r.vesselOnAuthorizedList, t('inspection.statusInRfmo.vesselOnuthorizedList.label')).isEmpty().errors,
                vesselOnIUUList: check(r.vesselOnIUUList, t('inspection.statusInRfmo.vesselIuuList.label')).isEmpty().errors,
            }
            errors.rfmoIuuListing && errors.rfmoIuuListing.push(error)
        })

        return errors
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const pirsapi = usePirs()

    const {t} = useIntl()

    const edited = pir as PirEdting

    const errors:InspectionErrors<ValidationType> = getStepErrors(key, edited)

    const validate = useValidation()

    const set = pirsapi.setStep(key, edited, validate)

    const editItem = (i, _m:Mutator<RFMOIUUListing>) => set(c=>{
        c.extended.pir.rfmoIuuListing[i] = copyAndApplyChanges(c.extended.pir.rfmoIuuListing[i], (s: RFMOIUUListing) => void _m(s))
    })

    const removeItem = (i) => set(c=>{
        c.extended.pir.rfmoIuuListing.splice(i, 1)
    })

    const addItem = () => set(c=>{
        c.extended.pir.rfmoIuuListing = [{
            vesselOnAuthorizedList:false,
            vesselOnIUUList:false
        } as RFMOIUUListing, ...c.extended.pir.rfmoIuuListing]
    })

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const rfmoInUse = edited.extended.pir.rfmoIuuListing.map(r=>r.rfmo)

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.statusInRfmo.section.title')}>
        <StepDescription>{t('inspection.statusInRfmo.section.subtitle')}</StepDescription>
        <IonList>
            
            <IonItemGroup>
                <IonItem><IonButton disabled={readonly} slot="end" onClick={addItem} color="primary">{t('inspection.statusInRfmo.section.add')}</IonButton></IonItem>
            </IonItemGroup>

            {[...edited.extended.pir.rfmoIuuListing].map((c, ci, items) => {
                const title = t('inspection.statusInRfmo.section.status', {index:items.length - ci})

                const error = (((errors && errors.rfmoIuuListing && errors.rfmoIuuListing[ci]) || {}) as InspectionErrors<RFMOIUUListing>)
                return <IonItemGroup key={title} className="new-item">
                     <IonItemDivider>
                        <IonLabel>{title}</IonLabel>
                        
                        <IonButtons slot="end">
                            <IonButton disabled={readonly} color="danger" shape="round" slot="end" size="small"
                            onClick={()=>removeItem(ci)}>
                                {t('common.delete')}
                                <IonIcon icon={removeOutline} />
                            </IonButton>
                        </IonButtons>

                    </IonItemDivider>

                    <Item description={t('inspection.statusInRfmo.vesselIdentifier.help')} disabled={readonly} mandatory={c.vesselOnAuthorizedList ? true : false} error={error.vesselIdentifer}>
                        <IonLabel>{t('inspection.statusInRfmo.vesselIdentifier.label')}</IonLabel>
                        <IonInput
                        value={c.vesselIdentifer}
                        placeholder={t('inspection.statusInRfmo.vesselIdentifier.placeholder')}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.vesselIdentifer = (e.detail.value!))}></IonInput>
                    </Item>
                    
                    <Item description={t('inspection.statusInRfmo.rfmo.help')} disabled={readonly} mandatory error={error.rfmo}>
                        <IonLabel>{t('inspection.statusInRfmo.rfmo.label')}</IonLabel>
                        <RfmoTypeAhead
                        placeholder={t('inspection.statusInRfmo.rfmo.placeholder')}
                        value={c.rfmo}
                        onChange={(v)=>v && editItem(ci, (p)=>p.rfmo = v)}/>
                    </Item>

                    <Item description={t('inspection.statusInRfmo.flagStateStatus.help')} disabled={readonly} mandatory error={error.flagstateStatus}>
                        <IonLabel>{t('inspection.statusInRfmo.flagStateStatus.label')}</IonLabel>
                        <IonInput
                        value={c.flagstateStatus}
                        placeholder={t('inspection.statusInRfmo.flagStateStatus.placeholder')}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.flagstateStatus = (e.detail.value!))}></IonInput>
                    </Item>

                    <Item description={t('inspection.statusInRfmo.vesselOnuthorizedList.help')} disabled={readonly} error={error.vesselOnAuthorizedList}>
                        <IonLabel>{t('inspection.statusInRfmo.vesselOnuthorizedList.label')}</IonLabel>
                        <IonToggle
                        color={c.vesselOnAuthorizedList ? 'success' : undefined}
                        checked={c.vesselOnAuthorizedList}
                        onIonChange={e=>e.detail.checked !== undefined && editItem(ci, (p)=>p.vesselOnAuthorizedList = e.detail.checked)}/>
                    </Item>

                    <Item description={t('inspection.statusInRfmo.vesselIuuList.help')} disabled={readonly} error={error.vesselOnAuthorizedList}>
                        <IonLabel>{t('inspection.statusInRfmo.vesselIuuList.label')}</IonLabel>
                        <IonToggle
                        color={c.vesselOnIUUList ? 'success' : undefined}
                        checked={c.vesselOnIUUList}
                        onIonChange={e=>e.detail.checked !== undefined && editItem(ci, (p)=>p.vesselOnIUUList = e.detail.checked)}/>
                    </Item>
                
                </IonItemGroup>
            })}
        </IonList>
    </StepContent>
}
const key = "statusinrfmo"

const Button = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    return <FormStepButton stepkey={key} pir={pir} />
}

export const Link = ({first, last, isDefault, pir}:BaseStepProps) =>{
    const {t} = useIntl()
    return StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.statusInRfmo.section.linkTitle'),
        hasError: stepContainsErrors(key, pir as PirEdting)
    })
}

export const StatusInRfmo:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}