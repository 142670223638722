
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { initialReferencedata, ReferencedataState } from './state';
export const ReferencedataContext = createContext<State<ReferencedataState>>(undefined!)

export const ReferencedataProvider = (props: PropsWithChildren<{}>) => {

    return <StateProvider  initialState={initialReferencedata} context={ReferencedataContext}>
            {props.children}
    </StateProvider>
}

