import { TypeAhead } from 'components/TypeAhead'
import React from 'react'
import { useReferencedata } from 'referencedata/api'

type Props = {
    value?: string,
    onChange,
    title?,
    placeholder?
}
export const RfmoTypeAhead = ({value, title, onChange, placeholder}:Props) => {

    const rfmosapi = useReferencedata()
    const rfmos = rfmosapi.current().rfmo
    
    return <TypeAhead value={value}
    showOptionsAlways
    placeholder={placeholder}
    title={title || placeholder}
    options={rfmos.map(f=>`${f.code}`)}
    onChange={onChange}/>
}