import { useConfig } from 'apprise-frontend-core/config/api';
import { fallbackStateOver } from 'apprise-frontend-core/state/api';
import { mocks } from 'apprise-frontend-core/utils/mock';
import MockAdapter from "axios-mock-adapter";
import { useContext } from 'react';
import { Mockery } from './model';
import { ClientContext, initialClientState } from './state';


export type MockStore<T> = {

    all: () => T[],
    replaceAll: (ts: T[]) => T[],
    oneWith: (id: any) => T | undefined
    add: (t: T) => T
    addOrUpdate: (t: T) => T
    addMany: (..._:T[]) => T[]
    update: (t: T) => T
    append: (t: T) => T
    appendMany: (..._:T[]) => T[]
    set: (at:number, t:T,) => T
    delete: (id: any) => void
    deleteObject: (t: T) => void
}

const defaultMockProperties = {
    delayResponse: 100,
    onNoMatch: 'passthrough' as any
}




export const useMockery = () => {

    const state = useContext(ClientContext) ?? fallbackStateOver(initialClientState)

    const config = useConfig()

    const self = {

         // initialises a mockery, unless mode is production.
         initMockery: (mockery: Mockery) => {

            // wraps an adapter around the axios implementation.
            const adapter = new MockAdapter(state.get().impl, {...defaultMockProperties, delayResponse: state.get().config.mocks.responseDelay})

            state.setQuietly(s=>s.mocks.adapter = adapter)

            // if we have a remote config and it says we're in production, undo any mocks we may have created.
            if (config.remote() && config.get().mode === 'production') {
                console.log("retracting all mocks in production...")
                adapter.restore()
                return
            }

            // if we don't have a remote config and the static one says we're in production, or if we've been built for it, 
            // store mockery and init later when and if we do have a remote config.
            if (config.static() && (config.get().mode === 'production' || config.get().buildMode === 'production')) 
                // stores mockery because if we re-init the client, we need to re-install it.
                state.setQuietly(s => s.mockeries.push(mockery))
            

            // activate mockey.
            mockery(adapter)

        }
   
        ,


        lookupMocks: (name: string) => state.get().mocks.stores[name]

        ,

        storeMocks: (name: string, store: MockStore<any>) => state.setQuietly(s => {

            s.mocks.stores[name] = store

        })

    }

    return self
}

export const useMocks = (props?:Partial<{debug:boolean}>) => {

    const state = useContext(ClientContext) 

   
    const privately = {


        lookupMocks: (name: string) => state.get().mocks.stores[name]

        ,

        storeMocks: (name: string, store: MockStore<any>) => state.set(s => {

            s.mocks.stores[name] = store

        })

    }


    const self = {

       
        // includes all utilities for convenience.
        ...mocks

        ,

       
        getOrCreateStore: <T, ID = string>(name: string, initial: () => T[] = ()=>[], id?: (t: T) => ID): MockStore<T> => {

            const _store = privately.lookupMocks(name)

             if (_store)
                return _store

            let data = initial() || []

            const idmap = id || (t => (t as any).id)
            const pred = <S>(id: S) => (t: T) => idmap(t) === id

            const log = (text: string, s: any) => { if (props?.debug) console.log(`mock ${name} store: ${text}`,s)}
            const store: MockStore<T> & {data:T[]} = {

                // added for inspection
                data:[...data],

                all: () => [...data],  // a copy protects from unintended mutations in client code 
                replaceAll: (ts: T[]) => { data = ts; return ts; },
                
                oneWith: id => {
                    log("looked up",id)
                    return data.find(pred(id))
                },
                
                add: t => {
                    log("added",t)
                    data.unshift(t)
                    return t
                },

                addOrUpdate: t => {
                    
                    store.oneWith(idmap(t)) ? store.update(t) : store.add(t)
                    return t
                },
                
                addMany: (...ts) => ts.reverse().map(store.add)
                
                ,

                append: t => {
                    log("appended",t)
                    data.push(t);
                    return t
                }
                ,

                appendMany: (...ts) => ts.map(store.append)

                ,
                
                update: t => {
                    log("updated",t)
                    data.splice(data.findIndex(pred(idmap(t))), 1, t);
                    return t;
                }


                ,

                set: (i,t) => data[i] = t

                ,
                
                delete: id => {
                    log("removed",id)
                    data.splice(data.findIndex(pred(id)), 1)
                },

                deleteObject: t => {
                    log("removed",t)
                    store.delete(idmap(t))
                }
            }

            privately.storeMocks(name, store)


            return store;
        }

    }

    return self;

}