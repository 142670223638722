import { ClientState } from "apprise-frontend-core/client/state";
import { State } from "apprise-frontend-core/state/api";
import { utils } from "apprise-frontend-core/utils/common";
import MockAdapter from "axios-mock-adapter/types";
import { referencedataTypes } from "referencedata/model";
import { flags } from "common/mocks/flags";


export const mockFlags = (mock:MockAdapter) => {
        // mock calls
        mock.onGet(new RegExp(`${referencedataTypes.flag}`)).reply((config)=>{
                return Promise.resolve([200,flags]).then(utils().wait(500))
        })

}