import { IonFab, IonFabButton, IonIcon, IonLabel } from '@ionic/react'
import { checkboxOutline, squareOutline } from 'ionicons/icons'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import { usePirs } from 'pir/api'
import { Pir } from 'pir/model'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

type Props = {
    pir:Pir,
    stepkey:string,
    isValid?
}

export const CompleteStepButton = ({pir, stepkey, isValid:_isValid}:Props) => {
    const pirapi = usePirs()
    
    const match = useRouteMatch();
    const history = useHistory();

    const {t} = useIntl()


    const isComplete = pir.inspectionstatus && pir.inspectionstatus[stepkey]
    const isValid = _isValid !== undefined ? _isValid : (pir.inspectionErrors && pir.inspectionErrors[stepkey] && pir.inspectionErrors[stepkey].isValid !== undefined ? pir.inspectionErrors[stepkey].isValid : undefined)
    const complete = async () => {
        await pirapi.set({...pir, inspectionstatus: {...pir.inspectionstatus, ...{[stepkey]:true}}})
        history.push(match.url)
    }
    
    return <IonFab vertical="bottom" slot="fixed" horizontal="end">
        <IonFabButton
            className="ion-fab-button-with-label"
            color="primary"
            disabled={isComplete || isValid===false}
            onClick={complete}
            mode="ios">
                <IonLabel>{t('inspection.step.markComplete')}</IonLabel>
                <IonIcon icon={isComplete ? checkboxOutline : squareOutline} />
        </IonFabButton>
    </IonFab> 
            
}