import { IonApp } from "@ionic/react"
import moment from "moment"
import React from "react"
import { useSettings } from "settings/api"
import { fontSizes } from "settings/model"

export const IonWrapper: React.FC = ({children}) => {
    const settingsapi = useSettings()
    const {darkMode, language, fontSize} = settingsapi.all()
    moment.locale(language)
    return <IonApp className={`${darkMode ? 'dark': ''} ${fontSize || fontSizes.medium}`}>
        {children}
      </IonApp>
  }