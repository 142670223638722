import { IonBadge, IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSearchbar } from "@ionic/react"
import { optionsOutline } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import React from "react"
import { useSettings } from "settings/api"

export const ReportsToolbar = ({text, onSetText, onToggleOptions}) => {

    const {t} = useIntl()

   return  React.useMemo(()=><IonGrid className="reports-toolbar">
        <IonRow>
            <IonCol size="8">
                <IonSearchbar placeholder={t('pir.reports.search.placeholder')} debounce={500} value={text} onIonChange={e => onSetText(e.detail.value)}></IonSearchbar>
            </IonCol>
            <FilterButton onToggleOptions={onToggleOptions}/>
        </IonRow>
    </IonGrid>, [text])
}

const FilterButton = ({onToggleOptions}) => {
    const settingsapi = useSettings()
    return <IonCol className={"ion-text-end ion-padding-vertical"}>
                <IonButton onClick={onToggleOptions} shape="round" color="dark" fill='clear'>
                    {settingsapi.hasActiveFilters() && <div className="filter-badge" color="danger"/>}
                    <IonIcon slot="icon-only" icon={optionsOutline} />
                </IonButton>
            </IonCol>
}