import { IonBadge, IonIcon, IonLabel } from '@ionic/react'
import { checkmarkOutline, timeOutline } from 'ionicons/icons'
import moment from 'moment'
import React from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import './Link.scss'
import { PirStepProps } from './model'

export const StepLink = (step:PirStepProps) => {

    const {first, isDefault, isForm, last, path, disabled, completed, hasError, content, date, icon, badge, badgeColor, title, className, isSignature} = step

    const match = useRouteMatch();

    const {hash} = useLocation();

    const selected = hash === path || (!hash && isDefault)

    const _className = ["pir-step",
        ...(isDefault ? ["is-default"]:[]),
        ...(selected ? ["selected"]:[]),
        ...(first ? ["first"]:[]),
        ...(last ? ["last"]:[]),
        ...(isForm ? ["is-form"]:[]),
        ...(isSignature ? ["is-signature"]:[]),
        ...(disabled ? ["disabled"]:[]),
        ...(completed ? ["completed"]:[]),
        ...(hasError ? ["has-error"]:[]),
    ].join(" ")
    
    const Comp = <div className={`${_className} ${className || ''}`}>

        <div className="step-rail">
            <div className="rail base"></div>
            {completed && <div className="rail completed">
            </div>}
            <div className="rail-check">
            {isForm && completed && <IonIcon icon={checkmarkOutline} />}
            </div>
        </div>

        {content ?
            <div className="step-link-custom-content">{content}</div> :
            <div className="step-link-content">
                <div className="step-link-content-head">
                    {title && <IonLabel>{title}</IonLabel>}
                    {badge >=0 && <div className="badge-wrapper"><IonBadge mode="ios" color={badgeColor || 'dark'}>{badge}</IonBadge></div>}
                    {icon && <IonIcon icon={icon}/>}
                </div>
                {date && <div className="step-date">
                    <IonIcon icon={timeOutline}/>
                    {moment(new Date(date)).fromNow()}
                </div>}
            </div>
        }
            
        
    </div>

    return (path && !disabled) ? <Link
    aria-current="step"
    className="pir-step-link"
    to={{
        pathname:match.url,
        hash:path
    }}>
        {Comp}
    </Link> :
        Comp
}