import { ClientState } from "apprise-frontend-core/client/state";
import { State } from "apprise-frontend-core/state/api";
import { utils } from "apprise-frontend-core/utils/common";
import MockAdapter from "axios-mock-adapter/types";
import { referencedataTypes } from "referencedata/model";
import { gears } from "common/mocks/gears";


export const mockGears = (mock:MockAdapter,) => {
        // mock calls
        mock.onGet(`/${referencedataTypes.gear}`).reply((config)=>{
                return Promise.resolve([200,gears]).then(utils().wait(500))
        })

}