import { State } from "lib/apprise-frontend-core/state/api";
import { ReferencedataState } from "referencedata/state";
import { pictureTypeCodes } from "./model/api";

export const documenttypeapi = (state: State<ReferencedataState>) => {
    const self = {
        current: () => state.get().documentType,
        
        pictureTypes: () => self.current().filter(t=>pictureTypeCodes.includes(t.code)),

        othertypes: () => self.current().filter(t=>!pictureTypeCodes.includes(t.code)),
    }   
    return self
}