import { useFileTask } from "file/task"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { usePirs } from "pir/api"
import { Pir } from "pir/model"
import { getRemoteFileContentType, pirmodelapi } from "pir/model/api"
import { usePirTasks } from "pir/tasks"
import { onResolveProcessProps, Process, ProcessHandlers } from "process/model"
import { newProcess } from "process/model/api"
import { useResolveProcess } from "process/model/processes"
import { toastApi } from "toast/api"

export const PREPARATION_PROCESS_TYPE = 'pirPreparation'

export const usePirPreparationProcess = ()=> {

  const {createFileTask} = useFileTask()
  const {createGetExtendedPirTask} = usePirTasks()
  const {t} = useIntl()
  
  const createPreparationProcess = (p:Pir) => {
      let processApi = newProcess(PREPARATION_PROCESS_TYPE, pirmodelapi(p).id(), t('process.prepare'))
      .addTask(createGetExtendedPirTask(p))
    
      pirmodelapi(p).getFiles().forEach(f=>{
        processApi.addTask(createFileTask({
          url:f.downloadLink,
          subtype: getRemoteFileContentType(f),
          name: f.name || f.downloadLink,
          subject: `${pirmodelapi(p).id()}`,
          canIgnore: true
        }))
      })
    
      return processApi.process
  }
  return createPreparationProcess
}
  
export const getPirPreparationProcesses = (pir:Pir, ps:Process[]) => {
  const processes = ps.filter(p=>p.type===PREPARATION_PROCESS_TYPE && p.subject===pirmodelapi(pir).id())
  return processes ? processes[0] : null
}

export const usePirPreparationProcessInitializer = () => {
    const pirs = usePirs()
    const resolveProcessApi = useResolveProcess()
    const {t} = useIntl()
    return {
      [PREPARATION_PROCESS_TYPE] : {  
        onResolve: async ({process}:onResolveProcessProps) => {
            const pir = pirs.find(process.subject)
            pir && await pirs.setAsReady(pir)
            await resolveProcessApi.defaulResolve({process})
            pir && await toastApi().create({
              duration: 5000,
              color:"success",
              message: t('toast.pirReady', {vessel:pirmodelapi(pir).title()})})
      }
    } as ProcessHandlers
  }
}