import { ReferenceData } from 'common'
import React from 'react'
import { useSettings } from 'settings/api'

type Props = {
    item: ReferenceData
}
export const ReferenceDataLabel = ({item}:Props) => {
    const Settings = useSettings()
    const language = Settings.all().language
    return <>{language === 'en' ? item.nameEn : item.nameFr}</>
}