

import { StateProvider } from 'apprise-frontend-core/state/provider'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import * as React from 'react'
import { useConfig } from './api'
import { Config } from './model'
import { ConfigContext, initialConfigState } from './state'




/**
    
    holds configuration for the application, in cases where it is centralised.

 */





export type ConfigProducer = () => Promise<Partial<Config>>


export type ConfigProps = React.PropsWithChildren<{

    config?: Partial<Config>

}>

export const ConfigurationProvider = (props: React.PropsWithChildren<{}>) => {

    const {children} = props

    return <StateProvider initialState={initialConfigState} context={ConfigContext}>
       {children}
    </StateProvider>
}

export const Configuration = (props: ConfigProps) => {

    const context = React.useContext(ConfigContext)

    const initialiser =  <ConfigurationInitialiser {...props} />

    return context ? initialiser : <ConfigurationProvider>{initialiser}</ConfigurationProvider>

}


const ConfigurationInitialiser = (props: ConfigProps) => {

   const { children, config: clientConfig = {} } = props

    const config = useConfig()

    const { content } = useRenderGuard({

        render: children,

        orRun: () => config.addStatic(clientConfig)

    })

    return content
}
