import { IonInput, IonItem, IonItemGroup, IonLabel, IonList } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { InspectionErrors, MonitoringDetails, PirEdting, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, stepContainsErrors } from "pir/model/inspectionErrorApi";
import React from "react";
import { useUser } from "user/api";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    inspectingAuthority,
    principalInspectorId,
    principalInspectorEmail,
}

const useValidation = () => {
    const {t} = useIntl()
    const validate:ValidateStep = (p) => {
        if(!p.extended){
            return {} as InspectionErrors<any>
        }
        const error:InspectionErrors<ValidationType> = {
            inspectingAuthority: check(p.extended.pir.monitoringDetails?.inspectingAuthority, t('inspection.info.authority.label')).isEmpty().errors,
            principalInspectorId: check(p.extended.pir.monitoringDetails?.principalInspectorId, t('inspection.info.principalInspectorId.label')).isEmpty().errors,
            principalInspectorEmail: check(p.extended.pir.monitoringDetails?.principalInspectorEmail, t('inspection.info.principalInspectorEmail.label')).isEmpty().isEmail().errors,
        }

        return error
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const pirsapi = usePirs()

    const userapi = useUser()

    const {t} = useIntl()

    const user = userapi.current()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const edited = pir as PirEdting

    const validate = useValidation()

    const set = pirsapi.setStep(key, edited, validate)

    React.useEffect(()=>{
        set(s=>s=s)
    }, [])

    const defaultMonitornigDetails = (pir:PirEdting) => {
        if(!pir.extended.pir.monitoringDetails){
            pir.extended.pir.monitoringDetails = {
                inspectingAuthority:'',
                principalInspectorEmail:'',
                principalInspectorId:'',
                principalInspectorName:''
            }
        }
    }

    const error:InspectionErrors<ValidationType> = getStepErrors(key, edited) || validate(edited)

    return <StepContent isForm={true} isDefault title={t('inspection.info.section.title')}>
        <StepDescription>{t('inspection.info.section.subtitle')}</StepDescription>
        <IonList>
            <IonItemGroup>
                <Item description={t('inspection.info.authority.help')} disabled={readonly} error={error.inspectingAuthority} mandatory >
                    <IonLabel>{t('inspection.info.authority.label')}</IonLabel>
                    <IonInput
                    placeholder={t('inspection.info.authority.placeholder')}
                    value={edited.extended.pir.monitoringDetails?.inspectingAuthority}
                    onIonChange={e=>set((p)=>{
                        defaultMonitornigDetails(p);
                        (p.extended.pir.monitoringDetails as MonitoringDetails).inspectingAuthority = (e.detail.value!)})}></IonInput>
                </Item>
                <Item description={t('inspection.info.principalInspectorId.help')} disabled={readonly} error={error.principalInspectorId} mandatory >
                    <IonLabel>{t('inspection.info.principalInspectorId.label')}</IonLabel>
                    <IonInput
                    placeholder={t('inspection.info.principalInspectorId.placeholder')}
                    value={edited.extended.pir.monitoringDetails?.principalInspectorId}
                    onIonChange={e=>set((p)=>{
                        defaultMonitornigDetails(p);
                        (p.extended.pir.monitoringDetails as MonitoringDetails).principalInspectorId = (e.detail.value!)})}></IonInput>
                </Item>
                <Item description={t('inspection.info.principalInspectorName.help')} disabled={readonly} mandatory error={check(user?.inspector?.name, t('inspection.info.principalInspectorName.label')).isEmpty().errors}>
                    <IonLabel>{t('inspection.info.principalInspectorName.label')}</IonLabel>
                    <IonInput
                    placeholder={t('inspection.info.principalInspectorName.placeholder')}
                    value={user?.inspector?.name}
                    onIonChange={e=>e.detail.value!== undefined && userapi.mutate(u=>u.inspector.name = e.detail.value!)}></IonInput>
                </Item>
                <Item description={t('inspection.info.principalInspectorEmail.help')} disabled={readonly} error={error.principalInspectorEmail} mandatory >
                    <IonLabel>{t('inspection.info.principalInspectorEmail.label')}</IonLabel>
                    <IonInput
                    type="email"
                    placeholder={t('inspection.info.principalInspectorEmail.placeholder')}
                    value={edited.extended.pir.monitoringDetails?.principalInspectorEmail}
                    onIonChange={e=>set((p)=>{
                        defaultMonitornigDetails(p);
                        (p.extended.pir.monitoringDetails as MonitoringDetails).principalInspectorEmail = (e.detail.value!)})}></IonInput>
                </Item>
                <Item disabled>
                    <IonLabel>{t('inspection.info.port.label')}</IonLabel>
                    <IonInput
                    value={edited.extended.pir.portOfInspection}/>
                </Item>
            </IonItemGroup>
        </IonList>
    </StepContent>
}
const key = "inspectioninformation"

const Button = ({pir}:StepButtonProps) => {

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    
    const userapi = useUser()

    const user = userapi.current()

    const {t} = useIntl()

    return <StepLink 
    completed={pir.inspectionstatus && pir.inspectionstatus[key] ? true : false}
    isDefault={isDefault}
    disabled={ pirmodelapi(pir).hasStatusLessThan(statuses.inspecting)}
    first={first}
    last={last}
    path={getPath(key)}
    isForm
    title={t('inspection.info.section.title')}
    hasError={ stepContainsErrors(key, pir as PirEdting) || !user?.inspector?.name}
     />
}

export const InspectionInformationForm:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}