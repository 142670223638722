import { IonSelect, IonSelectOption } from '@ionic/react'
import { Select } from 'components/Select'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import React, { useMemo } from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    ids?: number[],
    includeOther?, otherId?
    onChange,
    disabled?,
    title?,
    placeholder?
}
export const VmssSelect = ({disabled, ids, includeOther, otherId, onChange, title, placeholder}:Props) => {

    const vmssapi = useReferencedata()
    const _vmss = vmssapi.current().vms
    const vmss = includeOther ? [..._vmss, {id:otherId,code:"Other",nameEn:"Other",nameFr:"Other"}] : _vmss

    const values = ids && vmss.filter(f=>ids.includes(f.id)).map(v=>v.id)

    return <Select title={title || placeholder || "Select one or more vms type"}
    multiple
    placeholder={placeholder}
    value={values}
    onChange={onChange}
    options={useReferencedataOptions({data:vmss})}/>
}

const vmsOnBoardKeys = [
    "yes-national",
    'yes-rmfo',
    'no'
]

type OnBoardProps = {
    code?,
    onChange,
    placeholder?,
    title?
}

export const VmsOnBoardSelect = ({code, onChange, placeholder, title}:OnBoardProps) => {
    
    const {t} = useIntl()
    const vmsOnBoard = vmsOnBoardKeys.map(k=>({key:k,label:t(`inspection.vesselIdentity.vmsOnBoard.${k}`)}))
    const value = code && vmsOnBoard.find(f=>code === f.key)

    return <Select title={title || placeholder || "Pick one"}
    placeholder={placeholder}
    value={value ? [value.key] : []}
    onChange={v=>onChange(v[0])}
    options={vmsOnBoard.map(f=>({value:f.key, label:`${f.label}`}))}/>
}