import { IonBadge, IonIcon } from "@ionic/react"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { Pir, statuses, Statuses } from "pir/model"
import React from "react"
import { useUser } from "user/api"

import './StatusBadge.scss'

export const usePirStatus = (pir:Pir) => {
    const {t} = useIntl()
    const user = useUser()
    const status = pir.claimedByDevice !== null && pir.status === statuses.notready && pir.claimedByDevice !==user.current()?.deviceName ? statuses.claimed : pir.status
    const label = status !== statuses.claimed ? t('pir.status.'+status) : `${t('pir.status.'+status)} ${t('pir.status.by')} ${pir.claimedByDevice}`
    return {status, label, icon:Statuses[status].icon, color:Statuses[status].color || 'primary'}
}

export const PirStatusBadge = ({pir}:{pir:Pir}) => {
    const {status, label, icon, color} = usePirStatus(pir)
    return (
        <IonBadge color={color}
            className={`pir-status-badge ${Statuses[status].disabled ? 'disabled' : ''}`}>
            {icon && <IonIcon icon={icon} />}
            {label}
        </IonBadge>
    )
}
