import { PirFileImageHandler } from 'file/model'
import { usePirs } from "pir/api"
import { FormPicture } from 'pir/model'
import React, { useEffect } from "react"

export const usePirFormPictures = (pictures:FormPicture[]) => {
    const pirapi = usePirs()

    const [files, setFiles] = React.useState<PirFileImageHandler[]>([])

    useEffect(()=>{
        const getFiles = async () => {
            let files:PirFileImageHandler[] = []
            await Promise.all(pictures.map(async p=>{
                const f = await pirapi.getFormPictureFile(p) as PirFileImageHandler
                files.push(f)
            }))
            setFiles(files)
        }
        getFiles()
    }, [pictures])

    return files
}