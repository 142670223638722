export const vesselTypes = [
  {
    "id": 1,
    "code": "BB",
    "nameEn": "Pole and Line vessels",
    "nameFr": "Canneurs"
  },
  {
    "id": 2,
    "code": "GI",
    "nameEn": "Gill Netters",
    "nameFr": "Filets maillants"
  },
  {
    "id": 3,
    "code": "LL",
    "nameEn": "Longliners",
    "nameFr": "Palangriers"
  },
  {
    "id": 4,
    "code": "PS",
    "nameEn": "Purse seiners",
    "nameFr": "Senneurs à senne coulissante"
  },
  {
    "id": 5,
    "code": "LB",
    "nameEn": "Longliners-Pole and Line vessels",
    "nameFr": "Palangriers-Canneurs"
  },
  {
    "id": 6,
    "code": "LI",
    "nameEn": "Line vessels",
    "nameFr": "Bateaux à ligne"
  },
  {
    "id": 7,
    "code": "RT",
    "nameEn": "Research-Training",
    "nameFr": "Recherche-Formation"
  },
  {
    "id": 8,
    "code": "TW",
    "nameEn": "Trawlers",
    "nameFr": "Chalutiers"
  },
  {
    "id": 9,
    "code": "UN",
    "nameEn": "Unknown",
    "nameFr": "Inconnu"
  },
  {
    "id": 10,
    "code": "SP",
    "nameEn": "Supply vessels (purse seiners)",
    "nameFr": "Bateaux auxiliaires (senneurs)"
  },
  {
    "id": 11,
    "code": "LC",
    "nameEn": "Longliners and Carrier vessels",
    "nameFr": "Palangriers-Porteurs"
  },
  {
    "id": 12,
    "code": "CF",
    "nameEn": "Cargo Freezers",
    "nameFr": "Cargos frigorifiques"
  },
  {
    "id": 13,
    "code": "MU",
    "nameEn": "Multipurpose",
    "nameFr": "Polyvalents"
  }
]