
import { useConfig } from 'apprise-frontend-core/config/api';
import { Config } from 'apprise-frontend-core/config/model';
import { useComponentBridge } from 'apprise-frontend-core/utils/bridge';
import { useBusyState } from 'apprise-frontend-core/utils/busyguard';
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard';
import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import { useContext } from 'react';
import { initReactI18next } from "react-i18next";
import { Intl, IntlProps } from './intl';
import { InltContext } from "./state";




/**
 
    <I18next> wraps <Intl> and configures it with functions to translate and change language from react-i18next.

 */


// this wrappper places the real functionality below the generic <Intl> support
// so that it can use it.
export const I18Next = (props: IntlProps) => {

    return <Intl {...props}>
        <I18NextLoader {...props} />
    </Intl>

}


const I18NextLoader = (props: IntlProps) => {

    const { children } = props

    const state = useContext(InltContext) 

    // if available, we use the busy-wait system to fetch translations.
    const busy = useBusyState()

    // we need the baseURL to complete the path to the translation files.
    const config = useConfig<Config>()

    const bridge = useComponentBridge()
    
    const activate = () => {

        console.log("fetching translations...")

        const { defaultLanguage, path } = state.get().config

        var error: any = undefined;

        return busy.toggle("translations")
            .then(() =>

                i18next
                    .use(XHR)
                    .use(initReactI18next) // passes i18n down to react-i18next
                    .init({
                        lng: undefined,
                        returnEmptyString: false,
                        fallbackLng: defaultLanguage,
                        react: {
                            useSuspense: false
                        },

                        debug: false,
                        nsSeparator: "::",
                        backend: { loadPath: `${config.get().baseUrl}/${path}` },
                        interpolation: { escapeValue: false } // react already safe from XSS

                    },
                        //  throwing errors from here won't propagae to thenables (must be caught)
                        //  so we track the error manually and throw from the first thenable.
                        e => { if (e) error = new Error(e) }

                    ))

            .then(() => { if (error) throw error })

            .then(() => state.setQuietly(s => {

                s.t = i18next.t.bind(i18next)
                s.changeLanguage = i18next.changeLanguage.bind(i18next)

            }))
           
            .catch(e => bridge.renderError(e, "cannot load translations.",false))  // can't possibly translate this yet.
            .finally(() => busy.toggle("translations"))

    }

    // initialises i18next before rendering children.

    const { content } = useRenderGuard({

        render: children,

        orRun: activate

    })

    return content
}