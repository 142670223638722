import { PirFileBase64, PirFileImageBase64 } from 'file/model'
import { usePirs } from "pir/api"
import { Pir, RemoteFile } from 'pir/model'
import React, { useEffect } from "react"

export const useRemoteFile = (pir:Pir, remotefile:RemoteFile | undefined) => {
    const pirapi = usePirs()

    const [remote, setRemote] = React.useState<RemoteFile | undefined>(remotefile)
    const [file, setFile] = React.useState<PirFileBase64 | PirFileImageBase64 | undefined | null>(undefined)

    React.useEffect(()=>{
        
        !remote && remotefile && setRemote(remotefile)
        remote && !remotefile && setRemote(remotefile)
        remote && remotefile && remote.downloadLink != remotefile.downloadLink && setRemote(remotefile)
        
    }, [remotefile])

    useEffect(()=>{
        const getFile = async (f) => {
            try {
                const pirfile = await pirapi.getFormRemoteFile(pir, f)
                setFile(pirfile)
            } catch (error) {
                setFile(null)
            }
        }
        remote && getFile(remotefile)
    }, [remote])

    return file
}