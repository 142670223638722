import { Pir } from 'pir/model'
import React from 'react'
import { Step } from './model'

type Props = {
    steps: Step[],
    pir: Pir,
    currentStep: Step
}
export const StepsNavBar = ({steps, pir, currentStep}:Props) => {
    
    const firstFormIndex = steps.findIndex(s=>s.key === currentStep.key)

    return <>
        {
            steps.map((s, i)=>{
                return s.Link({
                    first:     i===0,
                    last:      i===steps.length-1,
                    isDefault: i === firstFormIndex,
                    pir
                })
            })
        }
    </>
}
