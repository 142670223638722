import { IonButton, IonButtons, IonDatetime, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonToggle } from "@ionic/react";
import { AuthoritiesSelect } from "authorities/Select";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import copyAndApplyChanges from 'immer';
import { addOutline, removeOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { Mutator } from "lib/apprise-frontend-core/state/api";
import { usePirs } from "pir/api";
import { OnFinishType, PirAttachmentButton } from "pir/Form/Attachment/Button";
import { PirFormAttachments } from "pir/Form/Attachment/PirAttachmentPictures";
import { FormAttachment, InspectionErrors, PirEdting, statuses, TranshipmentAuthorization, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, stepContainsErrors } from "pir/model/inspectionErrorApi";
import React, { useRef } from "react";
import { useReferencedata } from "referencedata/api";
import { move } from "utils";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    transhipmentAuthorizations
}

const useValidation = () => {
    
    const {t} = useIntl()

    const validate:ValidateStep = (p) => {
        let errors:InspectionErrors<ValidationType> = {}
        errors.transhipmentAuthorizations = [] as InspectionErrors<TranshipmentAuthorization>[]
        p.extended.pir.transhipmentAuthorizations.forEach(r=>{
            const error:InspectionErrors<TranshipmentAuthorization> = {
                identifier: check(r.identifier, t('inspection.trxAuthorisation.identifier.label')).isEmpty().errors,
                validFrom: check(r.validFrom, t('inspection.trxAuthorisation.validFrom.label')).isEmpty().isHigherThan(r.validTo, t('inspection.trxAuthorisation.validTo.label')).errors,
                validTo: check(r.validTo, t('inspection.trxAuthorisation.validTo.label')).isEmpty().isLowerThan(r.validFrom, t('inspection.trxAuthorisation.validFrom.label')).errors,
                issuedBy: check(r.issuedBy, t('inspection.trxAuthorisation.issuedBy.label')).isEmpty().errors,
            }
            errors.transhipmentAuthorizations && errors.transhipmentAuthorizations.push(error)
        })

        return errors
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const validate = useValidation()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const ref = useRef(null)

    const documenttypesapi = useReferencedata()

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const errors:InspectionErrors<ValidationType> = getStepErrors(key, edited)

    const set = pirsapi.setStep(key, edited, validate)

    const editItem = (i, _m:Mutator<TranshipmentAuthorization>) => set(c=>{
        c.extended.pir.transhipmentAuthorizations[i] = copyAndApplyChanges(c.extended.pir.transhipmentAuthorizations[i], (s: TranshipmentAuthorization) => void _m(s))
    })

    const removeItem = (i) => set(c=>{
        c.extended.pir.transhipmentAuthorizations.splice(i, 1)
    })

    const addItem = () => set(c=>{
        c.extended.pir.transhipmentAuthorizations && c.extended.pir.transhipmentAuthorizations.unshift({srcForm:'PIR'} as TranshipmentAuthorization)
    })

    const fileType = documenttypesapi.documentType.current().find(d=>d.code==='TRAU')?.id

    const onFinish = (i):OnFinishType => async ({type, files, attachmentUid}) => {
        let attachment = await pirsapi.setFormAttachmentPictures(files, pir)
        
        await set((p=>{
            const file = p.extended.pir.transhipmentAuthorizations[i].attachment
            if(attachmentUid){
                if(file && file.uid === attachmentUid){
                    (p.extended.pir.transhipmentAuthorizations[i].attachment as FormAttachment).pictures = [...file.pictures,  ...attachment.pictures]
                }
            }else{
                let newattachment = {...attachment}
                
                if(type && type > 0){
                    newattachment.type = type
                    newattachment.name = documenttypesapi.documentType.current().find(d=>d.id == type)?.nameEn || 'TRAU'
                }
                p.extended.pir.transhipmentAuthorizations[i].attachment = newattachment
            }
        }))
    }

    const onSetAttachment = (i, f:FormAttachment ) => set(p=>p.extended.pir.transhipmentAuthorizations[i].attachment = f)
    const onRemoveAttachmentPicture = (attachmentIndex, pictureIndex) => set(p=>(p.extended.pir.transhipmentAuthorizations[attachmentIndex].attachment as FormAttachment).pictures.splice(pictureIndex,1))
    const onRemoveAttachment = (i) => set(p=>p.extended.pir.transhipmentAuthorizations[i].attachment = null)
    const onReorderAttachmentPictures = (i, from, to) => set(p=>move((p.extended.pir.transhipmentAuthorizations[i].attachment as FormAttachment).pictures, from, to))

    const AttachmentButton = ({onClick}) => 
    <IonButton disabled={readonly} shape="round" slot="end" size="small"
    onClick={onClick}>
        {t('inspection.trxAuthorisation.attachment.add')}
        <IonIcon icon={addOutline} />
    </IonButton>

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.trxAuthorisation.section.title')}>
        <StepDescription>{t('inspection.trxAuthorisation.section.subtitle')}</StepDescription>
        <IonList ref={ref}>
            
            <IonItemGroup>
                <IonItem ><IonButton disabled={readonly} slot="end" onClick={addItem} color="primary">{t('inspection.trxAuthorisation.section.add')}</IonButton></IonItem>
            </IonItemGroup>

            {[...edited.extended.pir.transhipmentAuthorizations].map((c, ci, items) => {
                
                const transhipmentTitle = `${t('inspection.trxAuthorisation.section.trxAuthorisation')} ${items.length - ci}`

                const error = (((errors && errors.transhipmentAuthorizations && errors.transhipmentAuthorizations[ci]) || {}) as InspectionErrors<TranshipmentAuthorization>)
                return <IonItemGroup key={transhipmentTitle} className="new-item">
                     <IonItemDivider>
                        <IonLabel>{transhipmentTitle}</IonLabel>
                        
                        <IonButtons slot="end">
                            <IonButton disabled={readonly} color="danger" shape="round" slot="end" size="small"
                            onClick={()=>removeItem(ci)}>
                                {t('common.delete')}
                                <IonIcon icon={removeOutline} />
                            </IonButton>
                        </IonButtons>

                    </IonItemDivider>
                    

                    <Item description={t('inspection.trxAuthorisation.identifier.help')}
                        disabled={readonly} mandatory error={error.identifier}>
                        <IonLabel>{t('inspection.trxAuthorisation.identifier.label')}</IonLabel>
                        <IonInput
                        value={c.identifier}
                        placeholder={t('inspection.trxAuthorisation.identifier.placeholder')}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.identifier = (e.detail.value!))}></IonInput>
                    </Item>

                    <Item description={t('inspection.trxAuthorisation.issuedBy.help')} 
                        disabled={readonly} mandatory error={error.issuedBy}>
                        <IonLabel>{t('inspection.trxAuthorisation.issuedBy.label')}</IonLabel>
                        <AuthoritiesSelect id={c.issuedBy}
                        placeholder={t('inspection.trxAuthorisation.issuedBy.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.issuedBy = v )}/>
                    </Item>

                    <Item description={t('inspection.trxAuthorisation.validFrom.help')} disabled={readonly} mandatory error={error.validFrom}>
                        <IonLabel>{t('inspection.trxAuthorisation.validFrom.label')}</IonLabel>
                        <IonDatetime placeholder={t('inspection.trxAuthorisation.validFrom.placeholder')} value={c.validFrom ? new Date(c.validFrom).toISOString() : undefined}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.validFrom = new Date(e.detail.value!).getTime())}/>
                    </Item>

                    <Item description={t('inspection.trxAuthorisation.validTo.help')} disabled={readonly} mandatory error={error.validTo}>
                        <IonLabel>{t('inspection.trxAuthorisation.validTo.label')}</IonLabel>
                        <IonDatetime max={`${new Date().getFullYear()+100}`} placeholder={t('inspection.trxAuthorisation.validTo.placeholder')} value={c.validTo ? new Date(c.validTo).toISOString() : undefined}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.validTo = new Date(e.detail.value!).getTime())}/>
                    </Item>

                    <Item description={t('inspection.trxAuthorisation.attachment.help')} disabled={readonly} error={error.attachment}>
                    
                        <IonItemGroup>
                            <IonItemDivider>
                                    <IonLabel>{t('inspection.trxAuthorisation.attachment.label')}</IonLabel>
                                    
                                    <IonButtons slot="end">
                                        <PirAttachmentButton fileType={fileType} pir={edited} Button={AttachmentButton} onFinish={onFinish(ci)}/>
                                    </IonButtons>

                                </IonItemDivider>
                                {
                                    c.attachment &&
                                    <PirFormAttachments
                                    disabled={readonly}
                                    typeDisabled
                                    onFinish={onFinish(ci)}
                                    pir={pir}
                                    attachments={[c.attachment]}
                                    wrapperRef={ref}
                                    onSetAttachment={onSetAttachment}
                                    onRemoveAttachmentPicture={onRemoveAttachmentPicture}
                                    onRemoveAttachment={onRemoveAttachment}
                                    onReorderAttachmentPictures={onReorderAttachmentPictures}
                                    />
                                }
                        </IonItemGroup>
                    
                    </Item>

                    <Item disabled>
                        <IonLabel>{t('inspection.trxAuthorisation.source.label')}</IonLabel>
                        <IonInput value={c.srcForm}/>
                    </Item>

                    <Item description={t('inspection.trxAuthorisation.objection.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.trxAuthorisation.objection.label')}</IonLabel>
                        <IonToggle
                        color={c.objection ? 'success' : undefined}
                        checked={c.objection ? true : false}
                        onIonChange={e=>e.detail.checked !== undefined && editItem(ci, (p)=>p.objection = e.detail.checked)}/>
                    </Item>

                    <Item description={t('inspection.trxAuthorisation.comments.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.trxAuthorisation.comments.label')}</IonLabel>
                        <IonInput
                        placeholder={t('inspection.trxAuthorisation.comments.placeholder')}
                        value={c.comments}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.comments = (e.detail.value!))}></IonInput>
                    </Item>
                
                </IonItemGroup>
            })}
        </IonList>
    </StepContent>
}
const key = "RelevantTranshipmentAuthorisation"

const Button = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    const {t} = useIntl()
    return  StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.trxAuthorisation.section.title'),
        badge: pir.extended?.pir.transhipmentAuthorizations.length,
        hasError: stepContainsErrors(key, pir as PirEdting)
    })
}
export const RelevantTranshipmentAuthorisations:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}