import { useRenderGuard } from 'lib/apprise-frontend-core/utils/renderguard';
import React, { PropsWithChildren, useEffect } from 'react';
import { useProcess, useProcessRegistry } from './api';
import { useTaskInitializer } from './tasks';


const Renderer = ({children}) => {

    const processApi = useProcess()

    useEffect(() => {
        const asynctask= async () => {
            await processApi.checkTasks()
        }
        const interval = setInterval(() => {
            asynctask()
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const asynctask= async () => await processApi.syncStateWithDB()
        const interval = setInterval(() => {
            asynctask()
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return <>{children}</>
}


export type ProcesLoaderProps = PropsWithChildren<Partial<{

}>>

export const ProcesLoader = (props: ProcesLoaderProps) => {

    const { children } = props
    
    const processRegistry = useProcessRegistry()
    const tasks = useTaskInitializer()
    
    const activate = async () => {
        await processRegistry.registerTask(tasks)
    }

    const { content } = useRenderGuard({

        render: Renderer({children}),
        orRun: activate
    })

    return content
}
