import { strEnum } from "model/utils"

export type Settings = {
    darkMode: boolean,
    showAdvancedFeatures: boolean,
    updateAvailable: boolean,
    onLine: boolean,
    language: keyof typeof languages,
    fontSize: keyof typeof fontSizes,
    filters: {
        ports?: number[] 
    },
    application:{
        installed:boolean
    }
}

export const languagesList = ['en', 'fr']
export const languages = strEnum(['en', 'fr'])

export const languagesInfo = {
    [languages.en]: {
        label: 'language.english',
        key: languages.en
    },
    [languages.fr]: {
        label: 'language.french',
        key: languages.fr
    }
}

export const fontSizesList = ['small', 'medium', 'large']
export const fontSizes = strEnum(['small', 'medium', 'large'])

export const fontSizesInfo = {
    [fontSizes.small]: {
        label: 'fontSize.small',
        key: fontSizes.small
    },
    [fontSizes.medium]: {
        label: 'fontSize.medium',
        key: fontSizes.medium
    },
    [fontSizes.large]: {
        label: 'fontSize.large',
        key: fontSizes.large
    }
}
