import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonThumbnail } from "@ionic/react"
import { PirFileImageBase64, PirFileImageHandler } from "file/model"
import { humanFileSize, pirfileapi } from "file/model/api"
import { expandOutline, imageOutline, removeOutline } from "ionicons/icons"
import { FormPicture, Pir, VesselPicture } from "pir/model"
import React, { useMemo, useState } from "react"
import { usePirFormPictures } from "./usePictures"
import {PhotoSwipe} from 'react-photoswipe';

import './Pictures.scss'
import { useVesselPictures } from "pir/Vessel/useVesselPictures"
import { useUser } from "user/api"
import { ImagetypesSelect } from "imageType/Select"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { useDeleteDialog } from "components/DeleteDialog"
import { useReferencedata } from "referencedata/api"
import { useVesselPictureTagName } from "pir/Vessel/Pictures"

type PirVesselPictureItemProps = {
    file: PirFileImageBase64|PirFileImageHandler
    picture:VesselPicture,
    onClick: () => void,
}
export const PirVesselPictureItem = ({picture, file, onClick}:PirVesselPictureItemProps) => {
        
    const {t} = useIntl()

    const {getTagName} = useVesselPictureTagName()
    
    if(!file) return null

    const {width, height, size} = file

    return <IonItemGroup className="form-vessel-picture-group" key={file.url}>
        <IonThumbnail onClick={onClick} slot="start">
            <img src={pirfileapi(file).src()}/>
        </IonThumbnail>
        <div className="pir-group-info">
            <IonItemDivider>
                <IonButtons slot="end">
                    <IonButton disabled color="danger" shape="round" slot="end" size="small">
                        {t('common.delete')}
                        <IonIcon icon={removeOutline} />
                    </IonButton>
                </IonButtons>
            </IonItemDivider>

            <IonItem detail detailIcon={expandOutline} button onClick={onClick}>
                <IonIcon slot="start" icon={imageOutline}/>
                <div>
                    <IonLabel>{getTagName(picture)}</IonLabel>
                    <div className="item-detail">
                        {humanFileSize(size).label}
                    </div>
                    <div className="item-detail">
                        {width} x {height}
                    </div>
                </div>
            </IonItem>
            
        </div>
    </IonItemGroup>
    
}

type Props = {
    file:  PirFileImageBase64|PirFileImageHandler
    picture:FormPicture,
    onRemove: () => void
    onClick: () => void
    onChange: (p:FormPicture)=>void
    disabled?
}


export const PirFormPictureItem = ({disabled, picture, file, onRemove, onChange, onClick}:Props) => {

    const userapi = useUser()
    const {t} = useIntl()
    const showDeleteDialog = useDeleteDialog()

    if(!file) return null

    const {width, height, size, filename} = file

    const onChangeType = (type) => onChange({...picture, type})

    const user = userapi.current()


    return <IonItemGroup className="form-vessel-picture-group" key={picture.uid}>
        <IonThumbnail onClick={onClick} slot="start">
            <img src={pirfileapi(file).src()}/>
        </IonThumbnail>
        <div className="pir-group-info">
            <IonItemDivider>
                <IonButtons slot="end">
                    <IonButton disabled={disabled} color="danger" shape="round" slot="end" size="small" onClick={()=>showDeleteDialog({text:t('dialog.deleteVesselPicture.header'),onDelete:onRemove})}>
                        {t('inspection.pictures.delete')}
                        <IonIcon icon={removeOutline} />
                    </IonButton>
                </IonButtons>
            </IonItemDivider>

            <IonItem disabled={disabled} detail detailIcon={expandOutline} button onClick={onClick}>
                <IonIcon slot="start" icon={imageOutline}/>
                <div>
                    <IonLabel>{filename}</IonLabel>
                    <div className="item-detail">
                        {humanFileSize(size).label}
                    </div>
                    <div className="item-detail">
                        {width} x {height}
                    </div>
                </div>
            </IonItem>
            
            <IonItem disabled>
                <IonLabel>{t('inspection.pictures.inspector')}</IonLabel>
                <IonInput
                placeholder={"the inspector name"}
                value={user?.inspector?.name}
                />
            </IonItem>

            <IonItem disabled={disabled}>
                <IonLabel>{t('inspection.pictures.photoType')}</IonLabel>
                <ImagetypesSelect
                codeId={picture.type}
                onChange={type=>onChangeType(type)}/>
            </IonItem>
            
        </div>
    </IonItemGroup>
    
}

type PirFormPicturesProps = {
    disabled?
    pir:Pir,
    pictures:FormPicture[]
    onRemove: (i:number) => void
    onChange: (i:number) => (p:FormPicture)=>void
    wrapperRef:React.MutableRefObject<HTMLIonItemElement | null>
}

export const PirFormPictures = ({disabled, pir, pictures, onRemove, onChange, wrapperRef}:PirFormPicturesProps) => {

    const [pictureOpened, setPictureOpened] = useState<null | number>(null)

    const setOpen = (i) => {
        wrapperRef.current?.classList.add('open-photoswipe')
        setPictureOpened(i)
    }
    const setClose = () => {
        wrapperRef.current?.classList.remove('open-photoswipe')
        setPictureOpened(null)
    }

    const vesselPictureFiles = useVesselPictures(pir)
    const files = [...vesselPictureFiles, ...usePirFormPictures(pictures)]

    const uid = pictures.map(a=>a.uid).join()
    const filesuid = files.map(a=>a.url).join()
    const filesTypeuid = pictures.map(a=>a.type).join()

    return <>
        {useMemo(() => <Pictures
        disabled={disabled}
        vesselPictures={pir.vesselPictures}
        pictures={pictures}
        files={files}
        pictureOpened={pictureOpened}
        onRemove={onRemove}
        onChange={onChange}
        onSetClose={setClose}
        onSetOpen={setOpen}/>, [uid, filesuid, pictureOpened, filesTypeuid])}
    </>
}

type PicturesProps = {
    disabled?
    vesselPictures:VesselPicture[]
    pictures:FormPicture[],
    onSetClose,
    onSetOpen,
    files:(PirFileImageHandler | PirFileImageBase64)[],
    pictureOpened,
    onRemove: (i:number) => void,
    onChange: (i:number) => (p:FormPicture)=>void
}

export const Pictures = ({disabled, pictures,vesselPictures, onSetClose, onSetOpen, files, pictureOpened, onRemove, onChange}:PicturesProps) => {
return <>
    <PhotoSwipe
        isOpen={pictureOpened!==null}
        items={[...vesselPictures, ...pictures].map(p=>{
            const file = files.find(f=>pirfileapi(f).is(p))
            return file && {w: file.width,h: file.height,src:pirfileapi(file).src(),title:`${file.filename}`}
        }).filter(a=>a)}
        options={{index:pictureOpened,shareEl:false, fullscreenEl:false}} onClose={onSetClose}/>

    {vesselPictures.map((vp, vpi)=>{
        const file = files.find(f=>pirfileapi(f).is(vp))
        return file && <PirVesselPictureItem key={file.url} picture={vp} file={file} onClick={()=>onSetOpen(vpi)}/>
    })}

    {pictures.map((p, pi)=>{
    const file = files.find(f=>pirfileapi(f).is(p))
    return file && <PirFormPictureItem disabled={disabled} key={file.url} picture={p} file={file} onClick={()=>onSetOpen(pi)} onRemove={()=>onRemove(pi)} onChange={(v)=>onChange(pi)(v)}/>
    }).filter(a=>a)}
</>
}