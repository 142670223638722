import {alertController} from '@ionic/core'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'

type Props = {
  onDelete,
  text?,
  textDelete?,
  textCancel?
}

export const useDeleteDialog = () => {
  const {t} = useIntl()

  const present = async ({text, textCancel, textDelete, onDelete}:Props) => {
    const dialog = await alertController.create({
      header: text || t('dialog.delete.header'),
      buttons: [
        {
          text: textCancel || t('dialog.delete.no'),
          role: 'cancel'
        },
        {
          text: textDelete ||  t('dialog.delete.yes'),
          handler: onDelete
        }]
    });
    dialog.present()
  }

  return present

}