import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItemDivider, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { Item } from 'components/Item'
import { DesignatedPortSelect } from 'designatedPort/Select'
import { close, closeOutline, optionsOutline, removeOutline } from 'ionicons/icons'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'
import { usePirs } from 'pir/api'
import { PortSelect } from 'port/Select'
import React from 'react'
import { useSettings } from 'settings/api'
import './ConfigurationModal.scss'



export const ConfigurationModal = ({onSetOpen, open}) => {

    const ports = usePirs().ports()
    const settingsapi = useSettings()

    const filterPorts = settingsapi.filters().ports
    const {t} = useIntl()

    return <IonModal mode="ios"
    swipeToClose onDidDismiss={()=>onSetOpen(false)}
    isOpen={open}
    cssClass='configuration-modal'>
    <IonHeader>
        <IonToolbar>
            <IonTitle><div className="title-wrapper"><IonIcon icon={optionsOutline}/><span className="title-text">{t('pir.reports.configuration.title')}</span></div></IonTitle>
            <IonButtons slot="end">
                <IonButton onClick={()=>onSetOpen(false)}><IonIcon slot="icon-only" icon={close}></IonIcon></IonButton>
            </IonButtons>
        </IonToolbar>
        <IonToolbar>
            <IonButton color="danger" shape="round"
            slot="end" size="small"
            disabled={!settingsapi.hasActiveFilters()}
            onClick={()=>settingsapi.resetFilter()}>
                {t('pir.reports.configuration.clearAll')}
                <IonIcon icon={closeOutline} />
            </IonButton>
        </IonToolbar>
    </IonHeader>
    <IonContent className="configuration-modal-content" fullscreen>
        <ConfigurationItem
        onClear={()=>settingsapi.setPortsFilter([])}
        clearDisabled={(filterPorts && filterPorts.length > 0) ? false : true}>
            <IonLabel>{t('pir.reports.configuration.filterByPort.label')}</IonLabel>
            <DesignatedPortSelect availablePorts={ports} multiple
            placeholder={t('pir.reports.configuration.filterByPort.all')}
            ids={filterPorts}
            onChange={settingsapi.setPortsFilter}/>
        </ConfigurationItem>
    </IonContent>
</IonModal>
}

const ConfigurationItem = ({children, onClear, clearDisabled}) => {
    const {t} = useIntl()
    return <div className="configuration-item">
        <div className="configuration-item-content">
            {children}
        </div>
        <IonButton color="medium" disabled={clearDisabled}
        shape="round"
        slot="end" size="small"
        onClick={onClear}>
            {t('pir.reports.configuration.reset')}
            <IonIcon icon={closeOutline} />
        </IonButton>
    </div>
}