import { checkmarkDone, checkmarkOutline, hourglassOutline, infiniteOutline, warningOutline } from "ionicons/icons";
import { strEnum } from "model/utils";

export const taskMethods = strEnum([
    'GET','POST'
])
export type taskMethod = keyof typeof taskMethods;

export const taskStatuses = strEnum([
    "UNDEFINED","REGISTERED","COMPLETED","RESOLVED", "REJECTED", "IGNORED"
])
export type taskStatus = keyof typeof taskStatuses;

export type onResolveProcessProps = {process:Process}
export type onResolveProps = {task:Task}
export type onRegisterProps = {task:Task, processes?:Process[]}

export type TaskHandlers= {
    [key: string]: {
        onRegister?,
        onResolve?
    }
}

export type ProcessHandlers= {
    [key: string]: {
        onResolve?
    }
}

export interface Task {
    status: taskStatus,
    completion: number,
    completionDetails?: string
    type: string,
    subtype: string,
    name?:string
    method: taskMethod,
    body?: any,
    url: string,
    uid: string,
    result?: Error | any,
    subject: any,
    description: string,
    iamAlive?: number

    canIgnore?:boolean
}

export type Process = {
    uid: string,
    type: any,
    tasks: Task[],
    subject: any,
    description: string
}

export type taskStatusesProperty = {
    [key in taskStatus]: {
        color: string;
        icon: any;
        label: string;
        percentage: number;
        percentageType?: "determinate" | "indeterminate";
    };
};

export const taskStatusesProperties:taskStatusesProperty = {
    [taskStatuses.COMPLETED]:{
        color: 'secondary',
        icon: checkmarkOutline,
        label: 'Completed',
        percentage: 95
    },
    [taskStatuses.REGISTERED]:{
        color: 'primary',
        icon: hourglassOutline,
        label: 'Registered',
        percentage: 5
    },
    [taskStatuses.REJECTED]:{
        color: 'danger',
        icon: warningOutline,
        label: 'Rejected',
        percentage: 0
    },
    [taskStatuses.IGNORED]:{
        color: 'danger',
        icon: warningOutline,
        label: 'Ignored',
        percentage: 100
    },
    [taskStatuses.RESOLVED]:{
        color: 'success',
        icon: checkmarkDone,
        label: 'Resolved',
        percentage: 100
    },
    [taskStatuses.UNDEFINED]:{
        color: 'primary',
        icon: infiniteOutline,
        label: 'Undefined',
        percentage: 0,
        percentageType:"indeterminate"
    }
}