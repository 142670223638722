import { useStateRef } from "components/useStateRef"
import React, { useEffect } from "react"
import { SelectModal } from "./Modal"
import './Select.scss'
import { SelectViewer } from "./Viewer"

export type SelecteableValue = any
export type SelectableItem = {
    value:SelecteableValue,
    label:any
}

type Props = {
    value?:SelecteableValue[],
    onChange:(s:SelecteableValue[])=>void,
    multiple?:boolean,
    options:SelectableItem[],
    title?:string,
    placeholder?:string,
    forceApprove?,
    modalOnly?
    forceShow?:boolean
    onClosed?:()=>void
    disabled?
}

type State = {
    selected:any[],
    show,
    closing,
    saveWhileClose?
    completingCloseProcess
}



export const SelectWrapper = ({value:_value, onChange, title, options, multiple, placeholder:_placeholder, forceApprove=true, modalOnly, forceShow, onClosed, disabled}:Props) => {
    const placeholder = _placeholder || (multiple ? 'Select one or more items...' : 'Select one item...')
    const value = (_value || [])
    
    const [{selected, show, closing, saveWhileClose, completingCloseProcess}, setState, {set, setQuietly, reset}] = useStateRef<State>({
        selected:value,
        show:false,
        closing: false,
        saveWhileClose : false,
        completingCloseProcess: false
    })

    const setSelected = (sel) => set(s=>s.selected = sel)

    const setShow = (sh) => set(s=>s.show = sh)
    
    useEffect(()=>{
        if(forceShow && !show){
            setShow(true)
        }
        if(forceShow===false && show){
            setShow(false)
        }
    }, [forceShow])

    useEffect(()=>{
        if(closing){
            setQuietly(s=>{s.closing=false})
            if(saveWhileClose){
                onChange(selected)
            }else{
                reset()
                onClosed && onClosed()
            }
        }
    }, [closing])

    const onClose = (save?:boolean) => {
        set(s=>{s.closing=true;s.saveWhileClose=!forceApprove || save})
    }

    const onSelect = (s:SelecteableValue) => {
        if(multiple){
            setSelected([...selected, s])
        }else{
            setSelected([s])
        }
    }

    const onRemove = (s:SelecteableValue) => {
        setSelected(selected.filter(f=>f!==s))
    }

    const onToggle = (s:SelecteableValue, checked:boolean) => 
        checked ? onSelect(s) : onRemove(s)
    

    return <>
        {!modalOnly && <SelectViewer disabled={disabled} preview onOpen={()=>setShow(true)}
            placeholder={placeholder}
            options={options} selected={selected}/>
        }
        
        <SelectModal
        forceApprove={forceApprove}
        onToggle={onToggle}
        selected={selected}
        options={options}
        title={title}
        show={show} onClose={onClose}/>
    </>
}

export const Select = (props:Props) => 
    <SelectWrapper key={`${props.title}${props.value?.join('')}`} {...props} />
