import { Select } from 'components/Select'
import { SelectModal } from 'components/Select/Modal'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    codeId?: number,
    onChange,
    modalOnly?: boolean,
    forceShow?, onClosed?,
    disabled?
}
export const DocumenttypesSelect = ({codeId, onChange, modalOnly, forceShow, onClosed, disabled}:Props) => {

    const documenttypesapi = useReferencedata()
    
    const documenttypes = documenttypesapi.documentType.othertypes()

    const value = documenttypes.find(f=>f.id === codeId)

    const onChangeCallback = (v) => {
        if(v[0]){
            const val = documenttypes.find(f=>f.id === v[0])
            return onChange(val?.id, val?.nameEn)
        }else{
            return onChange(v[0])
        }
    }
    
    return <Select placeholder="Select a document type" title="Select a document type"
    disabled={disabled}
    modalOnly={modalOnly}
    forceShow={forceShow}
    onClosed={onClosed}
    value={value ? [value.id] : []}
    onChange={onChangeCallback}
    options={useReferencedataOptions({data:documenttypes, showCode:true})}/>
}