
import { useConfig } from 'apprise-frontend-core/config/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard';
import * as React from 'react';
import { useIntl } from './api';
import { IntlConfiguration } from './model';
import { initialIntlState, InltContext } from './state';



// mounts state before rendering children that might consume it.
export const IntlProvider = (props: React.PropsWithChildren<{}>) => {

    const { children } = props

    return <StateProvider initialState={initialIntlState} context={InltContext}>
        {children}
    </StateProvider>
}



/**
 
    <Intl> is a partial implementation of intl-support.

    It includes a default configuration, a noop implementation of the translation function,
    and a noop changeLanguage function. On its own, it simply avoid ungraceful failures
    of client modules.

    A concrete implmentation must wrap <Intl> and inject functions to translate and change functions.
    <I18Next> integrates react-i18next to provide such implementation.

 */

export type IntlProps = React.PropsWithChildren<Partial<{

    config: Partial<IntlConfiguration>

}>>



export const Intl = (props: IntlProps) => {

    // wrap in a state context, if we don't have one already.
    const intl = React.useContext(InltContext)

    const initialiser =  <IntlInitialiser {...props} /> 

    return intl ? initialiser : <IntlProvider>{initialiser}</IntlProvider>
    
}


// activates module before rendering children.
const IntlInitialiser = (props: IntlProps) => {

    const intl = useIntl()

    const config = useConfig<{ intl: IntlConfiguration }>()?.get().intl

    const { config: clientconfig = {}, children } = props

    const [active, activeSet] = React.useState(false)

    //updates in sync with shared config, typically when the latter is remotely loaded.
    React.useEffect(() => {

        // activation is alread a sync point, so we step in after that.
        if (active) {
            console.log("initialising internationalisation support on configuration change")
            intl.init(config)
        }

        // eslint-disable-next-line
    }, [config])

    // initialses module.
    const activate = () => {

        intl.init(clientconfig)

        activeSet(true)

    }

    // activates module before rendering children.
    const { content } = useRenderGuard({

        when: active,

        render: children,

        orRun: activate
    })

    return content
}
