import {
  setupConfig
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import 'App.scss';
import { Client } from 'apprise-frontend-core/client/client';
import { AppriseApplication } from 'AppriseApplication';
import { UserMenu } from 'components/UserMenu';
import { Files } from 'file/file';
import { IonWrapper } from 'IonWrapper';
import { Configuration } from 'lib/apprise-frontend-core/config/configuration';
import { I18Next } from 'lib/apprise-frontend-core/intl/i18next';
import { Pirs } from 'pir/pirs';
import { Process } from 'process/process';
import React from 'react';
import 'react-photoswipe/lib/photoswipe.css';
import { Referencedata } from 'referencedata/referencedata';
import { Settings } from 'settings/settings';
import { Shell } from 'Shell';
import { User } from 'user/user';
import './theme/variables.css';


setupConfig({
  rippleEffect: false,
  mode: 'md'
});

console.log('rendering app')

const App = () => 
  <AppriseApplication>    
    <I18Next config={{
    // path: '/locale/{{lng}}_translation.json',
    defaultLanguage: 'en',
    supportedLanguages: ['en', 'fr'],
    requiredLanguages: ['en', 'fr']
    }}>
      <Client>
        <Configuration config={{mode:"production"}}>
          <Settings>
            <IonWrapper>
              <User>
                <Process>
                  <Files>
                    <Referencedata>
                      <Pirs mock>
                        <IonReactRouter>
                          <Shell />
                          <UserMenu />
                        </IonReactRouter>
                      </Pirs>
                    </Referencedata>
                  </Files>
                </Process>
              </User>
            </IonWrapper>
          </Settings>
        </Configuration>
      </Client>
    </I18Next>
  </AppriseApplication>



export default App;
