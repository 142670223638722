import { FileLoader } from "./loader"
import { FileTasks } from "./tasks"

export type FileProps = {children}

export const Files = (props: FileProps) => {

    const { children } = props

    const content = <FileLoader>
                        {children}
                    </FileLoader>

    return content

}