import { alertController } from "@ionic/core";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { useEffect, useState } from "react";
import { toastApi } from "toast/api";
import { getPWADisplayMode, getPWAInstalledAppEvent, setPWAInstalledAppEvent, useSettings } from "./api";

const delay = ms => new Promise(res => setTimeout(res, ms));

export const useInstall = () => {
        
    const install = (e) => {

        e.prompt();
        //   // Wait for the user to respond to the prompt
        e.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
                setPWAInstalledAppEvent(null)
            } else {
                console.log('User dismissed the A2HS prompt');
            }
            
        });
    }

    

    return install
}

export const usePWAInstallApp = () => {

    const {t} = useIntl()
    const install = useInstall()
    const Settings = useSettings()

    const showInstallApp = async (confirm) => {
        const alert = await alertController.create({
            header: t('dialog.installApp.header'),
            buttons: [
                {
                    text: t('dialog.installApp.no'),
                    role: 'cancel'
                },
                {
                    text: t('dialog.installApp.yes'),
                    handler: () => confirm()
                }]
            });
            alert.present()
        }
        
    const showGoToApp = async (confirm) => {
        const alert = await alertController.create({
            header: t('dialog.openApp.header'),
            buttons: [
                {
                    text: t('dialog.openApp.no'),
                    role: 'cancel'
                },
                {
                    text: t('dialog.openApp.yes'),
                    handler: () => confirm()
                }]
            });
            alert.present()
    }
        
    const onBeforeInstall = async (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        setPWAInstalledAppEvent(e)
        // Update UI to notify the user they can add to home screen
        showInstallApp(()=>{
            e.prompt()
            e.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                    setPWAInstalledAppEvent(null)
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
            });
        })
    }    
    
    const onAppInstalled = async () => {        
        await delay(5000)

        if(navigator.userAgent.indexOf('Android') > -1){
            showGoToApp(()=>{
                let link:any = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
                link.href = window.location.href;
                link.target = '_blank';
                var event = new MouseEvent('click', {
                    'view': window,
                    'bubbles': false,
                    'cancelable': true
                });
                link.dispatchEvent(event);
            })
        }
        
        Settings.stateset(s=>{
            s.settings.application.installed = true
        })
        
        console.log('PWA was installed');
    }
    
    const settingsInstalled = Settings.all().application.installed
    const [installed, setInstalled] = useState(false)

    const [notifyToGoToApp, setNotifyToGoToApp] = useState(false)

    useEffect(() => {

        const checkRelatedApps = async () => {
            const relatedApps = await (navigator as any).getInstalledRelatedApps();
            console.log(relatedApps)
            relatedApps.forEach((app) => {
                console.log(app.id, app.platform, app.url);
            });
        }
        window.addEventListener('appinstalled', onAppInstalled);
        window.addEventListener('beforeinstallprompt', onBeforeInstall);
        setInstalled(settingsInstalled)
        checkRelatedApps()
        // cleanup if we unmount
        return () => {
          window.removeEventListener('appinstalled', ()=>{});
          window.removeEventListener('beforeinstallprompt', ()=>{});
        }
    }, []);

    useEffect(()=>{
        if(settingsInstalled && !installed){
            setInstalled(true)
        }
    }, [settingsInstalled, installed])

    useEffect(()=>{
        if(installed && !notifyToGoToApp && getPWADisplayMode() === 'browser'){
            toastApi().create({
                dismissable:true,
                duration: 0,
                color:"danger",
                message:t('toast.goToApp')})
            
            setNotifyToGoToApp(true)
        }
    }, [notifyToGoToApp])
}