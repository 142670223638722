


import { fallbackStateOver, State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import * as React from 'react'
import { useComponentBridge, WaitIndicatorProps } from './bridge'



export type BusyGuardProps = React.PropsWithChildren<{

    indicator: (props: Partial<WaitIndicatorProps>) => JSX.Element
    spinning: boolean

    fullbleed: boolean

}>


type Task = {

    key: string,
    msg?: React.ReactNode | undefined
}

//  enqueues signals of long-running tasks, so as to drive a global loading indicator without explicit dependencies.
//  as long as next() returns one, the application isn't ready to render anything but the indicator.
export type BusyState = {


    tasks: Task[]

}

const initialState: BusyState = {

    tasks: []

}

export const BusyContext = React.createContext<State<BusyState>>(undefined!)

export const BusyGuard = (props: Partial<BusyGuardProps>) => {

    return <StateProvider initialState={initialState} context={BusyContext}>
        <Inner {...props} />
    </StateProvider>

}

export const useBusyState = () => {


    const state = React.useContext(BusyContext) ?? fallbackStateOver(initialState)


    const self = {

        // the first busy task, if any.
        next: () => state.get().tasks[0]

        ,

        // updates the message of a busy task.
        update: (key: string, msg: React.ReactNode) => state.set(s => {

            s.tasks.forEach(t => {

                if (t.key === key)
                    t.msg = msg

            })

        })

        ,

        // signals the start and stop of a busy task ( and returns a promise for chaining the actual task).
        toggle: (key: string, msg?: React.ReactNode) =>

            Promise.resolve(state.set(s => {

                const index = s.tasks.findIndex(t => t.key === key)

                if (index < 0)
                    s.tasks.push({ key, msg })

                else
                    s.tasks.splice(index, 1)


            }))

    }

    return self
}


const Inner = (props: Partial<BusyGuardProps>) => {

    const busy = useBusyState()

    const bridge = useComponentBridge()

    const { fullbleed, spinning: forceSpinning, children, indicator: clientIndicator } = props

    const busyTask = busy.next()

    const spinning = forceSpinning || !!busyTask

    const Indicator = clientIndicator ?? bridge.Indicator

    return <Indicator style={{ height: "100%"}} fullbleed={fullbleed} spinning={spinning} msg={busyTask?.msg}>
        {children}
    </Indicator>
}