import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { PropsWithChildren, useEffect } from 'react'
import { register } from 'serviceWorkerRegistration'
import { useSettings } from 'settings/api'
import { usePWAInstallApp } from './usePWAInstall'

export type SettingsLoaderProps = PropsWithChildren<Partial<{

    mock: boolean

}>>

export const SettingsLoader = (props: SettingsLoaderProps) => {

    const { children, mock } = props

    usePWAInstallApp()

    const Settings = useSettings()
    const setOnline = async () => await Settings.set('onLine', true)
    const setOffline = async () => await Settings.set('onLine', false)
    
    useEffect(() => {
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);
    
        // cleanup if we unmount
        return () => {
          window.removeEventListener('offline', setOffline);
          window.removeEventListener('online', setOnline);
        }
    }, []);

    useEffect(()=>{
        register({onSetUpdateAvailabe:async(b)=>await Settings.set('updateAvailable', b)});
    }, [])
    
    const activate = () => Promise.resolve(true).then(Settings.fetchAll)

    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content
}