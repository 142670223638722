import copyAndApplyChanges from "immer";
import { cloneDeep } from "lodash";
import * as React from "react";
import { PropsWithChildren, useRef } from "react";
import { Mutator, State } from "./api";

// holds application state and shares an implementation of the State api through a React context.

export type StateProps<S=any> = PropsWithChildren<{

  initialState?: S
  context: React.Context<State<S>>

}>

export const StateProvider = <S extends any> (props: StateProps<S>) => {

  const [renderCounter, render] = React.useState(0)

  const { initialState, context } = props

  const state = useRef(cloneDeep(initialState))  // a clone supports reset.

  const get = () => state.current

  const set = (mutator: Mutator<any>) => setQuietly(mutator).then(() => render(r => ++r))

  const setQuietly = (mutator: Mutator<any>) => {

    state.current = copyAndApplyChanges(state.current, (s: any) => void mutator(s))

    return Promise.resolve()

  }



  const resetQuietly = (s?:S) => {

    state.current = s ?? cloneDeep(initialState)

  }

  const reset = (s?:S) => {

    resetQuietly(s)

    render(r => ++r)

  }
  
  const stateapi = React.useMemo(() => ( // changes only if state does, not on every client re-render.

    {
      get,
      set,
      setQuietly,
      reset,
      resetQuietly,

      current: state.current // used for debugging in dev tools
    }

  ),
    // render consumers only set()/reset(), not on setQuitely() or other reasons (eg parent renders)
    // eslint-disable-next-line    
    [renderCounter]) as State<S>

  const Context = context
  
  return <Context.Provider value={stateapi}>
    {props.children}
  </Context.Provider>

}


