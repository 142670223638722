import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonImg, IonThumbnail } from "@ionic/react";
import { getPath } from "components/Step/utils";
import { boatOutline, businessOutline, cameraOutline, chatbubbleEllipsesOutline, clipboardOutline, pencilOutline, timeOutline } from 'ionicons/icons';
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import moment from "moment";
import { claimedAndPreparedKey } from "pages/pir/Steps/ClaimedAndPrepared";
import { PirInspectionProgressBar } from "pir/InspectionProgressBar";
import { Pir, statuses } from 'pir/model';
import { pirmodelapi } from "pir/model/api";
import { PirStatusBadge } from "pir/StatusBadge";
import { useVesselPicture } from "pir/Vessel/useVesselPictures";
import { useProcess } from "process/api";
import { ProcessMiniViewer } from "process/Viewer/Mini";
import React from 'react';
import { useHistory } from "react-router-dom";
import { useReferencedata } from "referencedata/api";
import { ReferenceDataLabel } from "referencedata/Label";
import { useUser } from "user/api";
import './Card.scss';
import { PirCardItem } from "./Item";


const className = "pir-card"

type Props = {
    pir: Pir,
    noLink?: boolean,
    noImage?: boolean,
    noProcess?:boolean,
    showCommentBadge?: boolean
}

export const PirCardPlaceholder = () => <div className="pir-card-wrapper placeholder">
    <IonCard className={className}/>
</div>
export const PirCard = ({ pir, noLink, noImage, noProcess, showCommentBadge }:Props) => {

    let history = useHistory();

    const pathname = `/pir/${pirmodelapi(pir).id()}`
    function goToPir() {
        !noLink && history.push(pathname)
    }

    const goToMessage = () => {
        !noLink && history.push({
            pathname,
            hash: getPath(claimedAndPreparedKey)
        })
    }

    const processApi = useProcess()

    const process = processApi.getPirProcesses(pir)

    const inspecting = pirmodelapi(pir).isInspecting()

    const signing = pirmodelapi(pir).isSigning()

    const submitted = pirmodelapi(pir).isSubmitted()

    const portsapi = useReferencedata()
    const ports = portsapi.current().designatedPort
    const port = ports.find(p=>p.id === pir.portOfArrival)

    const {file, picture} = useVesselPicture(pir)
    const {t} = useIntl()
    
    return <div className="pir-card-wrapper">
        {
            pirmodelapi(pir).hasStatusLessThan(statuses.submitted) && showCommentBadge && pir.inspectionComment &&
            <div onClick={goToMessage} className="badge warning"><IonIcon icon={chatbubbleEllipsesOutline} slot="icon-only"/></div>
        }

        <IonCard
            button={noLink ? false : true}
            data-interception="off"
            onClick={goToPir} className={className}>
        
        <IonCardHeader className={`${className}-header`}>
            <div className={`${className}-header-left`}>
                <IonCardTitle><IonIcon icon={boatOutline}/> {pirmodelapi(pir).title()}</IonCardTitle>
                <PirStatusBadge pir={pir}/>
            </div>
            {!noImage && <IonThumbnail>
                    {file && <IonImg alt={picture.description} src={file?.content} />}
                </IonThumbnail>}
        </IonCardHeader>
        
        {!noProcess && !inspecting && process && <ProcessMiniViewer showErrorIcon process={process}/>}

        {!noProcess && (inspecting || signing) && <PirInspectionProgressBar pir={pir} />}
        
        <IonCardContent>
            <PirCardItem icon={businessOutline} title={t('pir.card.portOfArrival')} pir={pir}>
                {port && <ReferenceDataLabel item={port}/>}
            </PirCardItem>

            {
                !submitted && (inspecting || signing) && [
                    <PirCardItem icon={clipboardOutline} title={t('pir.card.inspectionStarted')} pir={pir} date={pir.lifecycle && pir.lifecycle[statuses.inspecting] && pir.lifecycle[statuses.inspecting].date || undefined} />
                    ,
                    <PirCardItem icon={cameraOutline} pir={pir} value={t('pir.card.photos', {count:pirmodelapi(pir).getFormPictures().length || 0})} />
                ]
            }
            {
                !submitted && !(inspecting || signing) && 
                <>
                <PirCardItem dateAsItIs icon={timeOutline} title={t('pir.card.expectedDateOfArrival')} pir={pir} date={pir.expectedArrival ? moment(pir.expectedArrival).format('ll') : t('pir.card.noDate')} />
                <PirCardItem dateAsItIs icon={clipboardOutline} title={t('pir.card.inspectionScheduledFor')} pir={pir} date={moment(pir.startOfInspection).format('ll')} />
                </>
            }
            {
                submitted && 
                <PirCardItem icon={clipboardOutline} title={t('pir.card.documentSubmitted')} pir={pir} date={pir.lifecycle && pir.lifecycle[statuses.submitted] && pir.lifecycle[statuses.submitted].date || undefined} />
                
            }
        </IonCardContent>

    </IonCard>
    </div>
}