
import { fallbackStateOver } from 'apprise-frontend-core/state/api'
import { utils } from 'apprise-frontend-core/utils/common'
import { useContext } from 'react'
import { IntlConfiguration, Language, Multilang } from "./model"
import { initialIntlState, InltContext } from "./state"



export const useT = () => useIntl().t
export const useL = () => useIntl().l

export const useIntl = () => {

    const state = useContext(InltContext) ?? fallbackStateOver(initialIntlState)


    const self = {

        init: (config:Partial<IntlConfiguration>) => {

             // merges client configuration with current configuration.
            const merged = utils().merge(self.config(),config ?? {})

            state.set(s=>s.config = merged)

            self.changeLanguage(merged.defaultLanguage)
        }

        ,

        t: state.get().t

        ,

        changeLanguage: (language: Language) => state.get().changeLanguage(language).then(() => state.set(s => s.language = language))

        ,

        language: ()=> state.get().language

        ,

        config: ()=>state.get().config

        ,

        l: (ml: Multilang | undefined) => self.multilang(ml).inCurrent()

        ,

        multilang: (ml:Multilang | undefined = {}) =>  withMulti(self.language(),self.config().defaultLanguage)(ml)

        ,

        multilangComparator: (m1: Multilang | undefined, m2: Multilang | undefined) =>

            self.l(m1 ?? { en: "" }).localeCompare(self.l(m2 ?? { en: "" }))


    }

    return self
}




const withMulti = (language: Language, defaultLanguage:Language) => (ml:Multilang)  => {

    const self = {

        ...ml,

        inCurrent: () => self.in(language),

        in: lang => ml[lang] ?? ml[defaultLanguage!],

        compareTo: other => self.inCurrent().localeCompare(other.inCurrent()),

        setCurrent: val => self.setIn(language).to(val),

        setIn: lang => ({

            to: val => {

                ml[lang] = val

                return self;

            }
        })

    }

    return self;

}