import { IonBadge, IonImg, IonItem, IonLabel, IonThumbnail } from '@ionic/react'
import { PirFileImageBase64 } from 'file/model';
import { pirfileapi } from 'file/model/api';
import { Pir, VesselPicture } from 'pir/model'
import React, { useMemo, useState } from 'react'
import {PhotoSwipe} from 'react-photoswipe';
import { useReferencedata } from 'referencedata/api';
import { useSettings } from 'settings/api';
import { useVesselPictures } from './useVesselPictures';

//The vessel pictures component in the pir vessel section
export const PirVesselPictures = ({pir, wrapperRef}:{pir:Pir, wrapperRef:React.MutableRefObject<HTMLIonContentElement | null>}) => {
    const [pictureOpened, setPictureOpened] = useState<null | number>(null)
    
    const setOpen = (i) => {
        wrapperRef.current?.classList.add('open-photoswipe')
        setPictureOpened(i)
    }
    const setClose = () => {
        wrapperRef.current?.classList.remove('open-photoswipe')
        setPictureOpened(null)
    }

    const files = useVesselPictures(pir)

    const filesuid = files.map(a=>a.url).join()

    return <>
        {useMemo(() => <Pictures pictureOpened={pictureOpened} pir={pir} files={files} setOpen={setOpen} setClose={setClose}/>
        ,[ filesuid, pictureOpened])}
    </>

}

export const useVesselPictureTagName = ()=> {
    const referencedata = useReferencedata()
    const imageTypes = referencedata.current().imageType
    const settings = useSettings()
    const getTagName = (f:VesselPicture)=> {
        const tag = imageTypes.find(t=>t.id==f.tagId)
        if(!tag)
            return 'unknown'
        return settings.all().language === 'en' ? tag?.nameEn : tag?.nameFr
    }
    return {getTagName}
}

const Pictures = ({pir, pictureOpened, files, setOpen, setClose}:{
    pir:Pir,
    pictureOpened:null | number,
    files:PirFileImageBase64[],
    setOpen:(n:number)=>void,
    setClose: ()=>void
}) => {
    const {getTagName} = useVesselPictureTagName()
    return <>
        <PhotoSwipe
            isOpen={pictureOpened!==null}
            items={pir.vesselPictures.map(p=>{
                const file = files.find(f=>f.url === p.downloadLink)
                return file && {w: file.width,h: file.height,src:pirfileapi(file).src(),title:`${p.description} - ${getTagName(p)}`}
            }).filter(a=>a)}
            options={{index:pictureOpened,shareEl:false, fullscreenEl:false}} onClose={setClose}/>
            
            {
                pir.vesselPictures.map((p, i)=> {

                    const file = files.find(f=>f.url === p.downloadLink)
                    return <IonItem className="picture-item" onClick={()=>setOpen(i)}>
                    <IonThumbnail>
                        <IonImg alt={p.description} src={file?.content} />
                    </IonThumbnail>
                    <IonBadge slot="end">{p.description}</IonBadge>
                    <IonLabel><div>{getTagName(p)}</div><small>{p.photoTakingDate}</small></IonLabel>
                </IonItem>    
                })
            }

        </>
}