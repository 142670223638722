import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    ids?: number[],
    onChange,
    multiple?
    placeholder?
}
export const ProductFormsSelect = ({ids, onChange, multiple, placeholder}:Props) => {
    
    const referencedataapi = useReferencedata()
    const productForms = referencedataapi.current().productForms
    
    const value = ids && productForms.filter(f=>ids.includes(f.id)).map(v=>v.id)

    return <Select title={multiple ? "Select one or more product Form" : "Select one product form"}
    multiple={multiple}
    placeholder={placeholder}
    value={value || []}
    onChange={v=>onChange(v)}
    options={useReferencedataOptions({data:productForms, showCode:true})}/>
}