export const ports = [
  {
    "id": 1,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUABP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Abbot Point",
    "nameFr": "Abbot Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 2,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUALP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Abell Point",
    "nameFr": "Abell Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 3,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUADL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Adelaide",
    "nameFr": "Adelaide",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 4,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUAIR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Airlie Terminal",
    "nameFr": "Airlie Terminal",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 5,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUALH",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Albany",
    "nameFr": "Albany",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 6,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUALD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Aldgate",
    "nameFr": "Aldgate",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 7,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUANN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Annandale",
    "nameFr": "Annandale",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 8,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUAPP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Applecross",
    "nameFr": "Applecross",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 9,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUARD",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ardrossan",
    "nameFr": "Ardrossan",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 10,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUAUA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Armadale",
    "nameFr": "Armadale",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 11,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDLP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Arndell Park",
    "nameFr": "Arndell Park",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 12,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUASH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ashton",
    "nameFr": "Ashton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 13,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUATT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Attadale",
    "nameFr": "Attadale",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 14,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUAGS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Augusta",
    "nameFr": "Augusta",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 15,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUALI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Australind",
    "nameFr": "Australind",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 16,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBAE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Baerami",
    "nameFr": "Baerami",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 17,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBAA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Balhannah",
    "nameFr": "Balhannah",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 18,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBAH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ballast Head",
    "nameFr": "Ballast Head",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 19,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "NFNLKBB",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ball Bay Norfolk Island",
    "nameFr": "Ball Bay Norfolk Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 20,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBAL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Balnarring",
    "nameFr": "Balnarring",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 21,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULME",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bangholme",
    "nameFr": "Bangholme",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 22,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBNN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bannockburn",
    "nameFr": "Bannockburn",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 23,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBWB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Barrow Island",
    "nameFr": "Barrow Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 24,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBAR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Barry Beach",
    "nameFr": "Barry Beach",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 25,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBRT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bathurst Island",
    "nameFr": "Bathurst Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 26,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBCH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Beachport",
    "nameFr": "Beachport",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 27,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBYP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Beauty Point",
    "nameFr": "Beauty Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 28,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBBL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Beelbangera",
    "nameFr": "Beelbangera",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 29,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBEL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bell Bay",
    "nameFr": "Bell Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 30,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBE9",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Belrose",
    "nameFr": "Belrose",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 31,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBVD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Belvidere",
    "nameFr": "Belvidere",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 32,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBFD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Beresfield",
    "nameFr": "Beresfield",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 33,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBKE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Berkeley Vale",
    "nameFr": "Berkeley Vale",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 34,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBRR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Berrys Creek",
    "nameFr": "Berrys Creek",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 35,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBIC",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bicheno",
    "nameFr": "Bicheno",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 36,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBBG",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bing Bong",
    "nameFr": "Bing Bong",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 37,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBLP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Black Point",
    "nameFr": "Black Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 38,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTTS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Blackmans Bay",
    "nameFr": "Blackmans Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 39,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Blacktip Terminal",
    "nameFr": "Blacktip Terminal",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 40,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBNY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Blayney",
    "nameFr": "Blayney",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 41,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBWS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Blewitts Springs",
    "nameFr": "Blewitts Springs",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 42,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bobin",
    "nameFr": "Bobin",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 43,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBDY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bomaderry",
    "nameFr": "Bomaderry",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 44,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOO",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Booby Island",
    "nameFr": "Booby Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 45,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Boondall",
    "nameFr": "Boondall",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 46,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Boort",
    "nameFr": "Boort",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 47,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUVIB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Boronia",
    "nameFr": "Boronia",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 48,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBTB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Botany Bay",
    "nameFr": "Botany Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 49,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Boundary Bend",
    "nameFr": "Boundary Bend",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 50,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUZBO",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bowen",
    "nameFr": "Bowen",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 51,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Boyer",
    "nameFr": "Boyer",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 52,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBBY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bremer Bay",
    "nameFr": "Bremer Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 53,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBOL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bridgewater-on-Loddon",
    "nameFr": "Bridgewater-on-Loddon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 54,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBSA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Brighton-Le-Sands",
    "nameFr": "Brighton-Le-Sands",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 55,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBNE",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Brisbane",
    "nameFr": "Brisbane",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 56,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBMW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Broadmeadows",
    "nameFr": "Broadmeadows",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 57,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNSB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Broke",
    "nameFr": "Broke",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 58,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBME",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Broome",
    "nameFr": "Broome",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 59,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBUV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Buffalo Venture",
    "nameFr": "Buffalo Venture",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 60,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBUL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bulli",
    "nameFr": "Bulli",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 61,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBLL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bullsbrook",
    "nameFr": "Bullsbrook",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 62,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBWI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bulwer",
    "nameFr": "Bulwer",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 63,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBUY",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bunbury",
    "nameFr": "Bunbury",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 64,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBDB",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bundaberg",
    "nameFr": "Bundaberg",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 65,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBUC",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Burketown",
    "nameFr": "Burketown",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 66,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBWT",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Burnie",
    "nameFr": "Burnie",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 67,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBYY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Bushy Park",
    "nameFr": "Bushy Park",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 68,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUBUS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Busselton",
    "nameFr": "Busselton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 69,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCNS",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cairns",
    "nameFr": "Cairns",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 70,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCAL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Caloundra Head",
    "nameFr": "Caloundra Head",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 71,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCMB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cambridge",
    "nameFr": "Cambridge",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 72,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCMP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Camperdown",
    "nameFr": "Camperdown",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 73,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCBR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Canberra",
    "nameFr": "Canberra",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 74,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCAP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Capalaba",
    "nameFr": "Capalaba",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 75,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCCU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cape Cuvier",
    "nameFr": "Cape Cuvier",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 76,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDON",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cape Don",
    "nameFr": "Cape Don",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 77,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCQP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cape Flattery",
    "nameFr": "Cape Flattery",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 78,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCLT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cape Lambert",
    "nameFr": "Cape Lambert",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 79,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCLN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cape Leeuwin",
    "nameFr": "Cape Leeuwin",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 80,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCPN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cape Preston",
    "nameFr": "Cape Preston",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 81,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCBO",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Carboor",
    "nameFr": "Carboor",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 82,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCAR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Carina Heights",
    "nameFr": "Carina Heights",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 83,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCLF",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Carlingford",
    "nameFr": "Carlingford",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 84,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCVQ",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Carnarvon",
    "nameFr": "Carnarvon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 85,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "NFNLKCB",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cascade Bay Norfolk Island",
    "nameFr": "Cascade Bay Norfolk Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 86,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCUN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Caufield North",
    "nameFr": "Caufield North",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 87,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCVS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Caversham",
    "nameFr": "Caversham",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 88,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCHV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Challis Venture (oil terminal)",
    "nameFr": "Challis Venture (oil terminal)",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 89,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCHA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Chatswood",
    "nameFr": "Chatswood",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 90,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCHE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Chermside",
    "nameFr": "Chermside",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 91,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCN6",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Chinderah",
    "nameFr": "Chinderah",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 92,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "CXXCH",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Christmas Island",
    "nameFr": "Christmas Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 93,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSCL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Clare",
    "nameFr": "Clare",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 94,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCLM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Claremont",
    "nameFr": "Claremont",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 95,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCLR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Clarence River",
    "nameFr": "Clarence River",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 96,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCDN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Clarendon",
    "nameFr": "Clarendon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 97,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCVN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cleveland",
    "nameFr": "Cleveland",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 98,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCVL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Clovelly",
    "nameFr": "Clovelly",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 99,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "CCCCK",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cocos (Keeling) Islands",
    "nameFr": "Cocos (Keeling) Islands",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 100,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCFS",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coffs Harbour",
    "nameFr": "Coffs Harbour",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 101,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCST",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coldstream",
    "nameFr": "Coldstream",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 102,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCLL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Collan Islands",
    "nameFr": "Collan Islands",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 103,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCMO",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Como",
    "nameFr": "Como",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 104,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCDE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Condamine",
    "nameFr": "Condamine",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 105,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCTN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cooktown",
    "nameFr": "Cooktown",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 106,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCOU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coolum",
    "nameFr": "Coolum",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 107,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCOO",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coomera",
    "nameFr": "Coomera",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 108,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCNW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coonawarra",
    "nameFr": "Coonawarra",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 109,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCPR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coorparoo",
    "nameFr": "Coorparoo",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 110,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCRL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coral Bay",
    "nameFr": "Coral Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 111,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCRS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coral Sea",
    "nameFr": "Coral Sea",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 112,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCBA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Corio Bay",
    "nameFr": "Corio Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 113,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCRV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Coromandel Valley",
    "nameFr": "Coromandel Valley",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 114,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCOP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cossack Pioneer (oil terminal)",
    "nameFr": "Cossack Pioneer (oil terminal)",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 115,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCTE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cottesloe",
    "nameFr": "Cottesloe",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 116,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCTT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cottles Bridge",
    "nameFr": "Cottles Bridge",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 117,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDIL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cowandilla",
    "nameFr": "Cowandilla",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 118,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCOW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cowaramup",
    "nameFr": "Cowaramup",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 119,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCRF",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Crafers",
    "nameFr": "Crafers",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 120,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCRE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Crestmead",
    "nameFr": "Crestmead",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 121,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUC8R",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cromer",
    "nameFr": "Cromer",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 122,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCRU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Cronulla",
    "nameFr": "Cronulla",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 123,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDIT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Dalrymple Islet",
    "nameFr": "Dalrymple Islet",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 124,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDAL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Dalton",
    "nameFr": "Dalton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 125,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDAM",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Dampier",
    "nameFr": "Dampier",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 126,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDAR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Dardanup",
    "nameFr": "Dardanup",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 127,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDAA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Darra",
    "nameFr": "Darra",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 128,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDRW",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Darwin",
    "nameFr": "Darwin",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 129,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDRB",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Derby",
    "nameFr": "Derby",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 130,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDPO",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Devonport",
    "nameFr": "Devonport",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 131,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDHI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Dhipirrinjura",
    "nameFr": "Dhipirrinjura",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 132,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDIA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Diamond Creek",
    "nameFr": "Diamond Creek",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 133,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDRY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Drysdale",
    "nameFr": "Drysdale",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 134,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUQDN",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Eden",
    "nameFr": "Eden",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 135,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUEDE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Eden Valley",
    "nameFr": "Eden Valley",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 136,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUEDB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Edithburgh",
    "nameFr": "Edithburgh",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 137,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUELE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Electrona",
    "nameFr": "Electrona",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 138,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUELB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Elizabeth Bay",
    "nameFr": "Elizabeth Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 139,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUELT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Eltham",
    "nameFr": "Eltham",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 140,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUEPR",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Esperance",
    "nameFr": "Esperance",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 141,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUEVL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Evans Landing",
    "nameFr": "Evans Landing",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 142,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPEX",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Exmouth",
    "nameFr": "Exmouth",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 143,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUEXM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Exmouth Gulf",
    "nameFr": "Exmouth Gulf",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 144,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUFIN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Findon",
    "nameFr": "Findon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 145,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUFIS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Fisherman Islands",
    "nameFr": "Fisherman Islands",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 146,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUFLN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Flynn",
    "nameFr": "Flynn",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 147,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUFLD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Frankland",
    "nameFr": "Frankland",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 148,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUFRE",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Fremantle",
    "nameFr": "Fremantle",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 149,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUFWT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Freshwater",
    "nameFr": "Freshwater",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 150,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGBT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Garbutt",
    "nameFr": "Garbutt",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 151,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGEX",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Geelong",
    "nameFr": "Geelong",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 152,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGEM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Gembrook",
    "nameFr": "Gembrook",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 153,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGEE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "George Town",
    "nameFr": "George Town",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 154,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGET",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Geraldton",
    "nameFr": "Geraldton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 155,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGLT",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Gladstone",
    "nameFr": "Gladstone",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 156,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGLL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Glebe Island",
    "nameFr": "Glebe Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 157,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGOI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Goods Is",
    "nameFr": "Goods Is",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 158,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGOA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Goolwa",
    "nameFr": "Goolwa",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 159,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGOR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Gordon",
    "nameFr": "Gordon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 160,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGOV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Gove",
    "nameFr": "Gove",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 161,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGTH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Grantham",
    "nameFr": "Grantham",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 162,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGRB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Gravelly Beach",
    "nameFr": "Gravelly Beach",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 163,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGTW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Great Western",
    "nameFr": "Great Western",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 164,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGRH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Greenhead",
    "nameFr": "Greenhead",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 165,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGRK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Greenock",
    "nameFr": "Greenock",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 166,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGRV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Griffin Venture (oil terminal)",
    "nameFr": "Griffin Venture (oil terminal)",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 167,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGTE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Groote Eylandt",
    "nameFr": "Groote Eylandt",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 168,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGRU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Gruyere",
    "nameFr": "Gruyere",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 169,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGUM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Gumeracha",
    "nameFr": "Gumeracha",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 170,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHAH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hahndorf",
    "nameFr": "Hahndorf",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 171,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHTT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Halletts Cove",
    "nameFr": "Halletts Cove",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 172,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHAM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hampton",
    "nameFr": "Hampton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 173,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHWD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hanwood",
    "nameFr": "Hanwood",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 174,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHPP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Happy Valley",
    "nameFr": "Happy Valley",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 175,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHYI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hardy Island",
    "nameFr": "Hardy Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 176,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHAS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hastings",
    "nameFr": "Hastings",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 177,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHPT",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hay Point",
    "nameFr": "Hay Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 178,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHTH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Heathcote",
    "nameFr": "Heathcote",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 179,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHBE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Heatherbrae",
    "nameFr": "Heatherbrae",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 180,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHFR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Henderson",
    "nameFr": "Henderson",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 181,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHNK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hinchinbrook Island",
    "nameFr": "Hinchinbrook Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 182,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHBA",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hobart",
    "nameFr": "Hobart",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 183,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHDD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hoddles Creek",
    "nameFr": "Hoddles Creek",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 184,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUHOT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Hope Tun",
    "nameFr": "Hope Tun",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 185,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUIGF",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ingle Farm",
    "nameFr": "Ingle Farm",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 186,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUIPS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ipswich",
    "nameFr": "Ipswich",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 187,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUIRY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Irymple",
    "nameFr": "Irymple",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 188,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUJOV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Jabiru Venture (oil terminal)",
    "nameFr": "Jabiru Venture (oil terminal)",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 189,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUJAR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Jarrahdale",
    "nameFr": "Jarrahdale",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 190,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKAN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Kangarilla",
    "nameFr": "Kangarilla",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 191,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKRB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Karumba",
    "nameFr": "Karumba",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 192,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKTR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Katherine",
    "nameFr": "Katherine",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 193,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKAY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Kayena",
    "nameFr": "Kayena",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 194,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKEI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Keith",
    "nameFr": "Keith",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 195,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKEY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Keyneton",
    "nameFr": "Keyneton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 196,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKIB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "King Bay",
    "nameFr": "King Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 197,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKGC",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Kingscote",
    "nameFr": "Kingscote",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 198,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGTY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Kingsford",
    "nameFr": "Kingsford",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 199,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKIT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Kingston",
    "nameFr": "Kingston",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 200,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKLP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Klein Point",
    "nameFr": "Klein Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 201,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKOI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Koolan Island",
    "nameFr": "Koolan Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 202,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKUR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Kurnell",
    "nameFr": "Kurnell",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 203,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKWI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Kwinana",
    "nameFr": "Kwinana",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 204,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULAB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Lady Barron",
    "nameFr": "Lady Barron",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 205,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULMQ",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Lake Macquarie",
    "nameFr": "Lake Macquarie",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 206,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULEN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Lakes Entrance",
    "nameFr": "Lakes Entrance",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 207,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULAN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Langarra",
    "nameFr": "Langarra",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 208,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULST",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Launceston",
    "nameFr": "Launceston",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 209,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUJML",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Laurieton",
    "nameFr": "Laurieton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 210,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULGT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Legendre Terminal",
    "nameFr": "Legendre Terminal",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 211,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULYD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Lilydale",
    "nameFr": "Lilydale",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 212,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULOW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Lisarow",
    "nameFr": "Lisarow",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 213,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDFE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Longwood",
    "nameFr": "Longwood",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 214,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULDH",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Lord Howe Is",
    "nameFr": "Lord Howe Is",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 215,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULUC",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Lucinda",
    "nameFr": "Lucinda",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 216,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMKY",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mackay",
    "nameFr": "Mackay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 217,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMQI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Macquarie Island",
    "nameFr": "Macquarie Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 218,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMRK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Macquarie Park",
    "nameFr": "Macquarie Park",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 219,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMBY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Maribyrnong",
    "nameFr": "Maribyrnong",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 220,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMAR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Marrickville",
    "nameFr": "Marrickville",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 221,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMBH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Maryborough",
    "nameFr": "Maryborough",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 222,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSAD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Maryborough",
    "nameFr": "Maryborough",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 223,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKLL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Maryknoll",
    "nameFr": "Maryknoll",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 224,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMEL",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Melbourne",
    "nameFr": "Melbourne",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 225,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMRS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Melrose",
    "nameFr": "Melrose",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 226,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMLI",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Melville Island",
    "nameFr": "Melville Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 227,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMID",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Midland",
    "nameFr": "Midland",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 228,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMIB",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Milner Bay",
    "nameFr": "Milner Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 229,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMIL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Milperra",
    "nameFr": "Milperra",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 230,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMON",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Montara Field",
    "nameFr": "Montara Field",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 231,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMLL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mooloolaba",
    "nameFr": "Mooloolaba",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 232,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMOK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Moorook",
    "nameFr": "Moorook",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 233,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMDC",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mordialloc",
    "nameFr": "Mordialloc",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 234,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMGE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mount Gravatt",
    "nameFr": "Mount Gravatt",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 235,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMHW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mount Hawthorn",
    "nameFr": "Mount Hawthorn",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 236,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMOU",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mourilyan",
    "nameFr": "Mourilyan",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 237,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMOW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mowbray Heights",
    "nameFr": "Mowbray Heights",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 238,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUMWA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Mulwala",
    "nameFr": "Mulwala",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 239,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNEQ",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Nericon",
    "nameFr": "Nericon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 240,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNLK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "New Norfolk",
    "nameFr": "New Norfolk",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 241,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNWS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "New South Wales",
    "nameFr": "New South Wales",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 242,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNTL",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Newcastle",
    "nameFr": "Newcastle",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 243,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUVIC",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Newtown",
    "nameFr": "Newtown",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 244,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNGH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Nganhurra",
    "nameFr": "Nganhurra",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 245,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNHU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Nhulunbuy",
    "nameFr": "Nhulunbuy",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 246,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNIV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ningaloo Vision",
    "nameFr": "Ningaloo Vision",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 247,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNB2",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "North Bridge",
    "nameFr": "North Bridge",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 248,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUNTE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Northern Endeavour",
    "nameFr": "Northern Endeavour",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 249,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUOKF",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Oak Flats",
    "nameFr": "Oak Flats",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 250,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUOKH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Okha FPSO",
    "nameFr": "Okha FPSO",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 251,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUONS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Onslow",
    "nameFr": "Onslow",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 252,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUOSB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Osborne Island",
    "nameFr": "Osborne Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 253,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPAG",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Paddington",
    "nameFr": "Paddington",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 254,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDSW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Padstow",
    "nameFr": "Padstow",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 255,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPMK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Palm Island",
    "nameFr": "Palm Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 256,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPAL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Palmerston",
    "nameFr": "Palmerston",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 257,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPEL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Pelican Point",
    "nameFr": "Pelican Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 258,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPEN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Pendle Hills",
    "nameFr": "Pendle Hills",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 259,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPER",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Perth",
    "nameFr": "Perth",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 260,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPIS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Phillip Island",
    "nameFr": "Phillip Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 261,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPIC",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Picton",
    "nameFr": "Picton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 262,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPHP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Point Henry Pier/Melbourne",
    "nameFr": "Point Henry Pier/Melbourne",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 263,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPSM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Point Samson",
    "nameFr": "Point Samson",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 264,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPWI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Point Wilson",
    "nameFr": "Point Wilson",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 265,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPAE",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Adelaide",
    "nameFr": "Port Adelaide",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 266,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPTL",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Alma",
    "nameFr": "Port Alma",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 267,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPAU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Arthur",
    "nameFr": "Port Arthur",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 268,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPUG",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Augusta",
    "nameFr": "Port Augusta",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 269,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPBY",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Bonython",
    "nameFr": "Port Bonython",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 270,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPBT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Botany",
    "nameFr": "Port Botany",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 271,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPCT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Curtis",
    "nameFr": "Port Curtis",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 272,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPTD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Dalrymple",
    "nameFr": "Port Dalrymple",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 273,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPDI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Denison",
    "nameFr": "Port Denison",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 274,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPTI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Douglas",
    "nameFr": "Port Douglas",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 275,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPGI",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Giles",
    "nameFr": "Port Giles",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 276,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPHE",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Hedland",
    "nameFr": "Port Hedland",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 277,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPHU",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Huon",
    "nameFr": "Port Huon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 278,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPJK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Jackson",
    "nameFr": "Port Jackson",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 279,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPKL",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Kembla",
    "nameFr": "Port Kembla",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 280,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPKN",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Kennedy",
    "nameFr": "Port Kennedy",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 281,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPLA",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Latta",
    "nameFr": "Port Latta",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 282,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPLO",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Lincoln",
    "nameFr": "Port Lincoln",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 283,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPBT",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port of Botany Bay",
    "nameFr": "Port of Botany Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 284,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPOR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Melbourne",
    "nameFr": "Port Melbourne",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 285,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPBN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port of Brisbane",
    "nameFr": "Port of Brisbane",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 286,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPPI",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Pirie",
    "nameFr": "Port Pirie",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 287,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPSY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Stanley",
    "nameFr": "Port Stanley",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 288,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPST",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Stanvac",
    "nameFr": "Port Stanvac",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 289,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPWL",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Walcott",
    "nameFr": "Port Walcott",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 290,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPWR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Warrender",
    "nameFr": "Port Warrender",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 291,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPTW",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Port Willunga",
    "nameFr": "Port Willunga",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 292,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPTJ",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Portland",
    "nameFr": "Portland",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 293,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPHR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Prahran",
    "nameFr": "Prahran",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 294,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPRC",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Price",
    "nameFr": "Price",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 295,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPRB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Proper Bay",
    "nameFr": "Proper Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 296,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPYE",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Pyrenees",
    "nameFr": "Pyrenees",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 297,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUPYR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Pyrmont",
    "nameFr": "Pyrmont",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 298,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURAF",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Raffles Bay",
    "nameFr": "Raffles Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 299,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURAB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Rapid Bay",
    "nameFr": "Rapid Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 300,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUREV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Revesby",
    "nameFr": "Revesby",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 301,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURLL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Reynella",
    "nameFr": "Reynella",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 302,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURDS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Rhodes",
    "nameFr": "Rhodes",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 303,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURCM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Richmond",
    "nameFr": "Richmond",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 304,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURDN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Risdon",
    "nameFr": "Risdon",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 305,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUROB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Robe",
    "nameFr": "Robe",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 306,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUROK",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Rockhampton",
    "nameFr": "Rockhampton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 307,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURYP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Rocky Point",
    "nameFr": "Rocky Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 308,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURBU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Roebourne",
    "nameFr": "Roebourne",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 309,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURNB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Rosslyn Bay",
    "nameFr": "Rosslyn Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 310,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AURTS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Rottnest Island",
    "nameFr": "Rottnest Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 311,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUROZ",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Rozelle",
    "nameFr": "Rozelle",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 312,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSMT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Saladin Marine Terminal",
    "nameFr": "Saladin Marine Terminal",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 313,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSGT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Sandgate",
    "nameFr": "Sandgate",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 314,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSCY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Scoresby",
    "nameFr": "Scoresby",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 315,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSSH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Sellicks Hill",
    "nameFr": "Sellicks Hill",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 316,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSMR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Seventeen Mile Rocks/Brisbane",
    "nameFr": "Seventeen Mile Rocks/Brisbane",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 317,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSHB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Shark Bay",
    "nameFr": "Shark Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 318,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSOL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Shea Oak Log",
    "nameFr": "Shea Oak Log",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 319,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSKA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Skardon River",
    "nameFr": "Skardon River",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 320,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSKV",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Skua Venture (oil terminal)",
    "nameFr": "Skua Venture (oil terminal)",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 321,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSKY",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Skye",
    "nameFr": "Skye",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 322,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSBN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "South Brisbane",
    "nameFr": "South Brisbane",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 323,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSTR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "South Trees",
    "nameFr": "South Trees",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 324,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSWR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "South West Rocks",
    "nameFr": "South West Rocks",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 325,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSPO",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Spreyton",
    "nameFr": "Spreyton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 326,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSPB",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Spring Bay",
    "nameFr": "Spring Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 327,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSTG",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Stag Platform",
    "nameFr": "Stag Platform",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 328,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSTA",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Stanley",
    "nameFr": "Stanley",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 329,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSTN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Steinbach",
    "nameFr": "Steinbach",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 330,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSRN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Strahan",
    "nameFr": "Strahan",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 331,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSUH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Surry Hills",
    "nameFr": "Surry Hills",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 332,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUSYD",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Sydney",
    "nameFr": "Sydney",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 333,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "NFNLKSB",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Sydney Bay (Kingston), Norfolk Island",
    "nameFr": "Sydney Bay (Kingston), Norfolk Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 334,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUGUR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "The Gurdies",
    "nameFr": "The Gurdies",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 335,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTHE",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Thevenard",
    "nameFr": "Thevenard",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 336,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUCTH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Thevenard Island",
    "nameFr": "Thevenard Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 337,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTIS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Thursday Island",
    "nameFr": "Thursday Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 338,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTOL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Toombul",
    "nameFr": "Toombul",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 339,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTRD",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Tooradin",
    "nameFr": "Tooradin",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 340,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTOR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Toronto",
    "nameFr": "Toronto",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 341,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AULKU",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Torrensville",
    "nameFr": "Torrensville",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 342,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTOB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Towford Bay",
    "nameFr": "Towford Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 343,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTSV",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Townsville",
    "nameFr": "Townsville",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 344,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTRB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Trial Bay",
    "nameFr": "Trial Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 345,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTRI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Troughton Island",
    "nameFr": "Troughton Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 346,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUTHS",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Tweed Heads South",
    "nameFr": "Tweed Heads South",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 347,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUDA5",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Twowells",
    "nameFr": "Twowells",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 348,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUULA",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ulladulla",
    "nameFr": "Ulladulla",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 349,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUULT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Ultimo",
    "nameFr": "Ultimo",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 350,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUUMB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Umbakumba",
    "nameFr": "Umbakumba",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 351,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUURN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Urangan",
    "nameFr": "Urangan",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 352,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUUSL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Useless Loop",
    "nameFr": "Useless Loop",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 353,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUVGH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Van Gogh Terminal",
    "nameFr": "Van Gogh Terminal",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 354,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUVAR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Varanus Island",
    "nameFr": "Varanus Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 355,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWAL",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Wallaroo",
    "nameFr": "Wallaroo",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 356,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWNT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Wandoo Terminal",
    "nameFr": "Wandoo Terminal",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 357,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWSN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Warners Bay",
    "nameFr": "Warners Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 358,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWMB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Warrnambool",
    "nameFr": "Warrnambool",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 359,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWAZ",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Warwick",
    "nameFr": "Warwick",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 360,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWTN",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Webberton",
    "nameFr": "Webberton",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 361,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWEI",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Weipa",
    "nameFr": "Weipa",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 362,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUKJH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Welland",
    "nameFr": "Welland",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 363,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWHL",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Welshpool",
    "nameFr": "Welshpool",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 364,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWWP",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Wentworth Point",
    "nameFr": "Wentworth Point",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 365,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWRB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Werribee",
    "nameFr": "Werribee",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 366,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWEP",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Westernport",
    "nameFr": "Westernport",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 367,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWSI",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Whitsunday Island",
    "nameFr": "Whitsunday Island",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 368,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWYA",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Whyalla",
    "nameFr": "Whyalla",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 369,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWIH",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Wingham",
    "nameFr": "Wingham",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 370,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUVIT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Wishart",
    "nameFr": "Wishart",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 371,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWNB",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Withnell Bay",
    "nameFr": "Withnell Bay",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 372,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWOT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Wonthaggi",
    "nameFr": "Wonthaggi",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 373,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWOO",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Woolloomooloo",
    "nameFr": "Woolloomooloo",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 374,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWBT",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Woollybutt (Oil facility)",
    "nameFr": "Woollybutt (Oil facility)",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 375,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUWYN",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Wyndham",
    "nameFr": "Wyndham",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 376,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUYBA",
    "disabled": 0,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Yamba",
    "nameFr": "Yamba",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 377,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUYAM",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Yampi",
    "nameFr": "Yampi",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 378,
    "city": "NULL",
    "country": "Australia",
    "line1": "Box 7051, Canberra BC, CANBERRA ACT 2610",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "AUYRR",
    "disabled": 1,
    "email": "info@afma.gov.au",
    "fax": "+61262255500",
    "nameEn": "Yarra Junction",
    "nameFr": "Yarra Junction",
    "phone": "+61262255555",
    "country_id": 16
  },
  {
    "id": 379,
    "city": "NULL",
    "country": "Indonesia",
    "line1": "Jl. Siliwangi PO BOX 22 Pelabuhanratu 43364 Jawa Barat",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IDPAL",
    "disabled": 0,
    "email": "ppn-ratu@yahoo.com",
    "fax": "+62266431355",
    "nameEn": "Archipelagic Fishing Port Palabuhanratu-West Java",
    "nameFr": "Archipelagic Fishing Port Palabuhanratu-West Java",
    "phone": "+62266431307",
    "country_id": 104
  },
  {
    "id": 380,
    "city": "NULL",
    "country": "Indonesia",
    "line1": "Jl. Sultan Hassanudin, Pandan Katsuri, Ambon",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IDAMQ",
    "disabled": 0,
    "email": "ppnambon@gmail.com",
    "fax": "+62911345291",
    "nameEn": "Ambon, Molucas",
    "nameFr": "Ambon, Molucas",
    "phone": "+62911355222",
    "country_id": 104
  },
  {
    "id": 381,
    "city": "NULL",
    "country": "Indonesia",
    "line1": "Kelurahan Aertembaga Satu, Kecamatan Aertembaga, Kota Bitung-Sulawesi Utara",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IDBIT",
    "disabled": 0,
    "email": "ari_wart@yahoo.com",
    "fax": "+6243834393",
    "nameEn": "Bitung, Sulawesi",
    "nameFr": "Bitung, Sulawesi",
    "phone": "+6143836775",
    "country_id": 104
  },
  {
    "id": 382,
    "city": "NULL",
    "country": "Indonesia",
    "line1": "Jl. Raya Padang Painan Km. 16 Padang, Sumatera Barat",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IDPDG",
    "disabled": 0,
    "email": "ppsbungus@yahoo.com",
    "fax": "+62751751122",
    "nameEn": "Padang",
    "nameFr": "Padang",
    "phone": "+62751751121",
    "country_id": 104
  },
  {
    "id": 383,
    "city": "NULL",
    "country": "Indonesia",
    "line1": "Jl. Muara Baru Ujung, Penjaringan Jakarta Utara 14440",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IDNIZ",
    "disabled": 0,
    "email": "ppsnzj@gmail.com",
    "fax": "+62216617868",
    "nameEn": "Oceanic Fishing Port Nizam Zachman-Jakarta",
    "nameFr": "Oceanic Fishing Port Nizam Zachman-Jakarta",
    "phone": "+62216617865",
    "country_id": 104
  },
  {
    "id": 384,
    "city": "NULL",
    "country": "Iran, Islamic Republic of",
    "line1": "No. 236, Dr. Fatemi Ave, Tehran",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IRSBR",
    "disabled": 0,
    "email": "maghsoodi40@yahoo.com",
    "fax": "NULL",
    "nameEn": "Shahid Bahonar",
    "nameFr": "Shahid Bahonar",
    "phone": "+989171612908",
    "country_id": 109
  },
  {
    "id": 385,
    "city": "NULL",
    "country": "Iran, Islamic Republic of",
    "line1": "No. 236, Dr. Fatemi Ave, Tehran",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IRZBR",
    "disabled": 0,
    "email": "sistani55@yahoo.com",
    "fax": "NULL",
    "nameEn": "Chah Bahar",
    "nameFr": "Chah Bahar",
    "phone": "+989153416058",
    "country_id": 109
  },
  {
    "id": 386,
    "city": "NULL",
    "country": "Iran, Islamic Republic of",
    "line1": "No. 236, Dr. Fatemi Ave, Tehran",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "IRSRP",
    "disabled": 0,
    "email": "maghsoodi40@yahoo.com",
    "fax": "NULL",
    "nameEn": "Shahid Rajaee Pt/Bandar Abbas",
    "nameFr": "Shahid Rajaee Pt/Bandar Abbas",
    "phone": "+989171612908",
    "country_id": 109
  },
  {
    "id": 387,
    "city": "NULL",
    "country": "Kenya",
    "line1": "P.O. Box 90423 - 80100 Mombasa",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "KEMBA",
    "disabled": 0,
    "email": "pdfcoast@gmail.com",
    "fax": "",
    "nameEn": "Mombasa",
    "nameFr": "Mombasa",
    "phone": "+254412221318",
    "country_id": 119
  },
  {
    "id": 388,
    "city": "NULL",
    "country": "Madagascar",
    "line1": "Antsiranana",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MGDIE",
    "disabled": 0,
    "email": "csp-diego@madagascar.vms-services.mg",
    "fax": "NULL",
    "nameEn": "Antsiranana",
    "nameFr": "Antsiranana",
    "phone": "+261320703929",
    "country_id": 142
  },
  {
    "id": 389,
    "city": "NULL",
    "country": "Madagascar",
    "line1": "Mahajanga",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MGMJN",
    "disabled": 0,
    "email": "t2jose@yahoo.fr",
    "fax": "NULL",
    "nameEn": "Majunga (Mahajanga)",
    "nameFr": "Majunga (Mahajanga)",
    "phone": "+261320703928",
    "country_id": 142
  },
  {
    "id": 390,
    "city": "NULL",
    "country": "Madagascar",
    "line1": "Toamasina",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MGTOA",
    "disabled": 0,
    "email": "razafisalamaboniface@yahoo.fr",
    "fax": "NULL",
    "nameEn": "Toamasina",
    "nameFr": "Toamasina",
    "phone": "+261320704238",
    "country_id": 142
  },
  {
    "id": 391,
    "city": "NULL",
    "country": "Madagascar",
    "line1": "Ehoala",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MGEHL",
    "disabled": 0,
    "email": "mahalefitrami@yahoo.fr",
    "fax": "NULL",
    "nameEn": "Ehoala",
    "nameFr": "Ehoala",
    "phone": "+261320704020",
    "country_id": 142
  },
  {
    "id": 392,
    "city": "NULL",
    "country": "Madagascar",
    "line1": "Tulear",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MGTLE",
    "disabled": 0,
    "email": "pierrotcsp@live.fr",
    "fax": "NULL",
    "nameEn": "Tulear",
    "nameFr": "Tulear",
    "phone": "+261320704357",
    "country_id": 142
  },
  {
    "id": 393,
    "city": "NULL",
    "country": "Malaysia",
    "line1": "Wisma Tani, Precint 4, 62628, Putrajaya, Malaysia",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MYLGK",
    "disabled": 0,
    "email": "mnn@dof.gov.my",
    "fax": "+60388892460",
    "nameEn": "Langkawi",
    "nameFr": "Langkawi",
    "phone": "+60388704000",
    "country_id": 159
  },
  {
    "id": 394,
    "city": "NULL",
    "country": "Malaysia",
    "line1": "Wisma Tani, Precint 4, 62628, Putrajaya, Malaysia",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MYPEN",
    "disabled": 0,
    "email": "mnn@dof.gov.my",
    "fax": "+60388892460",
    "nameEn": "Penang",
    "nameFr": "Penang",
    "phone": "+60388704000",
    "country_id": 159
  },
  {
    "id": 395,
    "city": "NULL",
    "country": "Maldives",
    "line1": "Velaanage, 7th floor, Ameer Ahmed Magu, Male�, 20096",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MVMLE",
    "disabled": 0,
    "email": "ahmed.shifaz@fishagri.gov.mv",
    "fax": "+603321168",
    "nameEn": "Male",
    "nameFr": "Male",
    "phone": "+9603305038",
    "country_id": 143
  },
  {
    "id": 396,
    "city": "NULL",
    "country": "Mauritius",
    "line1": "4th Floor, LICI Building, John Kennedy St., Port- Louis",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MUPLU",
    "disabled": 0,
    "email": "sfh@govmu.org",
    "fax": "+2302081929",
    "nameEn": "Port Louis",
    "nameFr": "Port Louis",
    "phone": "+2302112470",
    "country_id": 157
  },
  {
    "id": 397,
    "city": "NULL",
    "country": "Mozambique",
    "line1": "Rua Consiglier Pedroso 347, Maputo",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MZBEW",
    "disabled": 0,
    "email": "adnap@.gov.mz",
    "fax": "+25821320335",
    "nameEn": "Beira",
    "nameFr": "Beira",
    "phone": "+25821358000",
    "country_id": 153
  },
  {
    "id": 398,
    "city": "NULL",
    "country": "Mozambique",
    "line1": "Rua Consiglier Pedroso 347, Maputo",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MZMPM",
    "disabled": 0,
    "email": "adnap@.gov.mz",
    "fax": "+25821320335",
    "nameEn": "Maputo",
    "nameFr": "Maputo",
    "phone": "+25821358000",
    "country_id": 153
  },
  {
    "id": 399,
    "city": "NULL",
    "country": "Mozambique",
    "line1": "Rua Consiglier Pedroso 347, Maputo",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MZMNC",
    "disabled": 0,
    "email": "adnap@.gov.mz",
    "fax": "+25821320335",
    "nameEn": "Nacala",
    "nameFr": "Nacala",
    "phone": "+25821358000",
    "country_id": 153
  },
  {
    "id": 400,
    "city": "NULL",
    "country": "Oman",
    "line1": "P.O Box: 427, Muscat, Postal Code: 100",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "OMSLL",
    "disabled": 0,
    "email": "skomani@hotmail.com",
    "fax": "+96823201356",
    "nameEn": "Salalah",
    "nameFr": "Salalah",
    "phone": "+96823291527",
    "country_id": 173
  },
  {
    "id": 401,
    "city": "NULL",
    "country": "Seychelles",
    "line1": "Fishing Port. Victoria, Mahe Island",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "SCPOV",
    "disabled": 0,
    "email": "fmcsc@sfa.sc",
    "fax": "+2484224508",
    "nameEn": "Port Victoria",
    "nameFr": "Port Victoria",
    "phone": "+2484670300",
    "country_id": 212
  },
  {
    "id": 402,
    "city": "NULL",
    "country": "Somalia",
    "line1": "Maka Al-Mukarrama Road",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "SOMGQ",
    "disabled": 0,
    "email": "aidarous57@hotmail.com",
    "fax": "NULL",
    "nameEn": "Mogadishu",
    "nameFr": "Mogadishu",
    "phone": "+252618002485",
    "country_id": 203
  },
  {
    "id": 403,
    "city": "NULL",
    "country": "Somalia",
    "line1": "Maka Al-Mukarrama Road",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "SOKMU",
    "disabled": 0,
    "email": "ilyaasxuseen114@gmail.com",
    "fax": "NULL",
    "nameEn": "Kismayo",
    "nameFr": "Kismayo",
    "phone": "+252615161676",
    "country_id": 203
  },
  {
    "id": 404,
    "city": "NULL",
    "country": "Somalia",
    "line1": "Maka Al-Mukarrama Road",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "SOBOS",
    "disabled": 0,
    "email": "jafaan@gmail.com",
    "fax": "NULL",
    "nameEn": "Bossaso",
    "nameFr": "Bossaso",
    "phone": "+2529079996",
    "country_id": 203
  },
  {
    "id": 405,
    "city": "NULL",
    "country": "South Africa",
    "line1": "Foretrust Building, Marting Hammerschlag Way, Foreshore, 8012",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "ZACPT",
    "disabled": 0,
    "email": "portsSA@daff.gov.za",
    "fax": "+27214023113",
    "nameEn": "Cape Town",
    "nameFr": "Cape Town",
    "phone": "+27214023428",
    "country_id": 245
  },
  {
    "id": 406,
    "city": "NULL",
    "country": "South Africa",
    "line1": "3 Elton Place, Durban, 4001",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "ZADUR",
    "disabled": 0,
    "email": "portsSA@daff.gov.za",
    "fax": "+27312741174",
    "nameEn": "Durban",
    "nameFr": "Durban",
    "phone": "+27312741150",
    "country_id": 245
  },
  {
    "id": 407,
    "city": "NULL",
    "country": "South Africa",
    "line1": "21 Stanley Street, Port Elizabeth, 6006",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "ZAPLZ",
    "disabled": 0,
    "email": "portsSA@daff.gov.za",
    "fax": "+27415850385",
    "nameEn": "Port Elisabeth",
    "nameFr": "Port Elisabeth",
    "phone": "+2721415864051",
    "country_id": 245
  },
  {
    "id": 408,
    "city": "NULL",
    "country": "Sri Lanka",
    "line1": "New Secretariat",
    "line2": "Maligawatta",
    "line3": "Colombo 10",
    "stateProvinceCounty": "Sri Lanka",
    "zipOrPostcode": "NULL",
    "code": "LKDIK",
    "disabled": 0,
    "email": "kalpsm2014@gmail.com",
    "fax": "+94112942872",
    "nameEn": "Dikovita Fishery Harbour",
    "nameFr": "Dikovita Fishery Harbour",
    "phone": "+94112942872",
    "country_id": 132
  },
  {
    "id": 409,
    "city": "NULL",
    "country": "Sri Lanka",
    "line1": "New Secretariat",
    "line2": "Maligawatta",
    "line3": "Colombo 10",
    "stateProvinceCounty": "Sri Lanka",
    "zipOrPostcode": "NULL",
    "code": "LKCMB",
    "disabled": 0,
    "email": "kalpsm2014@gmail.com",
    "fax": "+94112327160",
    "nameEn": "Colombo Commercial Port",
    "nameFr": "Colombo Commercial Port",
    "phone": "+94112482213",
    "country_id": 132
  },
  {
    "id": 410,
    "city": "NULL",
    "country": "Sri Lanka",
    "line1": "New Secretariat",
    "line2": "Maligawatta",
    "line3": "Colombo 10",
    "stateProvinceCounty": "Sri Lanka",
    "zipOrPostcode": "NULL",
    "code": "LKGAL",
    "disabled": 0,
    "email": "kalpsm2014@gmail.com",
    "fax": "+94112234963",
    "nameEn": "Galle Commercial Port",
    "nameFr": "Galle Commercial Port",
    "phone": "+94912234824",
    "country_id": 132
  },
  {
    "id": 411,
    "city": "NULL",
    "country": "Sri Lanka",
    "line1": "New Secretariat",
    "line2": "Maligawatta",
    "line3": "Colombo 10",
    "stateProvinceCounty": "Sri Lanka",
    "zipOrPostcode": "NULL",
    "code": "LKTRR",
    "disabled": 0,
    "email": "kalpsm2014@gmail.com",
    "fax": "+94112449170",
    "nameEn": "Trincomalee",
    "nameFr": "Trincomalee",
    "phone": "+94112470439",
    "country_id": 132
  },
  {
    "id": 412,
    "city": "NULL",
    "country": "Sri Lanka",
    "line1": "New Secretariat",
    "line2": "Maligawatta",
    "line3": "Colombo 10",
    "stateProvinceCounty": "Sri Lanka",
    "zipOrPostcode": "NULL",
    "code": "LKHBA",
    "disabled": 0,
    "email": "kalpsm2014@gmail.com",
    "fax": "+94112449170",
    "nameEn": "Hambantota",
    "nameFr": "Hambantota",
    "phone": "+94112470439",
    "country_id": 132
  },
  {
    "id": 413,
    "city": "NULL",
    "country": "Tanzania",
    "line1": "Main Quay Number 1, P.O. Box 1130, Dar Es Salaam",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "TZDAR",
    "disabled": 0,
    "email": "pmdsm@tanzaniaports.com",
    "fax": "+255222113646",
    "nameEn": "Dar Es Salaam",
    "nameFr": "Dar Es Salaam",
    "phone": "+255222110401",
    "country_id": 228
  },
  {
    "id": 414,
    "city": "NULL",
    "country": "Tanzania",
    "line1": "P.O.BOX 530, TWARA",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "TZMYW",
    "disabled": 0,
    "email": "pmmtwara@tanzaniaports.com",
    "fax": "+25559333153",
    "nameEn": "Mtwara",
    "nameFr": "Mtwara",
    "phone": "+25559333125",
    "country_id": 228
  },
  {
    "id": 415,
    "city": "NULL",
    "country": "Tanzania",
    "line1": "Bandari House, P.O Box 443, TANGA",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "TZTGT",
    "disabled": 0,
    "email": "pmtanga@tanzaniaports.com",
    "fax": "+255272642360",
    "nameEn": "Tanga",
    "nameFr": "Tanga",
    "phone": "+255272643078",
    "country_id": 228
  },
  {
    "id": 416,
    "city": "NULL",
    "country": "Tanzania",
    "line1": "P.O.Box 263, Zanzibar",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "TZZNZ",
    "disabled": 0,
    "email": "zanzibar-port@zanzinet.com",
    "fax": "+255242232859",
    "nameEn": "Zanzibar",
    "nameFr": "Zanzibar",
    "phone": "+255242232017",
    "country_id": 228
  },
  {
    "id": 417,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Phuket Fish Inspection Office",
    "line2": "Cargo Phuket International Airport, Tambon Mai Khao,  Thalang District, Phuket, 83110",
    "line3": "NULL",
    "stateProvinceCounty": "Phuket Province",
    "zipOrPostcode": "NULL",
    "code": "THHKT",
    "disabled": 0,
    "email": "pktfishtradeins_2011@hotmail.com",
    "fax": "+6676327612",
    "nameEn": "Phuket Fishing port (Phuket Province)",
    "nameFr": "Phuket Fishing port (Phuket Province)",
    "phone": "+66922234274",
    "country_id": 217
  },
  {
    "id": 418,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Phuket Fish Inspection Office",
    "line2": "Cargo Phuket International Airport, Tambon Mai Khao,  Thalang District, Phuket, 83110",
    "line3": "NULL",
    "stateProvinceCounty": "Phuket Province",
    "zipOrPostcode": "NULL",
    "code": "THHKV",
    "disabled": 0,
    "email": "pktfishtradeins_2011@hotmail.com",
    "fax": "+6676327612",
    "nameEn": "Phuket Srithai Co., Ltd port  (Phuket Province)",
    "nameFr": "Phuket Srithai Port",
    "phone": "+66922234274",
    "country_id": 217
  },
  {
    "id": 419,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Phuket Fish Inspection Office",
    "line2": "Cargo Phuket International Airport, Tambon Mai Khao,  Thalang District, Phuket, 83110",
    "line3": "NULL",
    "stateProvinceCounty": "Phuket Province",
    "zipOrPostcode": "NULL",
    "code": "THHKW",
    "disabled": 0,
    "email": "pktfishtradeins_2011@hotmail.com",
    "fax": "+6676327612",
    "nameEn": "Phuket Deep Sea port  (Phuket Province)",
    "nameFr": "Phuket Deep Sea Port",
    "phone": "+66922234274",
    "country_id": 217
  },
  {
    "id": 420,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Samut Sakhon Fish Inspection Office",
    "line2": "1024 Wichianchodok Road Sub. Mahachai  A. Muang Samut sakorn, Samutsakhon 74000",
    "line3": "NULL",
    "stateProvinceCounty": "Samut Sakhon Province",
    "zipOrPostcode": "NULL",
    "code": "THVAD",
    "disabled": 0,
    "email": "sk_inspected@windowslive.com",
    "fax": "+6634422644",
    "nameEn": "Chainavee port (Samut Sakhon Province)",
    "nameFr": "Chainavee port (Samut Sakhon Province)",
    "phone": "+66818165046",
    "country_id": 217
  },
  {
    "id": 421,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Samut Sakhon Fish Inspection Office",
    "line2": "1024 Wichianchodok Road Sub. Mahachai  A. Muang Samut sakorn, Samutsakhon 74000",
    "line3": "NULL",
    "stateProvinceCounty": "Samut Sakhon Province",
    "zipOrPostcode": "NULL",
    "code": "THVAE",
    "disabled": 0,
    "email": "sk_inspected@windowslive.com",
    "fax": "+6634422644",
    "nameEn": "Thajeen Union Port Co., Ltd port (Samut Sakhon Province)",
    "nameFr": "Thajeen Union Port Co., Ltd port (Samut Sakhon Province)",
    "phone": "+66818165046",
    "country_id": 217
  },
  {
    "id": 422,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 3 (Songkhla)",
    "line2": "Songkhla Port Fish Inspection Office, Boyang, Maung District Songkhla",
    "line3": "NULL",
    "stateProvinceCounty": "Songkhla Province",
    "zipOrPostcode": "NULL",
    "code": "THSGZ1",
    "disabled": 0,
    "email": "sk_fishtrade@hotmail.com",
    "fax": "+6674321945",
    "nameEn": "Songkhla Deep sea port (Songkhla Province)",
    "nameFr": "Songkhla Deep sea port (Songkhla Province)",
    "phone": "+66819573455",
    "country_id": 217
  },
  {
    "id": 423,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Ranong Fish Inspection Office",
    "line2": "174 Village No. 1, Paknam sub-district,  Muang District, Ranong Province",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THUNO",
    "disabled": 0,
    "email": "Danranong_fishtrade@hotmail.com",
    "fax": "+6677816331",
    "nameEn": "Tanasarn port (Ranong Province)",
    "nameFr": "Tanasarn port (Ranong Province)",
    "phone": "+66813266419",
    "country_id": 217
  },
  {
    "id": 424,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 3 (Songkhla)",
    "line2": "Songkhla Port Fish Inspection Office, Boyang, Maung District Songkhla",
    "line3": "NULL",
    "stateProvinceCounty": "Songkhla Province",
    "zipOrPostcode": "NULL",
    "code": "THUNP",
    "disabled": 0,
    "email": "sk_fishtrade@hotmail.com",
    "fax": "+6674321945",
    "nameEn": "Southern Logistics (2009) Co., Ltd port (Songkhla Province)",
    "nameFr": "Southern Logistics (2009) Co., Ltd port (Songkhla Province)",
    "phone": "+66819573455",
    "country_id": 217
  },
  {
    "id": 425,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 3 (Songkhla)",
    "line2": "Songkhla Port Fish Inspection Office, Boyang, Maung District Songkhla",
    "line3": "NULL",
    "stateProvinceCounty": "Songkhla Province",
    "zipOrPostcode": "NULL",
    "code": "THUNQ",
    "disabled": 0,
    "email": "sk_fishtrade@hotmail.com",
    "fax": "+6674321945",
    "nameEn": "Songkhla Fishing port 2 (Tha Sa-an) (Songkhla Province)",
    "nameFr": "Songkhla Fishing port 2 (Tha Sa-an) (Songkhla Province)",
    "phone": "+66819573455",
    "country_id": 217
  },
  {
    "id": 426,
    "city": "NULL",
    "country": "Thailand",
    "line1": "174 Village No. 1, Paknam sub-district,  Muang District, Ranong Province",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THUNR",
    "disabled": 1,
    "email": "Danranong_fishtrade@hotmail.com",
    "fax": "NULL",
    "nameEn": "Thong Thai Dee Port",
    "nameFr": "Thong Thai Dee Port",
    "phone": "",
    "country_id": 217
  },
  {
    "id": 427,
    "city": "NULL",
    "country": "Thailand",
    "line1": "174 Village No. 1, Paknam sub-district,  Muang District, Ranong Province",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THUNS",
    "disabled": 1,
    "email": "Danranong_fishtrade@hotmail.com",
    "fax": "NULL",
    "nameEn": "Chong Pon Chai Port",
    "nameFr": "Chong Pon Chai Port",
    "phone": "NULL",
    "country_id": 217
  },
  {
    "id": 428,
    "city": "NULL",
    "country": "Thailand",
    "line1": "174 Village No. 1, Paknam sub-district,  Muang District, Ranong Province",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THUNT",
    "disabled": 1,
    "email": "Danranong_fishtrade@hotmail.com",
    "fax": "NULL",
    "nameEn": "Wassana adisorn Port",
    "nameFr": "Wassana adisorn Port",
    "phone": "NULL",
    "country_id": 217
  },
  {
    "id": 429,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Trat Fish Inspection Office",
    "line2": "Bann Had Lek Border Government Center Building, Floor 3, Village No.4,  Had lek Sub-district , Klongyai District, Trat Province, 23110",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THVAA",
    "disabled": 1,
    "email": "trad_fish@yahoo.co.th",
    "fax": "+6639588136",
    "nameEn": "Chalalai port (Trat Province)",
    "nameFr": "Chalalai port (Trat Province)",
    "phone": "+6639588136",
    "country_id": 217
  },
  {
    "id": 430,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Bann Had Lek Border Government Center Building, Floor 3, Village No.4,  Had lek Sub-district , Klongyai District, Trat Province, 23110",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THVAB",
    "disabled": 1,
    "email": "trad_fish @ yahoo.co.th",
    "fax": "+6639588136",
    "nameEn": "Kallpangha Port",
    "nameFr": "Kallpangha Port",
    "phone": "+66914108699",
    "country_id": 217
  },
  {
    "id": 431,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Bann Had Lek Border Government Center Building, Floor 3, Village No.4,  Had lek Sub-district , Klongyai District, Trat Province, 23110",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THVAC",
    "disabled": 1,
    "email": "trad_fish @ yahoo.co.th",
    "fax": "+6639588136",
    "nameEn": "Por Kasemsiri Port",
    "nameFr": "Por Kasemsiri Port",
    "phone": "+66914108699",
    "country_id": 217
  },
  {
    "id": 432,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Narathiwat Fish Inspection Office",
    "line2": "Chehe, Takbai, Narathiwat",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THLCI",
    "disabled": 0,
    "email": "narainspectiion@gmail.com",
    "fax": "+6673582157",
    "nameEn": "Pattani Fishing port (Pattani Province)",
    "nameFr": "Siam Seaport Terminal",
    "phone": "+6673582157",
    "country_id": 217
  },
  {
    "id": 433,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Narathiwat Fish Inspection Office",
    "line2": "Chehe, Takbai, Narathiwat",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THNAW",
    "disabled": 0,
    "email": "narainspectiion@gmail.com",
    "fax": "+6673582157",
    "nameEn": "Narathiwat Fishing port (Narathiwat Province)",
    "nameFr": "Narathiwat",
    "phone": "+6673582157",
    "country_id": 217
  },
  {
    "id": 434,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Satun Fish Inspection Office",
    "line2": "Tammalang, Mueang Satun, Satun 91000",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THSTH",
    "disabled": 0,
    "email": "satun.aaio@gmail.com",
    "fax": "+6674710621",
    "nameEn": "Satun Fishing port (Satun Province)",
    "nameFr": "Satun Fishing port (Satun Province)",
    "phone": "+6674710620",
    "country_id": 217
  },
  {
    "id": 435,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Narathiwat Fish Inspection Office",
    "line2": "Chehe, Takbai, Narathiwat",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THNAX",
    "disabled": 0,
    "email": "narainspectiion@gmail.com",
    "fax": "+6673582157",
    "nameEn": "Tak Bai Pacific port (Narathiwat Province)",
    "nameFr": "Tak Bai Pasific Port",
    "phone": "+6673582157",
    "country_id": 217
  },
  {
    "id": 436,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKL",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "Godung Thai Fa Co., Ltd port (Bangkok)",
    "nameFr": "Godung Thai Fa Co., Ltd port (Bangkok)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 437,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKM",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "33A-B port (Bangkok)",
    "nameFr": "33A-B port (Bangkok)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 438,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKN",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "Public Warehouse Organization port (2 storehouse port No. 27 A)  (Bangkok)",
    "nameFr": "Public Warehouse Organization port (2 storehouse port No. 27 A)  (Bangkok)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 439,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKO",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "Thanapornchai Co., Ltd port (Samut Prakan Province)",
    "nameFr": "Thanapornchai Co., Ltd port (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 440,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKP",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "TJ Land Company Limited port (Samut Prakan Province)",
    "nameFr": "TJ Land Company Limited port (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 441,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKQ",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "No. 11B port (Samut Prakan Province)",
    "nameFr": "No. 11B port (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 442,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKR",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "7C port (Samut Prakan Province)",
    "nameFr": "7C port (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 443,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKS",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626727901",
    "nameEn": "No. 7 port (Samut Prakan Province)",
    "nameFr": "No. 7 port (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 444,
    "city": "NULL",
    "country": "Singapore",
    "line1": "NULL",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "SGSIN",
    "disabled": 0,
    "email": "",
    "fax": "",
    "nameEn": "Singapore",
    "nameFr": "Singapour",
    "phone": "",
    "country_id": 195
  },
  {
    "id": 445,
    "city": "NULL",
    "country": "France (EU)",
    "line1": "40 avenue Louis Bougo, 56410 �TEL, France",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "REPDG",
    "disabled": 0,
    "email": "csp-France.cross-etel@developpement-durable.gouv.fr",
    "fax": "+33297552375",
    "nameEn": "Port de Pointe des Galets",
    "nameFr": "Port de Pointe des Galets",
    "phone": "+33297553535",
    "country_id": 76
  },
  {
    "id": 446,
    "city": "NULL",
    "country": "Somalia",
    "line1": "Maka Al-Mukarrama Road",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "SOBBO",
    "disabled": 0,
    "email": "NULL",
    "fax": "NULL",
    "nameEn": "Berbera",
    "nameFr": "Berbera",
    "phone": "NULL",
    "country_id": 203
  },
  {
    "id": 447,
    "city": "NULL",
    "country": "Maldives",
    "line1": "Velaanage, 7th floor, Ameer Ahmed Magu, Male�, 20096",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MVFEL",
    "disabled": 0,
    "email": "ahmed.shifaz@fishagri.gov.mv",
    "fax": "+603321169",
    "nameEn": "Felivaru",
    "nameFr": "Felivaru",
    "phone": "+9603322625",
    "country_id": 143
  },
  {
    "id": 448,
    "city": "NULL",
    "country": "Maldives",
    "line1": "Velaanage, 7th floor, Ameer Ahmed Magu, Male�, 20097",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MVFUN",
    "disabled": 0,
    "email": "ahmed.shifaz@fishagri.gov.mv",
    "fax": "+603321169",
    "nameEn": "Funaddoo",
    "nameFr": "Funaddoo",
    "phone": "+9603322625",
    "country_id": 143
  },
  {
    "id": 449,
    "city": "NULL",
    "country": "Maldives",
    "line1": "Velaanage, 7th floor, Ameer Ahmed Magu, Male�, 20098",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MVMAA",
    "disabled": 0,
    "email": "ahmed.shifaz@fishagri.gov.mv",
    "fax": "+603321169",
    "nameEn": "Maandhoo",
    "nameFr": "Maandhoo",
    "phone": "+9603322625",
    "country_id": 143
  },
  {
    "id": 450,
    "city": "NULL",
    "country": "Maldives",
    "line1": "Velaanage, 7th floor, Ameer Ahmed Magu, Male�, 20099",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MVHOA",
    "disabled": 0,
    "email": "ahmed.shifaz@fishagri.gov.mv",
    "fax": "+603321169",
    "nameEn": "Hoadedhdhoo",
    "nameFr": "Hoadedhdhoo",
    "phone": "+9603322625",
    "country_id": 143
  },
  {
    "id": 451,
    "city": "NULL",
    "country": "Maldives",
    "line1": "Velaanage, 7th floor, Ameer Ahmed Magu, Male�, 20101",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MVKOO",
    "disabled": 0,
    "email": "ahmed.shifaz@fishagri.gov.mv",
    "fax": "+603321169",
    "nameEn": "Kooddoo",
    "nameFr": "Kooddoo",
    "phone": "+9603322625",
    "country_id": 143
  },
  {
    "id": 452,
    "city": "NULL",
    "country": "Maldives",
    "line1": "Velaanage, 7th floor, Ameer Ahmed Magu, Male�, 20102",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "MVMAR",
    "disabled": 0,
    "email": "ahmed.shifaz@fishagri.gov.mv",
    "fax": "+603321169",
    "nameEn": "Maradhoo",
    "nameFr": "Maradhoo",
    "phone": "+9603322625",
    "country_id": 143
  },
  {
    "id": 453,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKT",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626718794",
    "nameEn": "23A Port (Samut Prakan Province)",
    "nameFr": "23A Port (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 454,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKU",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626718794",
    "nameEn": "23C Port (Samut Prakan Province)",
    "nameFr": "23C Port (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 455,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Fish Quarantine and Inspection Regional Center 2 (Bangkok)",
    "line2": "Pat-Sa-Du building, Floor 2 , Thai Port, Kwang Khlong Toei, Khet Khlong Toei, Bangkok",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THBKV",
    "disabled": 0,
    "email": "psmfishtrade@gmail.com",
    "fax": "+6626718794",
    "nameEn": "Sabasathaporn Company Limited port (21B) (Samut Prakan Province)",
    "nameFr": "Sabasathaporn Company Limited port (21B) (Samut Prakan Province)",
    "phone": "+6626718794",
    "country_id": 217
  },
  {
    "id": 456,
    "city": "NULL",
    "country": "Thailand",
    "line1": "Trat Fish Inspection Office",
    "line2": "Bann Had Lek Border Government Center Building, Floor 3, Village No.4,  Had lek Sub-district , Klongyai District, Trat Province, 23110",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "THKAL",
    "disabled": 0,
    "email": "trad_fish@yahoo.co.th",
    "fax": "+66898514594",
    "nameEn": "Kanlapangha port (Trad Province)",
    "nameFr": "Kanlapangha port (Trad Province)",
    "phone": "+6639588136",
    "country_id": 217
  },
  {
    "id": 457,
    "city": "NULL",
    "country": "Kenya",
    "line1": "PO Box 90423, 80100 Mombasa",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "KEMYD",
    "disabled": 0,
    "email": "pdfcoast@gmail.com",
    "fax": "",
    "nameEn": "Malindi",
    "nameFr": "Malindi",
    "phone": "+254412221318",
    "country_id": 119
  },
  {
    "id": 458,
    "city": "NULL",
    "country": "Kenya",
    "line1": "PO Box 90423, 80100 Mombasa",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "KELAU",
    "disabled": 0,
    "email": "pdfcoast@gmail.com",
    "fax": "NULL",
    "nameEn": "Lamu",
    "nameFr": "Lamu",
    "phone": "+254412221318",
    "country_id": 119
  },
  {
    "id": 459,
    "city": "NULL",
    "country": "Kenya",
    "line1": "PO Box 90423, 80100 Mombasa",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "KESMN",
    "disabled": 0,
    "email": "pdfcoast@gmail.com",
    "fax": "NULL",
    "nameEn": "Shimoni",
    "nameFr": "Shimoni",
    "phone": "+254412221318",
    "country_id": 119
  },
  {
    "id": 460,
    "city": "NULL",
    "country": "Oman",
    "line1": "P.O Box 427, Muscat, Postal Code 100",
    "line2": "NULL",
    "line3": "NULL",
    "stateProvinceCounty": "NULL",
    "zipOrPostcode": "NULL",
    "code": "OMDQM",
    "disabled": 0,
    "email": "muatasim4@hotmail.com",
    "fax": "+96823201356",
    "nameEn": "Duqm Port",
    "nameFr": "Port de Duqm",
    "phone": "+96823291527",
    "country_id": 173
  }
]