export const authorities = [
  {
    "id": 1,
    "code": "AUS",
    "nameEn": "AUSTRALIA - Australian Fisheries Management Authority",
    "nameFr": "AUSTRALIE - Australian Fisheries Management Authority"
  },
  {
    "id": 2,
    "code": "BGD",
    "nameEn": "BANGLADESH - Ministry of Fisheries and Livestock",
    "nameFr": "BANGLADESH - Ministry of Fisheries and Livestock"
  },
  {
    "id": 3,
    "code": "BLZ",
    "nameEn": "BELIZE - Belize High Seas Fisheries Unit",
    "nameFr": "BELIZE - Belize High Seas Fisheries Unit"
  },
  {
    "id": 4,
    "code": "CHN",
    "nameEn": "CHINA - Ministry of Agriculture, the People�s Republic of China",
    "nameFr": "CHINE - Ministry of Agriculture, the People�s Republic of China"
  },
  {
    "id": 5,
    "code": "TWN",
    "nameEn": "TAIWAN, CHINA - Council of Agriculture",
    "nameFr": "TAIWAN - Council of Agriculture"
  },
  {
    "id": 6,
    "code": "COM",
    "nameEn": "COMOROS - Minist�re De La Production, De L�Environnement, De L�Energie, L�Industrie  Et De L�Artisanat",
    "nameFr": "COMORES - Minist�re De La Production, De L�Environnement, De L�Energie, L�Industrie  Et De L�Artisanat"
  },
  {
    "id": 7,
    "code": "DJI",
    "nameEn": "DJIBOUTI - Minist�re de l'Agriculture, Direction de la P�che",
    "nameFr": "DJIBOUTI - Minist�re de l'Agriculture, Direction de la P�che"
  },
  {
    "id": 8,
    "code": "ERI",
    "nameEn": "ERITREA - Ministry of Fisheries",
    "nameFr": "ERYTHREE - Ministry of Fisheries"
  },
  {
    "id": 9,
    "code": "ESP",
    "nameEn": "SPAIN - Ministerio de Agricultura Alimentacion y Medio Ambiente, Secretaria General de Pesca",
    "nameFr": "ESPAGNE - Ministerio de Agricultura Alimentacion y Medio Ambiente, Secretaria General de Pesca"
  },
  {
    "id": 10,
    "code": "EUR",
    "nameEn": "EUROPE - European Commission",
    "nameFr": "EUROPE - European Commission"
  },
  {
    "id": 11,
    "code": "FRA",
    "nameEn": "FRANCE - Ministre de l��cologie, du d�veloppement durable et de l��nergie - Orthongel",
    "nameFr": "FRANCE - Ministre de l��cologie, du d�veloppement durable et de l��nergie - Orthongel"
  },
  {
    "id": 13,
    "code": "FRAT",
    "nameEn": "FRANCE OT - Terres australes et antarctiques fran�aises (TAFF)",
    "nameFr": "FRANCE TO - Terres australes et antarctiques fran�aises (TAFF)"
  },
  {
    "id": 14,
    "code": "GBR",
    "nameEn": "ROYAUME UNI - Marine Scotland",
    "nameFr": "ROYAUME UNI - Marine Scotland"
  },
  {
    "id": 15,
    "code": "GBRT",
    "nameEn": "ROYAUME UNI OT - MRAG LTD",
    "nameFr": "ROYAUME UNI TO - MRAG LTD"
  },
  {
    "id": 16,
    "code": "GIN",
    "nameEn": "GUINEA - Centre National des Sciences Halieutiques de Boussoura",
    "nameFr": "GUINEE - Centre National des Sciences Halieutiques de Boussoura"
  },
  {
    "id": 17,
    "code": "IDN",
    "nameEn": "INDONESIA - Directorate Fisheries Resources Management",
    "nameFr": "INDONESIE - Directorate Fisheries Resources Management"
  },
  {
    "id": 18,
    "code": "IND",
    "nameEn": "INDIA - Ministry of Agriculture, Department of Animal Husbandry, Dairying & Fisheries",
    "nameFr": "INDE - Ministry of Agriculture, Department of Animal Husbandry, Dairying & Fisheries"
  },
  {
    "id": 19,
    "code": "IRN",
    "nameEn": "IRAN - Ministry of Jahad-e-Agriculture",
    "nameFr": "IRAN - Ministry of Jahad-e-Agriculture"
  },
  {
    "id": 20,
    "code": "ITA",
    "nameEn": "ITALIA - Comando generale del Corpo delle capitanerie di porto �Guardia costiera",
    "nameFr": "ITALIE - Comando generale del Corpo delle capitanerie di porto �Guardia costiera"
  },
  {
    "id": 21,
    "code": "JPN",
    "nameEn": "JAPAN - Minister of Agriculture, Forestry and Fisheries, Fisheries Agency of Japan",
    "nameFr": "JAPON - Minister of Agriculture, Forestry and Fisheries, Fisheries Agency of Japan"
  },
  {
    "id": 22,
    "code": "KEN",
    "nameEn": "KENYA - State Department for Fisheries and The Blue Economy",
    "nameFr": "KENYA - State Department for Fisheries and The Blue Economy"
  },
  {
    "id": 23,
    "code": "KOR",
    "nameEn": "KOREA - Ministry of Oceans and Fisheries",
    "nameFr": "COREE - Ministry of Oceans and Fisheries"
  },
  {
    "id": 24,
    "code": "LKA",
    "nameEn": "SRI LANKA - Department of Fisheries and Aquatic Resources",
    "nameFr": "SRI LANKA - Department of Fisheries and Aquatic Resources"
  },
  {
    "id": 25,
    "code": "LTU",
    "nameEn": "LITUANIA - Ministry of Agriculture, Fisheries Services, Division of the Fisheries Control and Monitoring",
    "nameFr": "LITUANIE - Ministry of Agriculture, Fisheries Services, Division of the Fisheries Control and Monitoring"
  },
  {
    "id": 26,
    "code": "LBR",
    "nameEn": "LIBERIA - Liberian International Ship & Corporate Registry",
    "nameFr": "LIBERIA - Liberian International Ship & Corporate Registry"
  },
  {
    "id": 27,
    "code": "MDG",
    "nameEn": "MADAGASCAR - Minist�re de la p�che et des ressources halieutiques",
    "nameFr": "MADAGASCAR - Minist�re de la p�che et des ressources halieutiques"
  },
  {
    "id": 28,
    "code": "MDV",
    "nameEn": "MALDIVES - Ministry of Fisheries and Agriculture",
    "nameFr": "MALDIVES - Ministry of Fisheries and Agriculture"
  },
  {
    "id": 29,
    "code": "MLT",
    "nameEn": "MALTA - Fisheries Control Directorate",
    "nameFr": "MALTE - Fisheries Control Directorate"
  },
  {
    "id": 30,
    "code": "MOZ",
    "nameEn": "MOZAMBIQUE - National Fisheries Administration",
    "nameFr": "MOZAMBIQUE - National Fisheries Administration"
  },
  {
    "id": 31,
    "code": "MUS",
    "nameEn": "MAURITIUS - Ministry of Ocean Economy, Marine Resources, Fisheries, Shipping and Outer Island",
    "nameFr": "MAURICE - Ministry of Ocean Economy, Marine Resources, Fisheries, Shipping and Outer Island"
  },
  {
    "id": 32,
    "code": "MYS",
    "nameEn": "MALAYSIA - Department of Fisheries",
    "nameFr": "MALAISIE - Department of Fisheries"
  },
  {
    "id": 33,
    "code": "NLD",
    "nameEn": "NETHERLANDS - Netherlands (EU)",
    "nameFr": "NETHERLANDS - Netherlands (EU)"
  },
  {
    "id": 34,
    "code": "OMN",
    "nameEn": "OMAN - Ministry of Agriculture and Fisheries",
    "nameFr": "OMAN - Ministry of Agriculture and Fisheries"
  },
  {
    "id": 35,
    "code": "PAK",
    "nameEn": "PAKISTAN - Ministry of Ports & Shipping",
    "nameFr": "PAKISTAN - Ministry of Ports & Shipping"
  },
  {
    "id": 36,
    "code": "PAN",
    "nameEn": "PANAMA - Autoridad de los Recursos Acu�ticos de Panam�",
    "nameFr": "PANAMA - Autoridad de los Recursos Acu�ticos de Panam�"
  },
  {
    "id": 37,
    "code": "PHL",
    "nameEn": "PHILIPPINES - Department of Agriculture, Bureau of Fisheries and Aquatic Resources",
    "nameFr": "PHILIPPINES - Department of Agriculture, Bureau of Fisheries and Aquatic Resources"
  },
  {
    "id": 38,
    "code": "PRT",
    "nameEn": "PORTUGAL - Dire��o-Geral De Recursos Naturais, Seguran�a e Servi�os Mar�timo",
    "nameFr": "PORTUGAL - Dire��o-Geral De Recursos Naturais, Seguran�a e Servi�os Mar�timo"
  },
  {
    "id": 39,
    "code": "ROU",
    "nameEn": "ROUMANIE - Ministry of Agriculture and Rural Development",
    "nameFr": "ROUMANIE - Ministry of Agriculture and Rural Development"
  },
  {
    "id": 40,
    "code": "SDN",
    "nameEn": "SUDAN - General Directorate of Fisheries and Aquarium",
    "nameFr": "SOUDAN - General Directorate of Fisheries and Aquarium"
  },
  {
    "id": 41,
    "code": "SEN",
    "nameEn": "SENEGAL - Minist�re de la P�che et de l'Economie Maritime, Direction des P�ches Maritimes",
    "nameFr": "SENEGAL - Minist�re de la P�che et de l'Economie Maritime, Direction des P�ches Maritimes"
  },
  {
    "id": 42,
    "code": "SGP",
    "nameEn": "SINGAPORE - Agri-Food and Veterinary Authority",
    "nameFr": "SINGAPOUR - Agri-Food and Veterinary Authority"
  },
  {
    "id": 43,
    "code": "SLE",
    "nameEn": "SIERRA LEONE - Sierra Leone",
    "nameFr": "SIERA LEONE - Sierra Leone"
  },
  {
    "id": 44,
    "code": "SOM",
    "nameEn": "SOMALIA - Ministry of Fisheries and Marine Resources",
    "nameFr": "SOMALIE - Ministry of Fisheries and Marine Resources"
  },
  {
    "id": 45,
    "code": "SYC",
    "nameEn": "SEYCHELLES - Seychelles Fishing Authority",
    "nameFr": "SEYCHELLES - Seychelles Fishing Authority"
  },
  {
    "id": 46,
    "code": "THA",
    "nameEn": "THAILAND - Department of Fisheries",
    "nameFr": "THAILAND - Department of Fisheries"
  },
  {
    "id": 47,
    "code": "TZA",
    "nameEn": "TANZANIA - The Deep Sea Fishing Authority",
    "nameFr": "TANZANIA - The Deep Sea Fishing Authority"
  },
  {
    "id": 48,
    "code": "VUT",
    "nameEn": "VANUATU - Fisheries Department",
    "nameFr": "VANUATU - Fisheries Department"
  },
  {
    "id": 49,
    "code": "YEM",
    "nameEn": "YEMEN - Yemen",
    "nameFr": "YEMEN - Yemen"
  },
  {
    "id": 50,
    "code": "ZAF",
    "nameEn": "SOUTH AFRICA - Department of Agriculture Forestry and Fisheries",
    "nameFr": "AFRIQUE DU SUD - Department of Agriculture Forestry and Fisheries"
  },
  {
    "id": 51,
    "code": "UNK",
    "nameEn": "UNK - Not Elsewhere identified",
    "nameFr": "UNK - Not Elsewhere identified"
  }
]