import { State } from 'apprise-frontend-core/state/api'
import { AxiosInstance } from "axios"
import MockAdapter from 'axios-mock-adapter/types'
import { createContext } from 'react'
import { ClientConfiguration, defaultClientConfiguration, Mockery } from "./model"


export const ClientContext = createContext<State<ClientState>>(undefined!)


export type ClientState = {

  
  impl: AxiosInstance

  // internal configuration: used when there's not configuration support, or until there is.
  config: ClientConfiguration

  mockeries: Mockery[]

  mocks: { 
    
    adapter: MockAdapter
    stores: Record<string, any>

  }

  

}


export const initialClientState: ClientState = {

  impl: undefined!,
  mockeries: [],
  config: defaultClientConfiguration,
  mocks: {

    adapter: undefined!,
    stores:{}
  }

}