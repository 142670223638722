import { Process, ProcessHandlers, TaskHandlers } from "./model"

export type ProcesState = {
    processes: Process[]
}

export type ProcessRegistryState = {
    processes:ProcessHandlers
    tasks:TaskHandlers
}

export const initialProces = () => ({
    processes: []
} as ProcesState)

export const initialProcessRegistry:ProcessRegistryState = {
    processes: {},
    tasks: {}
}

