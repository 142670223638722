import { IonButton, IonButtons, IonIcon, IonItemDivider, IonItemGroup, IonLabel, IonList } from "@ionic/react";
import { Item } from "components/Item";
import { YesNoSelect } from "components/Select/Segment";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { addOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { OnFinishType, PirAttachmentButton } from "pir/Form/Attachment/Button";
import { PirFormAttachments } from "pir/Form/Attachment/PirAttachmentPictures";
import { FormAttachment, PirEdting, statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { containsErrors, getStepErrors } from "pir/model/inspectionErrorApi";
import React, { useRef } from "react";
import { useReferencedata } from "referencedata/api";
import { move } from "utils";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    monitoredLanTrx,
    monitoredLanTrxAttachments
}

// const useValidation = () => {
//     const {t} = useIntl()
//     const validate:ValidateStep = (p) => {
//         if(!p.extended){
//             return {} as InspectionErrors<any>
//         }
//         const error:InspectionErrors<ValidationType> = {
//             monitoredLanTrx: check(p.extended.pir.monitoredLanTrx, t('inspection.monitoring.monitored.label')).isEmpty().errors,
//         }

//         return error
//     }
//     return validate
// }

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const documenttypesapi = useReferencedata()

    const ref = useRef(null)
    
    const pirsapi = usePirs()

    const edited = pir as PirEdting

    // const validate = useValidation()

    const set = pirsapi.setStep(key, edited)

    const fileType = documenttypesapi.documentType.current().find(d=>d.code==='MFR')?.id

    const onFinish:OnFinishType = async ({type, files, attachmentUid}) => {
        let attachment = await pirsapi.setFormAttachmentPictures(files, pir)
        
        await set((p=>{
            if(attachmentUid){
                const index = p.extended.pir.monitoredLanTrxAttachments.findIndex(a=>a.uid === attachmentUid)
                if(index >= 0){
                    (p.extended.pir.monitoredLanTrxAttachments[index] as FormAttachment).pictures = [...(p.extended.pir.monitoredLanTrxAttachments[index] as FormAttachment).pictures,  ...attachment.pictures]
                }
            }else{
                let newattachment = {...attachment}
                if(type && type > 0){
                    newattachment.type = type
                    newattachment.name = documenttypesapi.documentType.current().find(d=>d.id == type)?.nameEn || 'MFR'
                }
                p.extended.pir.monitoredLanTrxAttachments = [...p.extended.pir.monitoredLanTrxAttachments || [], ...[newattachment]]
            }
        }))
    }

    const onSetAttachment = (i, f:FormAttachment ) => set(p=>p.extended.pir.monitoredLanTrxAttachments[i] = f)
    const onRemoveAttachmentPicture = (attachmentIndex, pictureIndex) => set(p=>(p.extended.pir.monitoredLanTrxAttachments[attachmentIndex] as FormAttachment).pictures.splice(pictureIndex,1))
    const onRemoveAttachment = (i) => set(p=>p.extended.pir.monitoredLanTrxAttachments.splice(i,1))
    const onReorderAttachmentPictures = (i, from, to) => set(p=>move((p.extended.pir.monitoredLanTrxAttachments[i] as FormAttachment).pictures, from, to))

    const AttachmentButton = ({onClick, disabled}:{onClick, disabled?}) => 
    <IonButton disabled={disabled} shape="round" slot="end" size="small"
    onClick={onClick}>
        {t('inspection.monitoring.attachment.add')}
        <IonIcon icon={addOutline} />
    </IonButton>

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.monitoring.section.title')}>
            <StepDescription>{t('inspection.monitoring.section.subtitle')}</StepDescription>
        <IonList ref={ref}>
            
            <IonItemGroup>
                <Item disabled={readonly} mandatory
                description={t('inspection.monitoring.monitored.help')}>
                    <IonLabel>{t('inspection.monitoring.monitored.label')}</IonLabel>
                    <YesNoSelect value={pir.extended?.pir.monitoredLanTrx}
                    onChange={v=>set((p)=>p.extended.pir.monitoredLanTrx = v)}/>
                </Item>
                <Item disabled={readonly || !pir.extended?.pir.monitoredLanTrx} description={t('inspection.monitoring.attachment.help')}>
                     
                    <IonItemGroup>
                        <IonItemDivider>
                                <IonLabel>{t('inspection.monitoring.attachment.label')}</IonLabel>
                                
                                <IonButtons slot="end">
                                    <PirAttachmentButton disabled={readonly || !pir.extended?.pir.monitoredLanTrx} fileType={fileType} pir={edited} Button={AttachmentButton} onFinish={onFinish}/>
                                </IonButtons>

                            </IonItemDivider>
                            {
                                pir.extended?.pir.monitoredLanTrxAttachments &&
                                <PirFormAttachments
                                disabled={readonly || !pir.extended?.pir.monitoredLanTrx}
                                typeDisabled
                                onFinish={onFinish}
                                pir={pir}
                                attachments={pir.extended.pir.monitoredLanTrxAttachments}
                                wrapperRef={ref}
                                onSetAttachment={onSetAttachment}
                                onRemoveAttachmentPicture={onRemoveAttachmentPicture}
                                onRemoveAttachment={onRemoveAttachment}
                                onReorderAttachmentPictures={onReorderAttachmentPictures}
                                />
                            }
                    </IonItemGroup>
                
                </Item>
            </IonItemGroup>

        </IonList>

    </StepContent>
}
const key = "monitoring"

const Button = ({pir}:StepButtonProps) => {

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) =>{
    const {t} = useIntl()
    return StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.monitoring.section.title'),
        hasError: containsErrors(getStepErrors(key, pir as PirEdting))
    })}
export const Monitoring:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}