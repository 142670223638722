import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { Loading } from "components/Loading"
import { PDFViewer } from "components/PDF"
import { PirFileBase64, PirFileImageBase64 } from "file/model"
import { isImageContentType, isPDF } from "file/model/api"
import { downloadOutline } from "ionicons/icons"
import { usePirs } from "pir/api"
import { useRemoteFile } from "pir/Form/useRemoteFile"
import { Pir } from "pir/model"
import { pirmodelapi } from "pir/model/api"
import React from "react"
import { useParams } from "react-router-dom"

export const FileContent = ({file}:{file:PirFileBase64 | PirFileImageBase64}) => {

    if(isImageContentType(file.contentType)){
        return <img src={file.content}/>
    }

    if(isPDF(file.contentType)){
       return <PDFViewer data={file.content} />
    }

    return null
}
export const VesselFile = () => {

    console.log('in file')

    let { id, fileId } = useParams<{ id, fileId }>();

    const pirapi = usePirs()

    const pir = pirapi.find(id)

    const remotefile = pir && pirmodelapi(pir).findFile(fileId)

    const file = useRemoteFile(pir as Pir, remotefile) 

    const fileName = remotefile && (remotefile.name || remotefile.tagName || remotefile.id+'')

    return <IonPage className="vessel-page">
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/" />
                </IonButtons>

                {
                    remotefile ? <IonTitle>{fileName}</IonTitle>
                    : <IonTitle>Loading...</IonTitle>
                }
                
                <IonButtons slot="secondary">
                    <IonButton disabled={!file} href={file && file.content || ''} download={fileName || 'file.pdf'}>
                        Download <IonIcon icon={downloadOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <Loading spinning={file === undefined} duration={100000} msg={'loading document'}>
                {file && <FileContent file={file}/>}
            </Loading>
        </IonContent>
    </IonPage>

}