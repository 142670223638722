import { IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react"
import { AppStatus } from "components/AppStatus/Index"
import { UserIcon } from "components/Usericon"
import { home, homeOutline, createOutline, calendar, calendarOutline, create } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { Calendar } from "pages/calendar"
import { PirPage } from "pages/pir"
import { ReportsPage } from "pages/reports"
import { usePirs } from "pir/api"
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom"

export const Shell: React.FC = () => {
    const { t } = useIntl()
  
    const pirsapi = usePirs()
  
    const isSelectedPirPath = useRouteMatch({path:"/pir", exact:false})
  
    return <div className="tabs-wrapper">
      <div className="tabs-content">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/reports" />} />
          <Route path="/reports" component={ReportsPage}/>
          <Route path="/pir" component={PirPage}/>
          <Route path="/calendar" exact component={Calendar}/>
        </Switch>
      </div>
  
      <IonTabBar className="main-tab-bar" slot="bottom">
          
          <IonTabButton className="side-tab tab-status">
            <AppStatus/>
          </IonTabButton>
  
          <IonTabButton tab="reports" href="/reports">
            <IonIcon icon={useRouteMatch("/reports") ? home : homeOutline} />
            <IonLabel>{t('app.navigation.reports')}</IonLabel>
          </IonTabButton>
  
          <IonTabButton selected={isSelectedPirPath ? true : false} tab="pir" href={`/pir/${pirsapi.lastOpenid() || 'pick-one'}`}>
            <IonIcon icon={isSelectedPirPath ? create : createOutline} />
            <IonLabel>{t('app.navigation.pir')}</IonLabel>
          </IonTabButton>
  
          <IonTabButton tab="calendar" href="/calendar">
            <IonIcon icon={useRouteMatch("/calendar") ? calendar : calendarOutline} />
            <IonLabel>{t('app.navigation.calendar')}</IonLabel>
          </IonTabButton>
          
          <IonTabButton className="side-tab tab-user">
            <UserIcon />
          </IonTabButton>
  
      </IonTabBar>
    </div>
}