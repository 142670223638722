import { Pir } from "pir/model"
import React from "react"
import { AddPhotoStepButton } from "./AddPhotoStepButton"
import { CompleteStepButton } from "./CompleteStepButton"

type Props = {
    type?
    noPhoto?
    pir:Pir
    stepkey
    children?
    noCompleteStepButton?
    isValid?
}
export const FormStepButton = ({type, noPhoto,pir, stepkey, children, noCompleteStepButton, isValid}:Props) => {
    return <div className="custom-fab-list form-step-button">
        {!noPhoto && <AddPhotoStepButton type={type} pir={pir}/>}
        {!noCompleteStepButton && <CompleteStepButton pir={pir} stepkey={stepkey} isValid={isValid}/>}
        {children}
    </div>
}