import { alertController } from "@ionic/core"
import { getToken, setToken } from "api"
import { Mutator } from "apprise-frontend-core/state/api"
import { bridge } from "bridge"
import copyAndApplyChanges from "immer"
import { initialComponentBridge } from "lib/apprise-frontend-core/utils/bridge"
import { useBusyState } from "lib/apprise-frontend-core/utils/busyguard"
import { useContext } from "react"
import { clearAllData } from "utils"
import Worker from 'worker'
import { useUserCalls } from "./calls"
import { Login, User } from "./model"
import { usermodelapi } from "./model/api"
import { UserContext } from "./provider"
import { UserState } from "./state"

const instance = new Worker();


const showUpdateUser = async (current:User, confirm, cancel) => {
    const alert = await alertController.create({
        header: `You are trying to login with a different user from the current one: ${current.name}`,
        message: `Are you sure you want to proceed? All the data recorded by "${current.name}" will be deleted`,
        buttons: [
          {
            text: `Cancel`,
            role: 'cancel',
            handler: async () => await cancel()
          },
          {
            text: 'Confirm and delete all the data',
            handler: async () => await confirm()
          }]
    });
    alert.present()
}

export const useUser = () => {

    const state = useContext(UserContext)

    if (!state)
        throw new Error("user module is not mounted or activated.")
    
    const busy = useBusyState()
    const call = useUserCalls()

    const stateset = async (_:Mutator<UserState>) => {
        state.set(_)
        const user = state.get().user
        user && instance.user.put(user)
    }

    const self = {

        init: async () => {
            const user = await instance.user.get()
            self.set(user);
        }

        ,

        export: async () => {
            const {token , ...user} = await instance.user.get() as User
            return user
        }

        ,

        restore: async (u:User) => {
            instance.user.put(u)
            self.set({...u, loggedIn:false});
        }
        
        ,

        login: async (login:Login) => {
            const current = self.current()
            const currentDeviceName = current?.deviceName
            return busy.toggle('user', 'Logging in')
                .then(()=>call.login({...login, deviceName:currentDeviceName}))
                .then(async ({user:another, token})=>{
                    const current = self.current()
                    setToken(token)
                    if(current && !usermodelapi(current).is(another)){
                        await showUpdateUser(current, clearAllData, self.logout)
                    }else{
                        return self.set({...another, token, loggedIn:true, inspector:current?.inspector || {}})
                    }
                })
                .catch(e=>{
                    bridge && bridge.renderError && bridge.renderError(e as any)
                })
                .finally(()=>busy.toggle('user'))
            }
        ,

        logout: async () => {
            const current = self.current()
            current && await self.set({...current, loggedIn:false})
        }
        ,

        current: () => state.get().user,

        isLoggedIn: async () => {
            if(!navigator.onLine){
                return true
            }
            const user = self.current()
            const haveLoginInfo = user && usermodelapi(user).isLoggedIn()
            const haveToken = getToken()
            if(haveLoginInfo && haveToken){
                return true
            }
            if(haveLoginInfo && !haveToken){
                const newToken = await self.refreshToken()
                return newToken ? true : false
            }
            return false
        }

        ,

        refreshToken: async () => {
            try {
                const {token} = await call.refreshToken()
                setToken(token)
            } catch (error) {
                setToken(null)
            } finally {
                return getToken()
            }
        }

        ,
        
        set: (user: User | null) => stateset(s => s.user = user),
        
        
        mutate: (_m:Mutator<User>) => {
            return stateset(s=>{
                s.user = copyAndApplyChanges(s.user, (s: any) => void _m(s))
            })
        }
        ,

        reset: () => stateset(s => s.user = null)
    }

    return self

}