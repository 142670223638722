import { IonItemGroup, IonLabel, IonList, IonTextarea } from "@ionic/react";
import { Item } from "components/Item";
import { YesNoSelect } from "components/Select/Segment";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { InspectionErrors, PirEdting, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, containsErrors, getStepErrors } from "pir/model/inspectionErrorApi";
import React from "react";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    hasInfringements
}

const useValidation = () => {
    const {t} = useIntl()

    const validate:ValidateStep = (p) => {
        if(!p.extended){
            return {} as InspectionErrors<any>
        }
        const error:InspectionErrors<ValidationType> = {
            hasInfringements: check(p.extended.pir.hasInfringements, t('inspection.infringements.infringements.label')).isEmpty().errors,
        }

        return error
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const validate = useValidation()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const set = pirsapi.setStep(key, edited, validate)

    const error:InspectionErrors<ValidationType> = getStepErrors(key, edited) || validate(edited)

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.infringements.section.title')}>
        <StepDescription>{t('inspection.infringements.section.subtitle')}</StepDescription>
        <IonList>
            
            <IonItemGroup>
                <Item
                    description={t('inspection.infringements.infringements.help')}
                    disabled={readonly} mandatory error={error?.hasInfringements}>
                    <IonLabel>{t('inspection.infringements.infringements.label')}</IonLabel>
                    <YesNoSelect value={pir.extended?.pir.hasInfringements}
                    onChange={v=>set((p)=>p.extended.pir.hasInfringements = v)}/>
                </Item>
            </IonItemGroup>

            <IonItemGroup>
                <Item lines="none" disabled={readonly || !pir.extended?.pir.hasInfringements}>
                    <IonLabel>{t('inspection.infringements.infringementsNoted.label')}</IonLabel>
                </Item>
                <Item disabled={readonly || !pir.extended?.pir.hasInfringements}
                description={t('inspection.infringements.infringementsNoted.help')}>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value !== undefined && set((p)=>p.extended.pir.infrigements = e.detail.value!)}
                    value={pir.extended?.pir.infrigements}/>
                </Item>
            </IonItemGroup>
            
            <IonItemGroup>
                <Item lines="none" disabled={readonly || !pir.extended?.pir.hasInfringements}>
                    <IonLabel>{t('inspection.infringements.actionsTaken.label')}</IonLabel>
                </Item>
                <Item disabled={readonly || !pir.extended?.pir.hasInfringements}
                description={t('inspection.infringements.actionsTaken.help')}>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value !== undefined && set((p)=>p.extended.pir.actionsTaken = e.detail.value!)}
                    value={pir.extended?.pir.actionsTaken}/>
                </Item>
            </IonItemGroup>
            
        </IonList>
    </StepContent>
}
const key = "infringement"

const Button = ({pir}:StepButtonProps) => {

    const validate = useValidation()

    const isValid=!containsErrors(getStepErrors(key, pir as PirEdting) || validate(pir as PirEdting))

    return <FormStepButton stepkey={key} pir={pir} isValid={isValid}/>
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    const {t} = useIntl()
    const validate = useValidation()
    return StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.infringements.section.linkTitle'),
        hasError: containsErrors(getStepErrors(key, pir as PirEdting) || validate(pir as PirEdting))
    })
}

export const Infringement:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}