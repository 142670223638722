import { Select } from 'components/Select'
import { DesignatedPort } from 'designatedPort/model'
import React from 'react'
import { useReferencedata } from 'referencedata/api'
import { useReferencedataOptions } from 'referencedata/referencedata'

type Props = {
    ids?: number[],
    onChange,
    multiple?
    availableDesignatedPorts?: number[],
    placeholder?: string,
    availablePorts?: number[]
}
export const DesignatedPortSelect = ({ids, onChange, multiple, availableDesignatedPorts, placeholder, availablePorts}:Props) => {
    
    const referencedataapi = useReferencedata()
    const ports = referencedataapi.current().designatedPort

    //use ports instead of filtered ports to eventually add that item to the list
    const portValue = ids && ports.filter(f=>ids.includes(f.id))
    
    let filteredPorts:DesignatedPort[] = availablePorts && availablePorts.length > 0 ? 
        ports.filter(p=>availablePorts.includes(p.id))
    : ports 

    if(availablePorts && availablePorts.length > 0 && portValue){
        portValue.forEach(v=>{
            if(!availablePorts.includes(v.id)){
                filteredPorts.push(v)
            }
        })
    }

    return <Select title={multiple ? "Select one or more designated ports" : "Select one designated ports"}
    placeholder={placeholder || (multiple ? "Select one or more designated ports" : "Select one designated port")}
    multiple={multiple}
    value={(portValue || []).map(p=>p.id)}
    onChange={v=>onChange(v)}
    options={useReferencedataOptions({data:filteredPorts, showCode:true})}/>
}