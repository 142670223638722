import { ClientState } from "apprise-frontend-core/client/state";
import { State } from "apprise-frontend-core/state/api";
import { utils } from "apprise-frontend-core/utils/common";
import MockAdapter from "axios-mock-adapter/types";
import { referencedataTypes } from "referencedata/model";
import { species } from "common/mocks/species";


export const mockSpecies = (mock:MockAdapter) => {
        // mock calls
        mock.onGet(`/${referencedataTypes.species}`).reply((config)=>{
                return Promise.resolve([200,species]).then(utils().wait(500))
        })

}