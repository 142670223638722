import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItemGroup, IonLabel, IonList, IonModal, IonRange, IonSelect, IonSelectOption, IonText, IonTitle, IonToggle, IonToolbar } from "@ionic/react"
import { Item } from "components/Item"
import { checkmarkOutline, close } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import { PirSelect } from "pir/Select"
import { usePirs } from "pir/api"
import { Pir, statuses } from "pir/model"
import { pirmodelapi, pirsModelApi } from "pir/model/api"
import React from "react"
import { useState } from "state/api"
import { useUser } from "user/api"

type Props = {
    show:boolean,
    onClose:() => void,
}

type ExportOptions = {
    pir:Pir,
    singlePirOnly?:boolean,
    imageQuality?:number,
    maxSize?:number,
}

export const DownloadTheDataModal = ({show, onClose:_onClose}:Props) => {

    const userapi = useUser()

    const user = userapi.current()

    const stateApi = useState()

    const pirsapi = usePirs()

    const [options, setOptions] = React.useState<ExportOptions>({pir:undefined!, singlePirOnly:false, imageQuality:30, maxSize:3000})

    const onClose = (name?) => {
        if(name && user){
            userapi.mutate(u=>u.inspector.name = name).then(()=>_onClose())
        }
    }

    const exportData = (e) => {
        stateApi.export(e, options.singlePirOnly ? [options.pir] : undefined, options.imageQuality, options.maxSize)
    }

    const {t} = useIntl()
    return <IonModal mode="ios" swipeToClose
    isOpen={show}
    onDidDismiss={()=>_onClose()}
    cssClass='my-custom-class'>
    <IonHeader>
        <IonToolbar>
            <IonTitle>{t('utils.downloadData.modal.title')}</IonTitle>
            <IonButtons slot="end">
                <IonButton onClick={()=>_onClose()}>
                    <IonIcon slot="icon-only" icon={close}/>
                </IonButton>
            </IonButtons>
        </IonToolbar>
    </IonHeader>
    <IonContent fullscreen className="">
        <IonList>
            <IonItemGroup>
            <Item>
                <IonLabel><strong>{t('utils.downloadData.modal.singlePirOnly')}</strong></IonLabel>
                <IonToggle
                        color={options.singlePirOnly ? 'success' : undefined}
                        checked={options.singlePirOnly ? true : false}
                        onIonChange={e=>e.detail.checked !== undefined && setOptions({...options, singlePirOnly:e.detail.checked, pir:!e.detail.checked?undefined!:options.pir})}/>
            </Item>
            <Item disabled={!options.singlePirOnly}>
                <IonLabel ><strong>{t('utils.downloadData.modal.pir')}</strong></IonLabel>
                <PirSelect onChange={(pir)=>setOptions({...options, pir})} value={options.pir} pirs={pirsModelApi(pirsapi.all()).haveStatusMoreThan(statuses.notready).pirs}/>
            </Item>
            <Item>
                <IonLabel style={{minWidth:'200px'}}><strong>{t('utils.downloadData.modal.imageQuality')}</strong></IonLabel>
                <IonRange min={1} onIonChange={(e)=>setOptions({...options, imageQuality:(e.detail as any).value})} value={options.imageQuality}/>
                <span>{options.imageQuality} %</span>
            </Item>
            <Item>
                <IonLabel style={{minWidth:'200px'}}><strong>{t('utils.downloadData.modal.maxSize')}</strong></IonLabel>
                <IonRange min={500} max={3000} onIonChange={(e)=>setOptions({...options, maxSize:(e.detail as any).value})} value={options.maxSize}/>
                <span>{options.maxSize} px</span>
            </Item>
        </IonItemGroup>
        </IonList>
    </IonContent>

    <IonFooter className="ion-no-border">
        <IonToolbar mode="md">
            <IonButtons slot="end">
                <IonButton onClick={()=>_onClose()}>{t('utils.downloadData.modal.cancel')}</IonButton>
                <IonButton color="primary" onClick={(e)=>exportData(e)}>{t('utils.downloadData.modal.export')} <IonIcon icon={checkmarkOutline}/></IonButton>
            </IonButtons>
        </IonToolbar>
    </IonFooter>
    
</IonModal>
}