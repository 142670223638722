import { IonItemDivider, IonItemGroup, IonLabel, IonList, IonTextarea } from "@ionic/react";
import { Item } from "components/Item";
import { YesNoSelect } from "components/Select/Segment";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { GearsSelect } from "gear/Select";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { InspectionErrors, PirEdting, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, containsErrors, getStepErrors } from "pir/model/inspectionErrorApi";
import React from "react";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    logBookExaminationStatus,
    catchDocumentationSchemeComplianceStatus,
    tradeInformationSchemeComplianceStatus
    gearExaminationStatus
}

const useValidation = () => {
    const {t} = useIntl()

    const validate:ValidateStep = (p) => {
        if(!p.extended){
            return {} as InspectionErrors<any>
        }
        const error:InspectionErrors<ValidationType> = {
            logBookExaminationStatus: check(p.extended.pir.logBookExamination?.status, t('inspection.examinations.logbook.label')).isEmpty().errors,
            catchDocumentationSchemeComplianceStatus: check(p.extended.pir.catchDocumentationSchemeCompliance?.status, t('inspection.examinations.catchCompliance.label')).isEmpty().errors,
            tradeInformationSchemeComplianceStatus: check(p.extended.pir.tradeInformationSchemeCompliance?.status, t('inspection.examinations.tradeCompliance.label')).isEmpty().errors,
            gearExaminationStatus: check(p.extended.pir.gearExamination?.status, t('inspection.examinations.examinedGears.label')).isEmpty().errors,
        }

        return error
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const validate = useValidation()

    const set = pirsapi.setStep(key, edited, validate)

    const error:InspectionErrors<ValidationType> = getStepErrors(key, edited) || validate(edited)

    return <StepContent isForm={true}
        isDefault
        title={ t('inspection.examinations.section.title')}>
            <StepDescription>{t('inspection.examinations.section.subtitle')}</StepDescription>
        <IonList>
            
            <IonItemGroup key="logbook">
                <Item disabled={readonly} mandatory error={error.logBookExaminationStatus}
                description={t('inspection.examinations.logbook.help')}>
                    <IonLabel>{t('inspection.examinations.logbook.label')}</IonLabel>
                    <YesNoSelect
                    value={pir.extended?.pir.logBookExamination?.status}
                    onChange={v=>set((p)=>p.extended.pir.logBookExamination = {...p.extended.pir.logBookExamination, status:v})}/>
                </Item>
                <Item disabled={readonly}>
                    <IonLabel position="stacked">{t('inspection.examinations.comments')}</IonLabel>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value && set((p)=>p.extended.pir.logBookExamination = {...p.extended.pir.logBookExamination, comments:e.detail.value!})}
                    value={pir.extended?.pir.logBookExamination?.comments}/>
                </Item>
            </IonItemGroup>

            <IonItemGroup key="catchCompliance">
                <Item disabled={readonly} mandatory error={error.catchDocumentationSchemeComplianceStatus}
                description={t('inspection.examinations.catchCompliance.help')}>
                    <IonLabel>{t('inspection.examinations.catchCompliance.label')}</IonLabel>
                    <YesNoSelect
                    value={pir.extended?.pir.catchDocumentationSchemeCompliance?.status}
                    onChange={v=>set((p)=>p.extended.pir.catchDocumentationSchemeCompliance = {...p.extended.pir.catchDocumentationSchemeCompliance, status:v})}/>
                </Item>
                <Item disabled={readonly}>
                    <IonLabel position="stacked">{t('inspection.examinations.comments')}</IonLabel>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value && set((p)=>p.extended.pir.catchDocumentationSchemeCompliance = {...p.extended.pir.catchDocumentationSchemeCompliance, comments:e.detail.value!})}
                    value={pir.extended?.pir.catchDocumentationSchemeCompliance?.comments}/>
                </Item>
            </IonItemGroup>

            <IonItemGroup key="tradeCompliance">
                <Item disabled={readonly} mandatory error={error.tradeInformationSchemeComplianceStatus}
                description={t('inspection.examinations.tradeCompliance.help')}>
                    <IonLabel>{t('inspection.examinations.tradeCompliance.label')}</IonLabel>
                    <YesNoSelect
                    value={pir.extended?.pir.tradeInformationSchemeCompliance?.status}
                    onChange={v=>set((p)=>p.extended.pir.tradeInformationSchemeCompliance = {...p.extended.pir.tradeInformationSchemeCompliance, status:v})}/>
                </Item>
                <Item disabled={readonly}>
                    <IonLabel position="stacked">{t('inspection.examinations.comments')}</IonLabel>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value && set((p)=>p.extended.pir.tradeInformationSchemeCompliance = {...p.extended.pir.tradeInformationSchemeCompliance, comments:e.detail.value!})}
                    value={pir.extended?.pir.tradeInformationSchemeCompliance?.comments}/>
                </Item>
            </IonItemGroup>


            <IonItemGroup key="gearsType">
                <Item disabled={readonly}>
                    <IonLabel>{t('inspection.examinations.gearsType.label')}</IonLabel>
                    <GearsSelect
                    placeholder={t('inspection.examinations.gearsType.help')}
                    onChange={(v)=>set((p)=>p.extended.pir.gearType = v )}
                    ids={pir.extended?.pir.gearType}/>
                </Item>
                <Item disabled={readonly} mandatory error={error.gearExaminationStatus}
                description={t('inspection.examinations.examinedGears.help')}>
                    <IonLabel>{t('inspection.examinations.examinedGears.label')}</IonLabel>
                    <YesNoSelect
                    value={pir.extended?.pir.gearExamination?.status}
                    onChange={v=>set((p)=>p.extended.pir.gearExamination = {...p.extended.pir.gearExamination, status:v})}/>
                </Item>
                <Item disabled={readonly}>
                    <IonLabel position="stacked">{t('inspection.examinations.comments')}</IonLabel>
                    <IonTextarea autoGrow
                    onIonChange={e=>e.detail.value && set((p)=>p.extended.pir.gearExamination = {...p.extended.pir.gearExamination, comments:e.detail.value!})}
                    value={pir.extended?.pir.gearExamination?.comments}/>
                </Item>
            </IonItemGroup>

            
        </IonList>
    </StepContent>
}
const key = "examinations"

const Button = ({pir}:StepButtonProps) => {

    const validate = useValidation()

    const isValid=!containsErrors(getStepErrors(key, pir as PirEdting) || validate(pir as PirEdting))

    return <FormStepButton stepkey={key} pir={pir} isValid={isValid}/>
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    const {t} = useIntl()
    const validate = useValidation()
    return <StepLink
        completed={pir.inspectionstatus && pir.inspectionstatus[key] ? true : false}
        isDefault={isDefault}
        disabled={pirmodelapi(pir).hasStatusLessThan(statuses.inspecting)}
        first={first}
        last={last}
        path={getPath(key)}
        isForm
        title={t('inspection.examinations.section.title')}
        hasError={containsErrors(getStepErrors(key, pir as PirEdting) || validate(pir as PirEdting))}
    />
}

export const Examinations:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}