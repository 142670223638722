import { Select } from 'components/Select'
import React from 'react'
import { useReferencedata } from 'referencedata/api'

type Props = {
    value: number | string | null,
    useCode?
    onChange,
    placeholder?
    title?
    exclude?: string[] | number[]
}
export const RfmosSelect = ({value, useCode, onChange, placeholder, title, exclude}:Props) => {
    
    const referencedataapi = useReferencedata()
    const rfmos = referencedataapi.current().rfmo

    const _value = rfmos.find(f=>useCode ? (f.code === value) : (f.id === value))
    const rfmosFiltered = [...((_value && exclude) ? [_value] : []), ...(exclude ? rfmos.filter(r=>useCode ? (!(exclude as string[]).includes(r.code)) : (!(exclude as number[]).includes(r.id))) : rfmos)]
        
    return <Select title={title || placeholder }
    placeholder={placeholder}
    value={_value ? [useCode ? _value.code : _value.id] : []}
    onChange={v=>onChange(v[0])}
    options={rfmosFiltered.map(f=>({value:useCode ? f.code : f.id, label:`${f.code}`}))}/>
}