import { IonInput, IonItemDivider, IonItemGroup, IonLabel, IonList } from "@ionic/react";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { FlagsSelect } from "flag/Select";
import { GearsSelect } from "gear/Select";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { usePirs } from "pir/api";
import { InspectionErrors, PirEdting, PirExtendedVesselRemote, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, hasNDigit, isDigit, isEmpty, isNE } from "pir/model/inspectionErrorApi";
import { PortsTypeAhead } from "port/TypeAhead";
import React, { useState } from "react";
import { RfmosSelect } from "rfmo/Select";
import { VesseltypesSelect } from "vesseltype/Select";
import { VmsOnBoardSelect, VmssSelect } from "vms/Select";
import { FormStepButton } from "./FormStepButton";

const isImoid = (v) => {
    const value = v+''
    //http://tarkistusmerkit.teppovuori.fi/coden.htm
    const end = 2
    let factor = 7
    let index = -1
    let sum = 0
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    value.split('').forEach(n=>{
        if(factor<end){
            index = +n
        }else{
            sum += (+n)*factor
            factor--
        }
    })

    const toCompare = sum.toString().split('').pop()

    return (toCompare && index > 0) ? +toCompare === index : false
}

const useValidation = () =>{
    const {t} = useIntl()
    const validate:ValidateStep = (p) => {
        const errors:InspectionErrors<PirExtendedVesselRemote> = {
            name: check(p.extended.vessel.name, t('inspection.vesselIdentity.vesselName.label')).isEmpty().errors,
            flagStateId: check(p.extended.vessel.flagStateId, t('inspection.vesselIdentity.vesselFlag.label')).isEmpty().errors,
            typeId: check(p.extended.vessel.typeId, t('inspection.vesselIdentity.vesselType.label')).isEmpty().errors,
            
            mmsi: check(p.extended.vessel.mmsi, t('inspection.vesselIdentity.mmsi.label'))
            .custom(
                ()=>!isEmpty(p.extended.vessel.mmsi) && (!isDigit(p.extended.vessel.mmsi) || !hasNDigit(p.extended.vessel.mmsi, 9))
                , t('inspection.vesselIdentity.mmsi.error')
            ).errors

            ,

            imoId: check(p.extended.vessel.imoId, t('inspection.vesselIdentity.imoId.label'))
            .custom(
                ()=>!isEmpty(p.extended.vessel.imoId) && !isDigit(p.extended.vessel.imoId) && !isNE(p.extended.vessel.imoId)
                , t('inspection.vesselIdentity.imoId.error')
            )
            .custom(
                ()=>!isEmpty(p.extended.vessel.imoId) && !isNE(p.extended.vessel.imoId) && (!isDigit(p.extended.vessel.imoId) || !hasNDigit(p.extended.vessel.imoId, 7))
                , t('inspection.vesselIdentity.imoId.error')
            ).custom(
                ()=>!isEmpty(p.extended.vessel.imoId) && isNE(p.extended.vessel.imoId) && (isDigit(p.extended.vessel.imoId) && hasNDigit(p.extended.vessel.imoId, 7) && !isImoid(p.extended.vessel.imoId))
                , t('inspection.vesselIdentity.imoId.errorImo')
            )
            .errors
            
            ,

            additionalRfmoIdCode: p.extended.vessel.additionalRfmoIdEntity ? check(p.extended.vessel.additionalRfmoIdCode, t('inspection.vesselIdentity.rfmo.label')).isEmpty().errors : [],
            beam: check(p.extended.vessel.dimension.beam, t('inspection.vesselIdentity.beam.label')).isNotPositiveNumber().errors,
            draft: check(p.extended.vessel.dimension.draft, t('inspection.vesselIdentity.draft.label')).isNotPositiveNumber().errors,
            totalVolumeOfFishHolds: check(p.extended.vessel.totalVolumeOfFishHolds, t('inspection.vesselIdentity.totalVolumeOfFishHolds.label')).isNotPositiveNumber().errors,
            length: check(p.extended.vessel.dimension.length, t('inspection.vesselIdentity.length.label')).isNotPositiveNumber().errors,

            inmarsat: check(p.extended.vessel.inmarsat, t('inspection.vesselIdentity.inmarsat.label'))
            .custom(
                ()=>!isEmpty(p.extended.vessel.inmarsat) && (!isDigit(p.extended.vessel.inmarsat) || !hasNDigit(p.extended.vessel.inmarsat, 9))
                , t('inspection.vesselIdentity.inmarsat.error')
            ).errors
            
        }

        return errors
    }
    return validate
}

const NumericInput = ({value, placeholder, onIonChange}) => {
    const getValue = (e) => {
        if(e.detail.value == "" || e.detail.value == null || e.detail.value === undefined){
            return null
        }else{
            return +(e.detail.value!)
        }
    }
    return <IonInput placeholder={placeholder}
                    type="number" value={value}
                    onIonChange={e=>onIonChange(getValue(e))}></IonInput>
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const validate = useValidation()

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const errors:InspectionErrors<PirExtendedVesselRemote> | undefined = getStepErrors(key, edited)
    
    const set = pirsapi.setStep(key, edited, validate)

    const [vms, setvms] = useState('yes-rmfo')
    
    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    return <StepContent isForm={true} isDefault title={t('inspection.vesselIdentity.section.title')}>
        <StepDescription>{t('inspection.vesselIdentity.section.subtitle')}</StepDescription>
        <IonList>
            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('inspection.vesselIdentity.section.nameAndType')}</IonLabel>
                </IonItemDivider>
                <Item description={t('inspection.vesselIdentity.vesselName.help')} mandatory error={errors?.name} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.vesselName.label')}</IonLabel>
                    <IonInput placeholder={t('inspection.vesselIdentity.vesselName.placeholder')} value={edited.extended.vessel.name} onIonChange={(e)=>set((p)=>p.extended.vessel.name = (e.detail.value!))}></IonInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.vesselFlag.help')} mandatory error={errors?.flagStateId}  disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.vesselFlag.label')}</IonLabel>
                    <FlagsSelect id={edited.extended.vessel.flagStateId} placeholder={t('inspection.vesselIdentity.vesselFlag.placeholder')} onChange={(v)=>set((p)=>p.extended.vessel.flagStateId = v )}/>
                </Item>
                <Item description={t('inspection.vesselIdentity.vesselType.help')} mandatory error={errors?.typeId}  disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.vesselType.label')}</IonLabel>
                    <VesseltypesSelect id={edited.extended.vessel.typeId} placeholder={t('inspection.vesselIdentity.vesselType.placeholder')} onChange={(v)=>set((p)=>p.extended.vessel.typeId = v )}/>
                </Item>
                <Item description={t('inspection.vesselIdentity.gearType.help')} error={errors?.gearIds}  disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.gearType.label')}</IonLabel>
                    <GearsSelect
                    placeholder={t('inspection.vesselIdentity.gearType.placeholder')}
                    ids={edited.extended.vessel.gearIds}
                    onChange={(v)=>set((p)=>p.extended.vessel.gearIds = v )}/>
                </Item>
            </IonItemGroup>
            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('inspection.vesselIdentity.section.identificationNumbers')}</IonLabel>
                </IonItemDivider>
                <Item description={t('inspection.vesselIdentity.iotc.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.iotc.label')}</IonLabel>
                    <IonInput placeholder={t('inspection.vesselIdentity.iotc.placeholder')} value={edited.extended.vessel.iotcId} onIonChange={e=>set((p)=>p.extended.vessel.iotcId = (e.detail.value!))}></IonInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.ircs.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.ircs.label')}</IonLabel>
                    <IonInput placeholder={t('inspection.vesselIdentity.ircs.placeholder')} value={edited.extended.vessel.ircs} onIonChange={e=>set((p)=>p.extended.vessel.ircs = (e.detail.value!))}></IonInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.registrationPort.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.registrationPort.label')}</IonLabel>
                    <PortsTypeAhead
                    placeholder={t('inspection.vesselIdentity.registrationPort.placeholder')}
                    title={t('inspection.vesselIdentity.registrationPort.label')}
                    value={edited.extended.vessel.registrationPort}
                    onChange={(v)=>set((p)=>p.extended.vessel.registrationPort = v )}/>
                    
                </Item>
                <Item description={t('inspection.vesselIdentity.mmsi.help')} mandatory error={errors?.mmsi} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.mmsi.label')}</IonLabel>
                    <IonInput placeholder={t('inspection.vesselIdentity.mmsi.placeholder')} value={edited.extended.vessel.mmsi} onIonChange={e=>set((p)=>p.extended.vessel.mmsi = (e.detail.value!))}></IonInput>
                </Item>
                <Item  description={t('inspection.vesselIdentity.externalId.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.externalId.label')}</IonLabel>
                    <IonInput placeholder={t('inspection.vesselIdentity.externalId.placeholder')} value={edited.extended.vessel.externalId} onIonChange={e=>set((p)=>p.extended.vessel.externalId = (e.detail.value!))}></IonInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.certificateRegistryId.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.certificateRegistryId.label')}</IonLabel>
                    <IonInput placeholder={t('inspection.vesselIdentity.certificateRegistryId.placeholder')} value={edited.extended.vessel.certificateRegistryId} onIonChange={e=>set((p)=>p.extended.vessel.certificateRegistryId = (e.detail.value!))}></IonInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.imoId.help')} error={errors?.imoId} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.imoId.label')}</IonLabel>
                    <IonInput placeholder={t('inspection.vesselIdentity.imoId.placeholder')} value={edited.extended.vessel.imoId} onIonChange={e=>set((p)=>p.extended.vessel.imoId = (e.detail.value!))}></IonInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.otherRfmo.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.otherRfmo.label')}</IonLabel>
                    <RfmosSelect
                    placeholder={t('inspection.vesselIdentity.otherRfmo.placeholder')}
                    value={edited.extended.vessel.additionalRfmoIdEntity}
                    onChange={(v)=>set((p)=>p.extended.vessel.additionalRfmoIdEntity = v )}/>
                </Item>
                <Item description={t('inspection.vesselIdentity.rfmo.help')} error={errors?.additionalRfmoIdCode} mandatory={edited.extended.vessel.additionalRfmoIdEntity} disabled={readonly || (edited.extended.vessel.additionalRfmoIdEntity ? false : true)}>
                    <IonLabel>{t('inspection.vesselIdentity.rfmo.label')}</IonLabel>
                    <IonInput style={{flex:4}} placeholder={t('inspection.vesselIdentity.rfmo.placeholder')} value={edited.extended.vessel.additionalRfmoIdCode} onIonChange={e=>set((p)=>p.extended.vessel.additionalRfmoIdCode = (e.detail.value!))}></IonInput>
                </Item>
            </IonItemGroup>

            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('inspection.vesselIdentity.section.dimensions')}</IonLabel>
                </IonItemDivider>
                <Item description={t('inspection.vesselIdentity.length.help')} error={errors?.length} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.length.label')}</IonLabel>
                    <NumericInput placeholder={t('inspection.vesselIdentity.length.placeholder')} value={edited.extended.vessel.dimension.length} onIonChange={v=>set((p)=>p.extended.vessel.dimension.length = v)}></NumericInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.beam.help')} error={errors?.beam} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.beam.label')}</IonLabel>
                    <NumericInput placeholder={t('inspection.vesselIdentity.beam.placeholder')} value={edited.extended.vessel.dimension.beam} onIonChange={v=>set((p)=>p.extended.vessel.dimension.beam = v)}></NumericInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.draft.help')} error={errors?.draft} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.draft.label')}</IonLabel>
                    <NumericInput placeholder={t('inspection.vesselIdentity.draft.placeholder')} value={edited.extended.vessel.dimension.draft} onIonChange={v=>set((p)=>p.extended.vessel.dimension.draft = v)}></NumericInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.totalVolumeOfFishHolds.help')} error={errors?.totalVolumeOfFishHolds} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.totalVolumeOfFishHolds.label')}</IonLabel>
                    <NumericInput placeholder={t('inspection.vesselIdentity.totalVolumeOfFishHolds.placeholder')}
                    value={edited.extended.vessel.totalVolumeOfFishHolds} onIonChange={v=>set((p)=>p.extended.vessel.totalVolumeOfFishHolds = v)}></NumericInput>
                </Item>
            </IonItemGroup>

            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{t('inspection.vesselIdentity.section.vms')}</IonLabel>
                </IonItemDivider>
                <Item description={t('inspection.vesselIdentity.vmsOnBoard.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.vmsOnBoard.label')}</IonLabel>
                    <VmsOnBoardSelect
                    placeholder={t('inspection.vesselIdentity.vmsOnBoard.placeholder')}
                    code={vms}
                    onChange={(v)=>setvms(v)}/>
                </Item>
                <Item description={t('inspection.vesselIdentity.vmsType.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.vmsType.label')}</IonLabel>
                    <VmssSelect
                    placeholder={t('inspection.vesselIdentity.vmsType.placeholder')}
                    disabled={vms==='no'}
                    ids={[...edited.extended.vessel.vmsType, ...(edited.extended.vessel.vmsTypeOtherName !== null ? [999]: [])]}
                    includeOther
                    otherId={999}
                    onChange={(v)=>set((p)=>{
                        if(p.extended.vessel.vmsTypeOtherName === null && v.includes(999)){
                            if(p.extended.vessel.vmsTypeOtherName === null){
                                p.extended.vessel.vmsTypeOtherName = ' '
                            }
                        }else if(p.extended.vessel.vmsTypeOtherName !== null && !v.includes(999)){
                            p.extended.vessel.vmsTypeOtherName = null
                        }
                        p.extended.vessel.vmsType = v.filter(vi=>vi!==999)
                    })}/>
                    
                </Item>
                <Item description={t('inspection.vesselIdentity.otherVmsType.help')} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.otherVmsType.label')}</IonLabel>
                    <IonInput
                    placeholder={t('inspection.vesselIdentity.otherVmsType.placeholder')}
                    // disabled={edited.extended.vessel.vmsTypeOtherName === null}
                    value={edited.extended.vessel.vmsTypeOtherName}
                    onIonChange={e=>set((p)=>{
                        const val = e.detail.value!
                        if(val === '' || !val){
                            p.extended.vessel.vmsTypeOtherName = null
                        }else{
                            if(p.extended.vessel.vmsTypeOtherName === ' ' && val !== ' '){
                                p.extended.vessel.vmsTypeOtherName = val.trim()
                            }else{
                                p.extended.vessel.vmsTypeOtherName = val
                            }
                        }
                    })}></IonInput>
                </Item>
                <Item description={t('inspection.vesselIdentity.inmarsat.help')} error={errors?.inmarsat} disabled={readonly}>
                    <IonLabel>{t('inspection.vesselIdentity.inmarsat.label')}</IonLabel>
                    <IonInput type="number"
                    placeholder={t('inspection.vesselIdentity.inmarsat.placeholder')}
                    value={edited.extended.vessel.inmarsat} onIonChange={e=>set((p)=>p.extended.vessel.inmarsat = (e.detail.value!))}></IonInput>
                </Item>
            </IonItemGroup>
        </IonList>
    </StepContent>
}
const key = "updatevesselidentity"

const Button = ({pir}:StepButtonProps) => {

    return <FormStepButton stepkey={key} pir={pir} />

}

const Link = ({first, last, isDefault, pir}:BaseStepProps) =>{
    const {t} = useIntl()
    return StepLink({
        completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
        isDefault,
        disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
        first,
        last,
        path:getPath(key),
        isForm: true,
        title:t('inspection.vesselIdentity.section.title')
    })
}

export const VesselIdentityForm:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}