import { useProcessRegistry } from "process/api"
import { useFileTasksInitializer } from "./task"

export const FileTasks = () => {
    const processRegistry = useProcessRegistry()
    const tasks = useFileTasksInitializer()
    
    processRegistry.registerTask(tasks)

    return <></>
}