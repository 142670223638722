import React from "react"
import { Route, Switch } from "react-router-dom"
import { PirCompilationPage } from "./Compilation"
import { PirVesselPage } from "./Vessel"

export const PirPage = ({match}) => {

    return <Switch>
        <Route exact path={`${match.path}/:id`} component={PirCompilationPage}/>
        <Route path={`${match.path}/:id/vessel`} component={PirVesselPage}/>
    </Switch>
}