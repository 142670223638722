import { IonCard, IonCardContent, IonCardSubtitle, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { chatbubbleEllipsesOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { statuses } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { getPirPreparationProcesses } from "pir/processes/preparation";
import { useProcess } from "process/api";
import { ProcessFullViewer } from "process/Viewer/Full";
import React from "react";
import { useUser } from "user/api";
import { ClaimedButton } from "./Button";

const Content = ({pir, isDefault}:StepContentProps) => {

const userapi = useUser()
const user = userapi.current()
const claimed = pir && user && pirmodelapi(pir).hasBeenClaimedBy(user) ? true : false

const processApi = useProcess()
const process = getPirPreparationProcesses(pir, processApi.all())
const preparing = pirmodelapi(pir).isPreparing()

const {t} = useIntl()


return <StepContent isDefault={isDefault} isForm={false}
    title={preparing ? t('inspection.claimed.preparing.title') : claimed ? t('inspection.claimed.default.title') : t('inspection.claimed.notClaimed.title')}>
    
    <StepDescription>{preparing ? t('inspection.claimed.preparing.subtitle') : claimed ? t('inspection.claimed.default.subtitle'): t('inspection.claimed.notClaimed.subtitle')}</StepDescription>

        {
            pir.inspectionComment &&
            <IonCard style={{borderRadius: '10px'}} mode="ios" color="warning">
                <IonCardContent>
                    <IonCardSubtitle><IonIcon icon={chatbubbleEllipsesOutline}/> {t('inspection.claimed.inspectorCommentTitle')}</IonCardSubtitle>
                    {pir.inspectionComment}
                </IonCardContent>
            </IonCard>
        }

        {preparing && process && 
           <ProcessFullViewer process={process}/>
        }
    </StepContent>
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
    
    const userapi = useUser()
    const user = userapi.current()

    const isAvailable = pir && user && pirmodelapi(pir).isVailablefor(user) ? true : false
    const isCompleted = pir && user ? ClaimedAndPrepared.isCompleted(pir,user) : false
    const date = isCompleted && pir.lifecycle && pir.lifecycle[statuses.ready] && pir.lifecycle[statuses.ready].date || undefined

    const claimed = pir && user && pirmodelapi(pir).hasBeenClaimedBy(user) ? true : false
    const preparing = pirmodelapi(pir).isPreparing()

    const {t} = useIntl()

    return <StepLink 
        disabled={!isAvailable}
        completed={isCompleted}
        isDefault={isDefault}
        first={first}
        last={last}
        isForm={false}
        path={getPath(key)}
        title={preparing ? t('inspection.claimed.preparing.linkTitle') : claimed ? t('inspection.claimed.default.linkTitle') : t('inspection.claimed.notClaimed.linkTitle')}
        date={date}
        icon={pir.inspectionComment && chatbubbleEllipsesOutline}
     />
}

const key = "claimedandprepared"
export const claimedAndPreparedKey = key
export const ClaimedAndPrepared:Step = {
    key,
    path: getPath(key),
    isForm: false,
    Link,
    Content,
    isCompleted: (pir, user) =>  pirmodelapi(pir).hasBeenClaimedBy(user) && ![statuses.notready, statuses.preparing].includes(pir.status as any),
    Button:ClaimedButton
}