import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonText, IonSearchbar, IonFooter } from "@ionic/react"
import { checkmarkOutline, close } from "ionicons/icons"
import { useIntl } from "lib/apprise-frontend-core/intl/api"
import React, { useState } from "react"
import { SelectableItem, SelecteableValue } from "."
import { SelectList } from "./List"
import { SelectViewer } from "./Viewer"

type Props = {
    show,
    onClose: (save?:boolean) => void,
    title?,
    onToggle:(s:SelecteableValue, checked:boolean)=>void,
    options:SelectableItem[],
    selected:SelecteableValue[],
    forceApprove?:boolean
}
export const SelectModal = ({show, onClose, title, selected, options, onToggle, forceApprove}:Props) => {

    const [filter, setFilter] = useState('')
    const filterLower = filter.toLowerCase()
    const filteredOptions = filter !== "" ? options.filter(o=>`${o.value}${o.label}`.toLowerCase().indexOf(filterLower) > -1) : options
    const {t} = useIntl()
    return <IonModal mode="ios" swipeToClose
        onDidDismiss={()=>onClose()}
        isOpen={show}
        cssClass='my-custom-class'>
        <IonHeader>
            <IonToolbar>
                {title && <IonTitle>{title}</IonTitle>}
                <IonButtons slot="end">
                    <IonButton onClick={()=>onClose()}>
                        <IonIcon slot="icon-only" icon={close}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonToolbar>
                <IonSearchbar onIonChange={e=>setFilter(e.detail.value!)}></IonSearchbar>
            </IonToolbar>
            <IonToolbar>
                <SelectViewer onToggle={onToggle} options={options} selected={selected}/>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <SelectList filter={filterLower} selected={selected} options={filteredOptions} onToggle={onToggle}/>
        </IonContent>
        
        {   
            forceApprove &&
            <IonFooter className="ion-no-border">
                <IonToolbar mode="md">
                    <IonButtons slot="end">
                        <IonButton onClick={()=>onClose()}>{t('dialog.genericModal.cancel')}</IonButton>
                        <IonButton color="primary" onClick={()=>onClose(true)}>{t('dialog.genericModal.apply')} <IonIcon icon={checkmarkOutline}/></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        }
        
    </IonModal>
}