import { Step } from "components/Step/model"
import { VesselPicturesForm } from "./VesselPictures"
import { ClaimedAndPrepared } from "./ClaimedAndPrepared"
import { InspectionStarted } from "./InspectionStarted"
import { PirAvailable } from "./PirAvailable"
import { VesselIdentityForm } from "./VesselIdentity"
import { InspectionInformationForm } from "./InspectionInformation"
import { PurposeAndPortOfCall } from "./PurposeAndPortOfCall"
import { PirAttachmentsForm } from "./PirAttachments"
import { StatusInRfmo } from "./StatusInRfmo"
import { RelevantFishingAuthorisations } from "./RelevantFishingAuthorisations"
import { RelevantTranshipmentAuthorisations } from "./RelevantTranshipmentAuthorisations"
import { CatchOnBoard } from "./CatchOnBoard"
import { CatchRetained } from "./CatchRetained"
import { Monitoring } from "./Monitoring"
import { Examinations } from "./Examinations"
import { Findings } from "./Findings"
import { Infringement } from "./Infringement"
import { Signature } from "./Signature"
import { Submit } from "./Submit"
import { TranshipmentInformation } from "./TranshipmentInfo/Index"

export const steps:Step[] = [
    PirAvailable,
    ClaimedAndPrepared,
    InspectionStarted,
    InspectionInformationForm,
    VesselIdentityForm,
    VesselPicturesForm,
    PurposeAndPortOfCall,
    StatusInRfmo,
    RelevantFishingAuthorisations,
    RelevantTranshipmentAuthorisations,
    TranshipmentInformation,
    CatchOnBoard,
    CatchRetained,
    Monitoring,
    Examinations,
    Findings,
    Infringement,
    PirAttachmentsForm,
    Signature,
    Submit
]