import { IonButton, IonButtons, IonDatetime, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonSegment, IonSegmentButton, IonToggle } from "@ionic/react";
import { AuthoritiesSelect } from "authorities/Select";
import { Item } from "components/Item";
import { StepContent } from "components/Step/Content";
import { StepDescription } from "components/Step/Description";
import { StepLink } from "components/Step/Link";
import { BaseStepProps, Step, StepButtonProps, StepContentProps } from "components/Step/model";
import { getPath } from "components/Step/utils";
import { FishingAreasSelect } from "fishingArea/Select";
import { GearsSelect } from "gear/Select";
import copyAndApplyChanges from 'immer';
import { addOutline, removeOutline } from "ionicons/icons";
import { useIntl } from "lib/apprise-frontend-core/intl/api";
import { Mutator } from "lib/apprise-frontend-core/state/api";
import { usePirs } from "pir/api";
import { OnFinishType, PirAttachmentButton } from "pir/Form/Attachment/Button";
import { PirFormAttachments } from "pir/Form/Attachment/PirAttachmentPictures";
import { FishingAuthorization, FormAttachment, InspectionErrors, PirEdting, statuses, ValidateStep } from "pir/model";
import { pirmodelapi } from "pir/model/api";
import { check, getStepErrors, stepContainsErrors } from "pir/model/inspectionErrorApi";
import React, { useRef } from "react";
import { useReferencedata } from "referencedata/api";
import { SpeciesForFishingAuthorizationSelect } from "speciesForFishingAuthorization/Select";
import { move } from "utils";
import { FormStepButton } from "./FormStepButton";

type ValidationType = {
    fishingAuthorizations
}

const useValidation = () =>{
    const {t} = useIntl()
    const validate:ValidateStep = (p) => {
        let errors:InspectionErrors<ValidationType> = {}
        errors.fishingAuthorizations = [] as InspectionErrors<FishingAuthorization>[]
        p.extended.pir.fishingAuthorizations.forEach(r=>{
            const error:InspectionErrors<FishingAuthorization> = {
                identifier: check(r.identifier, t('inspection.fishingAuthorisation.identifier.label')).isEmpty().errors,
                validFrom: check(r.validFrom, t('inspection.fishingAuthorisation.validFrom.label')).isEmpty().isHigherThan(r.validTo, 'Valid to').errors,
                validTo: check(r.validTo, t('inspection.fishingAuthorisation.validTo.label')).isEmpty().isLowerThan(r.validFrom, 'Valid from').errors,
                issuedBy: check(r.issuedBy, t('inspection.fishingAuthorisation.issuedBy.label')).isEmpty().errors,
                isTypeATF: check(r.isTypeATF, t('inspection.fishingAuthorisation.type.label')).isEmpty().errors
            }
            errors.fishingAuthorizations && errors.fishingAuthorizations.push(error)
        })

        return errors
    }
    return validate
}

const Content = ({pir, isDefault}:StepContentProps) => {

    const {t} = useIntl()

    const validate = useValidation()

    const readonly =  pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)

    const ref = useRef(null)

    const documenttypesapi = useReferencedata()

    const pirsapi = usePirs()

    const edited = pir as PirEdting

    const errors:InspectionErrors<ValidationType> = getStepErrors(key, edited)

    const set = pirsapi.setStep(key, edited, validate)

    const editItem = (i, _m:Mutator<FishingAuthorization>) => set(c=>{
        c.extended.pir.fishingAuthorizations[i] = copyAndApplyChanges(c.extended.pir.fishingAuthorizations[i], (s: FishingAuthorization) => void _m(s))
    })

    const removeItem = (i) => set(c=>{
        c.extended.pir.fishingAuthorizations.splice(i, 1)
    })

    const addItem = () => set(c=>{
        c.extended.pir.fishingAuthorizations && c.extended.pir.fishingAuthorizations.unshift({isTypeATF:true, srcForm:"PIR"} as FishingAuthorization)
    })

    const fileType = documenttypesapi.documentType.current().find(d=>d.code==='AUTF')?.id

    const onFinish = (i):OnFinishType => async ({type, files, attachmentUid}) => {
        let attachment = await pirsapi.setFormAttachmentPictures(files, pir)
        
        await set((p=>{
            const file = p.extended.pir.fishingAuthorizations[i].attachment
            if(attachmentUid){
                if(file && file.uid === attachmentUid){
                    (p.extended.pir.fishingAuthorizations[i].attachment as FormAttachment).pictures = [...file.pictures,  ...attachment.pictures]
                }
            }else{
                let newattachment = {...attachment}
                
                if(type && type > 0){
                    newattachment.type = type
                    newattachment.name = documenttypesapi.documentType.current().find(d=>d.id == type)?.nameEn || 'AUTF'
                }
                p.extended.pir.fishingAuthorizations[i].attachment = newattachment
            }
        }))
    }

    const onSetAttachment = (i, f:FormAttachment ) => set(p=>p.extended.pir.fishingAuthorizations[i].attachment = f)
    const onRemoveAttachmentPicture = (attachmentIndex, pictureIndex) => set(p=>(p.extended.pir.fishingAuthorizations[attachmentIndex].attachment as FormAttachment).pictures.splice(pictureIndex,1))
    const onRemoveAttachment = (i) => set(p=>p.extended.pir.fishingAuthorizations[i].attachment = null)
    const onReorderAttachmentPictures = (i, from, to) => set(p=>move((p.extended.pir.fishingAuthorizations[i].attachment as FormAttachment).pictures, from, to))

    const AttachmentButton = ({onClick}) => 
    <IonButton disabled={readonly} shape="round" slot="end" size="small"
    onClick={onClick}>
        {t('inspection.fishingAuthorisation.attachment.add')}
        <IonIcon icon={addOutline} />
    </IonButton>

    return <StepContent isForm={true}
        isDefault
        title={t('inspection.fishingAuthorisation.section.title')}>
        <StepDescription>{t('inspection.fishingAuthorisation.section.subtitle')}</StepDescription>
        <IonList ref={ref}>
            
            <IonItemGroup>
                <IonItem disabled={readonly}><IonButton slot="end" onClick={addItem} color="primary">{t('inspection.fishingAuthorisation.section.add')}</IonButton></IonItem>
            </IonItemGroup>

            {[...edited.extended.pir.fishingAuthorizations].map((c, ci, items) => {
                const title = `${t('inspection.fishingAuthorisation.section.fishingAuthorisation')} ${items.length - ci}`

                const error = (((errors && errors.fishingAuthorizations && errors.fishingAuthorizations[ci]) || {}) as InspectionErrors<FishingAuthorization>)
                return <IonItemGroup key={title} className="new-item">
                     <IonItemDivider>
                        <IonLabel>{title}</IonLabel>
                        
                        <IonButtons slot="end">
                            <IonButton disabled={readonly} color="danger" shape="round" slot="end" size="small"
                            onClick={()=>removeItem(ci)}>
                                {t('common.delete')}
                                <IonIcon icon={removeOutline} />
                            </IonButton>
                        </IonButtons>

                    </IonItemDivider>
                    

                    <Item description={t('inspection.fishingAuthorisation.identifier.help')} disabled={readonly} mandatory error={error.identifier}>
                        <IonLabel>{t('inspection.fishingAuthorisation.identifier.label')}</IonLabel>
                        <IonInput
                        value={c.identifier}
                        placeholder={t('inspection.fishingAuthorisation.identifier.placeholder')}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.identifier = (e.detail.value!))}></IonInput>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.type.help')} disabled={readonly} mandatory error={error.isTypeATF}>
                        <IonLabel>{t('inspection.fishingAuthorisation.type.label')}</IonLabel>
                        <IonSegment
                        placeholder={t('inspection.fishingAuthorisation.type.placeholder')}
                        value={c.isTypeATF ? 'ATF' : 'CSFL'}
                        onIonChange={e => editItem(ci, (p)=>p.isTypeATF = e.detail.value === 'ATF' ? true : false)}>
                            <IonSegmentButton value="ATF">
                                <IonLabel>ATF</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="CSFL">
                                <IonLabel>CSFL</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.issuedBy.help')} disabled={readonly} mandatory error={error.issuedBy}>
                        <IonLabel>{t('inspection.fishingAuthorisation.issuedBy.label')}</IonLabel>
                        <AuthoritiesSelect id={c.issuedBy}
                        placeholder={t('inspection.fishingAuthorisation.issuedBy.placeholder')}
                        onChange={(v)=>editItem(ci, (p)=>p.issuedBy = v )}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.validFrom.help')} disabled={readonly} mandatory error={error.validFrom}>
                        <IonLabel>{t('inspection.fishingAuthorisation.validFrom.label')}</IonLabel>
                        <IonDatetime placeholder={t('inspection.fishingAuthorisation.validFrom.placeholder')}
                        value={c.validFrom ? new Date(c.validFrom).toISOString() : undefined}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.validFrom = new Date(e.detail.value!).getTime())}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.validTo.help')} disabled={readonly} mandatory error={error.validTo}>
                        <IonLabel>{t('inspection.fishingAuthorisation.validTo.label')}</IonLabel>
                        <IonDatetime max={`${new Date().getFullYear()+100}`} placeholder={t('inspection.fishingAuthorisation.validTo.placeholder')}
                        value={c.validTo ? new Date(c.validTo).toISOString() : undefined}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.validTo = new Date(e.detail.value!).getTime())}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.fishingAreas.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.fishingAuthorisation.fishingAreas.label')}</IonLabel>
                        <FishingAreasSelect placeholder={t('inspection.fishingAuthorisation.fishingAreas.placeholder')} ids={c.fishingAreas}
                        onChange={(v)=>editItem(ci, (p)=>p.fishingAreas = v )}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.species.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.fishingAuthorisation.species.label')}</IonLabel>
                        <SpeciesForFishingAuthorizationSelect placeholder={t('inspection.fishingAuthorisation.species.placeholder')} ids={c.species}
                        multiple onChange={(v)=>editItem(ci, (p)=>p.species = v )}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.gears.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.fishingAuthorisation.gears.label')}</IonLabel>
                        <GearsSelect placeholder={t('inspection.fishingAuthorisation.gears.placeholder')} ids={c.gear}
                        onChange={(v)=>editItem(ci, (p)=>p.gear = v )}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.attachment.help')} disabled={readonly} error={error.attachment}>
                    
                        <IonItemGroup>
                            <IonItemDivider>
                                    <IonLabel>{t('inspection.fishingAuthorisation.attachment.label')}</IonLabel>
                                    
                                    <IonButtons slot="end">
                                        <PirAttachmentButton fileType={fileType} pir={edited} Button={AttachmentButton} onFinish={onFinish(ci)}/>
                                    </IonButtons>

                                </IonItemDivider>
                                {
                                    c.attachment &&
                                    <PirFormAttachments
                                    disabled={readonly}
                                    typeDisabled
                                    onFinish={onFinish(ci)}
                                    pir={pir}
                                    attachments={[c.attachment]}
                                    wrapperRef={ref}
                                    onSetAttachment={onSetAttachment}
                                    onRemoveAttachmentPicture={onRemoveAttachmentPicture}
                                    onRemoveAttachment={onRemoveAttachment}
                                    onReorderAttachmentPictures={onReorderAttachmentPictures}
                                    />
                                }
                        </IonItemGroup>
                    
                    </Item>

                    <Item disabled>
                        <IonLabel>{t('inspection.fishingAuthorisation.source.label')}</IonLabel>
                        <IonInput value={c.srcForm}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.objection.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.fishingAuthorisation.objection.label')}</IonLabel>
                        <IonToggle
                        placeholder={t('inspection.fishingAuthorisation.objection.placeholder')}
                        color={c.objection ? 'success' : undefined}
                        checked={c.objection ? true : false}
                        onIonChange={e=>e.detail.checked !== undefined && editItem(ci, (p)=>p.objection = e.detail.checked)}/>
                    </Item>

                    <Item description={t('inspection.fishingAuthorisation.objection.help')} disabled={readonly}>
                        <IonLabel>{t('inspection.fishingAuthorisation.comments.label')}</IonLabel>
                        <IonInput
                        placeholder={t('inspection.fishingAuthorisation.objection.placeholder')}
                        value={c.comments}
                        onIonChange={e=>e.detail.value !== undefined && editItem(ci, (p)=>p.comments = (e.detail.value!))}></IonInput>
                    </Item>
                
                </IonItemGroup>
            })}
        </IonList>
    </StepContent>
}
const key = "RelevantFishingauthorisation"

const Button = ({pir}:StepButtonProps) => {
    const pirapi = usePirs()

    return <FormStepButton stepkey={key} pir={pir} />
}

const Link = ({first, last, isDefault, pir}:BaseStepProps) => {
        const {t} = useIntl()
        return StepLink({
            completed: pir.inspectionstatus && pir.inspectionstatus[key] ? true : false,
            isDefault,
            disabled: pirmodelapi(pir).hasStatusLessThan(statuses.inspecting),
            first,
            last,
            path:getPath(key),
            isForm: true,
            badge: pir.extended?.pir.fishingAuthorizations.length,
            title:t('inspection.fishingAuthorisation.section.title'),
            hasError: stepContainsErrors(key, pir as PirEdting)
        })}
export const RelevantFishingAuthorisations:Step = {
    key,
    path: getPath(key),
    isForm: true,
    Link,
    Content,
    Button,
    isCompleted: (pir)=> pir.inspectionstatus && pir.inspectionstatus[key] ? true : false
}