export const species = [
  {
    "id": 1,
    "code": "YFT",
    "nameEn": "Yellowfin tuna (YFT)",
    "nameFr": "Albacore (YFT)",
    "targetGroup": "TROP"
  },
  {
    "id": 2,
    "code": "ALB",
    "nameEn": "Albacore (ALB)",
    "nameFr": "Germon (ALB)",
    "targetGroup": "OTHR"
  },
  {
    "id": 3,
    "code": "BET",
    "nameEn": "Bigeye tuna (BET)",
    "nameFr": "Patudo (BET)",
    "targetGroup": "TROP"
  },
  {
    "id": 4,
    "code": "SKJ",
    "nameEn": "Skipjack tuna (SKJ)",
    "nameFr": "Listao (SKY)",
    "targetGroup": "TROP"
  },
  {
    "id": 5,
    "code": "SWO",
    "nameEn": "Swordfish (SWO)",
    "nameFr": "Espadon (SWO)",
    "targetGroup": "OTHR"
  },
  {
    "id": 6,
    "code": "SBF",
    "nameEn": "Southern bluefin tuna (SBF)",
    "nameFr": "Thon rouge du Sud (SBF)",
    "targetGroup": "OTHR"
  },
  {
    "id": 7,
    "code": "BFT",
    "nameEn": "Northern bluefin tuna (BFT)",
    "nameFr": "Thon rouge du Nord (BFT)",
    "targetGroup": "OTHR"
  },
  {
    "id": 8,
    "code": "BIP",
    "nameEn": "Striped bonito (BIP)",
    "nameFr": "Bonite oriental (BIP)",
    "targetGroup": "OTHR"
  },
  {
    "id": 9,
    "code": "FRI",
    "nameEn": "Frigate tuna (FRI)",
    "nameFr": "Auxide (FRI)",
    "targetGroup": "OTHR"
  },
  {
    "id": 10,
    "code": "KAW",
    "nameEn": "Kawakawa (KAW)",
    "nameFr": "Thonine orientale (KAW)",
    "targetGroup": "OTHR"
  },
  {
    "id": 11,
    "code": "COM",
    "nameEn": "Narrow-barred Spanish mackerel (COM)",
    "nameFr": "Thazard ray� indo-pacifique (COM)",
    "targetGroup": "OTHR"
  },
  {
    "id": 12,
    "code": "MAZ",
    "nameEn": "Horse mackerels (MAZ)",
    "nameFr": "Horse mackerels (MAZ)",
    "targetGroup": "OTHR"
  },
  {
    "id": 13,
    "code": "BUK",
    "nameEn": "Butterfly kingfish (BUK)",
    "nameFr": "Thon papillon (BUK)",
    "targetGroup": "OTHR"
  },
  {
    "id": 14,
    "code": "WAH",
    "nameEn": "Wahoo (WAH)",
    "nameFr": "Thazard-b�tard (WAH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 15,
    "code": "BON",
    "nameEn": "Atlantic Bonito (BON)",
    "nameFr": "Bonite (BON)",
    "targetGroup": "OTHR"
  },
  {
    "id": 16,
    "code": "BLT",
    "nameEn": "Bullet tuna (BLT)",
    "nameFr": "Bonitou (BLT)",
    "targetGroup": "OTHR"
  },
  {
    "id": 17,
    "code": "GUT",
    "nameEn": "Indo-Pacific king mackerel (GUT)",
    "nameFr": "Thazard ponctu� indo-pacifique (GUT)",
    "targetGroup": "OTHR"
  },
  {
    "id": 18,
    "code": "LOT",
    "nameEn": "Longtail tuna (LOT)",
    "nameFr": "Thon mignon (LOT)",
    "targetGroup": "OTHR"
  },
  {
    "id": 19,
    "code": "AG01",
    "nameEn": "Yellowfin tuna (YFT), bigeye tuna (BET)",
    "nameFr": "Albacore (YFT), patudo (BET)",
    "targetGroup": "AGGR"
  },
  {
    "id": 20,
    "code": "AG03",
    "nameEn": "Yellowfin tuna (YFT), bigeye tuna (BET) and skipjack tuna (SKJ)",
    "nameFr": "Albacore (YFT), patudo (BET) et listao (SKJ)",
    "targetGroup": "AGGR"
  },
  {
    "id": 21,
    "code": "AG04",
    "nameEn": "Yellowfin tuna (YFT), skipjack tuna (SKJ)",
    "nameFr": "Albacore (YFT), listao (SKJ)",
    "targetGroup": "AGGR"
  },
  {
    "id": 22,
    "code": "AG16",
    "nameEn": "Bigeye tuna (BET) and skipjack tuna (SKJ)",
    "nameFr": "Patudo (BET), listao (SKJ)",
    "targetGroup": "AGGR"
  },
  {
    "id": 23,
    "code": "AG17",
    "nameEn": "Albacore tuna (ALB), yellowfin tuna (YFT), bigeye tuna (BET)",
    "nameFr": "Germon (ALB), albacore (YFT), patudo (BET)",
    "targetGroup": "AGGR"
  },
  {
    "id": 24,
    "code": "BUM",
    "nameEn": "Blue marlin (BUM)",
    "nameFr": "Makaire bleu (BUM)",
    "targetGroup": "OTHR"
  },
  {
    "id": 25,
    "code": "BLM",
    "nameEn": "Black marlin (BLM)",
    "nameFr": "Makaire noir (BLM)",
    "targetGroup": "OTHR"
  },
  {
    "id": 26,
    "code": "MLS",
    "nameEn": "Striped marlin (MLS)",
    "nameFr": "Marlin ray� (MLS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 27,
    "code": "SFA",
    "nameEn": "Indo-Pacific Sailfish (SFA)",
    "nameFr": "Voilier indo-pacifique (SFA)",
    "targetGroup": "OTHR"
  },
  {
    "id": 28,
    "code": "WHM",
    "nameEn": "White marlin (WHM)",
    "nameFr": "Makaire Blanc (WHM)",
    "targetGroup": "OTHR"
  },
  {
    "id": 29,
    "code": "SSP",
    "nameEn": "Short-billed spearfish (SSP)",
    "nameFr": "Makaire � rostre court (SSP)",
    "targetGroup": "OTHR"
  },
  {
    "id": 30,
    "code": "BIL",
    "nameEn": "Billfish mixed (BIL)",
    "nameFr": "Poissons � rostre m�lang�s (BIL)",
    "targetGroup": "AGGR"
  },
  {
    "id": 31,
    "code": "MARL",
    "nameEn": "Marlins mixed (MARL)",
    "nameFr": "Marlin ray� et makaires m�lang�s (MARL)",
    "targetGroup": "AGGR"
  },
  {
    "id": 32,
    "code": "BSH",
    "nameEn": "Blue shark (BSH)",
    "nameFr": "Peau bleue (BSH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 33,
    "code": "POR",
    "nameEn": "Porbeagle (POR)",
    "nameFr": "Requin-taupe commun (POR)",
    "targetGroup": "OTHR"
  },
  {
    "id": 34,
    "code": "OCS",
    "nameEn": "Oceanic whitetip shark (OCS)",
    "nameFr": "Requin oc�anique (OCS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 35,
    "code": "PSK",
    "nameEn": "Crocodile shark (PSK)",
    "nameFr": "Requin crocodile (BSK)",
    "targetGroup": "OTHR"
  },
  {
    "id": 36,
    "code": "TIG",
    "nameEn": "Tiger shark (TIG)",
    "nameFr": "Requin tigre commun (TIG)",
    "targetGroup": "OTHR"
  },
  {
    "id": 37,
    "code": "WSH",
    "nameEn": "Great white shark (WSH)",
    "nameFr": "Grand requin blanc (WSH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 38,
    "code": "FAL",
    "nameEn": "Silky shark (FAL)",
    "nameFr": "Requin soyeux (FAL)",
    "targetGroup": "OTHR"
  },
  {
    "id": 39,
    "code": "DUS",
    "nameEn": "Dusky shark (DUS)",
    "nameFr": "Requin de sable (DUS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 40,
    "code": "RHN",
    "nameEn": "Whale shark (RHN)",
    "nameFr": "Requin baleine (RHN)",
    "targetGroup": "OTHR"
  },
  {
    "id": 41,
    "code": "SMA",
    "nameEn": "Shortfin mako (SMA)",
    "nameFr": "Taupe bleue (SMA)",
    "targetGroup": "OTHR"
  },
  {
    "id": 42,
    "code": "LMA",
    "nameEn": "Longfin mako (LMA)",
    "nameFr": "Petite taupe (LMA)",
    "targetGroup": "OTHR"
  },
  {
    "id": 43,
    "code": "SPZ",
    "nameEn": "Smooth hammerhead (SPZ)",
    "nameFr": "Requin marteau commun (SPZ)",
    "targetGroup": "OTHR"
  },
  {
    "id": 44,
    "code": "SPL",
    "nameEn": "Scalloped hammerhead (SPL)",
    "nameFr": "Requin-marteau halicorne (SPL)",
    "targetGroup": "OTHR"
  },
  {
    "id": 45,
    "code": "PTH",
    "nameEn": "Pelagic thresher (PTH)",
    "nameFr": "Renard p�lagique (PTH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 46,
    "code": "BTH",
    "nameEn": "Bigeye thresher (BTH)",
    "nameFr": "Renard � gros yeux (BTH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 47,
    "code": "ALV",
    "nameEn": "Thresher (ALV)",
    "nameFr": "Renard (ALV)",
    "targetGroup": "OTHR"
  },
  {
    "id": 48,
    "code": "RME",
    "nameEn": "Longhorned mobula (RME)",
    "nameFr": "Mante diable (RME)",
    "targetGroup": "OTHR"
  },
  {
    "id": 49,
    "code": "RMB",
    "nameEn": "Giant manta (RMB)",
    "nameFr": "Manta g�ante (RMB)",
    "targetGroup": "OTHR"
  },
  {
    "id": 50,
    "code": "RMJ",
    "nameEn": "Spinetail mobula (RMJ)",
    "nameFr": "Mante aiguillat (RMJ)",
    "targetGroup": "OTHR"
  },
  {
    "id": 51,
    "code": "RMO",
    "nameEn": "Smoothtail mobula (RMO)",
    "nameFr": "Mante � queue lisse (RMO)",
    "targetGroup": "OTHR"
  },
  {
    "id": 52,
    "code": "PLS",
    "nameEn": "Pelagic stingray (PLS)",
    "nameFr": "Pastenague violette (PLS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 53,
    "code": "AML",
    "nameEn": "Grey reef shark (AML)",
    "nameFr": "Requin dagsit (AML)",
    "targetGroup": "OTHR"
  },
  {
    "id": 54,
    "code": "CCL",
    "nameEn": "Blacktip shark (CCL)",
    "nameFr": "Requin bord� (CCL)",
    "targetGroup": "OTHR"
  },
  {
    "id": 55,
    "code": "CCP",
    "nameEn": "Sandbar shark (CCP)",
    "nameFr": "Requin gris (CCP)",
    "targetGroup": "OTHR"
  },
  {
    "id": 56,
    "code": "BSK",
    "nameEn": "Basking Sharks (BSK)",
    "nameFr": "Requin p�lerin (BSK)",
    "targetGroup": "OTHR"
  },
  {
    "id": 57,
    "code": "SCK",
    "nameEn": "Kitefin shark (SCK)",
    "nameFr": "Squale liche (SCK)",
    "targetGroup": "OTHR"
  },
  {
    "id": 58,
    "code": "SKHS",
    "nameEn": "Sharks finned (round weight)",
    "nameFr": "Requins sans nageoires (poids vif)",
    "targetGroup": "OTHR"
  },
  {
    "id": 59,
    "code": "SKH",
    "nameEn": "Sharks mixed (SKH)",
    "nameFr": "Requins m�lang�es (SKH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 60,
    "code": "BAU",
    "nameEn": "Australian bonito (BAU)",
    "nameFr": "Bonite bagnard (BAU)",
    "targetGroup": "OTHR"
  },
  {
    "id": 61,
    "code": "BAR",
    "nameEn": "Barracudas (BAR)",
    "nameFr": "Brochets de mer (BAR)",
    "targetGroup": "OTHR"
  },
  {
    "id": 62,
    "code": "LEC",
    "nameEn": "Black escolar (LEC)",
    "nameFr": "Escolier noir (LEC)",
    "targetGroup": "OTHR"
  },
  {
    "id": 63,
    "code": "MAA",
    "nameEn": "Blue mackerel (MAA)",
    "nameFr": "Maquereau tachet� (MAA)",
    "targetGroup": "OTHR"
  },
  {
    "id": 64,
    "code": "DOL",
    "nameEn": "Dolphin Fish (DOL)",
    "nameFr": "Coryph�ne commune (DOL)",
    "targetGroup": "OTHR"
  },
  {
    "id": 65,
    "code": "DBM",
    "nameEn": "Double-lined mackerel (DBM)",
    "nameFr": "Thazard-kusara (DBM)",
    "targetGroup": "OTHR"
  },
  {
    "id": 66,
    "code": "AMB",
    "nameEn": "Greater amberjack (AMB)",
    "nameFr": "S�riole couronn�e (AMB)",
    "targetGroup": "OTHR"
  },
  {
    "id": 67,
    "code": "RAG",
    "nameEn": "Indian mackerel (RAG)",
    "nameFr": "Maquereau des Indes (RAG)",
    "targetGroup": "OTHR"
  },
  {
    "id": 68,
    "code": "KOS",
    "nameEn": "Korean seerfish (KOS)",
    "nameFr": "Thazard cor�en (KOS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 69,
    "code": "KAK",
    "nameEn": "Kanadi kingfish (KAS)",
    "nameFr": "Thazard kanadi (KAS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 70,
    "code": "SPF",
    "nameEn": "Longbill spearfish (SPF)",
    "nameFr": "Makaire b�cune (SPF)",
    "targetGroup": "OTHR"
  },
  {
    "id": 71,
    "code": "OIL",
    "nameEn": "Oilfish (OIL)",
    "nameFr": "Ruvet (OIL)",
    "targetGroup": "OTHR"
  },
  {
    "id": 72,
    "code": "MOO",
    "nameEn": "Moonfish (MOO)",
    "nameFr": "Luneur (MOO)",
    "targetGroup": "OTHR"
  },
  {
    "id": 73,
    "code": "LAG",
    "nameEn": "Opah (LAH)",
    "nameFr": "Opah (LAH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 74,
    "code": "SAP",
    "nameEn": "Pacific saury (SAP)",
    "nameFr": "Balaou du Japon (SAP)",
    "targetGroup": "OTHR"
  },
  {
    "id": 75,
    "code": "BRA",
    "nameEn": "Pomfrets (BRA)",
    "nameFr": "Castagnoles (BRA)",
    "targetGroup": "OTHR"
  },
  {
    "id": 76,
    "code": "CFW",
    "nameEn": "Pompano dolphinfish (CFW)",
    "nameFr": "Dorade (CFW)",
    "targetGroup": "OTHR"
  },
  {
    "id": 77,
    "code": "RRU",
    "nameEn": "Rainbow runner (RRU)",
    "nameFr": "Com�te saumon (CCU)",
    "targetGroup": "OTHR"
  },
  {
    "id": 78,
    "code": "STS",
    "nameEn": "Streaked seerfish (STS)",
    "nameFr": "Thazard cirrus (STS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 79,
    "code": "ANW",
    "nameEn": "Angelfishes (ANW)",
    "nameFr": "Demoiselles nca (ANW)",
    "targetGroup": "OTHR"
  },
  {
    "id": 80,
    "code": "ALF",
    "nameEn": "Alfonsino nei (ALF)",
    "nameFr": "B�ryx nca (ALF)",
    "targetGroup": "OTHR"
  },
  {
    "id": 81,
    "code": "OTHR",
    "nameEn": "Fish other than IOTC species or sharks",
    "nameFr": "Poissons hors esp�ces CTOI ou requins",
    "targetGroup": "OTHR"
  },
  {
    "id": 82,
    "code": "UNKN",
    "nameEn": "Unknown",
    "nameFr": "Inconnu",
    "targetGroup": "UNKN"
  },
  {
    "id": 83,
    "code": "AG00",
    "nameEn": "Unidentified tuna or tuna-like species",
    "nameFr": "Thons ou esp�ces associ�es non identifi�es",
    "targetGroup": "AGGR"
  },
  {
    "id": 84,
    "code": "AG02",
    "nameEn": "Juvenile yellowfin tuna (YFT) and bigeye tuna (BET)",
    "nameFr": "Juvenil albacore (YFT) et patudo (BET)",
    "targetGroup": "AGGR"
  },
  {
    "id": 85,
    "code": "AG05",
    "nameEn": "Yellowfin tuna (YFT) and swordfish (SWO)",
    "nameFr": "Albacore (YFT) et espadon (SWO)",
    "targetGroup": "AGGR"
  },
  {
    "id": 86,
    "code": "AG06",
    "nameEn": "Tropical tunas and/or swordfish (SWO)",
    "nameFr": "Thons tropicaux et/ou espadon (SWO)",
    "targetGroup": "AGGR"
  },
  {
    "id": 87,
    "code": "AG07",
    "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), Bigeye tuna (BET),Skipjack tuna (SKJ), swordfish (SWO), sailfish",
    "nameFr": "Germon (ALB), Albacore (YFT), Patudo (BET), Listao (SKJ), Espadon (SWO), voilier",
    "targetGroup": "AGGR"
  },
  {
    "id": 88,
    "code": "AG08",
    "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), Bigeye tuna (BET), swordfish (SWO)",
    "nameFr": "Germon (ALB), Albacore (YFT), Patudo (BET),  Espadon (SWO)",
    "targetGroup": "AGGR"
  },
  {
    "id": 89,
    "code": "AG09",
    "nameEn": "Yellowfin tuna (YFT), Bigeye tuna (BET), swordfish (SWO)",
    "nameFr": "Albacore (YFT), Patudo (BET),  Espadon (SWO)",
    "targetGroup": "AGGR"
  },
  {
    "id": 90,
    "code": "AG10",
    "nameEn": "Kawakawa (KAW) & Frigate tuna (FRI) & Bullet tuna (BLT)",
    "nameFr": "Thonine orientale (KAW) & Auxide (FRI) & Bonitou (BLT)",
    "targetGroup": "AGGR"
  },
  {
    "id": 91,
    "code": "AG11",
    "nameEn": "Black marlin (BLM), blue marlin (BUM) and striped marlin (MLS)",
    "nameFr": "Makaire noir (BLM), Makaire bleu (BUM) et Marlin ray� (MLS)",
    "targetGroup": "AGGR"
  },
  {
    "id": 92,
    "code": "AG12",
    "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), Bigeye tuna (BET),swordfish (SWO), sailfish",
    "nameFr": "Germon (ALB), Albacore (YFT), Patudo (BET), Espadon (SWO), voilier",
    "targetGroup": "AGGR"
  },
  {
    "id": 93,
    "code": "AG13",
    "nameEn": "Albacore tuna (ALB), Yellowfin tuna (YFT), Bigeye tuna (BET), Skipjack tuna (SKJ)",
    "nameFr": "Germon (ALB), Albacore (YFT), Patudo (BET), Listao (SKJ)",
    "targetGroup": "AGGR"
  },
  {
    "id": 94,
    "code": "AG14",
    "nameEn": "Albacore (ALB), Swordfish (SWO)",
    "nameFr": "Germon (ALB), Espadon (SWO)",
    "targetGroup": "AGGR"
  },
  {
    "id": 95,
    "code": "AG15",
    "nameEn": "Skipjack tuna (SKJ), Swordfish (SWO)",
    "nameFr": "Listao (SKJ), Espadon (SWO)",
    "targetGroup": "AGGR"
  },
  {
    "id": 96,
    "code": "AG18",
    "nameEn": "Tropical tunas, swordfish (SWO), Albacore (ALB)",
    "nameFr": "Thons tropicaux, espadon (SWO), Germon (ALB)",
    "targetGroup": "AGGR"
  },
  {
    "id": 97,
    "code": "AG19",
    "nameEn": "Bigeye (BET), Yellowfin (YFT), Southern Bluefin Tuna (SBF)",
    "nameFr": "Patudo (BET), Albacore (YFT), Thon rouge du Sud (SBF)",
    "targetGroup": "AGGR"
  },
  {
    "id": 98,
    "code": "AG20",
    "nameEn": "Bluefin Tuna and Skipjack (SKJ)",
    "nameFr": "Thon Rouge et listao (SKJ)",
    "targetGroup": "AGGR"
  },
  {
    "id": 99,
    "code": "AG21",
    "nameEn": "Swordfish (SWO) and Sharks",
    "nameFr": "Espadon (SWO) et Requins",
    "targetGroup": "AGGR"
  },
  {
    "id": 100,
    "code": "AG22",
    "nameEn": "Swordfish (SWO), sharks, tunas (not SBF)",
    "nameFr": "Espadon (SWO), requins, thons ( pas de thon rouge)",
    "targetGroup": "AGGR"
  },
  {
    "id": 101,
    "code": "KGX",
    "nameEn": "Seerfishes nei (KGX)",
    "nameFr": "Thazards nca (KGX)",
    "targetGroup": "AGGR"
  },
  {
    "id": 102,
    "code": "TUN",
    "nameEn": "Tunas nei (TUN)",
    "nameFr": "Thonid�s nca (TUN)",
    "targetGroup": "AGGR"
  },
  {
    "id": 103,
    "code": "MAK",
    "nameEn": "Mako sharks nei (MAK)",
    "nameFr": "Requin taupes nca (MAK)",
    "targetGroup": "AGGR"
  },
  {
    "id": 104,
    "code": "SPN",
    "nameEn": "Hammerhead Shark NEI (SPN)",
    "nameFr": "Requins marteau nca (SPN)",
    "targetGroup": "AGGR"
  },
  {
    "id": 105,
    "code": "MAN",
    "nameEn": "Mantas, devil rays nei (MAN)",
    "nameFr": "Mantes, diables de mer nca (MAN)",
    "targetGroup": "AGGR"
  },
  {
    "id": 106,
    "code": "RAJ",
    "nameEn": "Rays and skates nei (RAJ)",
    "nameFr": "Rajid�s nca (RAJ)",
    "targetGroup": "AGGR"
  },
  {
    "id": 107,
    "code": "THR",
    "nameEn": "Thresher sharks nei (THR)",
    "nameFr": "Renards de mer nca (THR)",
    "targetGroup": "AGGR"
  },
  {
    "id": 108,
    "code": "GTF",
    "nameEn": "Guitar shark (GTF)",
    "nameFr": "Requin guitare (GTF)",
    "targetGroup": "OTHR"
  },
  {
    "id": 109,
    "code": "ASM",
    "nameEn": "Australian spotted mackerel (ASM)",
    "nameFr": "Thazard australien (ASM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 110,
    "code": "BBM",
    "nameEn": "Broad-barred king mackerel (BBM)",
    "nameFr": "Thazard tigr� (BBM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 111,
    "code": "BKJ",
    "nameEn": "Black skipjack (BKJ)",
    "nameFr": "Thonine noire (BKJ)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 112,
    "code": "BLF",
    "nameEn": "Blackfin tuna (BLF)",
    "nameFr": "Thon a nageoires noires (BLF)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 113,
    "code": "BRS",
    "nameEn": "Serra Spanish mackerel (BRS)",
    "nameFr": "Thazard serra (BRS)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 114,
    "code": "BSF",
    "nameEn": "black scabbardfish (BSF)",
    "nameFr": "Sabre noir (BSF)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 115,
    "code": "CCG",
    "nameEn": "Galapagos shark (CCG)",
    "nameFr": "Requin des Galapagos (CCG)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 116,
    "code": "CER",
    "nameEn": "Cero (CER)",
    "nameFr": "Thazard franc (CER)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 117,
    "code": "CHY",
    "nameEn": "Chinese seerfish (CHY)",
    "nameFr": "Thazard n�buleux (CHY)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 118,
    "code": "CUT",
    "nameEn": "Hairtails, scabbardfishes nei (CUT)",
    "nameFr": "Poissons-sabres, sabres nca (CUT)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 119,
    "code": "DOT",
    "nameEn": "Dogtooth tuna (DOT)",
    "nameFr": "Bonite a gros yeux (DOT)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 120,
    "code": "GES",
    "nameEn": "Snake mackerel (GES)",
    "nameFr": "Escolier serpent (GES)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 121,
    "code": "ISB",
    "nameEn": "Cookie cutter shark (ISB)",
    "nameFr": "Squalelet f�roce (ISB)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 122,
    "code": "KGM",
    "nameEn": "King mackerel (KGM)",
    "nameFr": "Thazard barr� (KGM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 123,
    "code": "LEB",
    "nameEn": "Leaping bonito (LEB)",
    "nameFr": "Bonite a dos tachet� (LEB)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 124,
    "code": "LTA",
    "nameEn": "Little tunny(Atl.black skipj) (LTA)",
    "nameFr": "Thonine commune (LTA)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 125,
    "code": "MAC",
    "nameEn": "Atlantic mackerel (MAC)",
    "nameFr": "Maquereau commun (MAC)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 126,
    "code": "MAS",
    "nameEn": "Chub mackerel (MAS)",
    "nameFr": "Maquereau espagnol (MAS)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 127,
    "code": "MAW",
    "nameEn": "West African Spanish mackerel (MAW)",
    "nameFr": "Thazard blanc (MAW)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 128,
    "code": "MOS",
    "nameEn": "Monterey Spanish mackerel (MOS)",
    "nameFr": "Thazard de Monterey (MOS)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 129,
    "code": "MSK",
    "nameEn": "Mackerel sharks,porbeagles nei (MSK)",
    "nameFr": "Requins taupe nca (MSK)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 130,
    "code": "MSP",
    "nameEn": "Mediterranean spearfish (MSP)",
    "nameFr": "Marlin de la M�diterran�e (MSP)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 131,
    "code": "NEN",
    "nameEn": "Black gemfish (NEM)",
    "nameFr": "Escolier long nez (NEM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 132,
    "code": "NPH",
    "nameEn": "Japanese Spanish mackerel (NPH)",
    "nameFr": "Thazard oriental (NPH)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 133,
    "code": "PAP",
    "nameEn": "Papuan seerfish (PAP)",
    "nameFr": "Thazard papou (PAP)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 134,
    "code": "QUL",
    "nameEn": "Spined pygmy shark (QUL)",
    "nameFr": "Squale nain (QUL)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 135,
    "code": "QUM",
    "nameEn": "Queensland school mackerel (QUM)",
    "nameFr": "Thazard du Queensland (QUM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 136,
    "code": "RMH",
    "nameEn": "Lesser devil ray (RMH)",
    "nameFr": "Mante diable (RMH)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 137,
    "code": "RMM",
    "nameEn": "Devil fish (RMM)",
    "nameFr": "Mante diable (RMM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 138,
    "code": "RMN",
    "nameEn": "Lesser Guinean devil ray (RMN)",
    "nameFr": "Petit diable de Guin�e (RMN)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 139,
    "code": "RMT",
    "nameEn": "Chilean devil ray (RMT)",
    "nameFr": "Diable du Chili (RMT)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 140,
    "code": "RSK",
    "nameEn": "Requiem sharks nei (RSK)",
    "nameFr": "Requins nca (RSK)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 141,
    "code": "RSP",
    "nameEn": "Roundscale spearfish (RSP)",
    "nameFr": "Makaire �p�e (RSP)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 142,
    "code": "SAI",
    "nameEn": "Atlantic sailfish (SAI)",
    "nameFr": "Voilier de l'Atlantique (SAI)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 143,
    "code": "SFS",
    "nameEn": "Silver scabbardfish (SFS)",
    "nameFr": "Sabre argent� (SFS)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 144,
    "code": "SHM",
    "nameEn": "Shark mackerel (SHM)",
    "nameFr": "Thazard requin (SHM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 145,
    "code": "SIE",
    "nameEn": "Pacific sierra (SIE)",
    "nameFr": "Thazard sierra (Pacifique) (SIE)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 146,
    "code": "SLT",
    "nameEn": "Slender tuna (SLT)",
    "nameFr": "Thon �l�gant (SLT)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 147,
    "code": "SPK",
    "nameEn": "Great hammerhead (SPK)",
    "nameFr": "Grand requin marteau (SPK)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 148,
    "code": "SPY",
    "nameEn": "Hammerhead sharks, etc. nei (SPY)",
    "nameFr": "Requins marteau, etc. nca (SPY)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 149,
    "code": "SSM",
    "nameEn": "Atlantic Spanish mackerel (SSM)",
    "nameFr": "Thazard atlantique (SSM)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 150,
    "code": "STT",
    "nameEn": "Stingrays, butterfly rays nei (STT)",
    "nameFr": "Pastenagues, etc. nca (STT)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 151,
    "code": "TUS",
    "nameEn": "True tunas nei (TUS)",
    "nameFr": "Thons Thunnus nca (TUS)",
    "targetGroup": "ICCAT"
  },
  {
    "id": 152,
    "code": "SAX",
    "nameEn": "Bait - Sauries (SAX)",
    "nameFr": "App�t - Balaous, bananes de mer (SAX)",
    "targetGroup": "OTHR"
  },
  {
    "id": 153,
    "code": "MAX",
    "nameEn": "Bait - Mackerels (MAX)",
    "nameFr": "App�t - Maquereaux (MAX)",
    "targetGroup": "OTHR"
  },
  {
    "id": 154,
    "code": "SQU",
    "nameEn": "Bait - Squids (SQU)",
    "nameFr": "App�t - Calmars, encornets (SQU)",
    "targetGroup": "OTHR"
  },
  {
    "id": 155,
    "code": "NOO",
    "nameEn": "No fish or fish product onboard",
    "nameFr": "Aucun poisson ou produit de poisson a bord",
    "targetGroup": "OTHR"
  },
  {
    "id": 156,
    "code": "MIL",
    "nameEn": "Bait - Milkfish (MIL)",
    "nameFr": "App�t - Chano (MIL)",
    "targetGroup": "OTHR"
  },
  {
    "id": 157,
    "code": "MOP",
    "nameEn": "Sunfish (MOP)",
    "nameFr": "Poisson lune (MOP)",
    "targetGroup": "OTHR"
  },
  {
    "id": 158,
    "code": "SDX",
    "nameEn": "Bait - Scads (SDX)",
    "nameFr": "App�t - Com�tes (SDX)",
    "targetGroup": "OTHR"
  },
  {
    "id": 159,
    "code": "LJA",
    "nameEn": "African red snapper (LJA)",
    "nameFr": "Vivaneau africain rouge",
    "targetGroup": "OTHR"
  },
  {
    "id": 160,
    "code": "PEN",
    "nameEn": "Penaeus shrimps (PEN)",
    "nameFr": "Crevettes Penaeus (PEN)",
    "targetGroup": "OTHR"
  },
  {
    "id": 161,
    "code": "HKJ",
    "nameEn": "Humpback nylon shrimp (HKJ)",
    "nameFr": "Crevette nylon bossue (HKJ)",
    "targetGroup": "OTHR"
  },
  {
    "id": 162,
    "code": "URH",
    "nameEn": "Whip lobsters (URH)",
    "nameFr": "Langoustes Puerulus nca (URH)",
    "targetGroup": "OTHR"
  },
  {
    "id": 163,
    "code": "THQ",
    "nameEn": "Flathead lobster (THQ)",
    "nameFr": "Cigale raquette (THQ)",
    "targetGroup": "OTHR"
  },
  {
    "id": 164,
    "code": "SLV",
    "nameEn": "Tropical spiny lobsters (SLV)",
    "nameFr": "Langoustes tropicales (SLV)",
    "targetGroup": "OTHR"
  },
  {
    "id": 165,
    "code": "INN",
    "nameEn": "Spear lobster (INN)",
    "nameFr": "Langouste javelot (INN)",
    "targetGroup": "OTHR"
  },
  {
    "id": 166,
    "code": "GEY",
    "nameEn": "Deep-sea crabs, geryons (GEY)",
    "nameFr": "G�rionid�s (GEY)",
    "targetGroup": "OTHR"
  },
  {
    "id": 167,
    "code": "OCZ",
    "nameEn": "Octopuses (OCZ)",
    "nameFr": "Poulpes (OCZ)",
    "targetGroup": "OTHR"
  },
  {
    "id": 168,
    "code": "SQC",
    "nameEn": "Common squids (SQC)",
    "nameFr": "Calmars Loligo (SQC)",
    "targetGroup": "OTHR"
  },
  {
    "id": 169,
    "code": "IAX",
    "nameEn": "Cuttlefishes (IAX)",
    "nameFr": "Seiches (IAX)",
    "targetGroup": "OTHR"
  },
  {
    "id": 170,
    "code": "GRO",
    "nameEn": "Groundfishes (GRO)",
    "nameFr": "Poissons de fond (GRO)",
    "targetGroup": "OTHR"
  },
  {
    "id": 171,
    "code": "PEL",
    "nameEn": "Pelagic fishes (PEL)",
    "nameFr": "Poissons p�lagiques (PEL)",
    "targetGroup": "OTHR"
  },
  {
    "id": 172,
    "code": "SIX",
    "nameEn": "Bait - Sardinellas (SIX)",
    "nameFr": "App�t - Sardinelles (SIX)",
    "targetGroup": "OTHR"
  },
  {
    "id": 173,
    "code": "KRX",
    "nameEn": "Antarctic krill nei (KRX)",
    "nameFr": "Krill antarctique nca (KRX)",
    "targetGroup": "OTHR"
  },
  {
    "id": 174,
    "code": "TOP",
    "nameEn": "Patagonian toothfish (TOP)",
    "nameFr": "L�gine australe (TOP)",
    "targetGroup": "OTHR"
  },
  {
    "id": 175,
    "code": "CYO",
    "nameEn": "Portuguese dogfish (CYO)",
    "nameFr": "Pailona commun (CYO)",
    "targetGroup": "OTHR"
  },
  {
    "id": 176,
    "code": "SAO",
    "nameEn": "Tadpole codling (SAO)",
    "nameFr": "More t�tard (SAO)",
    "targetGroup": "OTHR"
  },
  {
    "id": 177,
    "code": "REG",
    "nameEn": "Golden redfish (REG)",
    "nameFr": "S�baste dor� (REG)",
    "targetGroup": "OTHR"
  },
  {
    "id": 178,
    "code": "KCS",
    "nameEn": "King crabs (KCS)",
    "nameFr": "Crabes royaux (KCS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 179,
    "code": "EPI",
    "nameEn": "Black cardinal fish (EPI)",
    "nameFr": "Poisson cardinal (EPI)",
    "targetGroup": "OTHR"
  },
  {
    "id": 180,
    "code": "RPG",
    "nameEn": "Red porgy (RPG)",
    "nameFr": "Pagre rouge (RPG)",
    "targetGroup": "OTHR"
  },
  {
    "id": 181,
    "code": "HKC",
    "nameEn": "Cape hakes (HKC)",
    "nameFr": "Merlus du Cap (HKC)",
    "targetGroup": "OTHR"
  },
  {
    "id": 182,
    "code": "WRF",
    "nameEn": "Wreckfish (WRF)",
    "nameFr": "Cernier commun (WRF)",
    "targetGroup": "OTHR"
  },
  {
    "id": 183,
    "code": "RIB",
    "nameEn": "Common mora (RIB)",
    "nameFr": "Moro commun (RIB)",
    "targetGroup": "OTHR"
  },
  {
    "id": 184,
    "code": "BXD",
    "nameEn": "Alfonsino (BXD)",
    "nameFr": "B�ryx commun (BXD)",
    "targetGroup": "OTHR"
  },
  {
    "id": 185,
    "code": "BYS",
    "nameEn": "Splendid alfonsino (BYS)",
    "nameFr": "B�ryx long (BYS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 186,
    "code": "SEY",
    "nameEn": "Violet warehou (SEY)",
    "nameFr": "Violet warehou (SEY)",
    "targetGroup": "OTHR"
  },
  {
    "id": 187,
    "code": "GUQ",
    "nameEn": "Leafscale gulper shark (GUQ)",
    "nameFr": "Squale-chagrin de l'Atlantique (GUQ)",
    "targetGroup": "OTHR"
  },
  {
    "id": 188,
    "code": "GUP",
    "nameEn": "Gulper shark (GUP)",
    "nameFr": "Squale-chagrin commun (GUP)",
    "targetGroup": "OTHR"
  },
  {
    "id": 189,
    "code": "MON",
    "nameEn": "Angler(=Monk) (MON)",
    "nameFr": "Baudroie commune (MON)",
    "targetGroup": "OTHR"
  },
  {
    "id": 190,
    "code": "CUS",
    "nameEn": "Pink cusk-eel (CUS)",
    "nameFr": "Abad�che ros� (CUS)",
    "targetGroup": "OTHR"
  },
  {
    "id": 191,
    "code": "ORY",
    "nameEn": "Orange roughy (ORY)",
    "nameFr": "Hoplost�te orange (ORY)",
    "targetGroup": "OTHR"
  },
  {
    "id": 192,
    "code": "DCA",
    "nameEn": "Birdbeak dogfish (DCA)",
    "nameFr": "Squale savate (DCA)",
    "targetGroup": "OTHR"
  },
  {
    "id": 193,
    "code": "SSO",
    "nameEn": "Smooth oreo dory (SSO)",
    "nameFr": "Smooth oreo dory (SSO)",
    "targetGroup": "OTHR"
  },
  {
    "id": 194,
    "code": "BOR",
    "nameEn": "Boarfishes nei (BOR)",
    "nameFr": "Sangliers nca (BOR)",
    "targetGroup": "OTHR"
  },
  {
    "id": 195,
    "code": "TOA",
    "nameEn": "Antarctic toothfish (TOA)",
    "nameFr": "L�gine antarctique (TOA)",
    "targetGroup": "OTHR"
  },
  {
    "id": 196,
    "code": "LBT",
    "nameEn": "Tristan da Cunha rock lobster (LBT)",
    "nameFr": "Langouste de Tristan da Cunha (LBT)",
    "targetGroup": "OTHR"
  },
  {
    "id": 197,
    "code": "YTC",
    "nameEn": "Yellowtail Amberjack (YTC)",
    "nameFr": "Yellowtail Amberjack (YTC)",
    "targetGroup": "OTHR"
  }
]