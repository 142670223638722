import React, { useEffect } from "react"
import { useReferencedata } from "./api"

export const ReferencedataRefresh = () => {
    const rd = useReferencedata()
    
    useEffect(()=>{
        window.setTimeout(()=>{
            rd.refresh()
        }, 2000)
    }, [])

    return null
}