import { useProcessRegistry } from "process/api";
import { JSON } from "process/tasks";
import { onRegisterProps, onResolveProps, taskStatuses } from ".";

export const useResolveTask = () => {
    const registry = useProcessRegistry()

    const self = {
        defaultResolve: async (props:onResolveProps) => {
            const {task} = props
            task.status = taskStatuses.RESOLVED
        },

        resolve: async (props:onResolveProps) => {
            const {task} = props
            if(task.type && registry.tasks()[task.type] && registry.tasks()[task.type].onResolve){
                await registry.tasks()[task.type]?.onResolve(props)
            }else{
                await self.defaultResolve(props)
            }
        }
    }
    return self
}

export const useRegisterTask = () => {
    const registry = useProcessRegistry()

    const self = {
        defaultRegisterFail: async (props:onResolveProps, e:Error) => {
            const {task} = props
            task.status = taskStatuses.REJECTED
            task.result = {message:e?.message || 'error', stack:e.stack || null}
            return
        },

        defaultRegister: async (props:onResolveProps) => {
            const {task} = props
            task.status = taskStatuses.COMPLETED
            return
        },

        register: async (props:onRegisterProps) => {
            const {task} = props
            if(task.type && registry.tasks()[task.type] && registry.tasks()[task.type].onRegister){
                await registry.tasks()[task.type].onRegister(props)
            }else{
                await registry.tasks()[JSON].onRegister(props)
                //await self.defaultRegister(props)
            }
        }
    }
    return self
}

