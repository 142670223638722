import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { PropsWithChildren } from 'react'

export type UserInitialiserProps = PropsWithChildren<{}>

export const UserInitialiser = (props: UserInitialiserProps) => {

    const { children } = props


    const activate = () => null

    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content
}