import { b64toBlob, b64ToFile, useFile } from "file/api"
import { PirFileImageHandler, PirFileImageBase64, PirFileBase64, PirPdfHandler } from "file/model"
import { isImageContentType, pirfileapi } from "file/model/api"
import { generatePdfFromImages } from "pir/Form/Attachment/topdf"
import { Pir, FormPicture, FormAttachmentPicture, FormAttachment, RemoteFile, PirEdting, RemoteFormAttachment } from "pir/model"
import { getAttachmentName, getRemoteFileContentType, isRemoteFormAttachment, pirmodelapi } from "pir/model/api"
import { resizePicture } from "pir/Vessel/useVesselPictures"
import { blobToFile } from "utils"
import { v4 as uuid } from 'uuid'


export const usePirsFilesAndPictures = () => {
    const fileapi = useFile()

    const self = {
        setFormPicture: async (content:File, pir:Pir) => {
            const file = await fileapi.setImageByFileHandler(content, pir)
           
            const picture:FormPicture = {
                author:'',
                type:0,
                uid:file.url
            }
            return picture
        }

        ,

        getFormPictureFile: async (picture:FormPicture) => {
            const file = await fileapi.get(picture.uid)
            return file
        }

        ,

        setFormAttachmentPictures: async (files:File[], pir:Pir) => {
            const pictures = await Promise.all(files.map(async (content) => {
                const picture = await fileapi.setImageByFileHandler(content, pir)
                const file:FormAttachmentPicture = {
                    uid:picture.url
                }
                return file
            }))

            const uid = uuid()
            const attachment:FormAttachment = {
                name: '',
                uid,
                pictures,
                type: 0
            }
            return attachment
        }

        ,

        getFormAttachmentPictureFile: async (picture:FormAttachmentPicture) => {
            const file = await fileapi.get(picture.uid)
            return file as PirFileImageBase64|PirFileImageHandler
        }
        
        ,

        getFormRemoteFile: async (pir:Pir, remoteFile:RemoteFile) => {
            try {
                const file = await fileapi.getOrFetch(pir, remoteFile.downloadLink, getRemoteFileContentType(remoteFile), remoteFile.name)
                if(isImageContentType(file.contentType)){
                    return file as PirFileImageBase64
                }else{
                    return file as PirFileBase64
                }    
            } catch (error) {
                throw error
            }
            
        }

        ,

        getAttachmentPdfFile: async (pir:Pir, attachment:FormAttachment) => {
            if(!attachment || !attachment.pictures){
                return null
            }
            //get all attachment pictures
            const pictures = await Promise.all(attachment.pictures.map(p=>self.getFormAttachmentPictureFile(p)))
            //convert attachment to pdf
            const pdfFile = await generatePdfFromImages(pictures, getAttachmentName(attachment))
            
            const pdf:PirPdfHandler = {
                url: attachment.uid,
                content: pdfFile,
                type: 'pdf',
                date: new Date(),
                filename: pdfFile.name,
                size: pdfFile.size,
                contentType: 'application/pdf',
                pir: pirmodelapi(pir).id()
            }
            await fileapi.set(pdf)
            return pdf
        }

        ,

        getPirExtendedFilesForUpload: async (pir:PirEdting) => {
            
            const pictures:PirFileImageHandler[] = !pir.extended.pictures ? [] : await Promise.all(pir.extended.pictures.map(async p=>{
                const pictureFileHandler = await self.getFormPictureFile(p) as PirFileImageHandler | PirFileImageBase64
                const pictureResizedb64 = await pirfileapi(pictureFileHandler).resize()
                const pictureResized = await b64ToFile(pictureResizedb64, pictureFileHandler.filename, pictureFileHandler.contentType)
                const newPictureFileHandler:PirFileImageHandler = {
                    ...pictureFileHandler,
                    type:"image",
                    size: pictureFileHandler.size || 0,
                    content:pictureResized
                }
                return newPictureFileHandler
            }))

            const generalAttachments = !pir.extended.attachments ? [] : await Promise.all(pir.extended.attachments.map(a=>self.getAttachmentPdfFile(pir, a)).filter(a=>a) as Promise<PirPdfHandler>[])
            const fishingAuthorizationsAttachments = !pir.extended.pir.fishingAuthorizations ? [] : await Promise.all(pir.extended.pir.fishingAuthorizations.map(f=>f.attachment && self.getAttachmentPdfFile(pir, f.attachment)).filter(f=>f!==null) as Promise<PirPdfHandler>[])
            const transhipmentAuthorizationsAttachments = !pir.extended.pir.transhipmentAuthorizations ? [] : await Promise.all(pir.extended.pir.transhipmentAuthorizations.map(f=>f.attachment && self.getAttachmentPdfFile(pir, f.attachment)).filter(f=>f!==null) as Promise<PirPdfHandler>[])
            const monitoredLanTrxAttachments = !pir.extended.pir.monitoredLanTrxAttachments ? [] : await Promise.all(pir.extended.pir.monitoredLanTrxAttachments.filter(a=>!isRemoteFormAttachment(a)).map(a=>self.getAttachmentPdfFile(pir, a as FormAttachment)).filter(a=>a) as Promise<PirPdfHandler>[])
            const attachments = [
                ...generalAttachments,
                ...fishingAuthorizationsAttachments,
                ...transhipmentAuthorizationsAttachments,
                ...monitoredLanTrxAttachments
            ].filter(a=>a)
            return {pictures, attachments}
        }
    }
    return self
}